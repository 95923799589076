<ng-container *ngIf="linkVisual.options.medium === 'vpn'">
  <svg:line
    [ngClass]="linkVisual.options.radio"
    [attr.x1]="linkVisual.source.x"
    [attr.y1]="linkVisual.source.y"
    [attr.x2]="linkVisual.target.x"
    [attr.y2]="linkVisual.target.y"
  ></svg:line>
</ng-container>
<ng-container *ngIf="linkVisual.options.medium !== 'vpn'">
  <ng-container *ngIf="ui !== 'noc' || linkVisual.options.radio === 'ethernet'">
    <svg:line
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [ngClass]="linkVisual.options.radio"
      [attr.x1]="linkVisual.source.x"
      [attr.y1]="linkVisual.source.y"
      [attr.x2]="linkVisual.target.x"
      [attr.y2]="linkVisual.target.y"
    ></svg:line>
    <svg:circle
      *ngIf="linkVisual.source.options.type == 'device'"
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [ngClass]="linkVisual.options.radio"
      [attr.cx]="calculate('sourcex')"
      [attr.cy]="calculate('sourcey')"
      r="5"
    ></svg:circle>
    <svg:circle
      *ngIf="linkVisual.target.options.type == 'device'"
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [ngClass]="linkVisual.options.radio"
      [attr.cx]="calculate('targetx')"
      [attr.cy]="calculate('targety')"
      r="5"
    ></svg:circle>
    <svg:g
      *ngIf="showChannel && linkVisual.options?.metadata?.channel && linkVisual.options.medium == 'wifi'"
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [attr.transform]="calculateChannel()"
    >
      <svg:circle r="12" class="channel" [ngClass]="linkVisual.options.radio"></svg:circle>
      <svg:text x="0" y="4" text-anchor="middle">{{ linkVisual.options.metadata.channel }}</svg:text>
    </svg:g>
  </ng-container>
  <ng-container *ngIf="ui === 'noc' && linkVisual.options.radio !== 'ethernet'">
    <svg:path
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [attr.d]="linkVisual.options.wave"
      [ngClass]="linkVisual.options.radio"
      [ngStyle]="{ stroke: getChannelColor() }"
    ></svg:path>
    <svg:g
      *ngIf="showChannel && linkVisual.options?.metadata?.channel && linkVisual.options.medium == 'wifi'"
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [attr.transform]="calculateChannel()"
    >
      <svg:circle
        r="12"
        [ngClass]="linkVisual.options.radio"
        [ngStyle]="{
          stroke: getChannelColor(),
          fill: 'var(--background-default)',
          animation: getOnboardingEffect()
        }"
      ></svg:circle>
      <svg:text x="0" y="4" text-anchor="middle">
        {{ linkVisual.options.metadata.channel }}
      </svg:text>
    </svg:g>
    <svg:g
      *ngIf="
        showBandwidth &&
        linkVisual.options?.metadata?.parentVapType === 'backhaul' &&
        linkVisual.options.medium == 'wifi'
      "
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [attr.transform]="calculateChannel()"
    >
      <svg:circle r="12" [ngStyle]="{ stroke: getChannelColor(), fill: 'var(--background-default)' }"></svg:circle>
      <svg:text x="0" y="4" text-anchor="middle">
        {{ linkVisual.options.metadata.channelWidth }}
      </svg:text>
    </svg:g>
  </ng-container>
</ng-container>
