import { Directive, Input, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Node } from 'src/app/lib/d3/models/objects/node';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Directive({
  selector: '[selectable]'
})
export class SelectableDirective implements OnInit {
  selected = false;

  @Input()
  nodeVisual: Node;

  @Input()
  barebone: boolean;

  constructor(
    private el: ElementRef,
    private plume: PlumeService,
    private router: Router,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (!this.barebone && this.nodeVisual.options.id.indexOf('vpn') < 0) {
      this.renderer.listen(this.el.nativeElement, 'click', (event: any) => this.click(event));
    }
  }

  click(event: any): void {
    if (this.nodeVisual.options.metadata?.threadNetwork) {
      return;
    }
    if (this.nodeVisual.options.type === 'pod') {
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'nodes',
        this.nodeVisual.id
      ]);
    }

    if (this.nodeVisual.options.type === 'device' && !this.plume.hidePersonalDetails()) {
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'devices',
        this.nodeVisual.id.toLowerCase()
      ]);
    }
  }
}
