<div class="container-fluid">
  <spacer>
    <div class="title">
      <div class="text">{{ 'configurations.title' | translate }}</div>
      <drop-down-search
        *ngIf="(loading$ | async) === false"
        (selected)="selectSearch($event)"
        [items]="searchList"
        [label]="'configurations.search' | translate"
      ></drop-down-search>
      <icon
        *ngIf="(loading$ | async) === false"
        name="icon-refresh"
        tooltip="{{ 'configurations.closeall' | translate }}"
        (click)="closeAll()"
      ></icon>
    </div>
  </spacer>
  <ng-container *ngIf="loading$ | async">
    <div class="loading">{{ 'configurations.loading' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="(loading$ | async) === false">
    <firmware
      [open]="expand.firmware"
      (toggle)="changeToggle('firmware', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></firmware>
    <optimization
      [open]="expand.optimization"
      (toggle)="changeToggle('optimization', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></optimization>
    <smallbusiness
      [open]="expand.smallbusiness"
      (toggle)="changeToggle('smallbusiness', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></smallbusiness>
    <wan
      [open]="expand.wan"
      (toggle)="changeToggle('wan', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></wan>
    <powermanagement
      [open]="expand.powermanagement"
      (toggle)="changeToggle('powermanagement', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></powermanagement>
    <steering
      [open]="expand.steering"
      (toggle)="changeToggle('steering', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></steering>
    <onboarding
      [open]="expand.onboarding"
      (toggle)="changeToggle('onboarding', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></onboarding>
    <capabilities
      [open]="expand.capabilities"
      (toggle)="changeToggle('capabilities', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></capabilities>
    <subscription
      [open]="expand.subscription"
      (toggle)="changeToggle('subscription', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></subscription>
    <ipv6
      [open]="expand.ipv6"
      (toggle)="changeToggle('ipv6', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></ipv6>
    <iptv
      [open]="expand.iptv"
      (toggle)="changeToggle('iptv', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></iptv>
    <privacyandsecurity
      [open]="expand.privacyandsecurity"
      (toggle)="changeToggle('privacyandsecurity', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></privacyandsecurity>
    <multicast
      [open]="expand.multicast"
      (toggle)="changeToggle('multicast', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></multicast>
    <wifiradio
      [open]="expand.wifiradio"
      (toggle)="changeToggle('wifiradio', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></wifiradio>
    <dhcp
      [open]="expand.dhcp"
      (toggle)="changeToggle('dhcp', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></dhcp>
    <dns
      [open]="expand.dns"
      (toggle)="changeToggle('dns', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></dns>
    <utilities
      [open]="expand.utilities"
      (toggle)="changeToggle('utilities', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></utilities>
    <notifications
      [open]="expand.notifications"
      (toggle)="changeToggle('notifications', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></notifications>
    <lte
      [open]="expand.lte"
      (toggle)="changeToggle('lte', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></lte>
    <agentintegrations
      [open]="expand.agentintegrations"
      (toggle)="changeToggle('agentintegrations', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></agentintegrations>
    <threadnetwork
      [open]="expand.threadnetwork"
      (toggle)="changeToggle('threadnetwork', $event)"
      (filter)="addToSearchList($event)"
      (clearFilter)="removeFromSearchList($event)"
    ></threadnetwork>
  </ng-container>
</div>
