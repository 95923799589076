import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  HostListener,
  OnChanges
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {
  open: boolean = false;
  visible: boolean = false;
  positionTop: boolean = false;
  calendar: any = {
    value: '',
    year: '',
    month: '',
    hours: '',
    minutes: '',
    headers: [],
    days: []
  };

  @Output() event = new EventEmitter<any>();

  @Input() startDate: string;

  @ViewChild('popup') popup: ElementRef;
  @ViewChild('hours') hours: ElementRef;
  @ViewChild('minutes') minutes: ElementRef;

  constructor(private elm: ElementRef) {}

  ngOnInit(): void {
    this.initDate();
    this.calculateDays(this.calendar.value);
  }

  ngOnChanges(): void {
    if (this.startDate) {
      this.initDate();
      this.calculateDays(this.calendar.value);
    }
  }

  initDate(): void {
    const date = this.startDate ? moment(this.startDate) : moment();
    this.calendar.value = date.format('L LT');
    this.calendar.year = date.format('YYYY');
    this.calendar.month = date.format('MMMM');
    this.calendar.hours = date.format('HH');
    this.calendar.minutes = date.format('mm');
  }

  changeMonth(mode: string): void {
    const date = moment(this.calendar.value, 'L LT');

    if (mode === 'next') {
      date.add(1, 'months');
    }

    if (mode === 'previous') {
      date.subtract(1, 'months');
    }

    this.calendar.month = date.format('MMMM');
    this.calendar.year = date.format('YYYY');
    this.calendar.value = date.format('L LT');
    this.calculateDays(this.calendar.value);
  }

  calculateDays(now: any): void {
    const firstDayMonth = moment(now, 'L LT').startOf('month');
    const lastDayMonth = moment(now, 'L LT').endOf('month');
    const firstDay = firstDayMonth.clone().startOf('week');
    const lastDay = lastDayMonth.clone().endOf('week');
    const daysToPlot = lastDay.diff(firstDay, 'days') + 1;
    const day = firstDay.clone();
    const calendar = [];

    let count = 0;
    let days = [];

    while (count <= daysToPlot) {
      if (count > 0 && count % 7 === 0) {
        calendar.push(days);
        days = [];
      }

      if (day.isSameOrBefore(lastDay)) {
        days.push({
          number: day.format('D'),
          date: day.format('YYYY-MM-DD'),
          isCurrent: day.isSame(moment(now, 'L LT'), 'day'),
          isThisMonth: day.isBetween(firstDayMonth, lastDayMonth, 'day', '[]')
        });
      }

      day.add(1, 'days');
      count++;
    }

    if (calendar.length) {
      const headers = [];

      calendar[0].forEach((day: any) => {
        headers.push(moment(day.date, 'YYYY-MM-DD').format('dd'));
      });

      this.calendar.headers = headers;
    }

    this.calendar.days = calendar;

    this.event.emit(moment(now, 'L LT').valueOf());
  }

  toggle(mode: string): void {
    if (mode === 'open') {
      if (!moment(this.calendar.value, 'L LT').isValid()) {
        this.initDate();
      }

      this.calculateDays(this.calendar.value);
      this.open = true;

      setTimeout(() => this.decideSide(), 10);
    } else {
      this.open = false;
      this.positionTop = false;
      this.visible = false;
    }
  }

  decideSide(): void {
    const bbox = this.popup.nativeElement.getBoundingClientRect();

    if (bbox.y + bbox.height > window.innerHeight) {
      this.positionTop = true;
    }

    this.visible = true;
  }

  selectDate(data: any): void {
    this.calendar.value = moment(
      data.date + ' ' + this.calendar.hours + ':' + this.calendar.minutes,
      'YYYY-MM-DD HH:mm'
    ).format('L LT');
    this.calculateDays(this.calendar.value);
  }

  selectDateTime(): void {
    this.hours.nativeElement.value = ('00' + this.hours.nativeElement.value).slice(-2);
    this.minutes.nativeElement.value = ('00' + this.minutes.nativeElement.value).slice(-2);
    this.calendar.hours = this.hours.nativeElement.value;
    this.calendar.minutes = this.minutes.nativeElement.value;
    this.calendar.value = moment(
      moment(this.calendar.value, 'L LT').format('YYYY-MM-DD') +
        ' ' +
        this.calendar.hours +
        ':' +
        this.calendar.minutes,
      'YYYY-MM-DD HH:mm'
    ).format('L LT');
    this.event.emit(moment(this.calendar.value, 'L LT').valueOf());
    this.open = false;
  }

  @HostListener('window:click', ['$event.target'])
  onWindowClick(target: Element): void {
    if (this.open && !this.elm.nativeElement.contains(target) && !target.classList.contains('calendar-day')) {
      this.toggle('close');
    }
  }
}
