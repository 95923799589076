import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayRef, ScrollDispatcher } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipContainerComponent } from 'src/app/components/tooltip-container/tooltip-container.component';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnChanges, OnDestroy {
  @Input() tooltip: any;
  @Input() onlyOverflowText = false;
  @Input() hideToolTip = false;
  @Input() maxWidth: number = null;
  @Input() template: TemplateRef<unknown>;
  @Input() noTouchToolTip = false;
  @Input() container: HTMLElement | SVGPathElement = null; // if set then tooltip will be shown on HTML element container and not on this directive html element

  overlayRef: OverlayRef | null;
  touchStarted = false;

  constructor(
    private overlay: Overlay,
    private scrollDispatcher: ScrollDispatcher,
    private viewContainerRef: ViewContainerRef
  ) {}

  /*
    touch element event sequence: touchstart, touchend, mouseenter
    touch same element again event sequence: touchstart, touchend
    touch another element: mouseleave
  */
  @HostListener('touchstart')
  showTouch(): void {
    this.touchStarted = true;
  }

  @HostListener('mouseenter')
  show(): void {
    if (this.noTouchToolTip && this.touchStarted) {
      this.touchStarted = false;
      return;
    }

    this.touchStarted = false;

    if (
      (this.onlyOverflowText &&
        !this.isEllipsisActive(this.container ?? this.viewContainerRef.element.nativeElement)) ||
      (!this.tooltip && !this.template) ||
      this.hideToolTip
    ) {
      return;
    }

    this.createOverlay();
  }

  @HostListener('mouseleave')
  hide(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      delete this.overlayRef;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hideToolTip?.currentValue) {
      this.hide();
    } else if (
      changes.hideToolTip &&
      !changes.hideToolTip.firstChange &&
      (this.container ?? this.viewContainerRef.element.nativeElement).matches(':hover')
    ) {
      this.show();
    }
  }

  private isEllipsisActive(e: HTMLElement): boolean {
    return e.offsetWidth < e.scrollWidth;
  }

  private createOverlay(): void {
    if (this.overlayRef) {
      return;
    }

    const elementRef = this.container ? new ElementRef(this.container) : this.viewContainerRef.element;
    const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(elementRef);
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(elementRef)
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withScrollableContainers(scrollableAncestors);

    this.overlayRef = this.overlay.create({
      positionStrategy: strategy,
      panelClass: [`tooltip`, `noPointerEvents`],
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    const position = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;

    position.withPositions([
      { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -5 },
      { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 20 },
      { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetX: 10 },
      { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: -10 }
    ]);

    const portal = new ComponentPortal(TooltipContainerComponent);
    const overlayRef = this.overlayRef.attach(portal);

    overlayRef.instance.data = this.tooltip;
    overlayRef.instance.maxWidth = this.maxWidth;
    overlayRef.instance.template = this.template;
  }

  ngOnDestroy(): void {
    this.hide();
  }
}
