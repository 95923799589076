import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QoestripComponent } from './qoestrip/qoestrip.component';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { FirebaseService } from 'src/app/lib/services/firebase.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { DeviceIconRefService } from 'src/app/lib/services/deviceIconRef.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { QoethreadService } from 'src/app/lib/webworker/qoethread.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  selectConfigAndState,
  selectPipeLocationOnChange,
  selectPowerManagementState
} from 'src/app/store/customer/customer.selectors';
import { MacAddrPipe } from 'src/app/lib/pipes/mac-addr.pipe';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import {
  selectDevices,
  selectLocationInternet,
  selectLocationQoE,
  selectLocationTopology,
  selectNodes
} from 'src/app/store/polling/polling.selector';
import {
  DeepReadonly,
  HeadersConfig,
  IDevice,
  IPowerState
} from 'src/app/lib/interfaces/interface';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  templateUrl: './qoe.component.html',
  styleUrls: ['./qoe.component.scss']
})
export class QoeComponent implements OnInit, OnDestroy {
  debouncerTimeout: any;
  permissions: any;
  onboarded: string = null;
  nodesResponse: any = null;
  devicesResponse: DeepReadonly<IDevice[]> = null;
  topologyResponse: any = null;
  qoeResponse: any = null;
  qoeMetrics: any = null;
  mode: string = 'nodes';
  itemid: string = null;
  firstScroll: boolean = true;
  firstMode: string = null;
  searchValue = '';
  superLiveMode: any = {
    show: false,
    enabled: false,
    timeout: 14,
    period: 5
  };

  historyDays = 7;
  loading: boolean = false;
  ui: string;

  nodes: any = {
    data: [],
    loading: true
  };

  devices: any = {
    data: [],
    loading: true
  };

  engine: any = {
    badge: {
      nodes: 0,
      devices: 0
    },
    show: false
  };

  live = {
    timeout: 30,
    enabled: false,
    timer: { hours: 0, mins: 0, secs: 0, handler: null, show: false }
  };

  recommendations: any = {
    nodes: {
      headers: null,
      data: null,
      sort: 'connection'
    },
    devices: {
      headers: null,
      data: null,
      sort: 'connection'
    }
  };
  enableQoE5gReports: boolean = false;
  qoe5gconfigAndState: boolean = false;
  demoModes: any[] = [
    { mode: 'normal', translation: 'qoe.demo.normal', values: {} },
    { mode: 'snr', translation: 'qoe.demo.lowSnr', values: {} },
    { mode: 'phyrateeff', translation: 'qoe.demo.lowPhyRateEff', values: {} },
    { mode: 'interference', translation: 'qoe.demo.highInterference', values: {} },
    { mode: 'prr', translation: 'qoe.demo.highPrr', values: {} },
    { mode: 'utilization', translation: 'qoe.demo.highUtilization', values: {} }
  ];

  powerState: IPowerState = null;

  demo = {
    deviceMap: [] as {
      text: string;
      value: string;
    }[],
    modesMap: this.demoModes.map((mode: any) => ({ text: mode.translation, value: mode.mode })),
    current: null
  };

  lteMetricsSupported$ = this.store.select(selectCapabilities).pipe(
    filter((capabilities) => !!capabilities),
    map((capabilities) => capabilities.lte.capable && this.plume.cloudVersionAbove1_92())
  );

  lteSuperLiveMetricsSupported$ = this.lteMetricsSupported$.pipe(
    map((supported) => supported && this.plume.cloudVersionAbove1_98())
  );

  wanSupported$ = this.getWanSupported$();

  @ViewChildren(QoestripComponent) qoestrip: QueryList<QoestripComponent>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private firebase: FirebaseService,
    private qoe: QoeService,
    private iconRef: DeviceIconRefService,
    private modelRef: ModelRefService,
    private thread: QoethreadService,
    private toast: ToastService,
    private theme: ThemeService,
    private pagescroll: PageScrollService,
    private store: Store,
    private macAddrPipe: MacAddrPipe
  ) {
    this.store
      .select(selectConfigAndState)
      .pipe(untilDestroyed(this))
      .subscribe((configAndState) => {
        this.qoe5gconfigAndState = configAndState.state.capabilities?.cellularStatistics;
      });

    combineLatest([this.plume.permissions.pipe(take(1)), this.route.params]).subscribe(([permissions, params]) => {
      if (params) {
        if (params.mode) {
          if (params.mode === 'nodes') {
            this.mixpanel.storeEvent('QOE_SCREEN_NODES');
            this.mode = 'nodes';
          } else if (params.mode === 'devices') {
            this.mixpanel.storeEvent('QOE_SCREEN_DEVICES');
            this.mode = 'devices';
          } else if (params.mode === 'wan') {
            this.mixpanel.storeEvent('QOE_SCREEN_WAN');
            this.mode = 'wan';
          } else if (params.mode === 'lte') {
            this.mixpanel.storeEvent('QOE_SCREEN_LTE');
            this.mode = 'lte';
          } else if (params.mode === '5g') {
            this.mixpanel.storeEvent('QOE_SCREEN_5G');
            this.mode = '5g';
          } else if (params.mode === 'summary' && permissions.qoeSummary) {
            this.mixpanel.storeEvent('QOE_SCREEN_SUMMARY');
            this.mode = 'summary';
          } else {
            if (permissions.qoeSummary) {
              this.mixpanel.storeEvent('QOE_SCREEN_SUMMARY');
              this.mode = 'summary';
            } else {
              this.mixpanel.storeEvent('QOE_SCREEN_NODES');
              this.mode = 'nodes';
            }
          }
        }

        if (params.id) {
          this.itemid = params.id;
        }

        this.changeModeIfNotSupported();
      }
    });
  }

  ngOnInit(): void {
    this.initHeaders();
    this.checkLiveMode();
    this.initTogglers();
    this.thread.start();
    this.ui = this.plume.getUI();

    this.nodesResponse = null;
    this.devicesResponse = null;
    this.topologyResponse = null;
    this.qoeResponse = null;
    this.qoeMetrics = null;
    this.plume.permissions.pipe(untilDestroyed(this)).subscribe((data: any) => {
      this.permissions = data;

    });
    this.store
      .select(selectPowerManagementState)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.powerState = data.state;
      });

    this.store
      .select(selectLocationInternet)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        if (response) {
          this.isOnboarded(response);
        }
      });

    this.store
      .select(selectNodes)
      .pipe(untilDestroyed(this))
      .subscribe((nodes) => {
        if (nodes) {
          this.nodesResponse = nodes;
          this.debounce(() => this.prepare(), 500);
        }
      });

    this.store
      .select(selectDevices)
      .pipe(untilDestroyed(this))
      .subscribe((devices) => {
        if (devices) {
          this.devicesResponse = devices;
          this.prepareDevices();
          this.debounce(() => this.prepare(), 500);
        }
      });

    this.store
      .select(selectLocationTopology)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        if (response) {
          this.topologyResponse = response;
          this.debounce(() => this.prepare(), 500);
        }
      });

    this.store
      .select(selectLocationQoE)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        if (response) {
          this.qoeResponse = response;
          this.debounce(() => this.prepare(), 500);
        }
      });

    this.theme.listener.pipe(untilDestroyed(this)).subscribe(() => {
      this.prepare();
    });

    this.enableQoE5gReports = this.plume.getPermissions().uiFeatures.qoe5gReports;
  }

  initTogglers(): void {
    this.superLiveMode.periods = [
      {
        value: '1',
        translation: '1s',
        selected: false
      },
      {
        value: '2',
        translation: '2s',
        selected: false
      },
      {
        value: '5',
        translation: '5s',
        selected: true
      },
      {
        value: '10',
        translation: '10s',
        selected: false
      }
    ];

    this.superLiveMode.statuses = [
      {
        value: true,
        translation: 'Start',
        selected: false
      },
      {
        value: false,
        translation: 'Stop',
        selected: true
      }
    ];
  }

  changeModeIfNotSupported(): void {
    if (this.mode === 'wan') {
      this.wanSupported$.pipe(take(1)).subscribe(
        (supported) => {
          if (!supported) {
            this.handle('nodes');
          }
        },
        () => {
          this.handle('nodes');
        }
      );
      return;
    }
    if (this.mode === 'lte') {
      this.lteMetricsSupported$.pipe(take(1)).subscribe(
        (supported) => {
          if (!supported) {
            this.handle('nodes');
          }
        },
        () => {
          this.handle('nodes');
        }
      );
      return;
    }
  }

  getWanSupported$(): Observable<boolean> {
    return combineLatest([this.plume.permissions, this.store.pipe(selectPipeLocationOnChange)]).pipe(
      filter(([permissions, location]) => !!permissions && !!location),
      untilDestroyed(this),
      map(
        ([permissions, location]) =>
          permissions?.uiFeatures.qoeWanMode &&
          (location?.flowCollection?.interfaceStats ||
            (!location?.flowCollection && location?.flowCollectionConfig?.interfaceStats === 'enable'))
      )
    );
  }

  action(command: string, action: string): void {
    switch (command) {
      case 'superlivePeriod':
        this.superLiveMode.period = action;

        if (this.superLiveMode.enabled) {
          this.toggleSuperLiveMode(true);
        }

        break;
    }
  }

  debounce(fn: any, delay: number): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, delay);
  }

  handle(mode: string, event: MouseEvent & { qoeIgnore?: boolean } = null): void {
    if (event?.qoeIgnore) {
      return;
    }

    if (mode === 'wan' || mode === 'lte' || mode === '5g') {
      this.loading = false;
    } else {
      this.loading = true;
    }

    switch (mode) {
      case 'nodes':
      case 'devices':
      case 'lte':
      case 'wan':
      case 'summary':
      case '5g':
        if (!this.engine.show) {
          this.mode = mode;
          this.router.navigate(
            ['customer', this.plume.customerid, 'location', this.plume.locationid, 'qoe', this.mode],
            { replaceUrl: true }
          );

          this.mixpanel.storeEvent('QOE_CHANGE_MODE', {
            QOE_MODE: this.live.enabled ? 'live' : '15min',
            QOE_TYPE: this.mode
          });

          if (mode !== 'wan' && mode !== 'lte' && mode !== '5g') {
            setTimeout(() => (this.loading = false), 350);

            if (mode !== 'summary') {
              this.changeSort(this[this.mode], this.live.enabled ? 'live' : 'min15');
              this.prepareSuperLive();
            }
          }
        }

        break;
      case 'engine':
        this.engine.show = !this.engine.show;
        this.mixpanel.storeEvent('QOE_RECOMMENDATION_ENGINE', {
          ENABLED: this.engine.show,
          RECOMMENDATIONS_COUNT: this.engine.badge.nodes + this.engine.badge.devices
        });

        if (!this.live.enabled) {
          this.enableLiveMode();
        } else {
          setTimeout(() => (this.loading = false), 350);
        }

        break;
      case 'live':
        this.enableLiveMode();
        break;
    }
  }

  openSuperLiveMode(): void {
    this.engine.show = false;
    this.superLiveMode.show = true;
    this.toggleSuperLiveMode(true);
  }

  closeSuperLiveMode(): void {
    this.superLiveMode.show = false;
    this.unsubscribeTimer();
    this.live.enabled = false;
    this.engine.show = false;
    this.mixpanel.storeEvent('QOE_SUPER_LIVE_MODE_CLOSE');
    this.toggleSuperLiveMode(false);
  }

  countdown(date: string): void {
    this.unsubscribeTimer();

    this.live.timer.handler = setInterval(() => {
      const diff = new Date(date).getTime() - Date.now();

      if (diff >= 0) {
        this.live.timer = {
          handler: this.live.timer.handler,
          hours: Math.floor(diff / (60 * 60 * 1000)),
          mins: Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000)),
          secs: Math.floor((diff % (60 * 1000)) / 1000),
          show: true
        };
      } else {
        this.unsubscribeTimer();

        if (this.live.enabled) {
          this.live.enabled = false;
          this.mixpanel.storeEvent('QOE_LIVE_MODE', { ENABLED: false });
        }

        if (this.superLiveMode.enabled) {
          this.superLiveMode.enabled = false;
          this.mixpanel.storeEvent('QOE_SUPER_LIVE_MODE', { ENABLED: false });
        }

        if (this.engine.show) {
          this.engine.show = false;
        }
      }
    }, 1000);
  }

  increaseLiveModeDuration(hours: number): void {
    const expire = new Date(
      Date.now() +
        (this.live.timer.hours + hours) * 60 * 60 * 1000 +
        this.live.timer.mins * 60 * 1000 +
        this.live.timer.secs * 1000
    );

    this.qoe.setLiveModeState$(true, expire.toISOString()).subscribe(() => {
      this.mixpanel.storeEvent('QOE_LIVE_MODE_ADD_1_HOUR', { EXPIRES_AT: expire.toISOString() });
      this.unsubscribeTimer();
      this.countdown(expire.toISOString());

      if (!this.live.enabled) {
        this.enableLiveMode();
      }
    });
  }

  cancelLiveMode(): void {
    this.qoe.setLiveModeState$(false).subscribe(() => {
      this.mixpanel.storeEvent('QOE_LIVE_MODE_CANCEL');

      this.unsubscribeTimer();
      this.countdown('1970');

      if (this.live.enabled) {
        this.enableLiveMode();
      }

      this.live.timer = {
        handler: this.live.timer.handler,
        hours: 0,
        mins: 0,
        secs: 0,
        show: this.live.timer.show
      };
    });
  }

  checkLPMMode(): void {
    this.store
      .select(selectCapabilities)
      .pipe(take(1))
      .subscribe((capabilities) => {
        if (!capabilities) {
          setTimeout(() => {
            this.checkLPMMode();
          }, 200);
        } else {
          if (
            capabilities?.powerManagement.capable &&
            this.permissions?.uiFeatures?.powerManagement &&
            this.powerState?.status !== 'PM_STATUS_INACTIVE' &&
            this.powerState?.status !== 'PM_STATUS_UNKNOWN'
          ) {
            this.toast.warning('toast.qoeLowPower.message', 'toast.qoeLowPower.title', {
              disableTimeOut: false
            });
          }
        }
      });
  }

  enableLiveMode(): void {
    if (!this.live.enabled) {
      this.checkLPMMode();
    }

    if (!this.live.enabled && !this.live.timer.handler) {
      const expire = new Date(Date.now() + this.live.timeout * 60 * 1000).toISOString();

      this.qoe.setLiveModeState$(true, expire).subscribe(
        () => {
          this.mixpanel.storeEvent('QOE_LIVE_MODE', { ENABLED: true });
          this.live.enabled = true;
          this.countdown(expire);

          setTimeout(() => (this.loading = false), 350);

          if (this.mode !== 'wan' && this.mode !== 'lte') {
            this.changeSort(this[this.mode], this.live.enabled ? 'live' : 'min15');
          }
        },
        () => {
          this.toast.error('qoe.enableLiveError', 'qoe.enableLiveErrorTitle');
          this.mixpanel.storeEvent('QOE_LIVE_MODE_FAILED');
          setTimeout(() => (this.loading = false), 350);
        }
      );
    } else {
      this.live.enabled = !this.live.enabled;

      setTimeout(() => (this.loading = false), 350);

      this.mixpanel.storeEvent('QOE_CHANGE_MODE', {
        QOE_MODE: this.live.enabled ? 'live' : '15min',
        QOE_TYPE: this.mode
      });

      if (!this.live.enabled) {
        this.engine.show = false;
      }

      if (this.mode !== 'wan' && this.mode !== 'lte') {
        this.changeSort(this[this.mode], this.live.enabled ? 'live' : 'min15');
      }
    }
  }

  toggleSuperLiveMode(enabled?: boolean): void {
    const status = enabled !== undefined ? enabled : !this.superLiveMode.enabled;

    if (status) {
      this.checkLPMMode();
    }

    this.superLiveMode.enabled = status;

    const expire = new Date(Date.now() + this.superLiveMode.timeout * 60 * 1000);

    this.unsubscribeTimer();

    this.qoe.setLiveModeState$(status, expire.toISOString(), Number(this.superLiveMode.period)).subscribe(() => {
      this.mixpanel.storeEvent('QOE_SUPER_LIVE_MODE', { ENABLED: status });

      if (status) {
        this.countdown(expire.toISOString());
      }
    });
  }

  checkLiveMode(): void {
    this.qoe.getLiveModeState$().subscribe(
      (response) => {
        this.live.enabled = response.enable;
        this.superLiveMode.enabled = response.enable && response.reportingInterval < 60;

        if (this.live.enabled || this.superLiveMode.enabled) {
          this.checkLPMMode();
        }

        if (this.superLiveMode.enabled) {
          this.superLiveMode.period = response.reportingInterval;
          this.superLiveMode.show = this.superLiveMode.enabled;
          this.superLiveMode.periods = this.superLiveMode.periods.map((data: any) => ({
            ...data,
            selected: +data.value === +this.superLiveMode.period
          }));

          this.handle('nodes');
        }
        if (this.live.enabled) {
          this.countdown(response.expiresAt);
        }
      },
      () => {
        this.live.enabled = false;
        this.superLiveMode.enabled = false;
        this.superLiveMode.show = false;
      }
    );
  }

  prepareQoeMetricsRequests(limit: number, requests: any[], nodes: string[], devices: string[]): any {
    let tempNodes = [];
    let tempDevices = [];
    let params = {};
    if (nodes.length >= limit) {
      tempNodes = nodes.splice(limit);
      tempDevices = devices;

      params = {
        nodeIds: nodes,
        deviceIds: []
      };
    } else {
      if (nodes.length + devices.length >= limit) {
        tempNodes = nodes.splice(limit);
        tempDevices = devices.splice(limit - nodes.length);
        params = {
          nodeIds: nodes,
          deviceIds: devices
        };
      } else {
        tempNodes = nodes.splice(limit);
        tempDevices = devices.splice(limit);
        params = {
          nodeIds: nodes,
          deviceIds: devices
        };
      }
    }

    requests.push(this.qoe.metrics$(params));

    if (tempNodes.length || tempDevices.length) {
      return this.prepareQoeMetricsRequests(limit, requests, tempNodes, tempDevices);
    } else {
      return requests;
    }
  }

  prepareQoeMetrics(): void {
    if (this.nodesResponse && this.devicesResponse && !this.qoeMetrics) {
      const nodes = this.nodesResponse.map((node: any) => node.id);
      const devices = this.devicesResponse.map((device: any) => device.mac);

      const requests = this.prepareQoeMetricsRequests(30, [], nodes, devices);
      forkJoin(requests).subscribe((responses: any) => {
        const qoeMetrics = {};
        responses.forEach((response: any) => {
          response.nodes.forEach((qoe: any) => {
            qoeMetrics[qoe.nodeId] = {
              weightedQoeScore: qoe.weightedQoeScore,
              busyRatio: qoe.busyRatio,
              lpmRatio: qoe.lpmRatio
            };
          });

          response.devices.forEach((qoe: any) => {
            qoeMetrics[qoe.mac] = {
              weightedQoeScore: qoe.weightedQoeScore,
              busyRatio: qoe.busyRatio,
              lpmRatio: qoe.lpmRatio
            };
          });
        });
        this.qoeMetrics = qoeMetrics;
        this.prepare();
      });
    }
  }

  isOnboarded(response: any): void {
    try {
      const permissions = this.plume.getPermissions();

      if (permissions.uiFeatures.overrideOnboarded) {
        this.onboarded = 'complete';
      } else {
        if (['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus)) {
          this.onboarded = 'complete';
        } else {
          this.onboarded = 'uncomplete';
        }
      }
    } catch (err) {
      this.onboarded = 'uncomplete';
    }
  }

  prepare(): void {
    this.prepareNodes();
    this.prepareDevices();
    this.prepareDemo();
    this.prepareSuperLive();
    this.prepareQoeMetrics();
  }

  prepareNodes(): void {
    const nodes = [];
    const recommendations = [];
    this.engine.badge.nodes = 0;

    if (this.nodesResponse && this.topologyResponse && this.qoeResponse && this.qoeMetrics) {
      this.topologyResponse.vertices.forEach((vertice: any) => {
        if (vertice.type === 'pod') {
          const edge = this.topologyResponse.edges.find((edge: any) => edge.target === vertice.id);
          const qoe = this.qoeResponse.nodes.find((qoe: any) => qoe.id === vertice.id) || null;

          let connection = 'qoe.ethernet';
          let channel = null;

          if (edge && edge.medium === 'wifi' && edge.metadata) {
            connection = edge.metadata.freqBand;
            channel = edge.metadata.channel;
          }
          const old = this.nodes.data.find((node: any) => node.id === vertice.id);
          const raw = this.nodesResponse.find((node: any) => node.id === vertice.id);
          const pod = this.modelRef.get(vertice.metadata.model);
          const object: any = {
            loading: old ? old.loading : true,
            icon: pod.icon,
            type: vertice.type,
            id: vertice.id,
            name: raw ? raw.nickname || raw.defaultName : '',
            connection,
            channel,
            status: vertice.connectionState,
            liveMode: this.live.enabled,
            liveData: old ? old.liveData : null,
            liveCharts: old ? old.liveCharts : null,
            chartMode: old ? old.chartMode : '24h',
            charts: old ? old.charts : null,
            updated15minAt: old ? old.updated15minAt : null,
            qoeMetrics: this.qoeMetrics[vertice.id] || null,
            connectionStateMsg: this.qoeMsg(raw),
            connectionStateChangeAt:
              raw && raw.connectionStateChangeAt ? moment.utc(raw.connectionStateChangeAt).local().format('L LT') : '',
            connectionChangeAt: raw && raw.connectionStateChangeAt ? raw.connectionStateChangeAt : '',
            expand: old ? old.expand : false
          };

          if (this.itemid && this.firstScroll && object.id === this.itemid) {
            object.expand = true;
            this.firstMode = 'nodes';
          }

          if (qoe) {
            object.rawQoe = qoe;
            object.qoe = {
              ...object,
              ...this.qoe.prepare(qoe)
            };

            object.recommendations = this.prepareRecommendations(object, this.firebase.snapshot.rules.nodes);
          } else {
            object.rawQoe = null;
            object.qoe = {
              ...object,
              ...this.qoe.prepare()
            };

            object.recommendations = [];
          }

          if (this.permissions.uiFeatures && this.permissions.uiFeatures.demoModeOption) {
            if (old && old.demo) {
              object.demo = old.demo;

              if (object.rawQoe) {
                this.adjustDemoValues(object);
              }
            } else {
              object.demo = {
                current: this.demoModes[0]
              };
            }
          }

          if (object.recommendations.length) {
            recommendations.push(object);
          }

          nodes.push(object);
        }
      });

      this.recommendations.nodes.data = recommendations;
      this.engine.badge.nodes = recommendations.length;
      this.nodes.data = nodes;
      this.nodes.loading = false;
      this.changeSort(this.nodes, this.live.enabled ? 'live' : 'min15');
    } else {
      if (this.nodes.loading) {
        setTimeout(() => (this.nodes.loading = false), 5000);
      }
    }
  }

  private qoeMsg(node: any): string {
    if (node.connectionState === 'unavailable' || !node.connectionStateChangeAt) {
      return 'nodes.node.unknownConnectionInformation';
    } else {
      return node.connectionState === 'connected' ? 'qoe.onlineSince' : 'qoe.offlineSince';
    }
  }

  prepareDevices(): void {
    const devices = [];
    const recommendations = [];

    this.engine.badge.devices = 0;

    if (this.devicesResponse && this.topologyResponse && this.qoeResponse && this.qoeMetrics) {
      this.devicesResponse.forEach((device) => {
        const old = this.devices.data.find((d: any) => d.id === device.mac);
        const qoe = this.qoeResponse.devices.find((qoe: any) => qoe.mac === device.mac) || null;
        const vertice = this.topologyResponse.vertices.find(
          (vertice: any) => vertice.type === 'device' && vertice.id === device.mac
        );

        let connection;
        let channel = device.channel;

        if (vertice && vertice.connectionState === 'connected') {
          const edge = this.topologyResponse.edges.find((edge: any) => edge.target === device.mac);

          if (edge && edge.medium === 'wifi' && edge.metadata) {
            connection = edge.metadata.freqBand;
            channel = edge.metadata.channel;
          } else {
            connection = 'qoe.ethernet';
            channel = null;
          }
        }

        const object: any = {
          loading: old ? old.loading : true,
          icon: this.iconRef.get(device.icon) + '.svg',
          type: 'device',
          id: device.mac,
          name: device.nickname || device.name,
          connection,
          channel: device.channel,
          status: device.connectionState,
          liveMode: this.live.enabled,
          liveData: old ? old.liveData : null,
          liveCharts: old ? old.liveCharts : null,
          freqBand: device.freqBand,
          parentNodeId: device.leafToRoot && device.leafToRoot.length ? device.leafToRoot[0].id : '',
          radio24: device.capabilities && 'radio24' in device.capabilities ? device.capabilities.radio24 : null,
          radio50: device.capabilities && 'radio50' in device.capabilities ? device.capabilities.radio50 : null,
          chartMode: old ? old.chartMode : '24h',
          charts: old ? old.charts : null,
          updated15minAt: old ? old.updated15minAt : null,
          qoeMetrics: this.qoeMetrics[device.mac] || null,
          connectionStateMsg: device.connectionState === 'connected' ? 'qoe.onlineSince' : 'qoe.offlineSince',
          connectionStateChangeAt: moment.utc(device.connectionStateChangeAt).local().format('L LT'),
          connectionChangeAt: device.connectionStateChangeAt,
          expand: old ? old.expand : false
        };

        if (this.itemid && this.firstScroll && object.id === this.itemid) {
          object.expand = true;
          this.firstMode = 'devices';
        }

        if (device.kind?.type?.iconV2 || device.iconV2) {
          object.iconV2 = device.kind?.type?.iconV2 || device.iconV2;
        }

        if (device.iconV3) {
          object.iconV3 = device.iconV3;
        }

        if (qoe) {
          object.rawQoe = qoe;
          object.qoe = {
            ...object,
            ...this.qoe.prepare(qoe)
          };

          object.recommendations = this.prepareRecommendations(object, this.firebase.snapshot.rules.devices);
        } else {
          object.rawQoe = null;
          object.qoe = {
            ...object,
            ...this.qoe.prepare()
          };

          object.recommendations = [];
        }

        if (this.permissions.uiFeatures && this.permissions.uiFeatures.demoModeOption) {
          if (old && old.demo) {
            object.demo = old.demo;

            if (object.rawQoe) {
              this.adjustDemoValues(object);
            }
          } else {
            object.demo = {
              current: this.demoModes[0]
            };
          }
        }

        if (object.recommendations.length) {
          recommendations.push(object);
        }

        object.privateName = this.macAddrPipe.transform(object.name, true);
        object.privateId = this.macAddrPipe.transform(object.id);
        devices.push(object);
      });

      this.recommendations.devices.data = recommendations;
      this.engine.badge.devices = recommendations.length;
      this.devices.data = devices;
      this.devices.loading = false;

      this.changeSort(this.devices, this.live.enabled ? 'live' : 'min15');
    } else {
      if (this.devices.loading) {
        setTimeout(() => (this.devices.loading = false), 5000);
      }
    }
  }

  prepareRecommendations(data: any, rules: any): any[] {
    const recommendations = [];

    if (data.status === 'connected' && data.connection !== 'ethernet' && data.qoe) {
      const r = JSON.parse(JSON.stringify(rules));

      r.map((rule: any) => (rule.result = this.ruleset(data.qoe, rule.ruleToEval)));
      r.forEach((rule: any) => {
        if (rule.result) {
          let dependancies = true;

          if (rule.ruleDependancies && rule.ruleDependancies.length) {
            rule.ruleDependancies.forEach((ruleId: number) => {
              if (!r[ruleId - 1].result) {
                dependancies = false;
              }
            });
          }

          if (dependancies) {
            let squelched = false;
            if (rule.ruleSquelchedBy && rule.ruleSquelchedBy.length) {
              rule.ruleSquelchedBy.forEach((ruleId: number) => {
                if (r[ruleId - 1] && r[ruleId - 1].result) {
                  squelched = true;
                }
              });
            }

            if (!squelched) {
              recommendations.push({
                icon: 'assets/icons/icon-alert-hollow.svg',
                description: rule.recommendationText,
                actions: rule.recommendationActions ? rule.recommendationActions : []
              });
            }
          }
        }
      });
    }

    return recommendations;
  }

  ruleset(data: any, rules: any): any {
    if (rules.length === 3) {
      rules.forEach((rule: any, index: number) => {
        if (rule instanceof Array) {
          rules[index] = this.ruleset(data, rule);
        }
      });

      if (typeof rules[0] === 'string') {
        if (rules[0] in data) {
          rules[0] = data[rules[0]];
        }
      }

      if (typeof rules[2] === 'string') {
        if (rules[2] in data) {
          rules[2] = data[rules[2]];
        }
      }

      const operators = {
        '+': (a: any, b: any) => a + b,
        '-': (a: any, b: any) => a - b,
        '*': (a: any, b: any) => a * b,
        '/': (a: any, b: any) => a / b,
        '>': (a: any, b: any) => a > b,
        '<': (a: any, b: any) => a < b,
        '==': (a: any, b: any) => a === b,
        '!=': (a: any, b: any) => a !== b,
        '>=': (a: any, b: any) => a >= b,
        '<=': (a: any, b: any) => a <= b
      };

      return operators[rules[1]](rules[0], rules[2]);
    }
  }

  eventAddIgnore(event: MouseEvent & { qoeIgnore?: boolean }): void {
    event.qoeIgnore = true;
  }

  initHeaders(): void {
    const live = this.getLiveHeaders();
    const min15 = this.getMin15Headers();
    this.nodes.live = JSON.parse(JSON.stringify(live));
    this.nodes.min15 = JSON.parse(JSON.stringify(min15));
    this.nodes.data = [];
    this.nodes.loading = true;

    this.devices.live = JSON.parse(JSON.stringify(live));
    this.devices.min15 = JSON.parse(JSON.stringify(min15));
    this.devices.data = [];
    this.devices.loading = true;

    this.recommendations.nodes.live = JSON.parse(JSON.stringify(live));
    this.recommendations.nodes.data = [];

    this.recommendations.devices.live = JSON.parse(JSON.stringify(live));
    this.recommendations.devices.data = [];
  }

  getLiveHeaders(): HeadersConfig {
    return {
      sort: 'connection',
      headers: [
        { key: 'space', value: '', desc: false },
        { key: 'name', value: 'qoe.headers.name', desc: false },
        { key: 'connection', value: 'qoe.headers.connection', desc: false },
        { key: 'busy', value: 'qoe.headers.busy', desc: false },
        { key: 'qoe', value: 'qoe.headers.qoe', desc: false },
        { key: 'rssi', value: 'qoe.headers.rssi', desc: false },
        { key: 'phyRateEff', value: 'qoe.headers.effPhyRate', desc: false },
        { key: 'congestion', value: 'qoe.headers.congestion', desc: false },
        { key: 'potentialThroughput', value: 'qoe.headers.predThroughput', desc: false }
      ]
    };
  }

  getMin15Headers(): HeadersConfig {
    return {
      sort: 'name',
      headers: [
        { key: 'space', value: '', desc: false },
        { key: 'name', value: 'qoe.headers.name', desc: false },
        { key: 'connectionState', value: 'qoe.headers.connection', desc: false }
      ]
    };
  }

  changeSort(collection: any, mode: string, column: string = null): void {
    const online = [];
    const offline = [];

    if (collection[mode]) {
      collection.data.forEach((item: any) => {
        if (item.status === 'connected') {
          online.push(item);
        } else {
          offline.push(item);
        }
      });

      if (column) {
        if (collection[mode].sort !== column) {
          collection[mode].sort = column;
        } else {
          for (const header of collection[mode].headers) {
            if (header.key === column) {
              header.desc = !header.desc;
            }
          }
        }
      }

      for (const header of collection[mode]?.headers ?? []) {
        const ascModifier = header.desc ? -1 : 1;

        if (header.key === collection[mode].sort) {
          if (header.key === 'connection') {
            this.sortArraysByConnection(online, offline, ascModifier);
          } else if (header.key === 'connectionState') {
            this.sortArraysByConnectionState(online, offline, ascModifier);
          } else {
            online.sort((a: any, b: any) => {
              return this.tryLowerCase(a[header.key]) === this.tryLowerCase(b[header.key])
                ? 0
                : this.tryLowerCase(a[header.key]) > this.tryLowerCase(b[header.key])
                ? 1 * ascModifier
                : -1 * ascModifier;
            });

            offline.sort((a: any, b: any) => {
              return this.tryLowerCase(a[header.key]) === this.tryLowerCase(b[header.key])
                ? 0
                : this.tryLowerCase(a[header.key]) > this.tryLowerCase(b[header.key])
                ? 1 * ascModifier
                : -1 * ascModifier;
            });
          }
        }
      }

      collection.data = [...online, ...offline];

      if (collection.data.length && this.itemid && this.firstScroll && this.mode === this.firstMode) {
        this.firstScroll = false;
        this.firstMode = null;
        this.scrollTo('#' + this.itemid, mode);
      }
    }
  }

  private tryLowerCase(possiblyString: any): any {
    if (typeof possiblyString === 'string') {
      return possiblyString.toLowerCase();
    }

    return possiblyString;
  }

  private sortArraysByConnection(online: any[], offline: any[], ascModifier: 1 | -1): void {
    online.sort((a: any, b: any) => {
      const ethernetSort = this.sortByEthernet(a, b);

      if (ethernetSort !== 0) {
        return ethernetSort * ascModifier;
      }

      if (a.connection !== b.connection) {
        return a.connection > b.connection ? 1 * ascModifier : -1 * ascModifier;
      }

      return this.sortByName(a, b) * ascModifier;
    });

    offline.sort((a: any, b: any) => {
      const lastConnectedSort = this.sortByLastConnectedIn24Hr(a, b);

      if (lastConnectedSort !== 0) {
        return lastConnectedSort * ascModifier;
      }

      return this.sortByName(a, b) * ascModifier;
    });
  }

  private sortArraysByConnectionState(online: any[], offline: any[], ascModifier: 1 | -1): void {
    online.sort((a: any, b: any) => {
      const ethernetSort = this.sortByEthernet(a, b);

      if (ethernetSort !== 0) {
        return ethernetSort * ascModifier;
      }

      return this.sortByName(a, b) * ascModifier;
    });

    offline.sort((a: any, b: any) => {
      const lastConnectedSort = this.sortByLastConnectedIn24Hr(a, b);

      if (lastConnectedSort !== 0) {
        return lastConnectedSort * ascModifier;
      }

      return this.sortByName(a, b) * ascModifier;
    });
  }

  private sortByName(a: any, b: any): number {
    return a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  }

  // Sort by connectionStateChangedAt
  private sortByLastConnectedIn24Hr(a: any, b: any): number {
    const aLast24hr = moment(a.connectionChangeAt);
    const bLast24hr = moment(b.connectionChangeAt);

    return bLast24hr.valueOf() - aLast24hr.valueOf();
  }

  // ethernet last
  private sortByEthernet(a: any, b: any): number {
    const aEthernet = a.connection === 'qoe.ethernet';
    const bEthernet = b.connection === 'qoe.ethernet';

    if (aEthernet && !bEthernet) {
      return 1;
    }

    if (!aEthernet && bEthernet) {
      return -1;
    }

    return 0;
  }

  scrollTo(selector: string, mode: string): void {
    setTimeout(
      () => {
        const view = document.getElementById('customer-view');

        this.pagescroll.scroll({
          document: window.document,
          scrollTarget: selector,
          scrollViews: [view],
          scrollOffset: 10
        });
      },
      mode === 'live' ? 500 : 200
    );
  }

  track(index: number, item: any): any {
    return item.id;
  }

  prepareDemo(): void {
    if (this.permissions.uiFeatures && this.permissions.uiFeatures.demoModeOption) {
      const devices = [...this.nodes.data, ...this.devices.data];

      this.demo.deviceMap = devices.map((device) => ({
        text: this.macAddrPipe.transform(device.name, true),
        value: device.id
      }));

      if (!this.demo.current && this.demo.deviceMap?.length > 0) {
        this.demo.current = devices[0];
        this.demoSelectDevice(this.demo.current.id);
      }
    }
  }

  prepareSuperLive(): void {
    if (this.permissions?.uiFeatures) {
      if (this.mode === 'nodes') {
        const nodes = [...this.nodes.data];

        if (!nodes.length) {
          this.superLiveMode.current = null;
          this.superLiveMode.list = [];
          return;
        }

        this.superLiveMode.current =
          !this.superLiveMode.current || !this.nodeInList(nodes) ? nodes[0] : this.nodeInList(nodes);

        this.superLiveMode.list = nodes
          .filter((node: any) => node && this.superLiveMode.current && node.id !== this.superLiveMode.current?.id)
          .map((node: any) => {
            return { text: node.name, value: node.id, object: node };
          });
      } else {
        const devices = [...this.devices.data];

        if (!devices.length) {
          this.superLiveMode.current = null;
          this.superLiveMode.list = [];
          return;
        }

        this.superLiveMode.current =
          !this.superLiveMode.current || !this.deviceInList(devices) ? devices[0] : this.deviceInList(devices);

        this.superLiveMode.list = devices.map((device: any) => {
          return { text: this.macAddrPipe.transform(device.name, true), value: device.id, object: device };
        });
      }
    }
  }

  filterSuperLiveDevices(value: any, filterText: string): any {
    return (
      (value.object.privateName || value.object.name).toLowerCase().includes(filterText.toLowerCase()) ||
      (value.object.privateId || value.object.id).toLowerCase().includes(filterText.toLowerCase())
    );
  }

  nodeInList(nodes: any[]): boolean {
    return nodes.find((node: any) => node.id === this.superLiveMode.current?.id);
  }

  deviceInList(devices: any[]): boolean {
    return devices.find((device: any) => device.id === this.superLiveMode.current?.id);
  }

  adjustDemoValues(device: any): void {
    const mode = device.demo.current.mode;
    const mocks = this.firebase.snapshot.mocks;
    const nodes = this.firebase.snapshot.rules.nodes;
    const devices = this.firebase.snapshot.rules.devices;

    switch (mode) {
      case 'interference':
      case 'prr':
      case 'snr':
      case 'phyrateeff':
      case 'utilization':
        device.qoe = {
          ...device.qoe,
          ...mocks[mode]
        };

        device.recommendations = this.prepareRecommendations(device, device.type === 'device' ? devices : nodes);
        break;
      default:
        device.qoe = {
          ...device.qoe,
          ...this.qoe.prepare(device.rawQoe ? device.rawQoe : {})
        };

        device.recommendations = this.prepareRecommendations(device, device.type === 'device' ? devices : nodes);
    }
  }

  demoSelectMode(modeId: string): void {
    const mocks = this.firebase.snapshot.mocks;

    this.demo.current.demo.current = { ...this.demoModes.find((mode: any) => mode.mode === modeId) };

    if (modeId === 'normal') {
      this.demo.current.demo.current.values = {};
    } else {
      this.demo.current.demo.current.values = mocks[modeId];
    }

    this.prepare();
  }

  demoSelectDevice(id: string): void {
    const devices = [...this.nodes.data, ...this.devices.data];
    this.demo.current = devices.find((device: any) => device.id === id);
  }

  selectSuperLiveDevice(id: string): void {
    if (this.mode === 'nodes') {
      const nodes = [...this.nodes.data];
      this.superLiveMode.current = nodes.find((node: any) => node.id === id);
    } else {
      const devices = [...this.devices.data];
      this.superLiveMode.current = devices.find((device: any) => device.id === id);
    }

    this.prepareSuperLive();
  }

  private getStartEndTime(value: '7d' | '24h'): { start: string; end: string } {
    return {
      start:
        value === '7d'
          ? moment().add(-this.historyDays, 'days').startOf('day').toISOString()
          : moment().add(-23, 'hours').startOf('hours').toISOString(),
      end: value === '7d' ? moment().add(-1, 'days').endOf('day').toISOString() : moment().toISOString()
    };
  }

  openDevice(deviceId: string): void {
    this.handle('devices');

    setTimeout(() => {
      const strip = this.qoestrip.find((item) => item.data.id === deviceId);

      strip.toggleExpand();
      strip.elm.nativeElement.scrollIntoView();
    }, 350);
  }

  openNode(nodeId: string): void {
    this.handle('nodes');

    setTimeout(() => {
      const strip = this.qoestrip.find((item) => item.data.id === nodeId);

      strip.toggleExpand();
      strip.elm.nativeElement.scrollIntoView();
    }, 350);
  }

  demoReset(): void {
    const devices = [...this.nodes.data, ...this.devices.data];

    devices.forEach((device: any) => {
      device.demo.current = this.demoModes[0];
    });

    this.prepare();
  }

  unsubscribeTimer(): void {
    if (this.live.timer.handler) {
      clearInterval(this.live.timer.handler);

      this.live.timer.handler = null;
      this.live.timer.show = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeTimer();

    this.thread.stop();

    if (this.debouncerTimeout) {
      clearTimeout(this.debouncerTimeout);
    }
  }
}
