import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GraphCalculationsService } from '../graph-calculations/graph-calculations.service';
import { DataWithPosition, GraphDataSetComputed } from '../graph.interface';

@Component({
  selector: '[flm-graph-segmented-interval-bar-container]',
  templateUrl: './graph-segmented-interval-bar-container.component.html',
  styleUrls: ['./graph-segmented-interval-bar-container.component.scss']
})
export class GraphSegmentedIntervalBarContainerComponent {
  @Output() barHover = new EventEmitter<{
    bar: DataWithPosition | undefined;
    dataSet: GraphDataSetComputed;
  }>();
  constructor(public calculations: GraphCalculationsService) {
  }
}
