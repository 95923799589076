import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import * as moment from 'moment/moment';
import { GroupService } from 'src/app/lib/services/group.service';
import { IGroup, IRecentCustomer } from 'src/app/lib/interfaces/groups.interface';
import { CustomerService } from 'src/app/lib/services/customer.service';

@Component({
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  groupSelected: boolean = false;
  group: IGroup = null;
  groupId: string;
  customerList: (IRecentCustomer & { date: string; group: string })[] = [];
  customerCount: number = 0;
  editGroupDescription: boolean = false;
  editGroupName: boolean = false;
  groupName: FormControl = new FormControl();
  groupDescription: FormControl = new FormControl();
  range = { start: new Date(), end: new Date() };

  @HostBinding('class.ready')
  ready: boolean = false;

  constructor(
    public plume: PlumeService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private modal: ModalService,
    private mixpanel: MixpanelService,
    private groupService: GroupService,
    private customer: CustomerService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.groupid) {
        if (params.customerid) {
          this.selectCustomer(params.groupid, params.customerid);
        } else {
          this.ready = true;
          this.mixpanel.storeEvent('GROUP_VIEW_SCREEN', { ID: params.groupid });
          this.getGroupDetails(params.groupid);
        }
      }
    });
  }

  goBack(): void {
    this.router.navigate(['groups']);
  }

  getGroupDetails(id: string): void {
    this.customerList = [];

    this.groupService.getGroup$(id).subscribe((response) => {
      this.group = response;

      this.groupService.getGroupCustomerCount$(id).subscribe((response) => {
        this.customerCount = response.count;

        if (this.customerCount > 0) {
          this.groupService.getRecentCustomers$(id).subscribe((response) => {
            this.customerList = response.map((customer) => ({
              ...customer,
              date: moment(customer.createdAt).format('L LT'),
              group: id
            }));
          });
        }
      });
    });
  }

  navigate(event: MouseEvent, groupid: string, customerid: string): void {
    event.preventDefault();

    this.mixpanel.storeEvent('GROUPS_SELECT_CUSTOMER');
    this.selectCustomer(groupid, customerid);
  }

  selectCustomer(groupid: string, customerid: string): void {
    this.customer.getLocationList$(customerid).subscribe(
      (response) => {
        if (response.length > 0) {
          this.router.navigate(['customer', customerid, 'location', response[0].id]);
        } else {
          this.toast.warning('header.invalidCustomerMsg', 'header.invalidCustomerTitle');
          this.router.navigate(['groups', groupid]);
        }
      },
      () => {
        this.toast.warning('header.invalidCustomerMsg', 'header.invalidCustomerTitle');
        this.router.navigate(['groups', groupid]);
      }
    );
  }

  clearAddGroupFields(): void {
    this.groupName.setValue('');
    this.groupDescription.setValue('');
  }

  toggleEditGroupDescription(): void {
    this.editGroupDescription = !this.editGroupDescription;
    this.groupDescription.setValue(this.group.description);
  }

  toggleEditGroupName(): void {
    this.editGroupName = !this.editGroupName;
    this.groupName.setValue(this.group.name);
  }

  updateGroupDescription(): void {
    this.editGroupDescription = !this.editGroupDescription;

    if (this.groupDescription.value) {
      this.groupService.editGroup$(`${this.group.id}`, { description: this.groupDescription.value }).subscribe(() => {
        this.toast.success('header.groupManagement.updatedGroupSuccessToast', 'header.success');
        this.mixpanel.storeEvent('GROUPS_GROUP_DESCRIPTION_EDIT');
        this.group.description = this.groupDescription.value;
        this.clearAddGroupFields();
      });
    }
  }

  updateGroupName(): void {
    this.editGroupName = !this.editGroupName;

    if (this.groupName.value) {
      this.groupService.editGroup$(`${this.group.id}`, { name: this.groupName.value }).subscribe(() => {
        this.toast.success('header.groupManagement.updatedGroupSuccessToast', 'header.success');
        this.mixpanel.storeEvent('GROUPS_GROUP_NAME_EDIT');
        this.group.name = this.groupName.value;
        this.clearAddGroupFields();
      });
    }
  }

  deleteModal(): void {
    this.mixpanel.storeEvent('DELETE_GROUP_DIALOG');

    this.modal
      .showDialog('header.groupManagement.modalContent', 'header.groupManagement.modalTitle', {
        buttons: [
          { style: 'tertiary light', value: 'header.groupManagement.cancel' },
          { style: 'super-primary', value: 'header.groupManagement.confirm' }
        ]
      })
      .subscribe((response: { item: { value: string } }) => {
        if (response.item.value === 'header.groupManagement.confirm') {
          this.deleteGroup();
        }
      });
  }

  deleteGroup(): void {
    this.groupService.removeGroup$(`${this.group.id}`).subscribe(
      (response) => {
        this.goto('groups');
        this.mixpanel.storeEvent('GROUPS_GROUP_DELETE_GROUP');
        this.toast.success('header.groupManagement.deleteGroupToast', 'header.success');
      },
      (error) => {
        this.mixpanel.storeEvent('GROUPS_GROUP_DELETE_ERRROR');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }

  goto(page: string): void {
    this.router.navigate([page]);
  }
}
