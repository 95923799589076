import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastService } from 'src/app/lib/services/toast.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  selectCaptivePortalConfig,
  selectCaptivePortalIsUprise,
  selectCaptivePortalLogo,
  selectCaptivePortalLogoLoadingMode
} from 'src/app/store/captive-portal/captive-portal.selectors';
import { take, tap } from 'rxjs/operators';
import {
  brandingFooterTextChanged,
  brandingTitleTextChanged,
  customTermsTextChanged,
  logoLoadingModeChanged,
  logoSizeChanged,
  logoEnabledChanged,
  logoUploading,
  scanningUrlForLogo
} from 'src/app/store/captive-portal/captive-portal.actions';
import { CaptiveService } from 'src/app/lib/services/captive.service';
import * as noUiSlider from 'nouislider';

@UntilDestroy()
@Component({
  selector: 'captive-info',
  templateUrl: './captive-info.component.html',
  styleUrls: ['./captive-info.component.scss']
})
export class CaptiveInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  name = new FormControl();
  footer = new FormControl();
  terms = new FormControl();
  imageSize: number = 150;
  imageNoUiSlider: noUiSlider.API;
  mode$ = this.store.select(selectCaptivePortalLogoLoadingMode);
  errorMissingTextValue: string = 'captiveportal.error.enterText';
  errorMissingImageValue: string = 'captiveportal.error.uploadImage';
  isUprise$ = this.store.select(selectCaptivePortalIsUprise);
  logo$ = this.store.select(selectCaptivePortalLogo);

  @ViewChild('imageSlider') imageSlider: ElementRef<HTMLDivElement>;

  constructor(private toast: ToastService, private captive: CaptiveService, private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectCaptivePortalConfig)
      .pipe(
        tap((config) => {
          this.imageSize = config?.branding.logoImageSize;
          this.name.setValue(config?.branding?.titleText ? config.branding.titleText : '', { emitEvent: false });
          this.footer.setValue(
            config?.branding?.campaignFooter?.support ? config.branding.campaignFooter.support : '',
            {
              emitEvent: false
            }
          );
          this.terms.setValue(
            config?.advancedOptions?.termsCondition?.customTerms?.text
              ? config.advancedOptions.termsCondition.customTerms.text
              : '',
            { emitEvent: false }
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.name.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.store.dispatch(brandingTitleTextChanged({ text: value }));
    });

    this.footer.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.store.dispatch(brandingFooterTextChanged({ text: value }));
    });

    this.terms.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.store.dispatch(customTermsTextChanged({ text: value }));

      if (!value.length) {
        this.toast.warning('captiveportal.error.termsMessage', 'captiveportal.error.termsTitle');
      }
    });
  }

  ngAfterViewInit(): void {
    this.isUprise$.pipe(take(1)).subscribe((isUprise) => {
      if (!isUprise) {
        this.imageNoUiSlider = noUiSlider.create(this.imageSlider.nativeElement, {
          start: this.imageSize,
          step: 1,
          connect: 'lower',
          range: {
            min: 50,
            max: 250
          }
        });

        this.imageNoUiSlider.on('update', (values: string[]) => {
          const size = parseInt(values[0], 10);
          this.store.dispatch(logoSizeChanged({ size }));
        });
      }
    });
  }

  disableLogo(logoLength: number): void {
    if (logoLength) {
      this.store.dispatch(logoEnabledChanged({ enabled: false, url: '' }));
    } else {
      this.showError(this.errorMissingImageValue);
    }
  }

  scanModeActivate(): void {
    this.store.dispatch(logoLoadingModeChanged({ mode: 'scan' }));
  }

  initModeActivate(): void {
    this.store.dispatch(logoLoadingModeChanged({ mode: 'init' }));
  }

  fetchLogo(url: string): void {
    this.store.dispatch(scanningUrlForLogo({ url }));
  }

  upload(): void {
    if (document.getElementById('file_graphic_uploader')) {
      document.body.removeChild(document.getElementById('file_graphic_uploader'));
    }

    const input = document.createElement('input');
    input.type = 'file';
    input.id = 'file_graphic_uploader';
    input.accept = 'image/png,image/gif,image/jpeg';

    document.body.appendChild(input);

    input.onchange = () => {
      const path = input.value.split('.');
      const extension = path[path.length - 1];

      if (['png', 'gif', 'jpeg', 'jpg'].includes(extension.toLowerCase())) {
        this.store.dispatch(logoUploading({ logo: input.files[0] }));
      } else {
        this.showError('captiveportal.error.invalidFile');
      }

      document.body.removeChild(input);
    };

    input.click();
  }

  showError(errorMsg: string): void {
    this.toast.error(errorMsg, 'captiveportal.error.defaultTitle');
  }

  ngOnDestroy(): void {
    if (document.getElementById('file_graphic_uploader')) {
      document.body.removeChild(document.getElementById('file_graphic_uploader'));
    }
  }
}
