import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { LoggingService } from './logging.service';
import jwtDecode from 'jwt-decode';

@Injectable()
export class OktaService {
  helper: GeneralHelper = new GeneralHelper();
  mode: string = 'domain';

  constructor(private http: HttpClient, private handler: HttpBackend, private logging: LoggingService) {
    this.http = new HttpClient(this.handler);

    const mode = sessionStorage.getItem('oktaMode') || null;

    if (mode) {
      this.mode = mode;
    }
  }

  authorize(url: string, nonce: string, state: string, sessionToken: string = null, idp: string = null): void {
    window.location.replace(
      environment.okta[this.mode] +
        '/oauth2/default/v1/authorize' +
        '?client_id=' +
        environment.okta.clientId +
        '&scope=' +
        encodeURIComponent(environment.okta.scope) +
        '&response_type=id_token' +
        '&redirect_uri=' +
        encodeURIComponent(url) +
        '&nonce=' +
        nonce +
        '&state=' +
        state +
        (sessionToken ? '&sessionToken=' + sessionToken : '') +
        (idp ? '&idp=' + idp : '')
    );
  }

  getToken(callback: any, sessionToken: string = null, idp: string = null): void {
    const redirectUri = window.location.origin + '/login/handle';
    const nonce = this.helper.generateRandomString(64);
    const state = this.helper.generateRandomString(64);
    const url =
      environment.okta[this.mode] +
      '/oauth2/default/v1/authorize' +
      '?client_id=' +
      environment.okta.clientId +
      '&scope=' +
      encodeURIComponent(environment.okta.scope) +
      '&response_type=id_token' +
      '&response_mode=okta_post_message' +
      '&redirect_uri=' +
      encodeURIComponent(redirectUri) +
      '&nonce=' +
      nonce +
      '&state=' +
      state +
      (sessionToken ? '&sessionToken=' + sessionToken : '') +
      (idp ? '&idp=' + idp : '');

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', url);
    iframe.style.display = 'none';

    let timeout = null;

    const messageHandler = (event: any) => {
      if (iframe) {
        if (timeout) {
          clearTimeout(timeout);
        }

        window.removeEventListener('message', messageHandler);
        iframe.removeEventListener('load', loadHandler);
        iframe.remove();
      }

      const validOrigins = [
        'https://plume.okta.com',
        'https://external.sso.plume.com',
        'https://dev-741674.oktapreview.com',
        'https://external-dev.sso.plume.com'
      ];
      if (event.data && event.data.id_token && event.data.state === state && validOrigins.indexOf(event.origin) > -1) {
        const token: any = jwtDecode(event.data.id_token);

        if (token.nonce === nonce) {
          callback({
            token: event.data.id_token,
            user: { ...token, id: token.email },
            role: token.cloud_role ? token.cloud_role[0] : '',
            groups: token.groups
              ? token.groups.map((group: string) => {
                  return { name: group.split(':')[2], id: group.split(':')[3] };
                })
              : [],
            ttl: token.exp * 1000
          });
        } else {
          callback(null);
        }
      } else {
        callback(null);
      }
    };

    const loadHandler = (event: any) => {
      timeout = setTimeout(() => {
        window.removeEventListener('message', messageHandler);
        iframe.removeEventListener('load', loadHandler);
        iframe.remove();

        callback(null);
      }, 2000);
    };

    window.addEventListener('message', messageHandler);
    iframe.addEventListener('load', loadHandler);
    document.body.appendChild(iframe);
  }

  checkSession(success: any, error: any): void {
    this.http.get(environment.okta.domain + '/api/v1/sessions/me', { withCredentials: true }).subscribe(
      () => {
        this.setMode('domain');
        success(this.mode);
      },
      () => {
        this.http.get(environment.okta.vanity + '/api/v1/sessions/me', { withCredentials: true }).subscribe(
          () => {
            this.setMode('vanity');
            success(this.mode);
          },
          () => {
            error();
          }
        );
      }
    );
  }

  deleteSession(callback?: any): void {
    this.http.delete(environment.okta[this.mode] + '/api/v1/sessions/me', { withCredentials: true }).subscribe(
      (response: any) => {
        if (callback) {
          callback(true);
        }
      },
      (error: any) => {
        if (callback) {
          callback(false);
        }
      }
    );
  }

  handleLogin(): any {
    const { nonce, state, hostname } = this.getSessionCookie();

    if (window.location.hash.length) {
      const params: any = {};

      window.location.hash
        .substr(1)
        .split('&')
        .forEach((param: any) => (params[param.split('=')[0]] = param.split('=')[1]));

      const token: any = params.id_token ? jwtDecode(params.id_token) : null;
      const nonceParam: any = token ? token.nonce : null;

      if (params.error) {
        this.logging.log('Okta login Error, ' + params.error_description);

        return {
          state: params.state,
          user: null,
          hostname: null,
          ttl: null,
          error: params.error_description
        };
      }

      if (state === params.state && nonce === nonceParam) {
        try {
          return {
            token: params.id_token,
            user: { ...token, id: token.email },
            hostname,
            ttl: token.exp * 1000
          };
        } catch (e) {
          this.logging.log(e);
        }
      }
    }

    return {
      token: null,
      user: null,
      hostname: null,
      ttl: null
    };
  }

  setMode(mode: string): void {
    this.mode = mode;
    sessionStorage.setItem('oktaMode', this.mode);
  }

  setSessionCookie(hostname: string, mode: string = null): any {
    const nonce = this.helper.generateRandomString(64);
    const state = this.helper.generateRandomString(64);
    const domain = window.location.hostname;

    this.setCookie('oktaNonce', nonce, domain);
    this.setCookie('oktaState', state, domain);
    this.setCookie('oktaHostname', hostname, domain);
    this.setCookie('oktaDomain', domain, domain);
    this.setCookie('oktaMode', mode ? mode : this.mode, domain);

    return { nonce, state };
  }

  getSessionCookie(): any {
    const nonce = this.getCookie('oktaNonce');
    const state = this.getCookie('oktaState');
    const hostname = this.getCookie('oktaHostname');
    const domain = this.getCookie('oktaDomain');
    const mode = this.getCookie('oktaMode');

    this.removeCookie('oktaNonce', domain);
    this.removeCookie('oktaState', domain);
    this.removeCookie('oktaHostname', domain);
    this.removeCookie('oktaDomain', domain);
    this.removeCookie('oktaMode', domain);

    this.setMode(mode);

    return { nonce, state, hostname };
  }

  setCookie(name: string, value: string, domain: string): void {
    const d = new Date();
    d.setTime(d.getTime() + 3 * 60000);
    document.cookie =
      name +
      '=' +
      escape(value) +
      '; expires=' +
      d.toUTCString() +
      '; path=/; ' +
      (domain === 'localhost' ? '' : 'domain=.' + domain + ';');
  }

  getCookie(name: string): string {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const data = cookie.trim().split('=');

      if (data[0] === name) {
        return unescape(data[1]);
      }
    }

    return null;
  }

  removeCookie(name: string, domain: string): void {
    document.cookie =
      name +
      '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ' +
      (domain === 'localhost' ? '' : 'domain=.' + domain + ';');
  }
}
