import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, iif, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ICaptivePortalOwnerAppIds } from 'src/app/lib/interfaces/captive-portal';
import { CaptiveService } from 'src/app/lib/services/captive.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import {
  campaignDataLoaded,
  campaignDataLoadedError,
  prepareCaptivePortal,
  logoUploading,
  logoUploadingError,
  logoUploadingFinished,
  previewLoaded,
  previewLoadedError,
  previewOpened,
  publishOpened,
  publishError,
  publishLoaded,
  scanningUrlForLogo,
  scanningUrlForLogoError,
  scanningUrlForLogoFinished,
  backgroundImageUploading,
  backgroundImageUploadingFinished,
  backgroundImageUploadingError
} from './captive-portal.actions';
import { selectCaptivePortalIsUprise } from './captive-portal.selectors';

@Injectable()
export class CaptivePortalEffects {
  loadInitData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prepareCaptivePortal, publishLoaded),
      switchMap(() =>
        combineLatest([
          this.captive.getCampaign$(),
          this.store.select(selectCaptivePortalIsUprise).pipe(
            switchMap((isUprise) =>
              iif(() => isUprise, of([] as ICaptivePortalOwnerAppIds[]), this.captive.getOwnerAppIds$()).pipe(
                catchError(() => of([] as ICaptivePortalOwnerAppIds[]))
              )
            ),
            map((ownerAppIds) =>
              this.captive.loginOptions.dynamic
                .map((option) => {
                  const appId = ownerAppIds.find((appId) => appId.type === option.data.name.toLowerCase());
                  return appId
                    ? {
                        ...option,
                        data: { ...option.data, appId: appId.id }
                      }
                    : null;
                })
                .filter((option) => option)
            ),
            map((loginOptions) => [...loginOptions, ...this.captive.loginOptions.fixed])
          )
        ])
      ),
      map(([config, loginOptions]) => {
        if (!config.branding.logo) {
          this.toast.warning('captiveportal.error.logoMessage', 'captiveportal.error.logoTitle');
        }

        return campaignDataLoaded({ config, loginOptions });
      }),
      catchError((error) => {
        return of(campaignDataLoadedError({ error: error.error.error.message }));
      })
    )
  );

  scanningUrlForLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(scanningUrlForLogo),
      switchMap(({ url }) =>
        this.captive.getImageFromUrl$(url).pipe(
          map((imageUrl) => scanningUrlForLogoFinished({ imageUrl })),
          catchError((error) => of(scanningUrlForLogoError({ error })))
        )
      )
    )
  );

  logoUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoUploading),
      switchMap(({ logo }) =>
        this.captive.compressAndUploadCampaignAsset$(logo).pipe(
          map(({ url }) => logoUploadingFinished({ url })),
          catchError((error) => of(logoUploadingError({ error })))
        )
      )
    )
  );

  logoUploadFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoUploadingError),
        tap(({ error }) => {
          if (error.status === 413) {
            this.toast.error('captiveportal.error.tooLargeMessage', 'captiveportal.error.tooLargeTitle');
          } else {
            this.toast.error('captiveportal.error.defaultMessage', 'captiveportal.error.defaultTitle');
          }
        })
      ),
    {
      dispatch: false
    }
  );

  backgroundImageUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backgroundImageUploading),
      switchMap(({ backgroundImage }) =>
        this.captive.compressAndUploadCampaignAsset$(backgroundImage).pipe(
          map(({ url }) => backgroundImageUploadingFinished({ url })),
          catchError((error) => of(backgroundImageUploadingError({ error })))
        )
      )
    )
  );

  backgroundImageUploadFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(backgroundImageUploadingError),
        tap(({ error }) => {
          if (error.status === 413) {
            this.toast.error('captiveportal.error.tooLargeMessage', 'captiveportal.error.tooLargeTitle');
          } else {
            this.toast.error('captiveportal.error.defaultMessage', 'captiveportal.error.defaultTitle');
          }
        })
      ),
    {
      dispatch: false
    }
  );

  previewOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(previewOpened),
      switchMap(() =>
        this.captive.setPreview$().pipe(
          map(({ previewUrl }) => previewLoaded({ previewUrl })),
          catchError((error) => of(previewLoadedError({ error })))
        )
      )
    )
  );

  publishOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishOpened),
      switchMap(() =>
        this.captive.doPublish$().pipe(
          map(() => publishLoaded()),
          catchError((error) => of(publishError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private captive: CaptiveService,
    private store: Store,
    private toast: ToastService
  ) {}
}
