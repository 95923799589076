import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { selectBaseUrl, selectCustomerIdentification } from 'src/app/store/customer/customer.selectors';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class UtilitiesService {
  constructor(private api: ApiService, private store: Store) {}

  getLocationAuditTrail(): Observable<any> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/auditTrail`))
    );
  }

  getCustomerAuditTrail(): Observable<any> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap(({ customerid }) => this.api.get(`/Customers/${customerid}/auditTrail`))
    );
  }
}
