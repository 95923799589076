import { scaleLinear, scaleTime, extent } from 'd3';
import { Line } from '../objects/line';
import { Tick } from '../objects/tick';
import { Point } from '../objects/point';

export class CurvedlineChart {
  private data: any[];
  private lines: Line[];
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private margins: any = {
    top: 80,
    right: 60,
    bottom: 80,
    left: 60
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepareScales(): void {
    this.xScale = scaleTime().range([0, this.calculateWidth()]);
    this.yScale = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareDomains(): void {
    const xdomains = [];
    const ydomains = [];

    this.data.forEach((line: any) => {
      xdomains.push(...line.data.map((d: any) => new Date(d.label)));
      ydomains.push(...line.data.map((d: any) => d.value));
    });

    this.xScale.domain(extent(xdomains));
    this.yScale.domain(extent(ydomains)).nice(5);
  }

  calculateLines(): void {
    this.lines = this.data.map((line: Line) => {
      const tension = 1;

      line.data.forEach((point: Point, index: number, points: any[]) => {
        if (index) {
          if (index < points.length - 1) {
            const p0 = index > 0 ? points[index - 1] : points[0];
            const p1 = points[index];
            const p2 = index < points.length - 1 ? points[index + 1] : p1;
            const p3 = index < points.length - 2 ? points[index + 2] : p2;

            const cp1x =
              this.xScale(new Date(p1.label)) +
              ((this.xScale(new Date(p2.label)) - this.xScale(new Date(p0.label))) / 6) * tension;
            const cp1y = this.yScale(p1.value) + ((this.yScale(p2.value) - this.yScale(p0.value)) / 6) * tension;

            const cp2x =
              this.xScale(new Date(p2.label)) -
              ((this.xScale(new Date(p3.label)) - this.xScale(new Date(p1.label))) / 6) * tension;
            const cp2y = this.yScale(p2.value) - ((this.yScale(p3.value) - this.yScale(p1.value)) / 6) * tension;

            line.d +=
              'C' +
              cp1x +
              ', ' +
              cp1y +
              ' ' +
              cp2x +
              ', ' +
              cp2y +
              ' ' +
              this.xScale(new Date(p2.label)) +
              ', ' +
              this.yScale(p2.value) +
              ' ';
          }
        } else {
          line.d = 'M' + this.xScale(new Date(point.label)) + ', ' + this.yScale(point.value) + ' ';
        }
      });

      return line;
    });
  }

  xAxis(): any[] {
    return this.xScale.ticks(5).map((tick: any) => {
      return new Tick(
        'xaxis',
        tick.toLocaleString('en-US', { hour: 'numeric', hour12: true }),
        'translate(' + this.xScale(tick) + ',' + (this.calculateHeight() + 20) + ')',
        { y: 10 },
        true,
        { y1: -9, y2: -11 }
      );
    });
  }

  yAxis(): any[] {
    return this.yScale.ticks(5).map((tick: any) => {
      return new Tick(
        'yaxis left',
        tick * 100 + '%',
        'translate(0, ' + this.yScale(tick) + ')',
        { x: -20 - this.margins.left / 2, dy: 0.32 },
        false
      );
    });
  }

  yLabel(label: string): Tick[] {
    return label.split('|').map((text, index) => {
      return new Tick(
        'yaxis',
        text,
        'translate(0, ' + (this.yScale(this.yScale.ticks()[0]) + this.calculateHeight() / 5) + ')',
        { x: -20 - this.margins.left / 2, dy: index * 1.1 + 0.32 },
        false
      );
    });
  }

  update(data: any[], width?: number, height?: number, margins?: any): Line[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateLines();

    return this.lines;
  }
}
