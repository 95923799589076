import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
  selectBaseUrl,
  selectMonitorMode,
  selectPipeLocationOnChange,
  selectReducedMode
} from 'src/app/store/customer/customer.selectors';
import { ApiService } from './api.service';
import { PlumeService } from './plume.service';

@Injectable({
  providedIn: 'root'
})
export class OptimizationService {
  constructor(private store: Store, private plume: PlumeService, private api: ApiService) {}

  enableOptimization$(): Observable<boolean> {
    return combineLatest([this.store.select(selectReducedMode), this.store.select(selectMonitorMode)]).pipe(
      map(([reducedMode, monitorMode]) => !this.plume.isStrictSupportRole() && !reducedMode && !monitorMode)
    );
  }

  getFastInterface$(): Observable<object> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/fastInterference`))
    );
  }

  deleteFastInterface$(): Observable<object> {
    return this.store.pipe(selectPipeLocationOnChange).pipe(
      take(1),
      switchMap(({ id }) => this.api.delete(`/Locations/${id}/fastInterferenceTriggerLimit`, 'awlan'))
    );
  }
}
