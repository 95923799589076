import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  selectCaptivePortalBaseUrl,
  selectCaptivePortalConfig,
  selectCaptivePortalNetworkUrl
} from 'src/app/store/captive-portal/captive-portal.selectors';
import {
  ICaptivePortalConfig,
  ICaptivePortalLoginOptions,
  ICaptivePortalOwnerAppIds
} from '../interfaces/captive-portal';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import imageCompression from 'browser-image-compression';
import produce from 'immer';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CaptiveService {
  readonly loginOptions: { fixed: ICaptivePortalLoginOptions[]; dynamic: ICaptivePortalLoginOptions[] } = {
    fixed: [
      {
        order: 1,
        text: 'captiveportal.list.connectForFreeWiFi',
        icon: 'fa-wifi',
        consent: false,
        editable: false,
        data: {
          name: 'Free Wi-Fi',
          valid: true,
          text: 'Connect for Free WiFi'
        }
      },
      {
        order: 2,
        text: 'captiveportal.list.connectWithPasscode',
        icon: 'fa-lock',
        consent: false,
        editable: true,
        data: {
          appId: null,
          name: 'Passcode',
          code: '',
          text: 'Enter a passcode',
          valid: true
        }
      },
      {
        order: 3,
        text: 'captiveportal.list.connectWithEmail',
        icon: 'fa-envelope',
        consent: true,
        editable: false,
        data: {
          name: 'Email',
          valid: true,
          fields: ['Email', 'Name']
        }
      },
      {
        order: 4,
        text: 'captiveportal.list.connectWithSMS',
        icon: 'fa-commenting',
        consent: true,
        editable: false,
        data: {
          appId: 2,
          name: 'SMS',
          valid: true,
          phone: '+17626675863',
          text: 'Enter this WiFi verification code to log in: @pin_code',
          fields: []
        }
      }
    ],
    dynamic: [
      {
        order: 5,
        text: 'captiveportal.list.connectWithFacebook',
        icon: 'fa-facebook',
        consent: true,
        editable: false,
        data: {
          appId: 14,
          name: 'Facebook',
          valid: true
        }
      }
    ]
  };
  languages = [
    { lang_code: 'en', lang: 'captiveportal.lang.en' },
    // { lang_code: 'en-GB', lang: 'captiveportal.lang.enGB' },
    // { lang_code: 'en-CA', lang: 'captiveportal.lang.enCA' },
    { lang_code: 'de', lang: 'captiveportal.lang.de' },
    { lang_code: 'es', lang: 'captiveportal.lang.es' },
    { lang_code: 'fi', lang: 'captiveportal.lang.fi' },
    { lang_code: 'fr', lang: 'captiveportal.lang.fr' },
    { lang_code: 'fr-CA', lang: 'captiveportal.lang.frCA' },
    { lang_code: 'it', lang: 'captiveportal.lang.it' },
    { lang_code: 'ja', lang: 'captiveportal.lang.ja' },
    { lang_code: 'pt', lang: 'captiveportal.lang.pt' },
    { lang_code: 'sv', lang: 'captiveportal.lang.sv' },
    { lang_code: 'vi', lang: 'captiveportal.lang.vi' }
  ];

  constructor(private api: ApiService, private store: Store, private auth: AuthService,  private translate: TranslateService, ) {}

  companyInfoSearch$(url: string, urlType: 'domain'): Observable<{ icon: string; logo: string }> {
    return this.store.select(selectCaptivePortalBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/companyInfo/search`, { urlType, url }))
    );
  }

  getImageFromUrl$(url: string): Observable<string> {
    return this.companyInfoSearch$(url, 'domain').pipe(
      map((response) => {
        const image = response.logo || response.icon;
        if (image.indexOf('https://') > -1) {
          return image;
        } else {
          return this.auth.getEnvFromUrl().mywifi + image;
        }
      })
    );
  }

  compressAndUploadCampaignAsset$(file: File, maxSizeMB = 1): Observable<{ url: string }> {
    return from(imageCompression(file, { maxSizeMB })).pipe(
      catchError((error) => {
        console.log(error);
        return of(null);
      }),
      filter((file) => file),
      switchMap((compressedFile) => this.uploadCampaignAsset$(compressedFile))
    );
  }

  uploadCampaignAsset$(compressedFile: File): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append(
      'file',
      new File([compressedFile], compressedFile.name.toLowerCase(), { type: compressedFile.type })
    );

    return this.store.select(selectCaptivePortalBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/uploadCampaignAsset`, formData)),
      map((response) => ({
        url: response.url.indexOf('https://') > -1 ? response.url : this.auth.getEnvFromUrl().mywifi + response.url
      }))
    );
  }

  getCampaign$(): Observable<ICaptivePortalConfig> {
    return this.store.select(selectCaptivePortalNetworkUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/campaign`)),
      map((data) => this.configPreviewInitValue(data))
    );
  }

  getOwnerAppIds$(): Observable<ICaptivePortalOwnerAppIds[]> {
    return this.store.select(selectCaptivePortalBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/ownerAppIds`))
    );
  }

  setPreview$(): Observable<{ previewUrl: string }> {
    return combineLatest([this.prepareStyleInformation(), this.store.select(selectCaptivePortalNetworkUrl)]).pipe(
      take(1),
      switchMap(([config, baseUrl]) =>
        this.api.post(`${baseUrl}/campaign/preview`, {
          campaignPayload: { payload: config }
        })
      )
    );
  }

  doPublish$(): Observable<void> {
    return combineLatest([this.prepareStyleInformation(), this.store.select(selectCaptivePortalNetworkUrl)]).pipe(
      take(1),
      switchMap(([config, baseUrl]) =>
        this.api.patch(`${baseUrl}/campaign`, {
          campaignPayload: { payload: config }
        })
      )
    );
  }


  public getLanguage(langCode: string): { lang_code: string; lang: string } {
    return this.languages.find((language) => language.lang_code === langCode) || this.languages[0];
  }

  private configPreviewInitValue(data: ICaptivePortalConfig): ICaptivePortalConfig {
    return produce(data, (config) => {
      config.branding.titleTextSize = 18;
      config.branding.campaignFooter.image = 0;
      config.branding.footerText = 0;
      config.branding.supportTextSize = 10;

      if (config.loginOptions.styles) {
        config.loginOptions.styles.primaryButtonTextAlign = {
          label: 'Center',
          value: 'center'
        };
      }

      config.branding.fontFamily = {
        label: 'Wigrum',
        value: 'Wigrum, sans-serif'
      };

      if (!config.branding.logoImageSize) {
        config.branding.logoImageSize = 150;
      }
      if (typeof config.branding.background === 'string' && config.branding.background.startsWith('http')) {
        if (!config.branding.backgroundRepeat ) {
          config.branding.backgroundRepeat = {
            label: 'Stretch',
            value: 'stretch'
          };
         }
        if (!config.branding.backgroundRepeatAlignment) {
          config.branding.backgroundRepeatAlignment = {
            label: this.translate.instant('captiveportal.backgroundImagePosition.centerCenter'),
            value: 'center center'
          };
        }
      }

    });
  }

  private prepareStyleInformation(): Observable<ICaptivePortalConfig> {
    return this.store.select(selectCaptivePortalConfig).pipe(
      map((oldConfig) => {
        return produce(oldConfig, (config) => {
          const staticStyles = `
            <style>
            body { background: #f7f8fa !important; }
            .app-container { height: auto; }
            #app1 { max-width: 600px;   background: #ffffff; padding: 20px; margin: 20px; margin-bottom: 50px; border-radius: 30px; box-shadow: 0 10px 30px #888888;}
            a.connect,
            button.connect,
            .phone-code,
            .custom-fields input,
            .custom-fields select,
            .social-btn { max-width: 250px; }
            .background-image { max-width: none; }
            .background-image .lang-select .v-select.dropdown { max-width: 298px; border: 1px solid #aaaaaa; border-radius: 16px; margin: auto; }
            .background-image .lang-select .v-select .open-indicator { top: 50%; transform: translateY(-50%); }
            .background-image .lang-select .v-select.open .open-indicator:before { transform: rotate(315deg) translate(-2px, 2px); }
            .background-image .lang-select .v-select .dropdown-menu { top: 48px; max-height: 250px !important; border-radius: 0 0 16px 16px; outline: 1px solid #aaaaaa; border: 0; width: calc(100% - 32px); left: 16px; }
            .logo-area { padding: 0; padding-top: 15px; }
            .logo-area .logo img { max-width: 80% !important; height: 150px !important; object-fit: contain; padding: 0; }
            .logo-area h2 { letter-spacing: 0px; font-size: 18px; font-weight: 400; margin: 0; margin-top: 21px; }
            .sub-title-text { display: none; }
            .terms { padding: 0; }
            p.anchor { margin-top: 30px; font-size: 12px; }
            .social-btn-holder { width: auto; }
            .social-btn-container .consent-form .consent-button { border: 1px solid #aaaaaa; box-shadow: none; background: #ffffff !important; color: #4d4d4d !important; max-width: 250px; text-align: center; border-radius: 16px;  }
            #social-holders { width: auto; }
            #social-holders a { border: 1px solid #aaaaaa; box-shadow: none; background: #ffffff !important; color: #4d4d4d !important; max-width: 250px; text-align: center; border-radius: 16px;  }
            #social-holders a i { display: inline !important; margin: 0 15px; }
            .campaign-footer { font-size: 10px; font-weight: 300; position: initial; }
            </style>
            `;

          const customStyles = `
            <style>
            #app1 {
              background-color: ${typeof config.branding.background === 'string' ? config.branding.background : ''};
              background-repeat: no-repeat;
            }
            .logo-area .logo img { width: 80% !important; height: ${config.branding.logoImageSize}px !important; }
            .background-image .lang-select .v-select .selected-tag { color: ${config.branding.textColor} !important; }
            .background-image .lang-select .v-select .open-indicator:before { border-color: ${config.branding.textColor} !important; }
            .consent-form .consent-text { color: ${config.branding.textColor}; }
            </style>
            `;

          config.advancedOptions.scripts.head = staticStyles + customStyles;
          config.advancedOptions.scripts.footer = '';
          if (typeof config.branding.background === 'string' && config.branding.background.startsWith('http')) {
            const relativeUrl = config.branding.background.replace(/^https?:\/\/[^\/]+/, ''); // Remove protocol and domain
            config.branding.background = `url(${relativeUrl})`; // Wrap in 'url()' CSS format
          }
          if (!config.branding.backgroundRepeat ) {
            config.branding.backgroundRepeat = {
              label: 'Stretch',
              value: 'stretch'
            };
           }
          if (!config.branding.backgroundRepeatAlignment ) {
            config.branding.backgroundRepeatAlignment = {
              label: this.translate.instant('captiveportal.backgroundImagePosition.centerCenter'),
              value: 'center center'
            };
           }

        });

      })
    );
  }
}
