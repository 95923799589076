<div class="row" id="conf-dhcp">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.dhcp.title' | translate }}</div>
      <div container *ngIf="expand">
        <ng-container *ngIf="(location$ | async)?.networkModeRealized === 'bridge'; else routerMode">
          {{ 'configurations.dhcp.notCapable' | translate }}
        </ng-container>
        <ng-template #routerMode>
          <div class="columns">
            <div class="column">
              <div class="row">
                <div class="key">{{ 'configurations.dhcp.subnet' | translate }}</div>
                <div class="value">
                  <div class="output" [class.hide]="editDhcp">
                    {{ dhcpSubnet?.value ? dhcpSubnet.value : ('configurations.dhcp.setSubnet' | translate) }}
                  </div>
                  <div class="editable" [class.hide]="!editDhcp">
                    <input
                      (keyup.enter)="confirmDhcpSubnetEdit()"
                      (keyup.escape)="cancelDhcpSubnetEdit()"
                      [formControl]="dhcpSubnet"
                      type="text"
                      placeholder="{{ 'configurations.dhcp.subnet' | translate }}.."
                      autofocus
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.dhcp.netmask' | translate }}</div>
                <div class="value">
                  <div class="output" [class.hide]="editDhcp">
                    {{ dhcpSubnetMask.value ? dhcpSubnetMask.value : ('configurations.dhcp.setNetMask' | translate) }}
                  </div>
                  <div class="editable" [class.hide]="!editDhcp">
                    <input
                      (keyup.enter)="confirmDhcpSubnetMaskEdit()"
                      (keyup.escape)="cancelDhcpSubnetmaskEdit()"
                      [formControl]="dhcpSubnetMask"
                      type="text"
                      placeholder="{{ 'configurations.dhcp.netmask' | translate }}.."
                      autofocus
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.dhcp.startIp' | translate }}</div>
                <div class="value">
                  <div class="output" [class.hide]="editDhcp">
                    {{ dhcpStartIp.value ? dhcpStartIp.value : ('configurations.dhcp.setStartIp' | translate) }}
                  </div>
                  <div class="editable" [class.hide]="!editDhcp">
                    <input
                      (keyup.enter)="confirmDhcpStartIpEdit()"
                      (keyup.escape)="cancelDhcpStartIpEdit()"
                      [formControl]="dhcpStartIp"
                      type="text"
                      placeholder="{{ 'configurations.dhcp.startIp' | translate }}.."
                      autofocus
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.dhcp.endIp' | translate }}</div>
                <div class="value">
                  <div class="output" [class.hide]="editDhcp">
                    {{ dhcpEndIp.value ? dhcpEndIp.value : ('configurations.dhcp.setEndIp' | translate) }}
                  </div>
                  <div class="editable" [class.hide]="!editDhcp">
                    <input
                      (keyup.enter)="confirmDhcpEndIpEdit()"
                      (keyup.escape)="cancelDhcpEndIpEdit()"
                      [formControl]="dhcpEndIp"
                      type="text"
                      placeholder="{{ 'configurations.dhcp.endIp' | translate }}.."
                      autofocus
                    />
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="plume.cloudVersionAbove1_114()">
                <div class="key">{{ 'configurations.dhcp.dhcpLeases' | translate }}</div>
                <div class="value">
                  <div class="data-wrap">
                    <div class="selector" [class.collapsed]="!leaseExpand" (click)="toggleLeaseExpand()">
                      {{ 'configurations.dhcp.object' | translate }}
                      <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="leaseExpand"></icon>
                    </div>
                    <ng-container *ngIf="leaseExpand">
                      <ngx-json-viewer [json]="DHCPLeases"></ngx-json-viewer>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="row top" *ngIf="!plume.hidePersonalDetails()">
                <div class="key">{{ 'configurations.dhcp.dhcpData' | translate }}</div>
                <div class="value">
                  <div class="data-wrap">
                    <div class="selector" [class.collapsed]="!dataExpand" (click)="toggleDataExpand()">
                      {{ 'configurations.dhcp.object' | translate }}
                      <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="dataExpand"></icon>
                    </div>
                    <ng-container *ngIf="dataExpand">
                      <ngx-json-viewer [json]="dhcp"></ngx-json-viewer>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="row noWrap">
                <button
                  *ngIf="!editDhcp"
                  class="super-primary"
                  (click)="editDhcp = true"
                  [disabled]="!plume.isSomeAdminRole() || !(capabilities$ | async)?.dhcp?.capable"
                >
                  {{ 'techdash.tooltip.edit' | translate }}
                </button>
                <button *ngIf="editDhcp" class="tertiary light" (click)="dhcpEditDone('close')">
                  {{ 'techdash.tooltip.cancel' | translate }}
                </button>
                <button *ngIf="editDhcp" class="super-primary" (click)="dhcpEditDone('set')">
                  {{ 'configurations.dhcp.portForward.save' | translate }}
                </button>
              </div>
            </div>
            <div
              class="column-2"
              *ngIf="
                (capabilities$ | async)?.dhcpReservations?.capable &&
                !plume.hidePersonalDetails() &&
                plume.isSomeAdminRole()
              "
            >
              <div class="title">
                {{ 'configurations.dhcp.dhcpReservation.title' | translate }}
              </div>
              <drop-down class="selector medium">
                {{ currentDevice.name | translate }}
                <ng-container dropdown>
                  <li (click)="selectDevice(device)" *ngFor="let device of devices">
                    {{ device.name }}
                  </li>
                </ng-container>
              </drop-down>
              <div class="sub-form">
                <div class="mac">
                  {{ 'configurations.dhcp.dhcpReservation.enterDeviceMac' | translate }}
                  <input type="text" [formControl]="currentDeviceMac" (focus)="enableButton()" />
                </div>
                <div class="reservation">
                  {{ 'configurations.dhcp.dhcpReservation.enterIpReservation' | translate }}
                  <div class="inline-input">
                    <span *ngIf="subnetPrefix">{{ subnetPrefix + '.' }}</span>
                    <input type="text" [formControl]="currentDeviceIpSuffix" (focus)="enableButton()" />
                  </div>
                </div>
              </div>
              <button class="action medium" [class.disabled]="!showButton" (click)="setDhcpReservation()">
                {{ 'configurations.dhcp.dhcpReservation.submitReservation' | translate }}
              </button>
            </div>
          </div>
          <ng-container *ngIf="dhcpReservation?.length > 0">
            <div class="section-2">
              <div class="columns">
                <div class="column">
                  <div class="title">{{ 'configurations.dhcp.dhcpReservation.list-title' | translate }}</div>
                  <div class="row top" *ngIf="!plume.hidePersonalDetails()">
                    <div class="key">{{ 'configurations.dhcp.dhcpReservation.reservationData' | translate }}</div>
                    <div class="value">
                      <div class="data-wrap">
                        <div
                          class="selector"
                          [class.collapsed]="!reservationDataExpand"
                          (click)="toggleReservationDataExpand()"
                        >
                          {{ 'configurations.dhcp.object' | translate }}
                          <icon
                            name="icon-arrow-down"
                            class="size-0_8"
                            [class.rotate180]="reservationDataExpand"
                          ></icon>
                        </div>
                        <ng-container *ngIf="reservationDataExpand">
                          <ngx-json-viewer [json]="dhcpReservation"></ngx-json-viewer>
                        </ng-container>
                      </div>
                    </div>
                    <div class="value" *ngIf="dhcpReservation?.length == 0">
                      {{ 'configurations.dhcp.dhcpReservation.noReservation' | translate }}
                    </div>
                  </div>
                  <div class="card" *ngFor="let reservation of dhcpReservation; index as i">
                    <div class="row">
                      <div class="key">{{ 'configurations.dhcp.dhcpReservation.mac' | translate }}</div>
                      <div class="value">{{ reservation.mac | macAddr }}</div>
                    </div>
                    <div class="row">
                      <div class="key">{{ 'configurations.dhcp.dhcpReservation.ip-address' | translate }}</div>
                      <div class="value">
                        <div class="output" [class.hide]="reservation.editDhcpIp">
                          {{ reservation.ip }}
                          <icon
                            name="icon-edit"
                            (click)="enableDhcpIpEdit(reservation)"
                            *ngIf="!reservation.editDhcpIp"
                          ></icon>
                        </div>
                        <div class="editable" [class.hide]="!reservation.editDhcpIp">
                          <input
                            (keyup.enter)="confirmDhcpIpEdit(reservation, i)"
                            (keyup.escape)="cancelDhcpIpEdit(reservation, i)"
                            [formControl]="formControls[i]"
                            type="text"
                            placeholder="{{ reservation.ip }}"
                          />
                          <div class="controls" *ngIf="reservation.editDhcpIp">
                            <icon name="icon-check" class="confirm" (click)="confirmDhcpIpEdit(reservation, i)"></icon>
                            <icon
                              name="icon-close"
                              class="size-0_8 cancel"
                              (click)="cancelDhcpIpEdit(reservation, i)"
                            ></icon>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="key">{{ 'configurations.dhcp.dhcpReservation.name' | translate }}</div>
                      <div class="value">{{ reservation.name }}</div>
                    </div>
                    <div class="title" *ngIf="reservation?.portForwards?.length > 0">
                      {{ 'configurations.dhcp.portForward.list-title' | translate }}
                    </div>
                    <div class="tableContainer" *ngIf="reservation?.portForwards?.length > 0">
                      <div class="table" [ngClass]="natLoopbackEnable === true ? 'showNat' : ''">
                        <div class="cell">
                          {{ 'configurations.dhcp.portForward.name' | translate }}
                        </div>
                        <div class="cell">
                          {{ 'configurations.dhcp.portForward.extPort' | translate }}
                        </div>
                        <div class="cell">
                          {{ 'configurations.dhcp.portForward.intPort' | translate }}
                        </div>
                        <div class="cell">
                          {{ 'configurations.dhcp.portForward.protocol' | translate }}
                        </div>
                        <div class="cell">
                          {{ 'configurations.dhcp.portForward.delete' | translate }}
                        </div>

                        <div class="cell" *ngIf="natLoopbackEnable === true">
                          {{ 'configurations.dhcp.portForward.natLoopback' | translate }}
                        </div>

                        <ng-container *ngFor="let portForward of reservation?.portForwards">
                          <div class="cell">
                            <div *ngIf="!portForward.editPortForwardName" class="ellipsis">
                              {{ portForward.name }}
                            </div>
                            <labeled-input *ngIf="portForward.editPortForwardName">
                              <input
                                [(ngModel)]="portForward.editName"
                                type="text"
                                placeholder="{{ portForward.name }}"
                              />
                            </labeled-input>
                            <input-buttons
                              (action)="
                                $event === 'set'
                                  ? confirmPortForwardName(reservation, portForward)
                                  : canclePortForwardName(portForward)
                              "
                              [(editState)]="portForward.editPortForwardName"
                            ></input-buttons>
                          </div>
                          <div class="cell">
                            {{ portForward.externalPort }}
                          </div>
                          <div class="cell">
                            <div *ngIf="!portForward.editPortForwardinternalPort">
                              {{ portForward.internalPort }}
                            </div>
                            <labeled-input class="smallNumber" *ngIf="portForward.editPortForwardinternalPort">
                              <input
                                [(ngModel)]="portForward.editInternalPort"
                                type="text"
                                type="number"
                                placeholder="{{ portForward.internalPort }}"
                              />
                            </labeled-input>
                            <input-buttons
                              (action)="
                                $event === 'set'
                                  ? confirmPortForwardInternalPort(reservation, portForward)
                                  : canclePortForwardInternalPort(portForward)
                              "
                              [(editState)]="portForward.editPortForwardinternalPort"
                            ></input-buttons>
                          </div>
                          <div class="cell">
                            <div *ngIf="!portForward.editPortForwardProtocol">
                              {{ portForward.protocol | uppercase }}
                            </div>
                            <drop-down-select
                              *ngIf="portForward.editPortForwardProtocol"
                              class="medium"
                              emptyText="configurations.dhcp.dhcpReservation.selectProtocol"
                              [values]="protocolValues"
                              [(ownModel)]="portForward.editProtocol"
                            >
                            </drop-down-select>
                            <input-buttons
                              (action)="
                                $event === 'set'
                                  ? confirmPortForwardProtocol(reservation, portForward)
                                  : canclePortForwardProtocol(portForward)
                              "
                              [(editState)]="portForward.editPortForwardProtocol"
                              (editStateChange)="$event && enablePortForwardProtocol(portForward)"
                            ></input-buttons>
                          </div>
                          <div class="cell">
                            <button class="action medium" (click)="showPortForwardDelete(portForward)">
                              {{ 'configurations.dhcp.portForward.delete' | translate }}
                            </button>
                          </div>

                          <div class="cell" *ngIf="natLoopbackEnable === true">
                            <div *ngIf="!portForward.natLoopbackShow" class="ellipsis">
                              {{ portForward.natLoopback | uppercase }}
                            </div>

                            <drop-down-select
                              *ngIf="portForward.natLoopbackShow"
                              class="selector medium"
                              [emptyText]="'configurations.dhcp.dhcpReservation.selectProtocol' | translate"
                              [values]="natValues"
                              [multiSelect]="false"
                              [(ownModel)]="portForward.natLoopback"
                            ></drop-down-select>

                            <input-buttons
                              (action)="
                                $event === 'set' ? confirmNat(reservation, portForward) : cancelPortNat(portForward)
                              "
                              [(editState)]="portForward.natLoopbackShow"
                              (editStateChange)="$event && enablePortNat(portForward)"
                            ></input-buttons>
                          </div>

                          <modal mode="compact" *ngIf="portForward.showDeleteModal">
                            <ng-container title>
                              {{ 'configurations.dhcp.portForward.modal.title' | translate }}
                            </ng-container>
                            <ng-container content>
                              {{ 'configurations.dhcp.portForward.modal.modal-content' | translate }}
                            </ng-container>
                            <ng-container controls>
                              <button class="tertiary light" (click)="closeDeleteModal(portForward)">
                                {{ 'configurations.dhcp.portForward.modal.cancel' | translate }}
                              </button>
                              <button class="super-primary" (click)="deletePortForward(reservation, portForward)">
                                {{ 'configurations.dhcp.portForward.modal.confirm' | translate }}
                              </button>
                            </ng-container>
                          </modal>
                        </ng-container>
                      </div>
                    </div>
                    <div *ngIf="!reservation.showPortForward" class="card-widget">
                      <button class="action medium" (click)="openPortForward(reservation)">
                        {{ 'configurations.dhcp.portForward.title' | translate }}
                      </button>
                      <button
                        class="action medium"
                        [disabled]="!plume.isSomeAdminRole()"
                        (click)="deleteDhcpReservation(i, reservation.mac)"
                      >
                        {{ 'configurations.dhcp.portForward.delete' | translate }}
                      </button>
                    </div>
                    <div *ngIf="reservation.showPortForward" class="card-form">
                      <div class="columns">
                        <div class="column">
                          <div class="row">
                            <div class="key">{{ 'configurations.dhcp.portForward.form.name' | translate }}</div>
                            <div class="value">
                              <input
                                [formControl]="portForwardName"
                                type="text"
                                placeholder="{{ 'configurations.dhcp.portForward.placeholder.enterName' | translate }}"
                                autofocus
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="key">{{ 'configurations.dhcp.portForward.form.extPort' | translate }}</div>
                            <div class="value">
                              <input
                                class="inputNumber"
                                [formControl]="externalPort"
                                type="number"
                                placeholder="{{
                                  'configurations.dhcp.portForward.placeholder.enterExtPort' | translate
                                }}"
                              />
                            </div>
                            <div class="row">
                              <div class="key">{{ 'configurations.dhcp.portForward.form.intPort' | translate }}</div>
                              <div class="value">
                                <input
                                  class="inputNumber"
                                  [formControl]="internalPort"
                                  type="number"
                                  placeholder="{{
                                    'configurations.dhcp.portForward.placeholder.enterIntPort' | translate
                                  }}"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="column">
                          <div class="row">
                            <div class="key">{{ 'configurations.dhcp.portForward.form.protocol' | translate }}</div>
                            <drop-down-select
                              class="selector medium"
                              [emptyText]="'configurations.dhcp.dhcpReservation.selectProtocol' | translate"
                              [values]="protocolValues"
                              [multiSelect]="false"
                              [(ownModel)]="addProtocolValue"
                            ></drop-down-select>
                          </div>

                          <div class="row" *ngIf="natLoopbackEnable === true">
                            <div class="key">{{ 'configurations.dhcp.portForward.form.natLoopback' | translate }}</div>
                            <drop-down-select
                              class="selector medium"
                              [emptyText]="'configurations.dhcp.dhcpReservation.selectLoopback' | translate"
                              [values]="natValues"
                              [multiSelect]="false"
                              [(ownModel)]="addNatValue"
                            ></drop-down-select>
                          </div>
                        </div>
                      </div>
                      <div class="card-widget">
                        <div class="multi">
                          <button class="action medium" (click)="cancelPortForwarding(reservation)">
                            {{ 'configurations.dhcp.portForward.cancel' | translate }}
                          </button>
                          <button class="action medium" (click)="postPortForwarding(reservation.mac)">
                            {{ 'configurations.dhcp.portForward.save' | translate }}
                          </button>
                        </div>
                        <button class="action medium" (click)="deleteDhcpReservation(i, reservation.mac)">
                          {{ 'configurations.dhcp.portForward.delete' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </collapsiblebox>
  </div>
</div>
