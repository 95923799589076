import { Component, Input, OnChanges } from '@angular/core';
import { Link } from 'src/app/lib/d3/models/objects/link';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ChannelHelper } from 'src/app/lib/helpers/channel.helper';

@Component({
  selector: '[linkVisual]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkVisualComponent implements OnChanges {
  ui: string = '';

  @Input()
  linkVisual: Link;

  @Input()
  showChannel: boolean;

  @Input()
  showBandwidth: boolean;

  constructor(private plume: PlumeService) {}

  ngOnChanges(): void {
    this.ui = this.plume.getUI();
  }

  getChannelColor(): string {
    return this.linkVisual.options?.metadata?.channel
      ? new ChannelHelper().getColor(this.linkVisual.options.metadata.channel)
      : 'var(--link)';
  }

  getOnboardingEffect(): string {
    return this.linkVisual.options?.metadata?.parentVapType === 'onboard' ? 'pulsing 1.5s infinite' : 'none';
  }

  calculate(node: string): number {
    const radius = 35;
    const distance = Math.sqrt(
      Math.pow(this.linkVisual.source.x - this.linkVisual.target.x, 2) +
        Math.pow(this.linkVisual.source.y - this.linkVisual.target.y, 2)
    );

    switch (node) {
      case 'sourcex':
        return (
          this.linkVisual.source.x + (radius * (this.linkVisual.target.x - this.linkVisual.source.x)) / distance || 0
        );
      case 'sourcey':
        return (
          this.linkVisual.source.y + (radius * (this.linkVisual.target.y - this.linkVisual.source.y)) / distance || 0
        );
      case 'targetx':
        return (
          this.linkVisual.target.x + (radius * (this.linkVisual.source.x - this.linkVisual.target.x)) / distance || 0
        );
      case 'targety':
        return (
          this.linkVisual.target.y + (radius * (this.linkVisual.source.y - this.linkVisual.target.y)) / distance || 0
        );
      default:
        return 0;
    }
  }

  calculateChannel(): string {
    const middleX = (this.linkVisual?.source?.x + this.linkVisual?.target?.x) / 2;
    const middleY = (this.linkVisual?.source?.y + this.linkVisual?.target?.y) / 2;
    return 'translate(' + middleX + ', ' + middleY + ')';
  }
}
