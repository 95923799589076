import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as noUiSlider from 'nouislider';
import { debounceTime, take, tap } from 'rxjs/operators';
import { CaptiveService } from 'src/app/lib/services/captive.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import {
  backgroundColorChanged,
  backgroundImageDeleted,
  backgroundImageUploading,
  backgroundRepeatAlignmentChanged,
  languageChanged,
  layerUpdated,
  multilingualChanged,
  redirectEnabledChanged,
  redirectTimeChanged,
  redirectUrlChanged,
  textColorChanged
} from 'src/app/store/captive-portal/captive-portal.actions';
import {
  selectCaptivePortalConfig,
  selectCaptivePortalIsUprise
} from 'src/app/store/captive-portal/captive-portal.selectors';

@UntilDestroy()
@Component({
  selector: 'captive-advanced',
  templateUrl: './captive-advanced.component.html',
  styleUrls: ['./captive-advanced.component.scss']
})
export class CaptiveAdvancedComponent implements OnInit, AfterViewInit, OnDestroy {
  textColor = new FormControl();
  backgroundColor = new FormControl();
  redirectUrl = new FormControl('', { updateOn: 'change' });
  redirectEnabled = true;
  redirectSeconds = 5;
  redirectNoUiSlider: noUiSlider.API;
  multilingual: boolean = false;
  language: { lang_code: string; lang: string };
  languages: { lang_code: string; lang: string }[];
  backgroundType: string[] = [];
  backgroundImagePositionDropdownLabel: string[] = [];
  backgroundImagePositionValues: string[] = [
    'left top',
    'center top',
    'right top',
    'left center',
    'center center',
    'right center',
    'left bottom',
    'center bottom',
    'right bottom'
  ];
  backgroundImageAdjustment: string[] = [];
  selectedBackgroundType: string = '';
  isImageUploaded: boolean = false;
  selectedBackgroundImagePosition: string = '';
  selectedBackgroundImageAdjustment: string = '';
  isUprise$ = this.store.select(selectCaptivePortalIsUprise);

  @ViewChild('redirectSlider') redirectSlider: ElementRef<HTMLDivElement>;

  constructor(private toast: ToastService, private store: Store, private captive: CaptiveService,  private translate: TranslateService, ) {}

  ngOnInit(): void {
    this.initializeTranslations();
    this.store
      .select(selectCaptivePortalConfig)
      .pipe(
        untilDestroyed(this),
        tap((config) => {
          const redirectUrl = config?.postLogin?.redirect?.options.find((option) => option.action === 'URL').url;
          this.redirectEnabled = config?.postLogin?.redirect.enabled;
          this.textColor.setValue(config?.branding?.textColor, { emitEvent: false });
          this.backgroundColor.setValue(config?.branding?.background, { emitEvent: false });
          this.redirectUrl.setValue(redirectUrl, { emitEvent: false });
          this.redirectSeconds = config?.postLogin?.redirect.redirectTime ?? this.redirectSeconds;
          this.multilingual = config?.advancedOptions?.language.showMultilingual;
          this.language = this.captive.getLanguage(config?.advancedOptions?.language.defaultLanguage.lang_code);
          this.languages = this.captive.languages;
          const background = config?.branding?.background;
          if (typeof background === 'string' && background.startsWith('url(')) {
            this.selectedBackgroundType = this.backgroundType[0];
            this.selectedBackgroundImagePosition =
              config?.branding?.backgroundRepeatAlignment?.label ?? 'Center-Center';
            this.selectedBackgroundImageAdjustment =
              config?.branding?.layer.enabled === false
                ? this.backgroundImageAdjustment[0]
                : config?.branding?.layer.layerColor === '#00000080'
                ? this.backgroundImageAdjustment[1]
                : this.backgroundImageAdjustment[2];
            this.isImageUploaded = true;
          } else if (typeof background === 'string' &&
            (/^#[0-9A-F]{6}$/i.test(background) || /^#[0-9A-F]{8}$/i.test(background))
          ) {
            this.selectedBackgroundType = this.backgroundType[1];
          }
        })
      )
      .subscribe();
  }
  initializeTranslations(): void {
    this.backgroundType = [
      this.translate.instant('captiveportal.backgroundTypeOptions.image'),
      this.translate.instant('captiveportal.backgroundTypeOptions.color')
    ];
    this.backgroundImagePositionDropdownLabel = [
      this.translate.instant('captiveportal.backgroundImagePosition.leftTop'),
      this.translate.instant('captiveportal.backgroundImagePosition.centerTop'),
      this.translate.instant('captiveportal.backgroundImagePosition.rightTop'),
      this.translate.instant('captiveportal.backgroundImagePosition.leftCenter'),
      this.translate.instant('captiveportal.backgroundImagePosition.centerCenter'),
      this.translate.instant('captiveportal.backgroundImagePosition.rightCenter'),
      this.translate.instant('captiveportal.backgroundImagePosition.leftBottom'),
      this.translate.instant('captiveportal.backgroundImagePosition.centerBottom'),
      this.translate.instant('captiveportal.backgroundImagePosition.rightBottom')
    ];
    this.backgroundImageAdjustment = [
      this.translate.instant('captiveportal.backgroundImageAdjustment.normal'),
      this.translate.instant('captiveportal.backgroundImageAdjustment.darken'),
      this.translate.instant('captiveportal.backgroundImageAdjustment.lighten')
    ];
    this.selectedBackgroundImagePosition = this.translate.instant('captiveportal.backgroundImagePosition.centerCenter');
    this.selectedBackgroundImageAdjustment = this.translate.instant('captiveportal.backgroundImageAdjustment.normal');
  }
  ngAfterViewInit(): void {
    this.isUprise$.pipe(take(1)).subscribe((isUprise) => {
      if (!isUprise) {
        this.redirectNoUiSlider = noUiSlider.create(this.redirectSlider.nativeElement, {
          start: this.redirectSeconds,
          step: 1,
          connect: 'lower',
          range: {
            min: 5,
            max: 30
          }
        });

        this.textColor.valueChanges.pipe(debounceTime(50), untilDestroyed(this)).subscribe((value: string) => {
          this.store.dispatch(textColorChanged({ color: value }));
        });

        this.backgroundColor.valueChanges.pipe(debounceTime(50), untilDestroyed(this)).subscribe((value: string) => {
          this.store.dispatch(backgroundColorChanged({ color: value }));
          this.store.dispatch(
            layerUpdated({
              layer: {
                enabled: false,
                layerColor: '#FFFFFF80'
              }
            })
          );
        });

        this.redirectNoUiSlider.on('update', (values: string[]) => {
          const time = parseInt(values[0], 10);
          this.store.dispatch(redirectTimeChanged({ time }));
        });
      }
    });

    this.redirectUrl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      value = value.toLowerCase();

      if (this.validateRedirectUrl(value) && value.length) {
        this.store.dispatch(redirectUrlChanged({ url: value, toggle: true }));
      } else {
        this.store.dispatch(redirectUrlChanged({ url: value, toggle: false }));
      }
    });
  }

  selectLanguage(language: { lang: string; lang_code: string }): void {
    this.language = language;
    this.store.dispatch(languageChanged(language));
  }

  changeBackgroundType(option: string): void {
    this.selectedBackgroundType = option;
  }
  changeBackgroundSetting(type: string, value: string): void {
    if (type === 'position') {
      this.selectedBackgroundImagePosition = value;
      const index = this.backgroundImagePositionDropdownLabel.indexOf(value);
      if (index !== -1) {
        const selectedValue = this.backgroundImagePositionValues[index];
        this.store.dispatch(
          backgroundRepeatAlignmentChanged({
            backgroundRepeatAlignment: {
              label: value,
              value: selectedValue
            }
          })
        );
      }
    } else if (type === 'adjustment') {
      this.selectedBackgroundImageAdjustment = value;
      if (value === this.backgroundImageAdjustment[0]) {
        this.store.dispatch(
          layerUpdated({
            layer: {
              enabled: false,
              layerColor: ''
            }
          })
        );
      } else if (value === this.backgroundImageAdjustment[2]) {
        this.store.dispatch(
          layerUpdated({
            layer: {
              enabled: true,
              layerColor: '#FFFFFF80'
            }
          })
        );
      } else if (value === this.backgroundImageAdjustment[1]) {
        this.store.dispatch(
          layerUpdated({
            layer: {
              enabled: true,
              layerColor: '#00000080'
            }
          })
        );
      }
    }
  }
  upload(): void {
    if (document.getElementById('file_graphic_uploader')) {
      document.body.removeChild(document.getElementById('file_graphic_uploader'));
    }

    const input = document.createElement('input');
    input.type = 'file';
    input.id = 'file_graphic_uploader';
    input.accept = 'image/png,image/gif,image/jpeg';

    document.body.appendChild(input);

    input.onchange = () => {
      const path = input.value.split('.');
      const extension = path[path.length - 1];

      if (['png', 'gif', 'jpeg', 'jpg'].includes(extension.toLowerCase())) {
        this.store.dispatch(backgroundColorChanged({ color: '#FFFFFF80' }));
        this.store.dispatch(backgroundImageUploading({ backgroundImage: input.files[0] }));
        this.isImageUploaded = true;
        this.selectedBackgroundType = this.backgroundType[0];
        if (this.selectedBackgroundImageAdjustment === this.backgroundImageAdjustment[0]) {
          this.store.dispatch(
            layerUpdated({
              layer: {
                enabled: false,
                layerColor: '#FFFFFF80'
              }
            })
          );
        }
      } else {
        this.showError('captiveportal.error.invalidFile');
      }

      document.body.removeChild(input);
    };

    input.click();
  }

  delete(): void {
    this.store.dispatch(backgroundImageDeleted({ enabled: false, url: '' }));
    this.selectedBackgroundType = this.backgroundType[0];
    this.isImageUploaded = false;
  }

  showError(errorMsg: string): void {
    this.toast.error(errorMsg, 'captiveportal.error.defaultTitle');
  }

  validateRedirectUrl(value: string): boolean {
    if (!value?.length || /^(http|https):\/\/[^ "]+$/.test(value)) {
      this.toast.dismiss('captiveportal.redirectURL');
      return true;
    } else {
      if (value.length > 4) {
        this.toast.singleton(
          'captiveportal.redirectURL',
          'error',
          'captiveportal.redirect.error',
          'captiveportal.redirect.errorTitle',
          { disableTimeOut: true }
        );
      }

      return false;
    }
  }

  toggleMultilingual(): void {
    this.multilingual = !this.multilingual;
    this.store.dispatch(multilingualChanged({ multilingual: this.multilingual }));
  }

  toggleRedirect(): void {
    if (
      !this.redirectUrl.value?.length ||
      (this.redirectUrl.value?.length && !this.validateRedirectUrl(this.redirectUrl.value))
    ) {
      return;
    }

    this.store.dispatch(redirectEnabledChanged({ enabled: !this.redirectEnabled }));
  }

  ngOnDestroy(): void {
    if (this.redirectNoUiSlider) {
      this.redirectNoUiSlider.destroy();
    }
    if (document.getElementById('file_graphic_uploader')) {
      document.body.removeChild(document.getElementById('file_graphic_uploader'));
    }
  }
}
