import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'logbubble',
  templateUrl: './logBubble.component.html',
  styleUrls: ['../bubbles.scss']
})
export class LogBubbleComponent implements OnInit, OnDestroy {
  @Input()
  id = null;

  @Input()
  buttonText: string = 'health.bubbles.logBubble.continue';

  @Input()
  buttonAction: string = 'confirm';

  recordlog: any = 'health.bubbles.logBubble.recordlog';
  showProgress: boolean = false;
  timeoutInterval: any;
  timeout: number = 5000;
  logSubscription: any;

  constructor(
    private toast: ToastService,
    private clipboard: ClipboardService,
    private troubleShoot: TroubleshootingService,
    private message: MessageService,
    private logging: LoggingService
  ) {}

  ngOnInit(): void {
    this.showProgress = true;
    this.setTimeout();

    this.logSubscription = this.troubleShoot.pulllog.subscribe((response: any) => {
      this.recordlog = response;
      this.showProgress = false;
      this.clearTimeout();
    });

    this.troubleShoot.initPullLog();
  }

  setTimeout(): void {
    this.timeoutInterval = setTimeout(() => {
      this.logging.error('Get log timeout with no response!');
      this.toast.error('toast.logBubble.errorMessage', 'toast.logBubble.errorTitle', {
        disableTimeOut: true
      });

      this.clearTimeout();
    }, this.timeout);
  }

  clearTimeout(): void {
    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval);
    }
  }

  sendMessage(): void {
    this.message.sendMessage({ source: 'log', message: this.buttonAction, msgId: this.id });
  }

  copy(value: string): void {
    this.toast.success('toast.logBubble.successMessage', 'toast.logBubble.successTitle');
    this.clipboard.copyFromContent(value);
  }

  ngOnDestroy(): void {
    this.clearTimeout();

    if (this.logSubscription) {
      this.logSubscription.unsubscribe();
    }
  }
}
