import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { load5gQoeMetrics, loadNeighborReportCns, neighborReportCnsLoaded, neighborReportCnsLoadedError, qoe5GReportsLoaded, qoe5GReportsLoadedError } from './qoeCellular.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable()
export class QoE5gMetricsEffects {
    constructor(
      private actions$: Actions,
      private qoeService: QoeService
    ) {}

    load5gQoeMetrics$ = createEffect(() =>
      this.actions$.pipe(
        ofType(load5gQoeMetrics),
        switchMap(({ startTime, endTime }) => {
          return this.qoeService.get5gQoeMetrics$(startTime, endTime).pipe(
            map((qoe5gReports) => qoe5GReportsLoaded({ qoe5gReports })),
            catchError((error) => of(qoe5GReportsLoadedError({ error })))
          );
        })
      )
    );

    loadNeighborReportCns$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadNeighborReportCns),
        switchMap(() =>
          this.qoeService.getNeighborReportCns$().pipe(
            map((neighborReportCns) => {
              return neighborReportCnsLoaded({ neighborReportCns });
            }),
            catchError((error) => {
              return of(neighborReportCnsLoadedError({ error }));
            })
          )
        )
      )
    );
  }
