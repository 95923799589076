import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as PubNub from 'pubnub';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addMessage, clearAllMessages } from 'src/app/store/guidedTroubleshooting/guidedTroubleshooting.actions';
import { selectGuidedTroubleShootingSessionInProgress } from 'src/app/store/guidedTroubleshooting/guidedTroublshooting.selector';
import { environment } from 'src/environments/environment';
import { CustomerService } from './customer.service';
import { LoggingService } from './logging.service';
import { PlumeService } from './plume.service';

@Injectable({ providedIn: 'root' })
export class GuidedTroubleShootingPubnubService {
  private pubnub: PubNub;
  private pubnubListener: PubNub.ListenerParameters;
  private writeChannel: string = '';
  private inProgress$: Observable<boolean> = this.store.select(selectGuidedTroubleShootingSessionInProgress);
  private sessionInProgress: boolean;

  message: any;
  constructor(
    private plume: PlumeService,
    private log: LoggingService,
    private customer: CustomerService,
    private store: Store
  ) {
    this.inProgress$ = this.store.select(selectGuidedTroubleShootingSessionInProgress);
    this.inProgress$.pipe().subscribe((inProgress) => {
      this.sessionInProgress = inProgress;
    });
  }

  start(): Observable<any> {
    return new Observable((observer) => {
      const env = this.plume.getEnv();
      if (environment.pubnub.enabled && env.signalUrl) {
        this.customer
          .guidedTroubleshootingPubnubSubscribe$()
          .pipe(
            catchError((error) => {
              observer.error(error);
              return of(null);
            })
          )
          .subscribe((credentials) => {
            this.pubnub = new PubNub({
              authKey: credentials?.authKey,
              subscribeKey: credentials?.subscribeKey,
              publishKey: credentials?.publishKey,
              userId: this.plume.getUser()?.sub ? this.plume.getUser().sub : this.plume.customerid
            });
            if (credentials?.token) {
              // TODO remove authKey once fully migrated to token
              this.pubnub.setToken(credentials?.token);
            }

            this.writeChannel = credentials.writeChannels[0];
            this.pubnub.subscribe({
              channels: [credentials.readChannels[0]]
            });

            this.pubnub.addListener({
              status: (status) => {
                if (status.category === 'PNConnectedCategory' && status.operation === 'PNSubscribeOperation') {
                  this.log.log('PubNub for Guided Troubleshooting is connected to:', this.plume.locationid);
                }
              },
              message: (message) => {
                const parsedMessage = JSON.parse(message.message);
                this.store.dispatch(
                  addMessage({
                    message: parsedMessage.message[0],
                    selections: parsedMessage.selections,
                    selectedOption: '',
                    responseType: parsedMessage.responseType
                  })
                );
              }
            });

            this.publishInitiationMessage();
            observer.next('PubNub connected successfully');
            observer.complete();
          });
      }
    });
  }

  checkAndRestart(): void {
    if (!this.sessionInProgress) {
      this.store.dispatch(clearAllMessages());
      this.publishInitiationMessage();
    }
  }

  private publishInitiationMessage(): void {
    const channel = this.writeChannel; // Assuming you have only one subscribed channel
    this.pubnub.publish(
      {
        message: { locale: navigator.language }, // Sending an initiation message
        channel
      },
      (status) => {
        if (status.error) {
          console.error('Publish error:', status.errorData);
        } else {
          this.log.log('Initiation message published successfully!');
        }
      }
    );
  }

  sendSelectedButton(selectedButton: string): void {
    if (this.pubnub) {
      const channel = this.writeChannel; // Assuming you have only one subscribed channel
      this.pubnub.publish(
        {
          message: { userSelection: selectedButton, locale: navigator.language },
          channel
        },
        (status, response) => {
          if (status.error) {
            console.error('Publish error:', status.errorData);
          }
        }
      );
    }
  }

  stop(): void {
    if (this.pubnub) {
      this.store.dispatch(clearAllMessages()); // Clear all messages from the store
      this.pubnub.unsubscribeAll();
      this.pubnub.removeListener(this.pubnubListener);
      delete this.pubnub;
    }
  }
}
