import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { RoutingModule } from 'src/app/lib/app.routing';
import {
  PlumeHttpInterceptor,
  HttpLoaderFactory,
  EnvironmentFactory,
  FirebaseFactory,
  MetasaurusFactory,
  MetasaurusDeviceFactory
} from 'src/app/lib/app.loaders';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { OverlayModule } from '@angular/cdk/overlay';

import { ApiService } from 'src/app/lib/services/api.service';
import { AuthService } from 'src/app/lib/services/auth.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { MessageSchedulerService } from 'src/app/lib/services/messageScheduler.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { AlarmedService } from 'src/app/lib/services/alarmed.service';
import { CriticalService } from 'src/app/lib/services/critical.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { DeviceIconRefService } from 'src/app/lib/services/deviceIconRef.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { ToolbarService } from 'src/app/lib/services/toolbar.service';
import { RoleService } from 'src/app/lib/services/role.service';
import { IconService } from 'src/app/lib/services/icon.service';
import { OktaService } from 'src/app/lib/services/okta.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { FirebaseService } from 'src/app/lib/services/firebase.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { NeighborsService } from 'src/app/lib/services/neighbors.service';
import { QoethreadService } from 'src/app/lib/webworker/qoethread.service';

import { RssiPipe } from 'src/app/lib/pipes/rssi.pipe';
import { DatePipe } from 'src/app/lib/pipes/date.pipe';
import { MacAddrPipe } from './pipes/mac-addr.pipe';
import { CachePipe } from './pipes/cache.pipe';
import { FilterArrayByPipe } from './pipes/filter-array-by.pipe';
import { Ipv6Pipe } from './pipes/ipv6.pipe';
import { Ipv4WanPipe } from './pipes/ipv4-wan.pipe';
import { CustomerNamePipe } from './pipes/customer-name.pipe';
import { EmailPipe } from './pipes/email.pipe';
import { SafePipe } from './pipes/safe.pipe';

import { TogglerDirective } from 'src/app/lib/directives/toggler.directive';
import { IconDirective } from 'src/app/lib/directives/icon.directive';
import { TooltipDirective } from 'src/app/lib/directives/tooltip.directive';
import { AutoreadonlyDirective } from 'src/app/lib/directives/autoreadonly.directive';
import { CountdownDirective } from 'src/app/lib/directives/countdown.directive';
import { KeyboardDirective } from 'src/app/lib/directives/keyboard.directive';
import { AutofocusDirective } from 'src/app/lib/directives/autofocus.directive';
import { DraggableDirective } from 'src/app/lib/d3/directives/draggable.directive';
import { SelectableDirective } from 'src/app/lib/d3/directives/selectable.directive';

import { AppComponent } from 'src/app/lib/app.component';

import { NotfoundComponent } from 'src/app/views/404/404.component';
import { LoginComponent, LoginHandleComponent } from 'src/app/views/login/login.component';
import { ResetPasswordComponent } from 'src/app/views/login/reset/reset.component';
import { LandingComponent } from 'src/app/views/landing/landing.component';
import { CustomerComponent } from 'src/app/views/customer/customer.component';
import { HealthComponent } from 'src/app/views/customer/health/health.component';
import { ProfilerComponent } from 'src/app/views/customer/profiler/profiler.component';
import { FeatureEngagementsComponent } from 'src/app/views/customer/profiler/featureengagements/featureengagements.component';
import { MostActiveDevicesComponent } from 'src/app/views/customer/profiler/mostactivedevices/mostactivedevices.component';
import { GuardCountsComponent } from 'src/app/views/customer/profiler/guardcounts/guardcounts.component';
import { DeviceCategoriesComponent } from 'src/app/views/customer/profiler/devicecategories/devicecategories.component';
import { WanStatsComponent } from 'src/app/views/customer/profiler/wanstats/wanstats.component';
import { ProfilerSummaryComponent } from 'src/app/views/customer/profiler/profilersummary/profilersummary.component';
import { TechnicianComponent } from 'src/app/views/customer/technician/technician.component';
import { NodesComponent } from 'src/app/views/customer/nodes/nodes.component';
import { DevicesComponent } from 'src/app/views/customer/devices/devices.component';
import { TopologyComponent } from 'src/app/views/customer/topology/topology.component';
import { QoeComponent } from 'src/app/views/customer/qoe/qoe.component';
import { ThriveComponent } from 'src/app/views/customer/thrive/thrive.component';
import { TrafficComponent } from 'src/app/views/customer/traffic/traffic.component';
import { TrafficConfigurationComponent } from 'src/app/views/customer/traffic/trafficconfiguration/trafficconfiguration.component';
import { TrafficMonitoringComponent } from 'src/app/views/customer/traffic/trafficmonitoring/trafficmonitoring.component';
import { FlexComponent } from 'src/app/views/customer/flex/flex.component';
import { TimelinesComponent } from 'src/app/views/customer/timelines/timelines.component';
import { SecurityComponent } from 'src/app/views/customer/security/security.component';
import { MotionComponent } from 'src/app/views/customer/motion/motion.component';
import { SpeedtestsComponent } from 'src/app/views/customer/speedtests/speedtests.component';
import { NodestripComponent } from 'src/app/views/customer/technician/nodestrip/nodestrip.component';
import { QoestripComponent } from 'src/app/views/customer/qoe/qoestrip/qoestrip.component';
import { QoestripliveComponent } from 'src/app/views/customer/qoe/qoestriplive/qoestriplive.component';
import { QoesuperliveComponent } from 'src/app/views/customer/qoe/qoesuperlive/qoesuperlive.component';
import { QoeWANComponent } from 'src/app/views/customer/qoe/qoewan/qoewan.component';
import { Qoe5gComponent } from 'src/app/views/customer/qoe/qoe5g/qoe5g.component';
import { LogBubbleComponent } from 'src/app/views/customer/health/bubbles/logBubble/logBubble.component';
import { RebootBubbleComponent } from 'src/app/views/customer/health/bubbles/rebootBubble/rebootBubble.component';
import { InformBubbleComponent } from 'src/app/views/customer/health/bubbles/informBubble/informBubble.component';
import { SpeedTestBubbleComponent } from 'src/app/views/customer/health/bubbles/speedTestBubble/speedTestBubble.component';
import { OptimizeBubbleComponent } from 'src/app/views/customer/health/bubbles/optimizeBubble/optimizeBubble.component';
import { NeighborBubbleComponent } from 'src/app/views/customer/health/bubbles/neighborBubble/neighborBubble.component';
import { NetworkComponent } from 'src/app/views/customer/health/network/network.component';
import { NetworkstatusComponent } from 'src/app/views/customer/health/network/networkstatus/networkstatus.component';
import { NetworkperformanceComponent } from 'src/app/views/customer/health/network/networkperformance/networkperformance.component';
import { WanusageComponent } from 'src/app/views/customer/health/wanusage/wanusage.component';
import { OptimizerTimelineComponent } from 'src/app/views/customer/timelines/optimizertimeline/optimizertimeline.component';
import { OptimizerDetailsComponent } from 'src/app/views/customer/timelines/optimizertimeline/optimizerdetails/optimizerdetails.component';
import { FirmwareTimelineComponent } from 'src/app/views/customer/timelines/firmwaretimeline/firmwaretimeline.component';
import { ReboottimelineComponent } from 'src/app/views/customer/timelines/reboottimeline/reboottimeline.component';
import { RecentEventsTimelineComponent } from 'src/app/views/customer/timelines/recenteventstimeline/recenteventstimeline.component';
import { NodeComponent } from 'src/app/views/customer/nodes/node/node.component';
import { DeviceComponent } from 'src/app/views/customer/devices/device/device.component';
import { DeviceHealthAlarmComponent } from 'src/app/views/customer/health/alarms/devicehealthalarm/devicehealthalarm.component';
import { ExtenderHealthAlarmComponent } from 'src/app/views/customer/health/alarms/extenderhealthalarm/extenderhealthalarm.component';
import { EthernetUplinkAlarmComponent } from '../views/customer/health/alarms/ethernetuplinkalarm/ethernetuplinkalarm.component';
import { ExtenderspeedalarmComponent } from 'src/app/views/customer/health/alarms/extenderspeedalarm/extenderspeedalarm.component';
import { InconsistentspeedalarmComponent } from 'src/app/views/customer/health/alarms/inconsistentspeedalarm/inconsistentspeedalarm.component';
import { CongestionalarmComponent } from 'src/app/views/customer/health/alarms/congestionalarm/congestionalarm.component';
import { CoveragealarmComponent } from 'src/app/views/customer/health/alarms/coveragealarm/coveragealarm.component';
import { InternetconnectionalarmComponent } from 'src/app/views/customer/health/alarms/internetconnectionalarm/internetconnectionalarm.component';
import { NeighborreportalarmComponent } from 'src/app/views/customer/health/alarms/neighborreportalarm/neighborreportalarm.component';
import { StabilityalarmComponent } from 'src/app/views/customer/health/alarms/stabilityalarm/stabilityalarm.component';
import { ExtenderconnectionsComponent } from 'src/app/views/customer/health/alarms/extenderconnections/extenderconnections.component';
import { SpeedtestNodeComponent } from 'src/app/views/customer/speedtests/speedtestnode/speedtestnode.component';
import { SpeedtestControlsComponent } from 'src/app/views/customer/speedtests/speedtestcontrols/speedtestcontrols.component';
import { ConfigurationComponent } from 'src/app/views/customer/configuration/configuration.component';

import { FirmwareComponent } from 'src/app/views/customer/configuration/firmware/firmware.component';
import { SteeringComponent } from 'src/app/views/customer/configuration/steering/steering.component';
import { SmallbusinessComponent } from 'src/app/views/customer/configuration/smallbusiness/smallbusiness.component';
import { UtilitiesComponent } from 'src/app/views/customer/configuration/utilities/utilities.component';
import { CustomerGroupsComponent } from 'src/app/components/customergroups/customergroups.component';
import { OnboardingComponent } from 'src/app/views/customer/configuration/onboarding/onboarding.component';
import { SubscriptionComponent } from 'src/app/views/customer/configuration/subscription/subscription.component';
import { NotificationsComponent } from 'src/app/views/customer/configuration/notifications/notifications.component';
import { OptimizationComponent } from 'src/app/views/customer/configuration/optimization/optimization.component';
import { Ipv6Component } from 'src/app/views/customer/configuration/ipv6/ipv6.component';
import { IptvComponent } from 'src/app/views/customer/configuration/iptv/iptv.component';
import { MulticastComponent } from 'src/app/views/customer/configuration/multicast/multicast.component';
import { DhcpComponent } from 'src/app/views/customer/configuration/dhcp/dhcp.component';
import { CapabilitiesComponent } from 'src/app/views/customer/configuration/capabilities/capabilities.component';
import { PrivacyandSecurityComponent } from 'src/app/views/customer/configuration/privacyandsecurity/privacyandsecurity.component';
import { WanComponent } from 'src/app/views/customer/configuration/wan/wan.component';
import { PowerManagementComponent } from 'src/app/views/customer/configuration/powermanagement/powermanagement.component';
import { DnsComponent } from 'src/app/views/customer/configuration/dns/dns.component';
import { WifiRadioComponent } from 'src/app/views/customer/configuration/wifiradio/wifiradio.component';
import { BleComponent } from 'src/app/views/customer/configuration/ble/ble.component';
import { AgentIntegrationsComponent } from 'src/app/views/customer/configuration/agentintegrations/agentintegrations.component';
import { ThreadNetworkComponent } from '../views/customer/configuration/threadnetwork/threadnetwork.component';
import { LteComponent } from 'src/app/views/customer/configuration/lte/lte.component';
import { GroupsComponent } from 'src/app/views/groups/groups.component';
import { GroupComponent } from 'src/app/views/groups/group/group.component';
import { NetworkRouterComponent } from 'src/app/views/networkRouter/networkRouter.component';
import { ApiexplorerComponent } from 'src/app/views/apiexplorer/apiexplorer.component';
import { NetopsComponent } from 'src/app/views/netops/netops.component';
import { UpgradeReportsComponent } from 'src/app/views/netops/upgradereports/upgradereports.component';
import { UpgradeFirmwareComponent } from 'src/app/views/netops/upgradefirmware/upgradefirmware.component';
import { UpgradeFirmwareV2Component } from 'src/app/views/netops/upgradefirmwarev2/upgradefirmwarev2.component';
import { SupportToolsComponent } from 'src/app/views/netops/supporttools/supporttools.component';
import { ReportsBubbleComponent } from 'src/app/views/netops/bubbles/reportsBubble/reportsBubble.component';
import { LocationBubbleComponent } from 'src/app/views/netops/bubbles/locationBubble/locationBubble.component';

import { NeighborsreportComponent } from 'src/app/components/neighborsreport/neighborsreport.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { HorizontalScrollComponent } from 'src/app/components/horizontalscroll/horizontalscroll.component';
import { SideMenuComponent } from 'src/app/components/sidemenu/sidemenu.component';
import { CollapsibleBoxComponent } from 'src/app/components/collapsiblebox/collapsiblebox.component';
import { CloudstatusComponent } from '../components/cloudstatus/cloudstatus.component';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { LeaftorootComponent } from 'src/app/components/leaftoroot/leaftoroot.component';
import { CustomerinfoComponent } from 'src/app/components/customerinfo/customerinfo.component';
import { LoadingIndicatorComponent } from 'src/app/components/loadingindicator/loadingindicator.component';
import { ToastComponent } from 'src/app/components/toast/toast.component';
import { CookieComponent } from 'src/app/components/cookie/cookie.component';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { SpacerComponent } from 'src/app/components/spacer/spacer.component';
import { SlidetogglerComponent } from 'src/app/components/slidetoggler/slidetoggler.component';
import { EnabletogglerComponent } from 'src/app/components/enabletoggler/enabletoggler.component';
import { FilterrowComponent } from 'src/app/components/filterrow/filterrow.component';
import { FilterdropdownComponent } from 'src/app/components/filterdropdown/filterdropdown.component';
import { LocationAuthorizationComponent } from 'src/app/components/locationauthorization/locationauthorization.component';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';
import { ToolmodalComponent } from 'src/app/components/toolmodal/toolmodal.component';
import { DeviceGroupManagementComponent } from 'src/app/components/devicegroupmanagement/devicegroupmanagement.component';
import { AddnodeComponent } from 'src/app/components/addnode/addnode.component';
import { AuditlogsComponent } from 'src/app/components/auditlogs/auditlogs.component';
import { TaskStatusesComponent } from '../components/taskstatuses/taskstatuses.component';
import { NetworksettingsComponent } from 'src/app/components/networksettings/networksettings.component';
import { SecondaryNetworksComponent } from 'src/app/components/networksettings/secondarynetworks/secondarynetworks.component';
import { HomepassComponent } from 'src/app/components/networksettings/homepass/homepass.component';
import { TabsComponent } from 'src/app/components/tabs/tabs.component';
import { TabComponent } from 'src/app/components/tabs/tab/tab.component';
import { TileComponent } from 'src/app/components/tile/tile.component';

import { D3Service } from 'src/app/lib/d3/service';
import { ForceChartVisualComponent } from 'src/app/lib/d3/visuals/charts/forcechart/forcechart.component';
import { TreeChartVisualComponent } from 'src/app/lib/d3/visuals/charts/treechart/treechart.component';
import { BarChartVisualComponent } from 'src/app/lib/d3/visuals/charts/barchart/barchart.component';
import { HistogramChartVisualComponent } from 'src/app/lib/d3/visuals/charts/histogramchart/histogramchart.component';
import { SpeedtestChartVisualComponent } from 'src/app/lib/d3/visuals/charts/speedtest/speedtest.component';
import { GroupsChartVisualComponent } from 'src/app/lib/d3/visuals/charts/groupschart/groupschart.component';
import { SpeedresultsChartVisualComponent } from 'src/app/lib/d3/visuals/charts/speedresults/speedresults.component';
import { MultibarChartVisualComponent } from 'src/app/lib/d3/visuals/charts/multibarchart/multibarchart.component';
import { LineChartVisualComponent } from 'src/app/lib/d3/visuals/charts/linechart/linechart.component';
import { Qoe15minChartVisualComponent } from 'src/app/lib/d3/visuals/charts/qoe15minchart/qoe15minchart.component';
import { QoeliveChartVisualComponent } from 'src/app/lib/d3/visuals/charts/qoelivechart/qoelivechart.component';
import { NodeChartVisualComponent } from 'src/app/lib/d3/visuals/charts/nodechart/nodechart.component';
import { QoethroughputChartVisualComponent } from 'src/app/lib/d3/visuals/charts/qoethroughputchart/qoethroughputchart.component';
import { SecurityChartVisualComponent } from 'src/app/lib/d3/visuals/charts/securitychart/securitychart.component';
import { TimelinesChartVisualComponent } from 'src/app/lib/d3/visuals/charts/timelineschart/timelineschart.component';
import { CurvedlineChartVisualComponent } from 'src/app/lib/d3/visuals/charts/curvedlinechart/curvedlinechart.component';
import { RecentEventsChartVisualComponent } from 'src/app/lib/d3/visuals/charts/recenteventschart/recenteventschart.component';
import { LinkVisualComponent } from 'src/app/lib/d3/visuals/elements/link/link.component';
import { NodeVisualComponent } from 'src/app/lib/d3/visuals/elements/node/node.component';
import { BarVisualComponent } from 'src/app/lib/d3/visuals/elements/bar/bar.component';
import { LineVisualComponent } from 'src/app/lib/d3/visuals/elements/line/line.component';
import { PathVisualComponent } from 'src/app/lib/d3/visuals/elements/path/path.component';
import { ClippingVisualComponent } from 'src/app/lib/d3/visuals/elements/clipping/clipping.component';
import { TickVisualComponent } from 'src/app/lib/d3/visuals/elements/tick/tick.component';
import { SeriesVisualComponent } from 'src/app/lib/d3/visuals/elements/series/series.component';
import { QoeLiveChartZoomComponent } from 'src/app/lib/d3/visuals/charts/qoe-live-chart-zoom/qoe-live-chart-zoom.component';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { DropDownSelectComponent } from 'src/app/components/drop-down-select/drop-down-select.component';
import { DropDownIconComponent } from 'src/app/components/drop-down-icon/drop-down-icon.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { LabeledInputComponent } from 'src/app/components/labeled-input/labeled-input.component';
import { InputTokenizerComponent } from '../components/input-tokenizer/input-tokenizer.component';
import { BoxComponent } from 'src/app/components/box/box.component';
import { LocationProductsComponent } from 'src/app/components/locationproducts/locationproducts.component';
import { MainMenuComponent } from 'src/app/components/main-menu/main-menu.component';
import { MainMenuLvl1Component } from 'src/app/components/main-menu/main-menu-lvl1/main-menu-lvl1.component';
import { MainMenuLvl2Component } from 'src/app/components/main-menu/main-menu-lvl2/main-menu-lvl2.component';
import { MainMenuLvl3Component } from 'src/app/components/main-menu/main-menu-lvl3/main-menu-lvl3.component';
import { MainMenuTitleComponent } from 'src/app/components/main-menu/main-menu-title/main-menu-title.component';
import { InputButtonsComponent } from 'src/app/components/input-buttons/input-buttons.component';
import { HealthLineComponent } from 'src/app/components/health-line/health-line.component';
import { HealthDotsComponent } from 'src/app/components/health-dots/health-dots.component';
import { HealthBallComponent } from 'src/app/components/health-ball/health-ball.component';
import { TooltipContainerComponent } from 'src/app/components/tooltip-container/tooltip-container.component';
import { LteViewComponent } from 'src/app/views/customer/lte-view/lte-view.component';
import { GraphModule } from './graph/graph.module';
import { DropDownSearchComponent } from 'src/app/components/drop-down-search/drop-down-search.component';
import { GraphNetworkStatusComponent } from './graph-network-status/graph-network-status.component';
import { LocationOnlineStateComponent } from 'src/app/views/customer/health/location-online-state/location-online-state.component';
import { QoelteComponent } from 'src/app/views/customer/qoe/qoelte/qoelte.component';
import { LineTickChartComponent } from './d3/visuals/charts/line-tick-chart/line-tick-chart.component';
import { CreateCustomerComponent } from 'src/app/components/createcustomer/createcustomer.component';
import { META_REDUCERS, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { effects, reducers } from '../store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CaptiveportalComponent } from 'src/app/views/captiveportal/captiveportal.component';
import { CaptivePreviewComponent } from 'src/app/views/captiveportal/captive-preview/captive-preview.component';
import { CaptiveMenuComponent } from 'src/app/views/captiveportal/captive-menu/captive-menu.component';
import { CaptiveInfoComponent } from 'src/app/views/captiveportal/captive-menu/captive-info/captive-info.component';
import { CaptiveOptionsComponent } from 'src/app/views/captiveportal/captive-menu/captive-options/captive-options.component';
import { CaptiveAdvancedComponent } from 'src/app/views/captiveportal/captive-menu/captive-advanced/captive-advanced.component';
import { ComponentClearerComponent } from 'src/app/views/component-clearer/component-clearer.component';
import { metaReducers, storeMetaReducerLoggerFactory } from '../store/metareducers';
import { ReactiveComponentModule } from '@ngrx/component';
import { QoesummaryComponent } from '../views/customer/qoe/qoesummary/qoesummary.component';
import { ChartsModule } from 'ng2-charts';
import { RangeSliderComponent } from '../components/range-slider/range-slider.component';
import { StackedBarComponent } from '../components/stacked-bar/stacked-bar.component';
import { GridChartsDetailsComponent } from '../components/grid-charts-details/grid-charts-details.component';
import { PieChartWithRingComponent } from '../components/pie-chart-with-ring/pie-chart-with-ring.component';
import { TrafficPieMonitorComponent } from '../views/customer/traffic/traffic-pie-monitor/traffic-pie-monitor.component';
import { TrafficPieMonitorDeviceComponent } from '../views/customer/traffic/traffic-pie-monitor/traffic-pie-monitor-device/traffic-pie-monitor-device.component';
import { CaptivePortalEffects } from '../store/captive-portal/captive-portal.effects';
import { TrimValueDirective } from './directives/trim-value.directive';
import { TextInCircleComponent } from '../components/text-in-circle/text-in-circle.component';
import { TrafficDeviceComponent } from '../views/customer/traffic/traffic-device/traffic-device.component';
import { BreadcrumbComponent, BreadcrumbItemDirective } from '../components/breadcrumb/breadcrumb.component';
import { DropDownDeviceComponent } from '../components/drop-down-device/drop-down-device.component';
import { WanConfigurationComponent } from '../views/customer/configuration/wan/wan-configuration/wan-configuration.component';
import { SliderToggleMobileAwareComponent } from '../components/slider-toggle-mobile-aware/slider-toggle-mobile-aware.component';
import { SsoAuditTrailComponent } from '../views/customer/health/network/networkstatus/sso-audit-trail/sso-audit-trail.component';
import { HeaderVoltComponent } from '../components/header-volt/header-volt.component';
import * as captivePortalStore from 'src/app/store/captive-portal/captive-portal.reducer';
import { DeviceRefService } from './services/deviceref.service';

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent,
    LandingComponent,
    CustomerComponent,
    HealthComponent,
    ProfilerComponent,
    FeatureEngagementsComponent,
    MostActiveDevicesComponent,
    GuardCountsComponent,
    DeviceCategoriesComponent,
    WanStatsComponent,
    ProfilerSummaryComponent,
    NodesComponent,
    DevicesComponent,
    TopologyComponent,
    QoeComponent,
    FlexComponent,
    TimelinesComponent,
    SecurityComponent,
    MotionComponent,
    SpeedtestsComponent,
    LoginComponent,
    LoginHandleComponent,
    ResetPasswordComponent,
    TechnicianComponent,
    HeaderComponent,
    HorizontalScrollComponent,
    SideMenuComponent,
    BoxComponent,
    LocationProductsComponent,
    CollapsibleBoxComponent,
    CloudstatusComponent,
    ToolbarComponent,
    CalendarComponent,
    LeaftorootComponent,
    ModalComponent,
    DialogComponent,
    NodestripComponent,
    QoestripComponent,
    QoestripliveComponent,
    QoesuperliveComponent,
    QoeWANComponent,
    Qoe5gComponent,
    ThriveComponent,
    TrafficComponent,
    TrafficConfigurationComponent,
    TrafficMonitoringComponent,
    ExtenderconnectionsComponent,
    LogBubbleComponent,
    RebootBubbleComponent,
    InformBubbleComponent,
    SpeedTestBubbleComponent,
    OptimizeBubbleComponent,
    NeighborBubbleComponent,
    ReportsBubbleComponent,
    LocationBubbleComponent,
    NeighborsreportComponent,
    NetworkComponent,
    NetworkstatusComponent,
    NetworkperformanceComponent,
    WanusageComponent,
    CustomerinfoComponent,
    CongestionalarmComponent,
    CoveragealarmComponent,
    InternetconnectionalarmComponent,
    NeighborreportalarmComponent,
    StabilityalarmComponent,
    SpacerComponent,
    SlidetogglerComponent,
    EnabletogglerComponent,
    FilterrowComponent,
    FilterdropdownComponent,
    LocationAuthorizationComponent,
    LoadingIndicatorComponent,
    NodeComponent,
    DeviceComponent,
    DeviceHealthAlarmComponent,
    ExtenderHealthAlarmComponent,
    EthernetUplinkAlarmComponent,
    ExtenderspeedalarmComponent,
    InconsistentspeedalarmComponent,
    SpeedtestNodeComponent,
    SpeedtestControlsComponent,
    OptimizerTimelineComponent,
    OptimizerDetailsComponent,
    FirmwareTimelineComponent,
    ReboottimelineComponent,
    RecentEventsTimelineComponent,
    ConfigurationComponent,
    FirmwareComponent,
    SteeringComponent,
    SmallbusinessComponent,
    UtilitiesComponent,
    CustomerGroupsComponent,
    OnboardingComponent,
    SubscriptionComponent,
    NotificationsComponent,
    WanComponent,
    PowerManagementComponent,
    OptimizationComponent,
    Ipv6Component,
    IptvComponent,
    MulticastComponent,
    DhcpComponent,
    CapabilitiesComponent,
    PrivacyandSecurityComponent,
    DnsComponent,
    WifiRadioComponent,
    GroupsComponent,
    GroupComponent,
    NetworkRouterComponent,
    ApiexplorerComponent,
    BleComponent,
    AgentIntegrationsComponent,
    ThreadNetworkComponent,
    FirmwareComponent,
    ToastComponent,
    CookieComponent,
    TogglerDirective,
    IconDirective,
    TooltipDirective,
    TrimValueDirective,
    AutoreadonlyDirective,
    CountdownDirective,
    KeyboardDirective,
    AutofocusDirective,
    DraggableDirective,
    SelectableDirective,
    RssiPipe,
    DatePipe,
    CachePipe,
    FilterArrayByPipe,
    MacAddrPipe,
    Ipv6Pipe,
    Ipv4WanPipe,
    CustomerNamePipe,
    EmailPipe,
    SafePipe,
    ForceChartVisualComponent,
    TreeChartVisualComponent,
    BarChartVisualComponent,
    HistogramChartVisualComponent,
    SpeedtestChartVisualComponent,
    GroupsChartVisualComponent,
    SpeedresultsChartVisualComponent,
    MultibarChartVisualComponent,
    LineChartVisualComponent,
    Qoe15minChartVisualComponent,
    QoeliveChartVisualComponent,
    NodeChartVisualComponent,
    QoethroughputChartVisualComponent,
    SecurityChartVisualComponent,
    TimelinesChartVisualComponent,
    CurvedlineChartVisualComponent,
    RecentEventsChartVisualComponent,
    LinkVisualComponent,
    NodeVisualComponent,
    BarVisualComponent,
    LineVisualComponent,
    PathVisualComponent,
    ClippingVisualComponent,
    TickVisualComponent,
    SeriesVisualComponent,
    ToolmodalComponent,
    AddnodeComponent,
    AuditlogsComponent,
    TaskStatusesComponent,
    NetworksettingsComponent,
    SecondaryNetworksComponent,
    HomepassComponent,
    TabsComponent,
    TabComponent,
    TileComponent,
    DeviceGroupManagementComponent,
    NetopsComponent,
    UpgradeFirmwareComponent,
    UpgradeFirmwareV2Component,
    UpgradeReportsComponent,
    SupportToolsComponent,
    LteComponent,
    QoeLiveChartZoomComponent,
    DropDownComponent,
    DropDownSelectComponent,
    DropDownIconComponent,
    DropDownDeviceComponent,
    IconComponent,
    MainMenuComponent,
    MainMenuLvl1Component,
    MainMenuLvl2Component,
    MainMenuLvl3Component,
    MainMenuTitleComponent,
    LabeledInputComponent,
    InputTokenizerComponent,
    InputButtonsComponent,
    HealthLineComponent,
    HealthDotsComponent,
    HealthBallComponent,
    TooltipContainerComponent,
    LteViewComponent,
    DropDownSearchComponent,
    GraphNetworkStatusComponent,
    LocationOnlineStateComponent,
    QoelteComponent,
    LineTickChartComponent,
    CreateCustomerComponent,
    CaptiveportalComponent,
    CaptivePreviewComponent,
    CaptiveMenuComponent,
    CaptiveInfoComponent,
    CaptiveOptionsComponent,
    CaptiveAdvancedComponent,
    ComponentClearerComponent,
    MacAddrPipe,
    Ipv6Pipe,
    Ipv4WanPipe,
    CustomerNamePipe,
    EmailPipe,
    QoesummaryComponent,
    RangeSliderComponent,
    StackedBarComponent,
    GridChartsDetailsComponent,
    PieChartWithRingComponent,
    TrafficPieMonitorComponent,
    TrafficPieMonitorDeviceComponent,
    TextInCircleComponent,
    TrafficDeviceComponent,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    WanConfigurationComponent,
    SliderToggleMobileAwareComponent,
    SsoAuditTrailComponent,
    HeaderVoltComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ClipboardModule,
    NgxJsonViewerModule,
    OverlayModule,
    GraphModule,
    NgxPageScrollCoreModule.forRoot({ duration: 250 }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, HttpBackend]
      }
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreModule.forFeature(captivePortalStore.featureName, captivePortalStore.reducer),
    EffectsModule.forFeature([CaptivePortalEffects]),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production
    }),
    ReactiveComponentModule,
    ChartsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: FirebaseFactory,
      deps: [FirebaseService, HttpClient, HttpBackend],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: EnvironmentFactory,
      deps: [HttpClient, HttpBackend, TranslateService, AuthService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: MetasaurusFactory,
      deps: [ModelRefService, AuthService, HttpClient, HttpBackend],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: MetasaurusDeviceFactory,
      deps: [DeviceRefService, AuthService, HttpClient, HttpBackend],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlumeHttpInterceptor,
      multi: true
    },
    [
      {
        provide: META_REDUCERS,
        deps: [MixpanelService],
        useFactory: storeMetaReducerLoggerFactory,
        multi: true
      }
    ],
    ApiService,
    AuthService,
    PlumeService,
    MessageService,
    LoggingService,
    ModelRefService,
    DeviceRefService,
    DeviceIconRefService,
    MessageSchedulerService,
    TroubleshootingService,
    AlarmedService,
    CriticalService,
    D3Service,
    ToastService,
    ModalService,
    ToolbarService,
    RoleService,
    IconService,
    OktaService,
    ThemeService,
    MixpanelService,
    FirebaseService,
    QoeService,
    NeighborsService,
    QoethreadService,
    CustomerNamePipe,
    EmailPipe,
    MacAddrPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
