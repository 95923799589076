import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ActivatedRoute } from '@angular/router';
import { selectLocationInternet } from 'src/app/store/polling/polling.selector';
import { Store } from '@ngrx/store';

@Component({
  templateUrl: './timelines.component.html',
  styleUrls: ['./timelines.component.scss']
})
export class TimelinesComponent implements OnInit, OnDestroy {
  onboardedSubscription: any;
  onboarded: string = null;
  mode: any = {
    optimizer: false,
    firmware: false,
    reboot: false,
    recentevents: false
  };

  permissionsSubscription: any;
  permissions: any;
  ui: string = '';

  constructor(
    private plume: PlumeService,
    private store: Store,
    private mixpanel: MixpanelService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params: { mode: string }) => {
      const enabledModes = params?.mode?.split(',') ?? [];
      this.mode.optimizer = enabledModes.includes('optimizer');
      this.mode.firmware = enabledModes.includes('firmware');
      this.mode.reboot = enabledModes.includes('reboot');
      this.mode.recentevents = enabledModes.includes('recentevents');
    });
  }

  ngOnInit(): void {
    this.mixpanel.storeEvent('TIMELINES_SCREEN');
    this.ui = this.plume.getUI();

    this.onboardedSubscription = this.store.select(selectLocationInternet).subscribe((response: any) => {
      if (response) {
        this.isOnboarded(response);
      }
    });

    this.permissionsSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });
  }

  isOnboarded(response: any): void {
    const permissions = this.plume.getPermissions();
    try {
      if (permissions.uiFeatures.overrideOnboarded) {
        this.onboarded = 'complete';
      } else {
        if (['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus)) {
          this.onboarded = 'complete';
        } else {
          this.onboarded = 'uncomplete';
        }
      }
    } catch (err) {
      this.onboarded = 'uncomplete';
    }
  }

  ngOnDestroy(): void {
    if (this.onboardedSubscription) {
      this.onboardedSubscription.unsubscribe();
    }

    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
  }
}
