import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class QoethreadService {
  private thread: any = null;
  private workers: any = {};

   start(): boolean {
    if (typeof Worker !== 'undefined') {
      if (!this.thread) {
        this.thread = new Worker('./qoethread.worker', { type: 'module' });

        this.thread.onmessage = ({ data }: any) => {
          this.workers[data.id].next(data.response);
          this.workers[data.id].complete();
          delete this.workers[data.id];
        };
      }
      return true;
    } else {
      return false;
    }
  }

  register(id: any, request: any): Subject<any> {
    this.workers[id] = new Subject<any>();
    this.thread.postMessage({ id, request });
    return this.workers[id];
  }

  stop(): void {
    if (this.thread) {
      this.thread.terminate();
      this.thread = null;
      this.workers = {};
    }
  }
}
