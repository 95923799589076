import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { selectDevices, selectLocationInternet } from 'src/app/store/polling/polling.selector';
import { ProfilerService } from 'src/app/lib/services/profiler.service';
import { IHomeSecurity, IHomeSecurityDevicesSounding, IInternet, IWifiMotion } from 'src/app/lib/interfaces/interface';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './motion.component.html',
  styleUrls: ['./motion.component.scss']
})
export class MotionComponent implements OnInit {
  capabilities$ = this.store.select(selectCapabilities);
  productName: string = 'Sense';

  loading: boolean = true;
  onboarded: string = null;
  enabledMotion: string = null;
  rawDevices: any = null;

  permissions: any = {};
  motionDetection: any[] = [];
  sensitivity: any[] = [];
  petMode: any[] = [];
  motionEvents: any[] = [];
  cooldown: any[] = [];
  enabledDevices: any[] = [];

  constructor(
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private store: Store,
    private profiler: ProfilerService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('PLUME_MOTION_SCREEN');

    this.init();

    this.store
      .pipe(selectPipeLocationOnChange)
      .pipe(untilDestroyed(this))
      .subscribe((location) => {
        this.productName = location?.profile === 'smallbusiness' ? 'Flow' : 'Sense';
      });

    this.store
      .select(selectDevices)
      .pipe(untilDestroyed(this))
      .subscribe((devices) => {
        this.rawDevices = devices;
      });

    this.store
      .select(selectLocationInternet)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (response) {
          this.isOnboarded(response);
        }
      });

    this.plume.permissions.pipe(untilDestroyed(this)).subscribe((data: any) => {
      this.permissions = data;
    });
  }

  init(): void {
    if (this.rawDevices) {
      forkJoin({
        wifiMotion: this.profiler.wifiMotion$().pipe(catchError((error: HttpErrorResponse) => of(error))),
        homeSecurity: this.profiler.homeSecurity$().pipe(catchError((error: HttpErrorResponse) => of(error))),
        sounding: this.profiler.homeSecurityDevicesSounding$().pipe(catchError((error: HttpErrorResponse) => of(error)))
      }).subscribe((responses) => {
        for (const key of Object.keys(responses)) {
          if (responses[key] instanceof HttpErrorResponse) {
            this.enabledMotion = 'false';
            break;
          }

          if (key === 'wifiMotion') {
            this.setWifiMotion((responses[key] as IWifiMotion).auto);
          }

          if (key === 'homeSecurity') {
            this.setSensitivity((responses[key] as IHomeSecurity).sensitivity);
            this.setPetMode((responses[key] as IHomeSecurity).petMode);
            this.setMotionEvents((responses[key] as IHomeSecurity).motionEventsEnabled);
            this.setCooldown((responses[key] as IHomeSecurity).cooldown);
          }

          if (key === 'sounding') {
            this.setSoundingDevices((responses[key] as IHomeSecurityDevicesSounding).soundingStates);
          }
        }

        if (this.enabledMotion !== 'false') {
          this.enabledMotion = 'true';
        }

        this.loading = false;
      });
    } else {
      setTimeout(() => this.init(), 200);
    }
  }

  updateSettings(mode: string, value: any): void {
    const params: Parameters<ProfilerService['homeSecuritySensitivity$']>[0] = {};

    switch (mode) {
      case 'wifiMotion':
        this.profiler.wifiMotionPatch$({ auto: value }).subscribe((response) => {
          this.setWifiMotion(response.auto);
          this.mixpanel.storeEvent('PLUME_MOTION_DETECTION', { ENABLED: response.auto });
        });
        break;
      case 'motionEvents':
        this.profiler
          .homeSecurityPatch$({
            motionEventsEnabled: value,
            source: 'user'
          })
          .subscribe((response) => {
            this.setMotionEvents(response.motionEventsEnabled);
            this.mixpanel.storeEvent('PLUME_MOTION_ALARMS', { ENABLED: response.motionEventsEnabled });
          });
        break;
      case 'sensitivity':
        params.sensitivity = value;

        if (value !== 'high') {
          params.petMode = 'none';
        }

        this.profiler.homeSecuritySensitivity$(params).subscribe((response) => {
          this.setSensitivity(response.sensitivity);
          this.mixpanel.storeEvent('PLUME_MOTION_SENSITIVITY', { SENSITIVITY: response.sensitivity });

          if (value !== 'high') {
            this.setPetMode(response.petMode);
            this.mixpanel.storeEvent('PLUME_MOTION_PETS', { MODE: response.petMode });
          }
        });
        break;
      case 'petMode':
        params.petMode = value;

        if (value === 'over30') {
          params.sensitivity = 'high';
        }

        this.profiler.homeSecuritySensitivity$(params).subscribe((response) => {
          this.setPetMode(response.petMode);
          this.mixpanel.storeEvent('PLUME_MOTION_PETS', { MODE: response.petMode });

          if (value === 'over30') {
            this.setSensitivity(response.sensitivity);
            this.mixpanel.storeEvent('PLUME_MOTION_SENSITIVITY', { SENSITIVITY: response.sensitivity });
          }
        });
        break;
      case 'cooldown':
        this.profiler.homeSecuritySensitivity$({ cooldown: value }).subscribe((response) => {
          this.setCooldown(response.cooldown);
          this.mixpanel.storeEvent('PLUME_MOTION_COOLDOWN', { COOLDOWN: response.cooldown });
        });
        break;
      case 'enabledDevices':
        this.profiler
          .homeSecurityDevicesSoundingPatch$({ soundingStates: this.enabledDevices })
          .subscribe((response) => {
            this.setSoundingDevices(response.soundingStates);
            this.mixpanel.storeEvent('PLUME_MOTION_DEVICE_DETECTION', { MAC: value.mac, ENABLED: value.auto });
          });
        break;
    }
  }

  setWifiMotion(value: boolean): void {
    this.motionDetection = [
      { value: true, translation: 'motion.on', selected: value },
      { value: false, translation: 'motion.off', selected: !value }
    ];
  }

  setSensitivity(value: string): void {
    this.sensitivity = [
      { value: 'low', translation: 'motion.low', selected: value === 'low' },
      { value: 'medium', translation: 'motion.medium', selected: value === 'medium' },
      { value: 'high', translation: 'motion.high', selected: value === 'high' }
    ];
  }

  setPetMode(value: string): void {
    this.petMode = [
      { value: 'over30', translation: 'motion.on', selected: value === 'over30' },
      { value: 'none', translation: 'motion.off', selected: value === 'none' }
    ];
  }

  setMotionEvents(value: boolean): void {
    this.motionEvents = [
      { value: true, translation: 'motion.on', selected: value },
      { value: false, translation: 'motion.off', selected: !value }
    ];
  }

  setCooldown(value: number): void {
    this.cooldown = [
      { value: 60, translation: 'motion.min1', selected: value === 60 },
      { value: 120, translation: 'motion.min2', selected: value === 120 },
      { value: 180, translation: 'motion.min3', selected: value === 180 },
      { value: 300, translation: 'motion.min5', selected: value === 300 }
    ];
  }

  setSoundingDevices(value: IHomeSecurityDevicesSounding['soundingStates']): void {
    const soundingDevices = value.filter((state: any) => {
      const device = this.rawDevices.find((device: any) => device.mac === state.mac) || null;

      if (device) {
        state.name = device.name;

        state.autoToggler = [
          { value: true, translation: 'motion.on', selected: state.auto },
          { value: false, translation: 'motion.off', selected: !state.auto }
        ];

        return state;
      }
    });

    this.enabledDevices = soundingDevices.sort((a: any, b: any) => {
      return a.name.localeCompare(b.name) || a.mac.localeCompare(b.mac);
    });
  }

  track(index: number, device: any): string {
    return device.mac;
  }

  isOnboarded(response: IInternet): void {
    try {
      const permissions = this.plume.getPermissions();

      if (permissions.uiFeatures.overrideOnboarded) {
        this.onboarded = 'complete';
      } else {
        if (['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus)) {
          this.onboarded = 'complete';
        } else {
          this.onboarded = 'uncomplete';
        }
      }
    } catch (err) {
      this.onboarded = 'uncomplete';
    }
  }
}
