<div class="container-fluid">
  <ng-container *ngIf="onboarded == 'uncomplete' && ui != 'noc'">
    <spacer>{{ 'completeOnboarding' | translate }}</spacer>
  </ng-container>
  <ng-container *ngIf="onboarded == 'complete' || ui == 'noc'">
    <spacer>{{ 'timelines.title' | translate }}</spacer>
    <optimizertimeline [active]="mode.optimizer"></optimizertimeline>
    <firmwaretimeline [active]="mode.firmware"></firmwaretimeline>
    <reboottimeline [active]="mode.reboot" *ngIf="permissions.uiFeatures.rebootTimeMachine"></reboottimeline>
    <recenteventstimeline
      [active]="mode.recentevents"
      *ngIf="permissions.uiFeatures.recentEvents"
    ></recenteventstimeline>
  </ng-container>
</div>
