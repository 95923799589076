import { Component, Input } from '@angular/core';

@Component({
  selector: 'health-dots',
  templateUrl: './health-dots.component.html',
  styleUrls: ['./health-dots.component.scss']
})
export class HealthDotsComponent {
  @Input()
  public set rssiOrQoe(
    v:
      | {
          qoe: { qoe: number };
        }
      | {
          rssi?: number;
          health?: {
            details?: {
              channelGain: number;
            };
          };
        }
  ) {
    this.qoeObj = v && 'qoe' in v ? v : undefined;
    this.rssiObj = !v ? undefined : !('qoe' in v) ? v : undefined;
  }
  qoeObj: {
    qoe: { qoe: number };
  } = null;

  rssiObj: {
    rssi?: number;
    health?: {
      details?: {
        channelGain: number;
      };
    };
  } = null;
  constructor() {}
}
