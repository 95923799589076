import { Pipe, PipeTransform } from '@angular/core';
import { PlumeService } from '../services/plume.service';

@Pipe({
  name: 'email'
})
export class EmailPipe implements PipeTransform {
  constructor(private plume: PlumeService) {}

  transform(value?: string): string {
    if (this.plume.hidePersonalDetails()) {
      return `*****@*****.***`;
    }
    return value;
  }
}
