<div class="row" id="conf-firmware">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.firmware.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column" *ngrxLet="firmwareUpgrade$ as firmwareUpgrade">
            <div class="row" *ngIf="firmwareUpgrade?.activeVersionMatrixId">
              <div class="key">{{ 'configurations.firmware.activeVersionMatrix' | translate }}</div>
              <div class="value">
                <div>{{ firmwareUpgrade?.activeVersionMatrixId }}</div>
                <icon name="icon-info" class="size-0_8" (click)="showMatrixDetails = true"></icon>
              </div>
            </div>
            <div class="row" *ngIf="firmwareUpgrade?.pendingVersionMatrixId">
              <div class="key">{{ 'configurations.firmware.targetVersionMatrix' | translate }}</div>
              <div class="value">{{ firmwareUpgrade?.pendingVersionMatrixId }}</div>
            </div>
            <div
              class="row"
              *ngIf="!firmwareUpgrade?.pendingVersionMatrixId && !firmwareUpgrade?.activeVersionMatrixId"
            >
              <div class="key">{{ 'configurations.firmware.targetVersionMatrix' | translate }}</div>
              <div class="value">{{ 'configurations.firmware.noTargetVersionMatrix' | translate }}</div>
            </div>
            <div
              class="row"
              *ngIf="firmwareUpgrade?.updatedAt && firmwareUpgrade?.updatedAt != '1970-01-01T00:00:00.000Z'"
            >
              <div class="key">{{ 'configurations.firmware.updated' | translate }}</div>
              <div class="value">{{ firmwareUpgrade?.updatedAt | date: 'LLL' }}</div>
            </div>
            <button class="action medium" (click)="openMatrixSelect()" *ngIf="canUpgradeFirmware">
              {{ 'configurations.firmware.selectVersionMatrix' | translate }}
            </button>
          </div>
          <div class="column fixed">
            <button class="action medium" (click)="showMinimalVersion = true">
              {{ 'configurations.firmware.minimumVersions' | translate }}
            </button>
            <button class="action medium" (click)="navigateTimelines()">
              {{ 'configurations.firmware.upgradeFtm' | translate }}
            </button>
          </div>
        </div>
        <div class="details" *ngIf="details">
          <div class="table">
            <div class="status">
              <div class="column">
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.target' | translate }}</div>
                  <div class="value">{{ details.target }}</div>
                </div>
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.createdAt' | translate }}</div>
                  <div class="value">{{ details.created | date: 'LLL' }}</div>
                </div>
              </div>
              <div class="column">
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.status' | translate }}</div>
                  <div class="value status">
                    <icon name="icon-spinner" class="spin" *ngIf="inProgress"></icon>
                    {{ details.status }}
                  </div>
                </div>
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.updatedAt' | translate }}</div>
                  <div class="value">{{ details.updated | date: 'LLL' }}</div>
                </div>
              </div>
            </div>
            <div class="headers">
              <div class="node">{{ 'configurations.firmware.nodeId' | translate }}</div>
              <div class="original">{{ 'configurations.firmware.originalFirmware' | translate }}</div>
              <div class="final">{{ 'configurations.firmware.updatedTo' | translate }}</div>
            </div>
            <div class="data" *ngFor="let node of details.nodes">
              <div class="node">{{ node.id }}</div>
              <div class="original">
                <div class="firmware">
                  {{
                    node.initFirmware.length ? node.initFirmware : ('configurations.firmware.noFirmwareSet' | translate)
                  }}
                </div>
                <div class="state">{{ node.initState }}</div>
              </div>
              <div class="final">
                <div class="firmware" *ngIf="!inProgress">
                  {{
                    node.finalFirmware.length ? node.finalFirmware : ('configurations.firmware.noChange' | translate)
                  }}
                </div>
                <div class="state">
                  <icon name="icon-spinner" class="size-0_7 spin" *ngIf="inProgress"></icon>
                  {{ node.finalState }}
                  <icon
                    name="icon-info"
                    class="size-0_7"
                    [tooltip]="node.errorDescription"
                    *ngIf="node.finalState === 'failed'"
                  ></icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>

<modal mode="compact" (overlayAction)="showMinimalVersion = $event" *ngIf="showMinimalVersion">
  <ng-container title>{{ 'configurations.firmware.minimumVersions' | translate }}</ng-container>
  <ng-container content>
    <div class="row" *ngFor="let version of minimumVersions">
      <div class="key">{{ version.model }}</div>
      <div class="value">{{ version.version }}</div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showMinimalVersion = false">
      {{ 'configurations.firmware.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal (overlayAction)="showMatrixDetails = $event" *ngIf="showMatrixDetails">
  <ng-container title>{{ 'configurations.firmware.activeVersionMatrixDetails' | translate }}</ng-container>
  <ng-container content>
    <div class="row name">
      <div class="key">{{ 'configurations.firmware.activeMatrix' | translate }}</div>
      <div class="value">{{ matrixDetails.versionMatrix }}</div>
    </div>

    <div class="row name" *ngIf="matrixDetails.versionMatrixDescription">
      <div class="key">{{ 'configurations.firmware.activeVersionMatrixDescription' | translate }}</div>
      <div class="value">{{ matrixDetails.versionMatrixDescription }}</div>
    </div>

    <div class="row date">
      <div class="key">{{ 'configurations.firmware.createdAt' | translate }}</div>
      <div class="value">{{ matrixDetails.createdAt | date: 'LLL' }}</div>
    </div>
    <div class="list">
      <div class="model" *ngFor="let model of matrixDetails.models">
        <div class="info">
          <div class="row type">
            <div class="key">{{ 'configurations.firmware.model' | translate }}</div>
            <div class="value">{{ model.model }}</div>
          </div>
          <div class="row version">
            <div class="key">{{ 'configurations.firmware.version' | translate }}</div>
            <div class="value">{{ model.firmwareVersion }}</div>
          </div>
          <icon
            name="icon-arrow-down"
            class="size-0_8"
            [class.rotate180]="model.expand"
            (click)="model.expand = !model.expand"
          ></icon>
        </div>
        <div class="details" [class.expand]="model.expand">
          <div class="row">
            <div class="key">{{ 'configurations.firmware.residentialGateway' | translate }}</div>
            <div class="value">{{ model.residentialGateway }}</div>
          </div>
          <div class="row">
            <div class="key">{{ 'configurations.firmware.downloadUrl' | translate }}</div>
            <div class="value">{{ model.downloadUrl }}</div>
          </div>
          <div class="row">
            <div class="key">{{ 'configurations.firmware.encryptedUrl' | translate }}</div>
            <div class="value">{{ model.encryptedDownloadUrl }}</div>
          </div>
          <div class="row" *ngIf="model.modules">
            <div class="key">{{ 'configurations.firmware.modules' | translate }}</div>
            <div class="value">
              <div class="module header">
                <div class="name">{{ 'configurations.firmware.name' | translate }}</div>
                <div class="version">{{ 'configurations.firmware.version' | translate }}</div>
                <div class="filename">{{ 'configurations.firmware.filename' | translate }}</div>
              </div>
              <div class="module" *ngFor="let module of model.modules">
                <div class="name">{{ module.name }}</div>
                <div class="version">{{ module.version }}</div>
                <div class="filename">{{ module.filename }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showMatrixDetails = false">
      {{ 'configurations.firmware.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="wide" class="version-select" (overlayAction)="showMatrixSelect = $event" *ngIf="showMatrixSelect">
  <ng-container title>{{ 'configurations.firmware.selectMatrix' | translate }}</ng-container>
  <ng-container content>
    <div class="row withGap">
      <div>{{ 'configurations.firmware.filterRelevantToThisLocation' | translate }}</div>
      <div class="toggler" (click)="filterLocationSpecificVmToggle()">
        <div class="checkbox">
          <label for="toggleChannel" class="toggle" [class.checked]="filterLocationSpecificVm"></label>
        </div>
      </div>
    </div>
    <ng-container *ngIf="matrices.length === 0 && !search.value">
      {{ 'configurations.firmware.noApplicableFirmware' | translate }}
    </ng-container>
    <ng-container *ngIf="matrices.length > 0 || search.value?.length > 0">
      <div class="top">
        <div class="left">
          <ng-container *ngIf="matrixDetails">
            <div class="row name">
              <div class="key">{{ 'configurations.firmware.activeMatrix' | translate }}</div>
              <div class="value">{{ matrixDetails.versionMatrix }}</div>
            </div>

            <div class="row name" *ngIf="matrixDetails.versionMatrixDescription">
              <div class="key">{{ 'configurations.firmware.activeVersionMatrixDescription' | translate }}</div>
              <div class="value">{{ matrixDetails.versionMatrixDescription }}</div>
            </div>

            <div class="row date">
              <div class="key">{{ 'configurations.firmware.createdAt' | translate }}</div>
              <div class="value">{{ matrixDetails.createdAt | date: 'LLL' }}</div>
            </div>
          </ng-container>
        </div>
        <div class="right">
          <slidetoggler [options]="downgradeItems" (toggle)="toggleDowngrade($event)"></slidetoggler>
          <div class="actions" *ngIf="matrixDetails">
            <button class="action medium" (click)="clear()">
              {{ 'configurations.firmware.clearTarget' | translate }}
            </button>
            <button class="action medium" (click)="disable()">
              {{ 'configurations.firmware.disableActiveMatrix' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="sections">
        <div class="section">
          <div class="search title">
            <span>
              {{ 'configurations.firmware.searchVersions' | translate }}
            </span>
            <span>
              {{ 'configurations.firmware.includeModelVersion' | translate }}
              <div class="toggler" (click)="filterIncludeModelsToggle()">
                <div class="checkbox">
                  <label class="toggle" [class.checked]="filterIncludeModels"></label>
                </div>
              </div>
            </span>
          </div>
          <div class="search">
            <input
              type="text"
              placeholder="{{ 'configurations.firmware.versionsPlaceholder' | translate }}"
              (input)="filterSearch()"
              [formControl]="search"
            />
          </div>
          <div class="list">
            <div
              class="version"
              [class.selected]="selectedVersion && version.versionMatrix === selectedVersion.versionMatrix"
              *ngFor="let version of matrices; trackBy: matricesTrack"
              (mouseenter)="versionDetails = version"
              (mouseleave)="versionDetails = selectedVersion ? selectedVersion : null"
              (click)="versionDetails = version; selectedVersion = version"
            >
              {{ version.versionMatrix }}
            </div>
          </div>
        </div>
        <div class="section">
          <div class="list" *ngIf="versionDetails">
            <div class="row date">
              <div class="key">{{ 'configurations.firmware.createdAt' | translate }}</div>
              <div class="value">{{ versionDetails.createdAt | date: 'LLL' }}</div>
            </div>
            <div class="row description" *ngIf="versionDetails.versionMatrixDescription">
              <div class="key">{{ 'configurations.firmware.activeVersionMatrixDescription' | translate }}</div>
              <div class="value">{{ versionDetails.versionMatrixDescription }}</div>
            </div>
            <div class="model" *ngFor="let model of versionDetails.models">
              <div class="row">
                <div class="key">{{ 'configurations.firmware.model' | translate }}</div>
                <div class="value">{{ model.model }}</div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.firmware.version' | translate }}</div>
                <div class="value">{{ model.firmwareVersion }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container controls>
    <div class="desktop">
      <button class="tertiary light" (click)="closeMatrixSelect()">
        {{ 'configurations.firmware.close' | translate }}
      </button>
      <div class="update" *ngIf="selectedVersion">
        <slidetoggler [options]="asapItems" (toggle)="toggleAsap($event)"></slidetoggler>
        <button class="super-primary" (click)="requestUpdate()">
          {{ 'configurations.firmware.requestUpdate' | translate }}
        </button>
      </div>
    </div>
    <button class="super-primary mobile" (click)="requestUpdate()" *ngIf="selectedVersion">
      {{ 'configurations.firmware.requestUpdate' | translate }}
    </button>
  </ng-container>
</modal>
