import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SpeedTestService } from 'src/app/lib/services/speed-test.service';
import { speedTestConfigure, speedTestConfigureError, speedTestConfigureFinished } from './speed-test.actions';

@Injectable()
export class SpeedTestEffects {
  speedTestConfigure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(speedTestConfigure),
      switchMap(({ configuration }) =>
        this.speedTestService.configure$(configuration).pipe(
          map((value) => speedTestConfigureFinished({ value })),
          catchError((error) => {
            return of(speedTestConfigureError({ error }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private speedTestService: SpeedTestService) {}
}
