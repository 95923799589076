import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ILocationBackhaul } from 'src/app/lib/interfaces/interface';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { SecondaryNetworksService } from 'src/app/lib/services/secondary-networks.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { backhaulSetFinished, dppSet, profileSet } from 'src/app/store/configview/config.actions';
import { selectCapabilities, selectCapabilityWpa3 } from 'src/app/store/customer/capabilities.selector';
import {
  selectIsUpriseLocation,
  selectPipeLocationOnChange,
  selectPipeLocationOnChangeOptimistic
} from 'src/app/store/customer/customer.selectors';
import { pollingPull } from 'src/app/store/polling/polling.actions';
import { selectLocationInternet, selectNodes } from 'src/app/store/polling/polling.selector';

@UntilDestroy()
@Component({
  selector: 'onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit, OnChanges {
  capabilities$ = this.store.select(selectCapabilities);
  location$ = this.store.pipe(selectPipeLocationOnChange).pipe(
    tap((location) => {
      this.getWifinetwork();

      if (location && !location.backhaul) {
        this.logging.error('ERROR: Unable to find backhaul in location');
      }
    })
  );
  locationOptimistic$ = this.store.pipe(selectPipeLocationOnChangeOptimistic);
  isUprise$ = this.store.select(selectIsUpriseLocation);
  expand: boolean = false;
  wdsItems$ = this.locationOptimistic$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected: location.backhaul.wds !== 'enable' && location.backhaul.wds !== 'disable'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.backhaul.wdsEnable === true,
        selected: location.backhaul.wds === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.backhaul.wdsEnable === false,
        selected: location.backhaul.wds === 'disable'
      }
    ])
  );
  profileItems$ = this.locationOptimistic$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'configurations.onboarding.homePass',
        selected: location.profile === 'auto'
      },
      {
        value: 'smallbusiness',
        translation: 'configurations.onboarding.workPass',
        selected: location.profile === 'smallbusiness'
      }
    ])
  );
  onboardingItems$ = this.locationOptimistic$.pipe(
    map((location) => [
      { value: 'auto', translation: 'auto', selected: location.backhaul.mode === 'auto' },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.backhaul.enable === true,
        selected: location.backhaul.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.backhaul.enable === false,
        selected: location.backhaul.mode === 'disable'
      }
    ])
  );
  dynamicbeaconItems$ = this.locationOptimistic$.pipe(
    map((location) => [
      { value: 'auto', translation: 'auto', selected: location.backhaul.dynamicBeacon === 'auto' },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.backhaul.dynamicBeaconEnable === true,
        selected: location.backhaul.dynamicBeacon === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.backhaul.dynamicBeaconEnable === false,
        selected: location.backhaul.dynamicBeacon === 'disable'
      }
    ])
  );
  backhaulWpaMode: any[] = [];
  dppItems$ = this.locationOptimistic$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected: location.dppConfiguration && location.dppConfiguration.mode === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.dppConfiguration && location.dppConfiguration.modeRealized === 'enable',
        selected: location.dppConfiguration && location.dppConfiguration.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.dppConfiguration && location.dppConfiguration.modeRealized === 'disable',
        selected: location.dppConfiguration && location.dppConfiguration.mode === 'disable'
      }
    ])
  );
  hitlessTopologyItems$ = this.locationOptimistic$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected: location.backhaul && location.backhaul.hitlessTopology === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.backhaul && location.backhaul.hitlessTopologyEnable === true,
        selected: location.backhaul && location.backhaul.hitlessTopology === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.backhaul && location.backhaul.hitlessTopologyEnable === false,
        selected: location.backhaul && location.backhaul.hitlessTopology === 'disable'
      }
    ])
  );

  wifiNetwork: any = {};
  wpa3capable$ = this.store.select(selectCapabilityWpa3).pipe(map((capable) => capable));
  onboardingEditLoading = false;
  internet: any = null;
  bleSelection: any = {
    current: '--',
    currentNode: 'configurations.onboarding.allNodes',
    bleModes: ['on', 'off', 'wps', 'connectable']
  };
  wpsSelection: any = {
    currentKey: 'configurations.onboarding.selectKeyOrDefault',
    currentKeyString: 'configurations.onboarding.selectKeyOrDefault',
    currentNode: 'configurations.onboarding.selectNode',
    enabled: false,
    keys: [],
    networkId: ''
  };
  nodes: any[] = [];
  fronthaul$: Observable<{ text: string; value: string }[]>;
  confirmProfileChange = { message: 'configurations.onboarding.confirmProfileChange' };
  network = null;
  backhaulResponse: ILocationBackhaul = null;

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private customerService: CustomerService,
    private secondaryNetworks: SecondaryNetworksService,
    private toast: ToastService,
    private mixpanel: MixpanelService,
    private logging: LoggingService,
    private translate: TranslateService,
    private troubleShoot: TroubleshootingService,
    private nodeService: NodeService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.registerFilter();

    this.store
      .select(selectNodes)
      .pipe(untilDestroyed(this))
      .subscribe((nodes) => {
        if (nodes) {
          this.nodes = nodes;
        }
      });

    this.store
      .select(selectLocationInternet)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        this.internet = response;
        // onboarding state changed, so editing is complete
        this.onboardingEditLoading = false;
      });

    this.customerService
      .getSSIDs$()
      .pipe(untilDestroyed(this))
      .subscribe((network) => {
        this.network = network;
      });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_ONBOARDING_SCREEN');
    }
  }

  getWifinetwork(): void {
    this.customerService.getWifiNetwork$().subscribe((response) => {
      this.wifiNetwork = response.wifiNetwork;
      this.wpsSelection.keys = this.wifiNetwork.keys;
      this.getBackhaul();
      this.getFronthaul();
    });
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'onboarding' });

    this.translate
      .get('configurations.onboarding.wds')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'wds', translation: translated })
      );

    this.translate
      .get('configurations.onboarding.profile')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'profile', translation: translated })
      );

    // Adding smallbusiness as a searchable phrase
    this.translate
      .get('configurations.onboarding.smallBusiness')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'profile', translation: translated })
      );

    this.translate
      .get('configurations.onboarding.dynamicbeacon')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'dynamicbeacon', translation: translated })
      );

    this.translate
      .get('configurations.onboarding.onboardnetwork')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'onboardnetwork', translation: translated })
      );

    this.translate
      .get('configurations.onboarding.bleMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'bleMode', translation: translated })
      );

    this.translate
      .get('configurations.onboarding.wps')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'wps', translation: translated })
      );

    this.translate
      .get('configurations.onboarding.dpp')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'dpp', translation: translated })
      );

    this.translate
      .get('configurations.onboarding.backhaulWpaMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'backhaulWpaMode', translation: translated })
      );

    this.translate
      .get('configurations.onboarding.hitlessTopology')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'hitlessTopology', translation: translated })
      );

    this.translate
      .get('configurations.onboarding.onboardingState')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'onboarding', property: 'onboardingState', translation: translated })
      );
  }

  action(command: string, action: string, backhaul: ILocationBackhaul): void {
    switch (command) {
      case 'wds':
        this.putBackhaul(command, action, { ...backhaul, wds: action });
        break;
      case 'onboardnetwork':
        this.putBackhaul(command, action, { ...backhaul, mode: action });
        break;
      case 'dynamicbeacon':
        this.putBackhaul(command, action, { ...backhaul, dynamicBeacon: action });
        break;
      case 'profile':
        this.store.dispatch(profileSet({ profile: action as any }));
        break;
      case 'dpp':
        this.store.dispatch(dppSet({ dpp: action as any }));
        break;
      case 'backhaulWpaMode':
        this.putBackhaul(command, action, { ...backhaul, wpaMode: action });
        break;
      case 'hitlessTopology':
        this.putBackhaul(command, action, { ...backhaul, hitlessTopology: action });
        break;
    }
  }

  getBackhaul(): void {
    this.customerService.getBackhaul$().subscribe((response) => {
      this.backhaulResponse = response;
      this.setBackhaulWpaModeToggler(response);
    });
  }

  private getFronthaul(): void {
    this.fronthaul$ = this.secondaryNetworks
      .getFronthaul$()
      .pipe(
        map((values) => [
          { text: 'configurations.onboarding.defaultNetwork', value: '' },
          ...values.map((value) => ({ text: value.ssid, value: value.networkId }))
        ])
      );
  }

  putBackhaul(command: string, action: string, backhaul: ILocationBackhaul): void {
    this.customerService.updateBackhaul$(backhaul).subscribe(
      (response) => {
        this.mixpanel.storeEvent('CONFIGURATION_BACKHAUL_PUT', { COMMAND: command, ACTION: action });
        this.store.dispatch(backhaulSetFinished({ backhaul }));
        setTimeout(() => this.getBackhaul(), 1000);
      },
      (error: any) => {
        this.mixpanel.storeEvent('CONFIGURATION_BACKHAUL_PUT_ERROR');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }

  selectNodebleMode(nodeValue: any): void {
    this.bleSelection.currentNode = nodeValue;
  }

  selectbleMode(action: string): void {
    this.bleSelection.current = action;
  }

  setbleMode(): void {
    if (this.bleSelection.current === '--') {
      this.toast.error(
        'configurations.onboarding.toastSetbleMessageFailure',
        'configurations.onboarding.toastSetbleTitle'
      );
    } else {
      (this.bleSelection.currentNode !== 'configurations.onboarding.allNodes'
        ? this.nodeService.setBleMode$(this.bleSelection.currentNode, this.bleSelection.current)
        : this.customerService.setBleMode$(this.bleSelection.current)
      ).subscribe(() => {
        this.mixpanel.storeEvent('CONFIGURATION_LOCATION_BLEMODE', { BLEMODE: this.bleSelection.current });
        this.toast.success(
          'configurations.onboarding.toastSetbleMessageSuccess',
          'configurations.onboarding.toastSetbleTitle'
        );
      });
    }
  }

  selectNodeWps(nodeValue: any): void {
    this.wpsSelection.currentNode = nodeValue;
  }

  selectWpsKey(keyId: string, accessZone: string = '', accessZoneId: string = ''): void {
    if (keyId === 'configurations.onboarding.selectKeyOrDefault') {
      this.wpsSelection.currentKey = 'configurations.onboarding.selectKeyOrDefault';
      this.wpsSelection.currentKeyString = 'configurations.onboarding.selectKeyOrDefault';
    } else {
      this.wpsSelection.currentKey = keyId;
      this.wpsSelection.currentKeyString = keyId + ' - ' + accessZone + ' - ' + accessZoneId;
    }
  }

  wpsStart(): void {
    if (this.wpsSelection.currentNode === 'configurations.onboarding.selectNode') {
      this.toast.error(
        'configurations.onboarding.toastWpsMessageFailureNode',
        'configurations.onboarding.toastWpsTitle'
      );
    } else {
      const postData =
        this.wpsSelection.currentKey !== 'configurations.onboarding.selectKeyOrDefault'
          ? {
              nodeId: this.wpsSelection.currentNode,
              keyId: this.wpsSelection.currentKey
            }
          : { nodeId: this.wpsSelection.currentNode };

      if (this.wpsSelection.networkId) {
        postData['networkId'] = this.wpsSelection.networkId;
      }
      this.customerService.setStartWps$(postData).subscribe(
        () => {
          this.mixpanel.storeEvent('CONFIGURATION_WPSSTART_SUCCESS');
          this.toast.success(
            'configurations.onboarding.toastWpsMessageSuccess',
            'configurations.onboarding.toastWpsTitle'
          );
        },
        (error: any) => {
          this.mixpanel.storeEvent('CONFIGURATION_WPSSTART_FAILURE', {
            ERROR: error.error.error.message
          });
          this.toast.error(error.error.error.message, 'configurations.onboarding.toastWpsTitle');
        }
      );
    }
  }

  setBackhaulWpaModeToggler(backhaulNetwork: ILocationBackhaul): void {
    this.backhaulWpaMode = [
      {
        value: 'auto',
        translation: 'auto',
        selected: backhaulNetwork.wpaMode === 'auto'
      },
      {
        value: 'psk2',
        translation: 'health.networkInformation.wpa2',
        marked: backhaulNetwork.wpaModeRealized === 'psk2',
        selected: backhaulNetwork.wpaMode === 'psk2'
      },
      {
        value: 'sae-mixed',
        translation: 'health.networkInformation.wpa3',
        marked: backhaulNetwork.wpaModeRealized === 'sae-mixed',
        selected: backhaulNetwork.wpaMode === 'sae-mixed'
      }
    ];
  }

  resetOnboarding(): void {
    this.mixpanel.storeEvent('TD_ONBOARDING_DELETE');
    this.onboardingEditLoading = true;
    this.troubleShoot.deleteOnboarding().subscribe(
      (response: any) => {
        this.store.dispatch(pollingPull({ debugSource: 'onboarding reset' }));
      },
      () => {
        this.onboardingEditLoading = false;
      }
    );
  }

  completeOnboarding(ssid: string, profile: 'auto' | 'smallbusiness' | 'property'): void {
    const onboard = () => {
      this.mixpanel.storeEvent('CONFIG_ONBOARDING_COMPLETE');
      this.onboardingEditLoading = true;
      this.troubleShoot.setOnboarding('OnboardingComplete').subscribe(
        (response: any) => {
          this.store.dispatch(pollingPull({ debugSource: 'onboarding complete' }));
          this.logging.log('<setOnboarding> ', response);
          this.toast.success('toast.technician.successOnboardingMessage', 'toast.technician.successOnboardingTitle');
          this.onboardingEditLoading = false;
        },
        () => {
          this.onboardingEditLoading = false;
        }
      );
    };

    if (profile !== 'auto') {
      this.secondaryNetworks.getFronthaul$().subscribe((response) => {
        if (ssid === null) {
          this.toast.warning(
            'toast.technician.cannotCompleteOnboardingMessage',
            'toast.technician.cannotCompleteOnboardingTitle'
          );
        }

        if (!response.length) {
          this.toast.warning(
            'toast.technician.cannotCompleteOnboardingVSBMessage',
            'toast.technician.cannotCompleteOnboardingVSBTitle'
          );
        }

        if (ssid && response.length) {
          onboard();
        }
      });
    } else {
      if (ssid === null) {
        this.toast.warning(
          'toast.technician.cannotCompleteOnboardingMessage',
          'toast.technician.cannotCompleteOnboardingTitle'
        );
      } else {
        onboard();
      }
    }
  }
}
