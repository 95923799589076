import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/lib/services/api.service';
import { AuthService } from 'src/app/lib/services/auth.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import SwaggerUI from 'swagger-ui';

@Component({
  templateUrl: './apiexplorer.component.html',
  styleUrls: ['./apiexplorer.component.scss']
})
export class ApiexplorerComponent implements OnInit {
  ui: any;
  searchname: FormControl = new FormControl('');
  mode: string = 'Customer';
  debug: boolean = false;

  constructor(
    public plume: PlumeService,
    private api: ApiService,
    private auth: AuthService,
    private mixpanel: MixpanelService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('API_EXPLORER_SCREEN');
    this.getCustomer();
    this.route.queryParams.subscribe((params: any) => {
      this.debug = params?.debug || null;
    });
  }

  getCustomer(): void {
    this.api.get('/swagger.json', 'explorer').subscribe((response: any) => {
      response.host = this.api.hostname().split('/')[2];
      this.mode = 'Customer';
      this.initSwagger(response);
    });
  }

  getReports(): void {
    this.api.get('/explorer/swagger.json', 'reports').subscribe((response: any) => {
      response.host = this.api.hostname().split('/')[2];
      response.servers = [{ url: this.api.hostname() + response.servers?.[0]?.url || '' }];
      this.mode = 'Reports';
      this.initSwagger(response);
    });
  }

  getLTE(): void {
    this.api
      .raw(
        'get',
        `${this.plume.getEnv().lteUrl}/lteservice/explorer-json`,
        {},
        {
          headers: {
            ...this.auth.getHeaders()
          }
        }
      )
      .subscribe((response: any) => {
        response.servers = [{ url: this.plume.getEnv().lteUrl, description: 'LTE server' }];
        this.mode = 'LTE';
        this.initSwagger(response);
      });
  }

  initSwagger(spec: any): void {
    spec = this.prepare(spec);
    const AdvancedFilterPlugin = (system: any) => {
      return {
        fn: {
          opsFilter: (taggedOps: any, phrase: string) => {
            phrase = phrase.toLowerCase();

            const normalTaggedOps = JSON.parse(JSON.stringify(taggedOps));

            for (const tagObj of Object.keys(normalTaggedOps)) {
              const operations = normalTaggedOps[tagObj].operations;
              let i = operations.length;

              while (i--) {
                const operation = operations[i].operation;

                if (
                  operations[i].path.toLowerCase().indexOf(phrase) === -1 &&
                  (!operation.summary || operation.summary.toLowerCase().indexOf(phrase) === -1) &&
                  (!operation.description || operation.description.toLowerCase().indexOf(phrase) === -1)
                ) {
                  operations.splice(i, 1);
                }
              }

              if (operations.length === 0) {
                delete normalTaggedOps[tagObj];
              } else {
                normalTaggedOps[tagObj].operations = operations;
              }
            }

            return system.Im.fromJS(normalTaggedOps);
          }
        }
      };
    };

    this.ui = SwaggerUI({
      dom_id: '#swagger-ui',
      spec,
      docExpansion: ['none'],
      defaultModelsExpandDepth: 0,
      filter: true,
      tryItOutEnabled: true,
      displayRequestDuration: true,
      plugins: [AdvancedFilterPlugin],
      requestInterceptor: (req: any) => {
        req.url += req.url.indexOf('?') === -1 ? '?' + this.auth.getParams() : '&' + this.auth.getParams();
        req.headers.Authorization = this.auth.getHeaders().Authorization;
        return req;
      }
    });
  }

  filterOutIntegrationOnlyTags(arr: any): any {
    const removeTagList = ['IntegrationHealthCheck', 'Location', 'PartnerConfig', 'Cohort', 'Inventory'];

    if (arr) {
      return arr.filter((tag: any) => !removeTagList.includes(tag.name));
    } else {
      return arr;
    }
  }

  filterOutIntegrationOnlyPaths(paths: any): any {
    const removeList = [
      '^/checkIntegrationHealth',
      '^/Locations',
      '^/partnerConfig',
      '^/cohorts',
      '^/inventory/nodes/{nodeId}$',
      '/lteservice/partnerconfigs'
    ];
    const re = new RegExp(removeList.join('|'), 'i');

    if (paths) {
      return Object.keys(paths)
        .filter((key: string) => !re.test(key))
        .reduce((obj: any, key: string) => {
          obj[key] = paths[key];
          return obj;
        }, {});
    } else {
      return paths;
    }
  }

  filterOutAdminOnlyPaths(paths: any): any {
    const removeList = [
      '^/BatchFirmwareUpgrades',
      '^/Nodes/count',
      '^/inventory/nodes/',
      '^/Customers/count$',
      '^/Customers/search',
      '^/Groups$',
      '^/Customers/{id}/accessTokens',
      '^/Groups/{id}/customers/rel/{fk}',
      '^/Groups/count$',
      '^/Groups/{id}/deviceHappiness$',
      '^/Groups/{id}/optimizationStats$',
      '^/Groups/{id}/bandSteeringStats$',
      '^/Groups/{id}/clientSteeringStats$',
      '^/Groups/{id}/alarms$'
    ];
    const re = new RegExp(removeList.join('|'), 'i');

    if (paths) {
      return Object.keys(paths)
        .filter((key: string) => !re.test(key))
        .reduce((obj: any, key: string) => {
          obj[key] = paths[key];
          return obj;
        }, {});
    } else {
      return paths;
    }
  }

  filterOutAdminOnlyTags(tags: any): any {
    const removeTagList = this.mode === 'Customer' ? ['BatchFirmwareUpgrade'] : ['BatchFirmwareUpgrade', 'Group'];

    if (tags) {
      return tags.filter((tag: any) => !removeTagList.includes(tag.name));
    } else {
      return tags;
    }
  }

  filterOldGroupsApiTag(tags: any): any {
    if (tags) {
      return tags.filter((tag: any) => !['Group'].includes(tag.name));
    } else {
      return tags;
    }
  }

  filterOldGroupsApiPath(paths: any): any {
    const re = new RegExp('^/Groups');

    if (paths) {
      return Object.keys(paths)
        .filter((key: string) => !re.test(key))
        .reduce((obj: any, key: string) => {
          obj[key] = paths[key];
          return obj;
        }, {});
    } else {
      return paths;
    }
  }

  prepare(swagger: any): any {
    if (swagger.tags && !this.debug) {
      swagger.tags = this.filterOutIntegrationOnlyTags(swagger.tags);
    }

    if (swagger.paths) {
      if (!this.debug) {
        swagger.paths = this.filterOutIntegrationOnlyPaths(swagger.paths);

        if (this.plume.isGroupRole()) {
          swagger.paths = this.filterOutAdminOnlyPaths(swagger.paths);
          swagger.tags = this.filterOutAdminOnlyTags(swagger.tags);

          swagger.paths = this.filterOldGroupsApiPath(swagger.paths);
          swagger.tags = this.filterOldGroupsApiTag(swagger.tags);
        }
      }

      for (const apiPathKey of Object.keys(swagger.paths)) {
        for (const operationKey of Object.keys(swagger.paths[apiPathKey])) {
          if (
            swagger.paths[apiPathKey][operationKey].parameters &&
            swagger.paths[apiPathKey][operationKey].parameters.length
          ) {
            if (swagger.paths[apiPathKey][operationKey].parameters.find((param: any) => param.in === 'formData')) {
              swagger.paths[apiPathKey][operationKey].consumes = ['application/x-www-form-urlencoded'];
            }
          }
        }
      }
    }

    return swagger;
  }
}
