import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { ToolbarService } from 'src/app/lib/services/toolbar.service';
import * as erdm from 'element-resize-detector';

@Component({
  selector: 'toolmodal',
  templateUrl: './toolmodal.component.html',
  styleUrls: ['./toolmodal.component.scss']
})
export class ToolmodalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  pos: any = {};

  @Input()
  stack: number = 20000;

  @Output()
  toTop: any = new EventEmitter<any>();

  @ViewChild('modal')
  modal: ElementRef;

  modalState: any = {
    drag: false,
    dragable: true,
    docked: true,
    offset: { x: 0, y: 0 },
    position: { left: this.pos.xpos, top: this.pos.ypos }
  };

  show: boolean = false;
  modalPad: number = 25;
  modalYPad: number = 20;
  widthBreak: number = 1024; // below this width, items are docked and not dragable
  toolbarSubscription: any;
  erd: any = erdm({ strategy: 'scroll' });

  constructor(private toolbarEvents: ToolbarService) {}

  ngAfterViewInit(): void {
    this.erd.listenTo(this.modal.nativeElement, () => {
      this.fixBounds();
    });
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent): void {
    if (this.modalState.drag === true) {
      this.fixBounds({ x: e.clientX, y: e.clientY });
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: MouseEvent): void {
    if (this.modalState.drag === true) {
      this.modalState.drag = false;
      this.storeState();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth < this.widthBreak) {
      this.modalState.dragable = false;
      this.modalState.dockable = false;
    } else {
      this.modalState.dragable = true;
      this.modalState.dockable = true;
    }
    if (this.modalState.docked) {
      setTimeout(() => { // if docked then move modal to docked position
        this.dock();
      }, 0);

    }

    this.fixBounds();
  }

  ngOnInit(): void {
    const modalStore = JSON.parse(sessionStorage.getItem('modalState-' + this.pos.action));

    if (modalStore) {
      this.modalState = modalStore;
      this.show = true;
    } else {
      setTimeout(() => {
        this.dock();
        this.show = true;
      });
    }

    this.toolbarSubscription = this.toolbarEvents.event.subscribe((event: any) => {
      this.dock();
    });

    this.onResize();
  }

  storeState(): void {
    sessionStorage.setItem('modalState-' + this.pos.action, JSON.stringify(this.modalState));
  }

  startDrag(e: MouseEvent): void {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.preventDefault) {
      e.preventDefault();
    }

    e.cancelBubble = true;
    e.returnValue = false;

    this.toTop.emit();

    if (this.modalState.drag === false) {
      if (this.modalState.docked === true) {
        const { top, left } = this.modal.nativeElement.getBoundingClientRect();

        this.modalState.offset = { x: left - e.clientX, y: top - e.clientY };
        this.modalState.docked = false;
      }

      this.modalState.drag = true;
    }
  }

  dock(): void {
    this.modalState.drag = false;
    this.modalState.docked = true;
    this.modalState.offset = { x: 0, y: 0 };

    this.correctBounds({ x: this.pos.xpos, y: this.pos.ypos });
    this.storeState();
  }

  isDocked(): any {
    return this.modalState.docked;
  }

  fixBounds(position: any = null): void {
    if (window.innerWidth < this.widthBreak) {
      this.dock();
    } else {
      this.correctBounds(position);
    }
  }

  correctBounds(position: any = null): void {
    if (this.modal && this.modal.nativeElement) {
      const innerW = window.innerWidth;
      const innerH = window.innerHeight;
      const { top, left, width, height } = this.modal.nativeElement.getBoundingClientRect();

      let posX = position ? position.x + this.modalState.offset.x : left;
      let posY = position ? position.y + this.modalState.offset.y : top;

      if (posX <= 20) {
        posX = 20;
      }

      if (posY <= 100) {
        posY = 100;
      }

      if (posX >= innerW - width - 20) {
        posX = innerW - width - 20;
      }

      if (posY >= innerH - height - 20) {
        posY = innerH - height - 20;
      }

      this.modalState.position.left = posX;
      this.modalState.position.top = posY;
    }
  }

  ngOnDestroy(): void {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
  }
}
