import { Component, Input, Output, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { FormControl } from '@angular/forms';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'addnode',
  templateUrl: './addnode.component.html',
  styleUrls: ['./addnode.component.scss']
})
export class AddnodeComponent implements OnInit, OnDestroy {
  locationSubscription: any;
  permissionSubscription: any;
  permissions: any;

  loading: boolean = false;
  isUprise: boolean = false;
  nodeAddOpen: boolean = false;
  nodenameFormControl: FormControl = new FormControl();

  @Output()
  nodeAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private plume: PlumeService,
    private nodeService: NodeService,
    private toast: ToastService,
    private logging: LoggingService,
    private mixpanel: MixpanelService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.permissionSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });

    this.locationSubscription = this.store.pipe(selectPipeLocationOnChange).subscribe((response) => {
      this.isUprise = response.uprise;
    });
  }

  toggleAddNode(): void {
    this.nodenameFormControl.setValue('');
    this.nodeAddOpen = !this.nodeAddOpen;

    if (this.nodeAddOpen) {
      this.mixpanel.storeEvent('NODES_ADD_NODE_DIALOG');
    } else {
      this.mixpanel.storeEvent('NODES_ADD_NODE_DISABLE');
    }
  }

  createNewNode(): void {
    if (!this.isUprise) {
      this.loading = true;
      this.nodeAddOpen = false;
      const serialNumber = this.nodenameFormControl.value.trim();
      this.nodeService.create$(serialNumber).subscribe(
        (response: any) => {
          this.loading = false;
          this.nodeAdded.emit(true);
          if (response.status === 204) {
            this.mixpanel.storeEvent('NODES_ADD_NODE_SAMENODE', { NODE_ID: serialNumber });
            this.toast.error('toast.node.errorNodeAddAlreadyExistsMessage', 'toast.node.errorNodeAddTitle', {
              params: {
                serial: serialNumber
              }
            });
          } else {
            this.mixpanel.storeEvent('NODES_ADD_NODE_SUCCESS', { NODE_ID: serialNumber });
            this.logging.log('Node Created ' + serialNumber, response.body);
            this.toast.success('toast.node.successNodeAddMessage', 'toast.node.successNodeAddTitle', {
              params: {
                serial: serialNumber
              }
            });
          }
        },
        (error: any) => {
          this.loading = false;
          this.nodeAdded.emit(false);
          this.mixpanel.storeEvent('NODES_ADD_NODE_ERROR', {
            NODE_ID: serialNumber,
            ERROR: error.error.error.message
          });

          this.logging.error('Node creation error', error.error.error);
          this.toast.error('toast.node.errorNodeAddMessage', 'toast.node.errorNodeAddTitle', {
            params: {
              error: error.error.error.message
            }
          });
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.permissionSubscription) {
      this.permissionSubscription.unsubscribe();
    }

    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }
}
