import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/lib/services/auth.service';
import { prepareCaptivePortal } from 'src/app/store/captive-portal/captive-portal.actions';
import { selectCaptivePortalErrorMsg, selectCaptivePortalState } from 'src/app/store/captive-portal/captive-portal.selectors';

@Component({
  templateUrl: './captiveportal.component.html',
  styleUrls: ['./captiveportal.component.scss']
})
export class CaptiveportalComponent implements OnInit {
  state$ = this.store.select(selectCaptivePortalState, {
    ...this.auth.getEnvFromUrl(),
    authenticated: this.auth.isAuthenticated()
  });

  errorMessage$ = this.store.select(selectCaptivePortalErrorMsg);

  constructor(private route: ActivatedRoute, private store: Store, private auth: AuthService) {}

  ngOnInit(): void {
    this.store.dispatch(
      prepareCaptivePortal({
        network: this.route.snapshot.params.network,
        mode: this.route.snapshot.params.mode
      })
    );
  }

  close(): void {
    window.close();
  }
}
