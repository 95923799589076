import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { SecurityPolicyService } from 'src/app/lib/services/security-policy.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { Store } from '@ngrx/store';
import {
  selectAppTime,
  selectLocationConfigState,
  selectLocationConfigStateLoading,
  selectPipeLocationOnChange,
  selectPipeLocationOnChangeOptimistic
} from 'src/app/store/customer/customer.selectors';
import { autoModes, IConfigAndState, SlideTogglerItems } from 'src/app/lib/interfaces/interface';
import { map, switchMap, tap } from 'rxjs/operators';
import { sipAlgModeSet } from 'src/app/store/customer/customer.actions';
import { selectCapabilities, selectCapabilitySomeSecurity } from 'src/app/store/customer/capabilities.selector';
import {
  configPaSAppTimeEnableSet,
  configPaSDosProtectionActionSet,
  configPaSDosProtectionSet,
  configPaSDpiContentFilteringSet,
  configPaSFlowStatsSet,
  configPaSInlineDpiSet,
  configPaSIpThreatIntelligenceSet,
  configPaSIpThreatProtectSet,
  configPaSPreferredIntelligenceSet,
  configPaSWcHealthTelemetrySet,
  configPaSWcHeroTelemetrySet
} from 'src/app/store/configview/config.actions';
import { combineLatest } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'privacyandsecurity',
  templateUrl: './privacyandsecurity.component.html',
  styleUrls: ['./privacyandsecurity.component.scss']
})
export class PrivacyandSecurityComponent implements OnInit, OnChanges {
  expand: boolean = false;
  optimisticLocation$ = this.store.pipe(selectPipeLocationOnChangeOptimistic);
  appTime$ = this.store.select(selectAppTime);
  ipFlowLogging: boolean = false;
  ipFlowLoggingExpiry: string;
  privateMode: boolean = false;
  privateModeUpdatedDate: string;
  ipThreatSecurityPolicy$ = this.store
    .pipe(selectPipeLocationOnChange)
    .pipe(switchMap(() => this.securityPolicyService.realizedState$()));
  appTimeItems$ = this.appTime$.pipe(
    map((appTime) => [
      {
        value: true,
        translation: 'enable',
        marked: appTime?.enableRealized === true,
        selected: appTime?.enable
      },
      {
        value: false,
        translation: 'disable',
        marked: appTime?.enableRealized === false,
        selected: !appTime?.enable
      }
    ])
  );
  ipIotDeviceFlowItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.flowCollectionConfig ||
          !location.flowCollectionConfig.ipIotDeviceFlow ||
          location.flowCollectionConfig.ipIotDeviceFlow === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location?.flowCollection?.iotDeviceIpStats === true,
        selected: location.flowCollectionConfig && location.flowCollectionConfig.ipIotDeviceFlow === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location?.flowCollection?.iotDeviceIpStats === false,
        selected: location.flowCollectionConfig && location.flowCollectionConfig.ipIotDeviceFlow === 'disable'
      }
    ])
  );
  ipScreenDeviceFlowItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.flowCollectionConfig ||
          !location.flowCollectionConfig.ipScreenDeviceFlow ||
          location.flowCollectionConfig.ipScreenDeviceFlow === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location?.flowCollection?.screenDeviceIpStats === true,
        selected: location.flowCollectionConfig && location.flowCollectionConfig.ipScreenDeviceFlow === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location?.flowCollection?.screenDeviceIpStats === false,
        selected: location.flowCollectionConfig && location.flowCollectionConfig.ipScreenDeviceFlow === 'disable'
      }
    ])
  );
  lanIotDeviceFlowItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.flowCollectionConfig ||
          !location.flowCollectionConfig.lanIotDeviceFlow ||
          location.flowCollectionConfig.lanIotDeviceFlow === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.flowCollection && location.flowCollection.lanStats,
        selected: location.flowCollectionConfig && location.flowCollectionConfig.lanIotDeviceFlow === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.flowCollection && !location.flowCollection.lanStats,
        selected: location.flowCollectionConfig && location.flowCollectionConfig.lanIotDeviceFlow === 'disable'
      }
    ])
  );
  interfaceStatsItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.flowCollectionConfig ||
          !location.flowCollectionConfig.interfaceStats ||
          location.flowCollectionConfig.interfaceStats === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.flowCollection && location.flowCollection.interfaceStats,
        selected: location.flowCollectionConfig && location.flowCollectionConfig.interfaceStats === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.flowCollection && !location.flowCollection.interfaceStats,
        selected: location.flowCollectionConfig && location.flowCollectionConfig.interfaceStats === 'disable'
      }
    ])
  );
  preferredIntelligenceItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected: !location.securityConfiguration || location.securityConfiguration.preferredIntelligence === 'auto'
      },
      {
        value: 'brightcloud',
        translation: 'brightcloud',
        marked: location.security && location.security.intelligence === 'brightcloud',
        selected:
          location.securityConfiguration && location.securityConfiguration.preferredIntelligence === 'brightcloud'
      },
      {
        value: 'gatekeeper',
        translation: 'gatekeeper',
        marked: location.security && location.security.intelligence === 'gatekeeper',
        selected:
          location.securityConfiguration && location.securityConfiguration.preferredIntelligence === 'gatekeeper'
      }
    ])
  );
  ipThreatIntelligenceItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.securityConfiguration ||
          location.securityConfiguration.ipThreatIntelligence === 'auto' ||
          !location.securityConfiguration.ipThreatIntelligence
      },
      {
        value: 'gatekeeper',
        translation: 'gatekeeper',
        marked: location.security && location.security.ipThreatIntelligence === 'gatekeeper',
        selected: location.securityConfiguration && location.securityConfiguration.ipThreatIntelligence === 'gatekeeper'
      }
    ])
  );
  wcHealthTelemetryItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.securityConfiguration ||
          !location.securityConfiguration.wcHealthTelemetry ||
          location.securityConfiguration.wcHealthTelemetry === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location?.security?.wcHealthTelemetry === true,
        selected: location.securityConfiguration && location.securityConfiguration.wcHealthTelemetry === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location?.security?.wcHealthTelemetry === false,
        selected: location.securityConfiguration && location.securityConfiguration.wcHealthTelemetry === 'disable'
      }
    ])
  );
  wcHeroTelemetryItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.securityConfiguration ||
          !location.securityConfiguration.wcHeroTelemetry ||
          location.securityConfiguration.wcHeroTelemetry === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location?.security?.wcHeroTelemetry === true,
        selected: location.securityConfiguration && location.securityConfiguration.wcHeroTelemetry === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location?.security?.wcHeroTelemetry === false,
        selected: location.securityConfiguration && location.securityConfiguration.wcHeroTelemetry === 'disable'
      }
    ])
  );
  ipThreatProtectItems$ = combineLatest([this.optimisticLocation$, this.ipThreatSecurityPolicy$]).pipe(
    map(([location, ipThreatSecurityPolicy]) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.securityConfiguration ||
          !location.securityConfiguration.ipThreatProtect ||
          location.securityConfiguration.ipThreatProtect === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: ipThreatSecurityPolicy.ipThreatEnable,
        selected: location.securityConfiguration && location.securityConfiguration.ipThreatProtect === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: !ipThreatSecurityPolicy.ipThreatEnable,
        selected: location.securityConfiguration && location.securityConfiguration.ipThreatProtect === 'disable'
      }
    ])
  );
  wifiNetwork: any = {};
  sniffingDnsItems: any[] = [];
  sniffingHttpItems: any[] = [];
  sniffingUpnpItems: any[] = [];
  sniffingMdnsItems: any[] = [];
  unauthorizedInboundConnectionItems: any[] = [];
  sniffingLocationConfiguration: any = {};
  dpiContentFilteringItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.securityConfiguration ||
          !location.securityConfiguration.dpiContentFiltering ||
          location.securityConfiguration.dpiContentFiltering === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.security && location.security.dpiContentFiltering,
        selected: location.securityConfiguration && location.securityConfiguration.dpiContentFiltering === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.security && !location.security.dpiContentFiltering,
        selected: location.securityConfiguration && location.securityConfiguration.dpiContentFiltering === 'disable'
      }
    ])
  );
  inlineDpiItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.securityConfiguration ||
          !location.securityConfiguration.inlineDpi ||
          location.securityConfiguration.inlineDpi === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.security && location.security.inlineDpi,
        selected: location.securityConfiguration && location.securityConfiguration.inlineDpi === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.security && !location.security.inlineDpi,
        selected: location.securityConfiguration && location.securityConfiguration.inlineDpi === 'disable'
      }
    ])
  );
  dosProtectionItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.securityConfiguration ||
          !location.securityConfiguration.dosProtection ||
          location.securityConfiguration.dosProtection?.mode === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.security && location.security.dosProtection && location.security.dosProtection.enable,
        selected:
          location.securityConfiguration &&
          location.securityConfiguration.dosProtection &&
          location.securityConfiguration.dosProtection.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.security && location.security.dosProtection && !location.security.dosProtection.enable,
        selected:
          location.securityConfiguration &&
          location.securityConfiguration.dosProtection &&
          location.securityConfiguration.dosProtection.mode === 'disable'
      }
    ])
  );
  dosProtectionActionItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          !location.securityConfiguration ||
          !location.securityConfiguration.dosProtection ||
          location.securityConfiguration.dosProtection?.action === 'auto'
      },
      {
        value: 'observe',
        translation: 'observe',
        marked:
          location.security && location.security.dosProtection && location.security.dosProtection.action === 'observe',
        selected:
          location.securityConfiguration &&
          location.securityConfiguration.dosProtection &&
          location.securityConfiguration.dosProtection.action === 'observe'
      },
      {
        value: 'block',
        translation: 'block',
        marked:
          location.security && location.security.dosProtection && location.security.dosProtection.action === 'block',
        selected:
          location.securityConfiguration &&
          location.securityConfiguration.dosProtection &&
          location.securityConfiguration.dosProtection.action === 'block'
      }
    ])
  );
  remoteConnection: {
    mode: 'auto' | 'enable' | 'highRiskOnly' | 'disable';
    modeRealized: 'enable' | 'highRiskOnly' | 'disable';
  } = null;
  locationConfig$ = this.store.select(selectLocationConfigState);
  locationConfigLoading$ = this.store.select(selectLocationConfigStateLoading);
  sipALgItems$ = this.locationConfig$.pipe(map((data) => this.getSipALgItems(data)));
  sipAlgCapable$ = this.store.select(selectCapabilities).pipe(map((capability) => capability?.sipAlg?.capable));
  someSecurityCapable$ = this.store.select(selectCapabilitySomeSecurity);
  dnsCategoryBlockingCapable$ = this.store.select(selectCapabilities).pipe(
    map((capabilities) => capabilities?.dnsCategoryBlocking?.capable),
    tap((capable) => this.registerFilter(capable))
  );
  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private toast: ToastService,
    private store: Store,
    private securityPolicyService: SecurityPolicyService,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  init(): void {
    this.getRemoteConnections();
    this.getWifiNetwork();
    this.getIpFlows();
    this.getSniffing();
    this.initTogglers();
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    this.initTogglers();

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_SCREEN');
    }
  }

  registerFilter(dnsCategoryBlockingCapable: boolean): void {
    this.clearFilter.emit({ section: 'privacyandsecurity' });

    this.translate
      .get('configurations.privacyandsecurity.appTime')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'privacyandsecurity', property: 'appTime', translation: translated })
      );

    this.translate
      .get('configurations.privacyandsecurity.privateMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'privacyandsecurity', property: 'privateMode', translation: translated })
      );
    if (dnsCategoryBlockingCapable) {
      this.translate
        .get('configurations.privacyandsecurity.ipFlowLogging')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'privacyandsecurity', property: 'ipFlowLogging', translation: translated })
        );

      this.translate
        .get('configurations.privacyandsecurity.iotDeviceIpFlowstats')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'privacyandsecurity', property: 'iotDeviceIpFlowstats', translation: translated })
        );

      this.translate.get('configurations.privacyandsecurity.screenDeviceIpFlowStats').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'screenDeviceIpFlowStats',
          translation: translated
        })
      );

      this.translate.get('configurations.privacyandsecurity.interfaceStats').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'interfaceStats',
          translation: translated
        })
      );

      this.translate
        .get('configurations.privacyandsecurity.sniffingDns')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'privacyandsecurity', property: 'sniffingDns', translation: translated })
        );
      this.translate
        .get('configurations.privacyandsecurity.sniffingHttp')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'privacyandsecurity', property: 'sniffingHttp', translation: translated })
        );
      this.translate
        .get('configurations.privacyandsecurity.sniffingUpnp')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'privacyandsecurity', property: 'sniffingUpnp', translation: translated })
        );
      this.translate
        .get('configurations.privacyandsecurity.sniffingMdns')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'privacyandsecurity', property: 'sniffingMdns', translation: translated })
        );

      this.translate.get('configurations.privacyandsecurity.iotDeviceLanFlowstats').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'iotDeviceLanFlowstats',
          translation: translated
        })
      );

      this.translate.get('configurations.privacyandsecurity.securityContentProvider').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'securityContentProvider',
          translation: translated
        })
      );

      this.translate.get('configurations.privacyandsecurity.ipThreatIntelligence').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'ipThreatIntelligence',
          translation: translated
        })
      );

      this.translate.get('configurations.privacyandsecurity.webclassifierHealthStats').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'webclassifierHealthStats',
          translation: translated
        })
      );

      this.translate.get('configurations.privacyandsecurity.webclassifierHeroStats').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'webclassifierHeroStats',
          translation: translated
        })
      );

      this.translate
        .get('configurations.privacyandsecurity.ipThreatProtection')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'privacyandsecurity', property: 'ipThreatProtection', translation: translated })
        );

      this.translate.get('configurations.privacyandsecurity.dpiContentFiltering').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'dpiContentFiltering',
          translation: translated
        })
      );

      this.translate.get('configurations.privacyandsecurity.inlineDpi').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'inlineDpi',
          translation: translated
        })
      );

      this.translate.get('configurations.privacyandsecurity.dosProtection').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'dosProtection',
          translation: translated
        })
      );
      this.translate.get('configurations.privacyandsecurity.dosProtectionAction').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'dosProtectionAction',
          translation: translated
        })
      );

      this.translate.get('configurations.privacyandsecurity.sipAlg').subscribe((translated: string) =>
        this.filter.emit({
          section: 'privacyandsecurity',
          property: 'sipAlg',
          translation: translated
        })
      );

      this.translate
        .get('configurations.privacyandsecurity.unauthorizedInboundConnection')
        .subscribe((translated: string) =>
          this.filter.emit({
            section: 'privacyandsecurity',
            property: 'unauthorizedInboundConnection',
            translation: translated
          })
        );
    }
  }

  getSniffing(): void {
    this.customerService.getSniffing$().subscribe((sniffingResponse) => {
      this.sniffingLocationConfiguration = sniffingResponse;
      this.initTogglers();
    });
  }

  getRemoteConnections(): void {
    this.securityPolicyService.remoteConnections$().subscribe((response) => {
      this.remoteConnection = response;
      this.setUnauthorizedInboundConnectionItems();
    });
  }

  updateSniffing(): void {
    this.customerService.updateSniffing$(this.sniffingLocationConfiguration).subscribe(
      () => {
        this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_SNIFFING');
        this.initTogglers();
      },
      (error: any) => {
        this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_SNIFFING_ERROR');
        this.toast.error(error.error.error.message, 'header.failed');
        this.initTogglers();
      }
    );
  }

  getWifiNetwork(): void {
    this.customerService.getWifiNetwork$().subscribe((response) => {
      this.wifiNetwork = response.wifiNetwork;
      this.privateMode = this.wifiNetwork.privateMode;
      this.privateModeUpdatedDate = this.wifiNetwork.privateModeUpdatedAt
        ? this.formatDate(this.wifiNetwork.privateModeUpdatedAt)
        : null;
    });
  }

  getIpFlows(): void {
    this.customerService.getIpFlow$().subscribe((response) => {
      this.ipFlowLogging = response.enable;
      if ('expiresAt' in response) {
        this.ipFlowLoggingExpiry = this.formatDate(response.expiresAt);
      }
      this.initTogglers();
    });
  }

  updateAppTime(enable: boolean): void {
    if (this.plume.isSomeAdminRole()) {
      this.store.dispatch(configPaSAppTimeEnableSet({ value: { enable, appliesToAllDevices: true } }));
    }
  }

  updatePrivateMode(): void {
    if (this.plume.isSomeAdminRole()) {
      this.privateMode = !this.privateMode;
      this.customerService.patchWifiNetwork$({ privateMode: this.privateMode }).subscribe(
        () => {
          this.getWifiNetwork();
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_PRIVATEMODE');
        },
        (error: any) => {
          this.privateMode = !this.privateMode;
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_PRIVATEMODE_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
    }
  }

  updateIpFlowLogging(): void {
    if (this.plume.isSomeAdminRole()) {
      this.ipFlowLogging = !this.ipFlowLogging;

      const week = 7 * 24 * 60 * 60 * 1000;
      const expire = new Date(Date.now() + week);
      const dataToSend = this.ipFlowLogging ? { enable: true, expiresAt: expire.toISOString() } : { enable: false };
      this.customerService.updateIpFlow$(dataToSend).subscribe(
        (response) => {
          if ('expiresAt' in response) {
            this.ipFlowLoggingExpiry = this.formatDate(response.expiresAt);
          }
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_IPFLOWLOGGING');
        },
        (error: any) => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_IPFLOWLOGGING_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
    }
  }

  action(command: string, action: string): void {
    switch (command) {
      case 'ipIotDeviceFlowStats':
        this.setFlowStats({ iotDeviceConfig: action as any }, 'IPIOT_DEVICE_FLOWSTATS');
        break;
      case 'ipScreenDeviceFlowStats':
        this.setFlowStats({ screenDeviceConfig: action as any }, 'IPSCREEN_DEVICE_FLOWSTATS');
        break;
      case 'lanIotDeviceFlowStats':
        this.setFlowStats({ lanIotDeviceConfig: action as any }, 'LANIOT_DEVICE_FLOWSTATS');
        break;
      case 'interfaceStats':
        this.setFlowStats({ interfaceStatsConfig: action as any }, 'INTERFACE_STATS');
        break;
      case 'preferredIntelligence':
        this.store.dispatch(configPaSPreferredIntelligenceSet({ value: action as any }));
        break;
      case 'ipThreatIntelligence':
        this.store.dispatch(configPaSIpThreatIntelligenceSet({ value: action as any }));
        break;
      case 'wcHealthTelemetry':
        this.store.dispatch(configPaSWcHealthTelemetrySet({ value: action as any }));
        break;
      case 'wcHeroTelemetry':
        this.store.dispatch(configPaSWcHeroTelemetrySet({ value: action as any }));
        break;
      case 'ipThreatProtect':
        this.store.dispatch(configPaSIpThreatProtectSet({ value: action as any }));
        break;
      case 'sniffingDns':
        if (this.sniffingLocationConfiguration) {
          this.sniffingLocationConfiguration.dns.mode = action;
          this.updateSniffing();
        }
        break;
      case 'sniffingHttp':
        if (this.sniffingLocationConfiguration) {
          this.sniffingLocationConfiguration.http.mode = action;
          this.updateSniffing();
        }
        break;
      case 'sniffingUpnp':
        if (this.sniffingLocationConfiguration) {
          this.sniffingLocationConfiguration.upnp.mode = action;
          this.updateSniffing();
        }
        break;
      case 'sniffingMdns':
        if (this.sniffingLocationConfiguration) {
          this.sniffingLocationConfiguration.mdns.mode = action;
          this.updateSniffing();
        }
        break;
      case 'inlineDpi':
        this.store.dispatch(configPaSInlineDpiSet({ value: action as any }));
        break;
      case 'dosProtection':
        this.store.dispatch(configPaSDosProtectionSet({ value: action as any }));
        break;
      case 'dosProtectionAction':
        this.store.dispatch(configPaSDosProtectionActionSet({ value: action as any }));
        break;
      case 'dpiContentFiltering':
        this.store.dispatch(configPaSDpiContentFilteringSet({ value: action as any }));
        break;
      case 'unauthorizedInboundConnection':
        this.securityPolicyService.setRemoteConnections$(action as any).subscribe(
          () => {
            this.getRemoteConnections();
            this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_UNAUTHORIZED_INBOUND_CONNECTION');
          },
          (error: any) => {
            this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_UNAUTHORIZED_INBOUND_CONNECTION_ERROR');
            this.toast.error(error.error.error.message, 'header.failed');
            this.getRemoteConnections();
          }
        );
        break;
    }
  }

  setFlowStats(body: { [key: string]: 'enable' | 'disable' | 'auto' }, msgId): void {
    this.store.dispatch(configPaSFlowStatsSet({ value: body, msgId }));
  }

  initTogglers(): void {
    this.sniffingDnsItems = [
      {
        value: 'auto',
        translation: 'auto',
        selected: !(
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.dns &&
          this.sniffingLocationConfiguration.dns.mode !== 'auto'
        )
      },
      {
        value: 'enable',
        translation: 'enable',
        selected:
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.dns &&
          this.sniffingLocationConfiguration.dns.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        selected:
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.dns &&
          this.sniffingLocationConfiguration.dns.mode === 'disable'
      }
    ];

    this.sniffingHttpItems = [
      {
        value: 'auto',
        translation: 'auto',
        selected: !(
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.http &&
          this.sniffingLocationConfiguration.http.mode !== 'auto'
        )
      },
      {
        value: 'enable',
        translation: 'enable',
        selected:
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.http &&
          this.sniffingLocationConfiguration.http.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        selected:
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.http &&
          this.sniffingLocationConfiguration.http.mode === 'disable'
      }
    ];

    this.sniffingUpnpItems = [
      {
        value: 'auto',
        translation: 'auto',
        selected: !(
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.upnp &&
          this.sniffingLocationConfiguration.upnp.mode !== 'auto'
        )
      },
      {
        value: 'enable',
        translation: 'enable',
        selected:
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.upnp &&
          this.sniffingLocationConfiguration.upnp.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        selected:
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.upnp &&
          this.sniffingLocationConfiguration.upnp.mode === 'disable'
      }
    ];

    this.sniffingMdnsItems = [
      {
        value: 'auto',
        translation: 'auto',
        selected: !(
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.mdns &&
          this.sniffingLocationConfiguration.mdns.mode !== 'auto'
        )
      },
      {
        value: 'enable',
        translation: 'enable',
        selected:
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.mdns &&
          this.sniffingLocationConfiguration.mdns.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        selected:
          this.sniffingLocationConfiguration &&
          this.sniffingLocationConfiguration.mdns &&
          this.sniffingLocationConfiguration.mdns.mode === 'disable'
      }
    ];
  }

  setUnauthorizedInboundConnectionItems(): void {
    this.unauthorizedInboundConnectionItems = [
      {
        value: 'auto',
        translation: 'auto',
        selected: this.remoteConnection?.mode === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        selected: this.remoteConnection?.mode === 'enable',
        marked: this.remoteConnection?.modeRealized === 'enable'
      },
      {
        value: 'highRiskOnly',
        translation: 'highRiskOnly',
        selected: this.remoteConnection?.mode === 'highRiskOnly',
        marked: this.remoteConnection?.modeRealized === 'highRiskOnly'
      },
      {
        value: 'disable',
        translation: 'disable',
        selected: this.remoteConnection?.mode === 'disable',
        marked: this.remoteConnection?.modeRealized === 'disable'
      }
    ];
  }

  formatDate(d: string): string {
    if (d) {
      return moment(d).format('L LT');
    } else {
      return 'N/A';
    }
  }

  setSipAlgMode(mode: autoModes): void {
    this.store.dispatch(sipAlgModeSet({ mode }));
  }

  private getSipALgItems(data: IConfigAndState): SlideTogglerItems<autoModes> {
    return [
      {
        value: 'AUTO',
        translation: 'auto',
        selected: data?.config?.sipAlg?.mode === 'AUTO'
      },
      {
        value: 'ENABLE',
        translation: 'enable',
        marked: data?.state?.sipAlg?.enable,
        selected: data?.config?.sipAlg?.mode === 'ENABLE'
      },
      {
        value: 'DISABLE',
        translation: 'disable',
        marked: !data?.state?.sipAlg?.enable,
        selected: data?.config?.sipAlg?.mode === 'DISABLE'
      }
    ];
  }
}
