<ng-container *ngIf="ready">
  <header></header>
  <div class="container-fluid">
    <div class="container-fluid">
      <div class="row center-xs">
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4">
          <icon [path]="logo" class="logo"></icon>
          <div class="warning" *ngIf="!environment.production">
            <div class="title">CAUTION - {{ environment.ENV }} SITE</div>
            <div class="message">Please redirect to Production URL</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid" *ngIf="globalDomain">
      <div class="row center-xs">
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4">
          <div class="row center-xs" (keyup.enter)="oktaLogin()">
            <div class="dropdown" toggler>
              <div class="content">
                <div class="text">{{ env.selected.name }}</div>
                <div class="list">
                  <div class="item" *ngFor="let env of env.list" (click)="selectEnv(env)">{{ env.name }}</div>
                </div>
              </div>
              <icon name="icon-arrow-down" class="size-0_8"></icon>
            </div>
          </div>
        </div>
      </div>
      <div class="row center-xs">
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4">
          <div class="row center-xs">
            <button class="col-xs-12 env-select" (click)="redirect()">
              <span>{{ 'login.selectDeployment' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid" *ngIf="!globalDomain">
      <div class="row center-xs">
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4">
          <tabs name="loginOption" (tabChange)="resetForm()">
            <tab id="okta" label="login.loginOptionOkta" *ngIf="env.selected.oktaAuth">
              <div class="container-fluid">
                <button class="login-button" (click)="oktaLogin()">{{ 'login.loginOkta' | translate }}</button>
              </div>
            </tab>
            <tab #loopbackTab id="loopback" label="login.loginOptionSystemUser" *ngIf="env.selected.loopbackAuth">
              <div class="container-fluid" (keyup.enter)="login()" *ngIf="loopbackTab.active">
                <input
                  [class.error]="error.show"
                  [formControl]="email"
                  type="email"
                  placeholder="{{ 'login.emailPlaceholder' | translate }}"
                  autofocus
                />
                <input
                  [class.error]="error.show"
                  [formControl]="password"
                  type="password"
                  placeholder="{{ 'login.passwordPlaceholder' | translate }}"
                  autofocus
                />
                <button class="login-button" (click)="login()">
                  <span *ngIf="!loading">{{ 'login.login' | translate }}</span>
                  <ng-container *ngIf="loading">
                    &nbsp;<icon name="icon-spinner" class="size-1_5"></icon>
                  </ng-container>
                </button>
                <a routerLink="/reset">{{ 'login.resetPassword' | translate }}</a>
              </div>
            </tab>
            <tab id="idp" label="login.loginOptionExternalIDP" *ngIf="env.selected.idps?.length > 0">
              <div class="container-fluid">
                <div class="dropdown" toggler *ngIf="env.selected.idps.length > 1">
                  <div class="content">
                    <div class="text">{{ env.selected.idps[env.selectedIdpIndex].description }}</div>
                    <div class="list">
                      <div class="item" *ngFor="let idp of env.selected.idps; let i = index" (click)="selectIdp(i)">
                        {{ env.selected.idps[i].description }}
                      </div>
                    </div>
                  </div>
                  <icon name="icon-arrow-down" class="size-0_8"></icon>
                </div>
                <button class="login-button" (click)="cloudLogin(env.selected.idps[env.selectedIdpIndex] || null)">
                  <span>{{ 'login.loginSso' | translate }}</span>
                  {{ env.selected.idps[env.selectedIdpIndex].description }}
                </button>
              </div>
            </tab>
          </tabs>
        </div>
      </div>

      <div class="row center-xs">
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4">
          <cloudstatus></cloudstatus>
        </div>
      </div>
    </div>

    <div class="container-error" *ngIf="error.show">
      {{ error.message | translate }}
    </div>
  </div>

  <div class="version">{{ applicationName }} v{{ versionString }} {{ environment.ENV }}</div>

  <modal mode="compact" *ngIf="showIdp">
    <ng-container title>{{ 'login.idp.title' | translate }}</ng-container>
    <ng-container content>{{ 'login.idp.message' | translate: { count: count } }}</ng-container>
    <ng-container controls>
      <button class="tertiary light" (click)="cancelIdp()">
        {{ 'login.idp.cancel' | translate }}
      </button>
    </ng-container>
  </modal>
</ng-container>
