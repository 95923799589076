import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[trimValue]' })
export class TrimValueDirective {
  constructor(ngControl: NgControl) {
    const original = ngControl.valueAccessor.registerOnChange;

    ngControl.valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
      return original.call(ngControl.valueAccessor, (value: unknown) => {
        return fn(typeof value === 'string' ? value.trim() : value);
      });
    };
  }
}
