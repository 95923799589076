import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { DeepReadonly, ILocation, IModeString } from 'src/app/lib/interfaces/interface';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import {
  selectConfigAndState,
  selectNonFullModeCapable,
  selectPipeLocationOnChange
} from 'src/app/store/customer/customer.selectors';
import { pollingPull } from 'src/app/store/polling/polling.actions';
import { selectNodes } from 'src/app/store/polling/polling.selector';
import { customerLocationConfigAndStateReload } from '../../../../store/customer/customer.actions';

@UntilDestroy()
@Component({
  selector: 'wifiradio',
  templateUrl: './wifiradio.component.html',
  styleUrls: ['./wifiradio.component.scss']
})
export class WifiRadioComponent implements OnInit, OnChanges, OnDestroy {
  expand: boolean = false;
  locationSubscription: any;
  location: DeepReadonly<ILocation> = {} as any;
  wifiRadio: boolean;
  uAPSD: boolean = false;
  haahs: string = '';
  haahsRealized: string = '';
  haahsItems: any[] = [];
  wifiNetwork: any = {};
  groupRekeyItems: any[] = [];
  puncturingItems: any[] = [];
  MinWifiMode24Items: any[] = [];
  controlModeItems: any[] = [];
  controlMode: string = '';
  controlModeRealized: string = '';
  punctureModeRealized: boolean = false;
  punctureModeRequest: string = '';
  punctureWait: boolean = false;
  punctureCapable: boolean = false;

  onboarded: boolean = false;
  nodes$ = this.store.select(selectNodes);
  capabilities$ = this.store.select(selectCapabilities);
  selectNonFullModeCapable$ = this.store.select(selectNonFullModeCapable);

  configAndState: any = null;
  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    private customerService: CustomerService,
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private translate: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.registerFilter();

    this.locationSubscription = this.store
      .pipe(selectPipeLocationOnChange)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        if (response) {
          this.location = response;
          this.getPuncture();
          this.getWifinetwork();
          this.getControlMode();
          this.initTogglers();
        }
      });

    combineLatest([
      this.store.pipe(selectPipeLocationOnChange),
      this.capabilities$.pipe(
        distinctUntilChanged((a, b) => a?.haahs?.capable && b?.haahs?.capable),
        filter((capabilities) => capabilities?.haahs?.capable)
      )
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getHaahs();
        this.getHaahsRealized();
      });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'wifiradio' });

    this.translate
      .get('configurations.wifiradio.puncturing')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wifiradio', property: 'puncturing', translation: translated })
      );

    this.translate
      .get('configurations.wifiradio.haahs')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wifiradio', property: 'haahs', translation: translated })
      );

    this.translate
      .get('configurations.wifiradio.uapsd')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wifiradio', property: 'uapsd', translation: translated })
      );

    this.translate
      .get('configurations.wifiradio.groupRekey')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wifiradio', property: 'groupRekey', translation: translated })
      );

    this.translate
      .get('configurations.wifiradio.MinWifiMode24')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wifiradio', property: 'MinWifiMode24', translation: translated })
      );

    this.translate
      .get('configurations.wifiradio.controlMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wifiradio', property: 'controlMode', translation: translated })
      );

    this.translate
      .get('customerinfo.locationConfiguration.monitorMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wifiradio', property: 'controlMode', translation: translated })
      );
  }

  getWifinetwork(): void {
    this.customerService.getWifiNetwork$().subscribe((response) => {
      this.mixpanel.storeEvent('CONFIGURATION_GET_WIFI_NETWORK');
      this.wifiNetwork = response.wifiNetwork;
      this.wifiRadio = this.wifiNetwork.enabled;
      this.uAPSD = this.wifiNetwork.uapsd;
      this.initTogglers();
    });
  }

  getPuncture(): void {
    this.store
      .select(selectConfigAndState)
      .pipe(untilDestroyed(this))
      .subscribe((configAndState) => {
        this.configAndState = configAndState;
        this.punctureCapable = configAndState.state?.capabilities?.puncturing;
        if (this.punctureCapable) {
          this.punctureModeRealized = configAndState.state?.puncturing?.enable;
          this.punctureModeRequest = configAndState.config?.puncturing?.mode;
          this.initPunctureToggle();
        }
      });
  }

  getHaahs(): void {
    this.customerService
      .getHaas$()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.haahs = response.mode;
        this.initTogglers();
      });
  }

  getHaahsRealized(): void {
    this.haahsRealized = this.location.haahsConfiguration.mode;
    this.initTogglers();
  }

  getControlMode(): void {
    this.customerService
      .getControlMode$()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.mixpanel.storeEvent('CONFIGURATION_GET_CONTROL_MODE');
        this.controlMode = response.mode;
        this.controlModeRealized = response.modeRealized;
        this.initTogglers();
      });
  }

  updateWifiRadio(): void {
    this.capabilities$
      .pipe(
        take(1),
        filter((capabilities) => capabilities.radioDisableSupported.capable || !this.wifiRadio),
        tap(() => {
          this.wifiRadio = !this.wifiRadio;
        }),
        switchMap(() => this.customerService.enableWifiNetwork$(this.wifiRadio))
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any) => {
          this.wifiNetwork = response;
          this.mixpanel.storeEvent('CONFIGURATION_UPDATEWIFI_RADIO');
        },
        (error: any) => {
          this.wifiRadio = !this.wifiRadio;
          this.mixpanel.storeEvent('CONFIGURATION_UPDATEWIFI_RADIO_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
  }

  updateuAPSD(): void {
    this.uAPSD = !this.uAPSD;
    this.customerService
      .patchWifiNetwork$({
        uapsd: this.uAPSD
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.wifiNetwork = response;
          this.mixpanel.storeEvent('CONFIGURATION_WIFI_NETWORK_UAPSD_UPDATE');
        },
        (error: any) => {
          this.uAPSD = !this.uAPSD;
          this.mixpanel.storeEvent('CONFIGURATION_WIFI_NETWORK_UAPSD_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
  }

  action(command: string, action: string): void {
    switch (command) {
      case 'punctureChange': {
        if (action !== this.punctureModeRequest) {
          this.punctureWait = true;
          this.customerService
            .setPuncturing$(action as 'ENABLE' | 'DISABLE' | 'AUTO')
            .pipe(untilDestroyed(this))
            .subscribe(
              (response) => {
                this.mixpanel.storeEvent('CONFIGURATION_PUNCTURING', { PUNCTURING: action });
                setTimeout(() => {
                  this.store.dispatch(
                    customerLocationConfigAndStateReload({
                      actionType: '[Configuration][Puncturing] Puncturing mode set'
                    })
                  );
                  this.punctureWait = false;
                  this.toast.success('configurations.wifiradio.puncturingSet', 'header.success');
                }, 5000);
              },
              (error: any) => {
                this.mixpanel.storeEvent('CONFIGURATION_PUNCTURING_ERROR');
                this.toast.error(error.error.error.message, 'header.failed');
              }
            );
        }
        break;
      }

      case 'groupRekey':
        this.customerService
          .patchWifiNetwork$({
            groupRekey: action
          })
          .pipe(untilDestroyed(this))
          .subscribe(
            (response) => {
              this.wifiNetwork = response;
              this.mixpanel.storeEvent('CONFIGURATION_WIFI_NETWORK_GROUP_REKEY', { GROUPREKEY: action });
              this.store.dispatch(pollingPull({ debugSource: 'wifi radio - groupRekey' }));
            },
            (error: any) => {
              this.mixpanel.storeEvent('CONFIGURATION_WIFI_NETWORK_GROUP_REKEY_ERROR');
              this.toast.error(error.error.error.message, 'header.failed');
            }
          );
        break;

      case 'MinWifiMode24':
        this.customerService
          .patchWifiNetwork$({
            minWifiMode24: action
          })
          .pipe(untilDestroyed(this))
          .subscribe((response) => {
            this.wifiNetwork = response;
            this.mixpanel.storeEvent('CONFIGURATION_WIFI_NETWORK_MIN_WIFI_MODE_24', { MINWIFIMODE24: action });
            this.store.dispatch(pollingPull({ debugSource: 'wifi radio - MinWifiMode24' }));
          });
        break;

      case 'controlMode':
        this.customerService
          .setControlMode$(action as 'full' | 'monitor' | 'reduced' | 'battery')
          .pipe(untilDestroyed(this))
          .subscribe(
            (response) => {
              this.controlMode = response.mode;
              this.mixpanel.storeEvent('CONFIGURATION_CONTROL_MODE', { CONTROLMODE: action });
              this.initTogglers();
            },
            (error: any) => {
              this.mixpanel.storeEvent('CONFIGURATION_CONTROL_MODE_ERROR');
              this.toast.error(error.error.error.message, 'header.failed');
              this.initTogglers();
            }
          );
        break;
      case 'haahs':
        this.customerService
          .setHaas$(action as 'auto' | 'enable' | 'disable')
          .pipe(untilDestroyed(this))
          .subscribe(
            () => {
              this.haahs = action;
              this.mixpanel.storeEvent('CONFIGURATION_HAAHS', { HAAHS: action });
              this.initTogglers();
            },
            (error: any) => {
              this.mixpanel.storeEvent('CONFIGURATION_HAAS_ERROR');
              this.toast.error(error.error.error.message, 'header.failed');
              this.initTogglers();
            }
          );
        break;
    }
  }

  initPunctureToggle(): void {
    this.puncturingItems = [
      {
        value: 'AUTO',
        translation: 'auto',
        selected: !this.punctureModeRequest || this.punctureModeRequest === 'AUTO'
      },
      {
        value: 'ENABLE',
        translation: 'enabled',
        marked: this.punctureModeRealized,
        selected: this.punctureModeRequest === 'ENABLE'
      },
      {
        value: 'DISABLE',
        translation: 'disabled',
        marked: !this.punctureModeRealized,
        selected: this.punctureModeRequest === 'DISABLE'
      }
    ];
  }

  initTogglers(): void {
    this.groupRekeyItems = [
      {
        value: 'auto',
        translation: 'auto',
        selected: !this.wifiNetwork?.groupRekey || this.wifiNetwork.groupRekey === 'auto'
      },
      {
        value: 'enable',
        translation: 'enabled',
        marked: this.location?.groupRekey,
        selected: this.wifiNetwork.groupRekey === 'enable'
      },
      {
        value: 'disable',
        translation: 'disabled',
        marked: !this.location?.groupRekey,
        selected: this.wifiNetwork.groupRekey === 'disable'
      }
    ];

    this.MinWifiMode24Items = [
      {
        value: 'auto',
        translation: 'auto',
        selected: !this.wifiNetwork?.minWifiMode24 || this.wifiNetwork.minWifiMode24 === 'auto'
      },
      {
        value: '11b',
        translation: '11b',
        marked: this.location?.minWifiMode24 === '11b',
        selected: this.wifiNetwork.minWifiMode24 === '11b'
      },
      {
        value: '11b-high',
        translation: '11b-high',
        marked: this.location?.minWifiMode24 === '11b-high',
        selected: this.wifiNetwork.minWifiMode24 === '11b-high'
      },
      {
        value: '11g',
        translation: '11g',
        marked: this.location?.minWifiMode24 === '11g',
        selected: this.wifiNetwork.minWifiMode24 === '11g'
      },
      {
        value: '11n',
        translation: '11n',
        marked: this.location?.minWifiMode24 === '11n',
        selected: this.wifiNetwork.minWifiMode24 === '11n'
      }
    ];

    this.controlModeItems = [
      {
        value: 'full',
        translation: 'configurations.wifiradio.full',
        marked: this.controlModeRealized === 'full',
        selected: this.location?.controlMode === 'full' || (this.location?.controlMode as IModeString)?.mode === 'full'
      },
      {
        value: 'monitor',
        translation: 'configurations.wifiradio.monitor',
        marked: this.controlModeRealized === 'monitor',
        selected:
          this.location?.controlMode === 'monitor' || (this.location?.controlMode as IModeString)?.mode === 'monitor'
      },
      {
        value: 'reduced',
        translation: 'configurations.wifiradio.reduced',
        marked: this.controlModeRealized === 'reduced',
        selected:
          this.location?.controlMode === 'reduced' || (this.location?.controlMode as IModeString)?.mode === 'reduced'
      },
      {
        value: 'battery',
        translation: 'configurations.wifiradio.battery',
        marked: this.controlModeRealized === 'battery',
        selected:
          this.location?.controlMode === 'battery' || (this.location?.controlMode as IModeString)?.mode === 'battery'
      }
    ];

    this.haahsItems = [
      {
        value: 'auto',
        translation: 'auto',
        marked: this.haahsRealized === 'auto',
        selected: !this.haahs || this.haahs === 'auto'
      },
      {
        value: 'enable',
        translation: 'enabled',
        marked: this.haahsRealized === 'enable',
        selected: this.haahs === 'enable'
      },
      {
        value: 'disable',
        translation: 'disabled',
        marked: this.haahsRealized === 'disable',
        selected: this.haahs === 'disable'
      }
    ];
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);
    this.initTogglers();

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_WIFI_RADIO_SETTINGS_SCREEN');
    }
  }

  ngOnDestroy(): void {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }
}
