/// <reference types="web-bluetooth" />

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastService } from 'src/app/lib/services/toast.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { Store } from '@ngrx/store';
import { selectNodes } from 'src/app/store/polling/polling.selector';
import { CustomerService } from 'src/app/lib/services/customer.service';

@Component({
  selector: 'ble',
  templateUrl: './ble.component.html',
  styleUrls: ['./ble.component.scss']
})
export class BleComponent implements OnInit, OnDestroy {
  subscription: any;
  mode: string = 'disconnected';
  pin: number = null;
  device: any = null;
  node: any = null;
  nodes: any[] = [];
  allNodes: any[] = [];
  characteristic: any = null;
  timeout: any;
  writeSuccess: boolean = false;

  pppoe: boolean = false;
  username: any = new FormControl();
  password: any = new FormControl();
  dataService: boolean = false;
  vlan: any = new FormControl();
  qos: any = new FormControl();
  staticIPv4: boolean = false;
  ip: any = new FormControl();
  subnet: any = new FormControl();
  gateway: any = new FormControl();
  primaryDns: any = new FormControl();
  secondaryDns: any = new FormControl();

  constructor(
    private customerService: CustomerService,
    private toast: ToastService,
    private nodeService: NodeService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.subscription = this.store.select(selectNodes).subscribe((nodes) => {
      if (nodes) {
        this.allNodes = nodes;
        this.selectNode();
      }
    });
  }

  selectNode(node: any = null): void {
    if (node) {
      this.node = node;
    }

    this.nodes = this.allNodes.filter((n: any) => n.id !== this.node?.id);
  }

  scan(): void {
    this.pin = null;
    this.device = null;

    new Promise((resolve: any) => {
      if (this.node.connectionState === 'connected') {
        this.nodeService.setBleMode$(this.node.id, 'connectable').subscribe(() => {
          resolve();
        });
      } else {
        resolve();
      }
    }).then(() => {
      try {
        navigator.bluetooth
          .requestLEScan({ filters: [{ services: [0xfe71] }] })
          .then((data: any) => {
            this.mode = 'scanning';

            let advertismentCounter = 0;

            navigator.bluetooth.addEventListener('advertisementreceived', (event: any) => {
              let decoded = null;

              event.manufacturerData.forEach((data: any) => {
                decoded = this.decode(data);
              });

              if (decoded && decoded.value && this.node && decoded.value.includes(this.node.id)) {
                advertismentCounter++;

                if (advertismentCounter > 10) {
                  clearTimeout(this.timeout);
                }

                if (decoded.hasToken) {
                  // @ts-ignore
                  navigator.bluetooth.removeAllListeners();
                  data.stop();
                  this.connect(event, decoded);
                } else {
                  this.timeout = setTimeout(() => {
                    // @ts-ignore
                    navigator.bluetooth.removeAllListeners();
                    data.stop();
                    this.disconnect();
                  }, 5000);
                }
              }
            });
          })
          .catch((error: any) => {
            console.log(error);
            this.disconnect();
          });
      } catch (e) {
        if (e.message.indexOf('requestLEScan') > -1) {
          this.toast.warning(
            'Please enable chrome experimental flag in chrome://flags/#enable-experimental-web-platform-features',
            'Enable experimental flag',
            { disableTimeOut: true }
          );
        }

        this.disconnect();
      }
    });
  }

  requestDevice(): void {
    this.pin = null;
    this.device = null;

    new Promise((resolve: any) => {
      if (this.node.connectionState === 'connected') {
        this.nodeService.setBleMode$(this.node.id, 'connectable').subscribe(() => {
          resolve();
        });
      } else {
        resolve();
      }
    }).then(() => {
      try {
        navigator.bluetooth
          .requestDevice({ filters: [{ services: [0xfe71] }] })
          .then((device: any) => {
            this.mode = 'scanning';

            const abortController = new AbortController();

            let advertismentCounter = 0;

            device.watchAdvertisements({ signal: abortController.signal });

            device.addEventListener('advertisementreceived', (event: any) => {
              let decoded = null;

              event.manufacturerData.forEach((data: any) => {
                decoded = this.decode(data);
              });

              if (decoded && decoded.value && this.node && decoded.value.includes(this.node.id)) {
                advertismentCounter++;

                if (advertismentCounter > 10) {
                  clearTimeout(this.timeout);
                }

                if (decoded.hasToken) {
                  device.removeAllListeners();
                  abortController.abort();
                  this.connect(event, decoded);
                } else {
                  this.timeout = setTimeout(() => {
                    device.removeAllListeners();
                    abortController.abort();
                    this.disconnect();
                  }, 5000);
                }
              }
            });
          })
          .catch((error: any) => {
            if (error.message.indexOf('watchAdvertisements') > -1) {
              this.toast.warning(
                'Please enable chrome experimental flag in chrome://flags/#enable-experimental-web-platform-features',
                'Enable experimental flag',
                { disableTimeOut: true }
              );
            }

            this.disconnect();
          });
      } catch (e) {
        console.log(e);
        this.disconnect();
      }
    });
  }

  decode(data: any, json: boolean = false): any {
    const textDecoder = new TextDecoder('ascii');
    const asciiString = textDecoder.decode(data.buffer).replace(/\0/g, '');

    if (json) {
      let obj = {};

      try {
        obj = JSON.parse(asciiString);

        if (!Object.keys(obj).length) {
          this.toast.warning('Data set is empty, try read data again or write the first set of data.', 'No data');
        }
      } catch (e) {
        this.toast.warning('Data set is empty, try read data again or write the first set of data.', 'No data');
      }

      return {
        value: asciiString,
        json: obj
      };
    } else {
      const bytes = [...new Uint8Array(data.buffer)].slice(data.byteLength - 4);

      return {
        value: asciiString,
        token: bytes.map((b: any) => b.toString(16).padStart(2, '0')).join(''),
        hasToken: bytes.reduce((a: number, v: number) => a + v, 0) ? true : false
      };
    }
  }

  connect(event: any, decoded: any): void {
    this.mode = 'pairing';

    this.requestPin(this.node.id, decoded.token).then((pin: number) => {
      if (pin) {
        this.pin = pin;

        event.device.gatt
          .connect()
          .then((status: any) => {
            this.device = event.device;
            return status.getPrimaryService(0xfe71);
          })
          .then((service: any) => {
            return service.getCharacteristic('e5e01504-fe71-4a90-9898-376fa622de9c');
          })
          .then((characteristic: any) => {
            this.characteristic = characteristic;
            return characteristic.readValue();
          })
          .then(() => {
            this.mode = 'connected';
            this.readValues();
          })
          .catch((error: any) => {
            if (error.message.indexOf('not authorized.') > -1) {
              this.toast.warning(
                'Selected node has never been bluetooth paired to this device. Please select device using "Request Device" method first. If there is a Operating System dialog enter the PIN ' +
                  this.pin,
                'Never Bluetooth Paired',
                { disableTimeOut: true }
              );
            }

            if (error.message.indexOf('unknown') > -1) {
              this.toast.warning(
                'Unknown Bluetooth error. Please REFRESH this screen/Reboot node and try again',
                'Bluetooth Pairing error',
                {
                  disableTimeOut: true
                }
              );
            }
            if (error.message.indexOf('Not paired.') > -1) {
              this.toast.warning(
                'This device is not paired. Please enter PIN ' + this.pin + ' in Operating System dialog, and retry.',
                'Pairing required',
                {
                  disableTimeOut: true
                }
              );
            }
            console.log(error);

            this.disconnect();
          });
      } else {
        console.log('PIN not acquired');
        this.disconnect();
      }
    });
  }

  disconnect(): void {
    if (this.device) {
      this.device.gatt.disconnect();
    }

    clearTimeout(this.timeout);

    this.characteristic = null;
    this.pin = null;
    this.device = null;
    this.mode = 'disconnected';
  }

  readValues(): void {
    if (this.characteristic) {
      this.characteristic.readValue().then((data: any) => {
        const response = this.decode(data, true);

        this.pppoe = response.json.PPPoE?.enabled ? response.json.PPPoE.enabled : false;
        this.username.setValue(response.json.PPPoE?.username ? response.json.PPPoE.username : '');
        this.password.setValue(response.json.PPPoE?.password ? response.json.PPPoE.password : '');

        this.dataService = response.json.DataService?.enabled ? response.json.DataService.enabled : false;
        this.vlan.setValue(response.json.DataService?.VLAN ? response.json.DataService?.VLAN : 0);
        this.qos.setValue(response.json.DataService?.QoS ? response.json.DataService?.QoS : 0);

        this.staticIPv4 = response.json.staticIPv4?.enabled ? response.json.staticIPv4.enabled : false;
        this.ip.setValue(response.json.staticIPv4?.ip ? response.json.staticIPv4.ip : '');
        this.subnet.setValue(response.json.staticIPv4?.subnet ? response.json.staticIPv4.subnet : '');
        this.gateway.setValue(response.json.staticIPv4?.gateway ? response.json.staticIPv4.gateway : '');
        this.primaryDns.setValue(response.json.staticIPv4?.primaryDns ? response.json.staticIPv4.primaryDns : '');
        this.secondaryDns.setValue(response.json.staticIPv4?.secondaryDns ? response.json.staticIPv4.secondaryDns : '');
      });
    }
  }

  writeValues(): void {
    if (this.characteristic) {
      const encoder = new TextEncoder();
      const data = encoder.encode(
        JSON.stringify({
          wanConnectionType: 'dynamic',
          PPPoE: {
            enabled: this.pppoe,
            username: this.username.value,
            password: this.password.value
          },
          DataService: {
            enabled: this.dataService,
            VLAN: parseInt(this.vlan.value, 10),
            QoS: parseInt(this.qos.value, 10)
          },
          staticIPv4: {
            enabled: this.staticIPv4,
            ip: this.ip.value,
            subnet: this.subnet.value,
            gateway: this.gateway.value,
            primaryDns: this.primaryDns.value,
            secondaryDns: this.secondaryDns.value
          }
        })
      );

      this.characteristic.writeValue(data).then(() => {
        this.writeSuccess = true;
        this.readValues();
      });
    }
  }

  requestPin(nodeId: string, token: string): Promise<number> {
    return new Promise((resolve: any) => {
      this.nodeService.getBlePairingPin$(nodeId, token).subscribe(
        (response: any) => {
          resolve(response.blePairingPin);
        },
        (error: any) => {
          resolve(null);
        }
      );
    });
  }

  pushToCloud(): void {
    const data = {
      pppoe: {
        enabled: this.pppoe,
        username: this.username.value,
        password: this.password.value
      },
      uplink: {
        enabled: this.dataService,
        hardwarePort: 'any',
        vlan: parseInt(this.vlan.value, 10),
        qos: parseInt(this.qos.value, 10)
      },
      staticIPv4: {
        enabled: this.staticIPv4,
        ip: this.ip.value,
        subnet: this.subnet.value,
        gateway: this.gateway.value,
        primaryDns: this.primaryDns.value,
        secondaryDns: this.secondaryDns.value
      }
    };

    this.customerService.setWanConfiguration$(data).subscribe(() => {
      this.toast.success('Configuration from node ' + this.node.id + ' was successfully synced to cloud!', 'Success');
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
