import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ToastService {
  toasts: any[] = [];
  event: Subject<any> = new Subject<any>();
  remove: Subject<any> = new Subject<any>();
  action: Subject<any> = new Subject<any>();

  sendMessage(type: string, message: string, title: string, options: any, customId: string = null): string {
    const id = customId
      ? customId
      : Math.random()
          .toString(36)
          .substr(2, 9);

    const toast = {
      id,
      state: 'add',
      type,
      message,
      title,
      options
    };

    this.event.next(toast);

    this.toasts.push(toast);

    return id;
  }

  success(message: string, title: string, options: any = {}): string {
    return this.sendMessage('success', message, title, options);
  }

  warning(message: string, title: string, options: any = {}): string {
    return this.sendMessage('warning', message, title, options);
  }

  error(message: string, title: string, options: any = {}): string {
    return this.sendMessage('error', message, title, options);
  }

  singleton(id: string, type: string, message: string, title: string, options: any = {}): void {
    const exist = this.toasts.filter((toast: any) => toast.id === id);

    if (!exist.length) {
      this.sendMessage(type, message, title, options, id);
    }
  }

  dismiss(id: string): void {
    this.toasts = this.toasts.filter((toast: any) => id !== toast.id);
    this.remove.next(id);
  }

  dismissAll(): void {
    this.toasts = this.toasts.filter((toast: any) => {
      if (toast.options.global) {
        return true;
      } else {
        this.remove.next(toast.id);
        return false;
      }
    });
  }

  check(id: string): boolean {
    const found = this.toasts.find((toast: any) => id === toast.id);

    return found ? true : false;
  }
}
