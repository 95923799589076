<div class="table">
  <div class="headers">
    <div class="header">
      <div class="sortable" [ngClass]="header.key" *ngFor="let header of headers">
        <div
          class="txt"
          [class.disabled]="header.key === 'upgradeParameters' || header.key === 'batchSize'"
          (click)="changeSort(header.key)"
        >
          {{ header.value | translate }}
        </div>
        <icon
          name="icon-triangle-down"
          class="size-0_8"
          [class.rotate180]="header.desc"
          *ngIf="header.key === sort"
        ></icon>
      </div>
    </div>
    <div class="header header-filter" *ngIf="!noFilters">
      <div class="input-filter-wrap" [formGroup]="formGroup" [ngClass]="header.key" *ngFor="let header of headers">
        <ng-container *ngIf="header.key !== 'batchSize' && header.key !== 'timestamp'">
          <input
            class="input-filter"
            (keyup)="filterChange($event, header.key)"
            [formControlName]="header.key"
            type="text"
          />
        </ng-container>
        <div class="filter-batchSize" *ngIf="header.key === 'batchSize'">
          <span class="over-one">>1: </span>
          <div class="toggler" (click)="toggleBatchSizeOverOne()">
            <div class="checkbox"><label class="toggle" [class.checked]="batchSizeOverOne"></label></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let report of list">
    <div
      class="reports"
      [class.loading]="loadingReports"
      *ngIf="!batchSizeOverOne || (batchSizeOverOne && report.batchSize > 1)"
    >
      <div class="batchID" (click)="selectID(report)">{{ report.id }}</div>
      <div class="targetVersion">{{ report.upgradeParameters.targetVersionMatrix }}</div>
      <div class="batchSize">{{ report.batchSize }}</div>
      <div class="requestedBy">{{ report.userIdOfRequestor }}</div>
      <div class="approvedBy">{{ report.userIdOfApprover }}</div>
      <div class="timestamp">{{ report.timestamp | date: 'LLL' }}</div>
    </div>
  </ng-container>
</div>
