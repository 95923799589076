import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import {
  IAppFacade,
  IHomeSecurity,
  IHomeSecurityDevicesSounding,
  IPerson,
  IWanStatsDaily,
  IWanStatsMonthly,
  IWanStatsWeekly,
  IWifiMotion
} from '../interfaces/interface';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ProfilerService {
  constructor(private api: ApiService, private store: Store) {}

  appFacadeDashboard$(): Observable<IAppFacade> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/appFacade/dashboard`))
    );
  }

  wifiMotion$(): Observable<IWifiMotion> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/wifiMotion`))
    );
  }

  wifiMotionPatch$(data: Partial<Omit<IWifiMotion, 'createdAt' | 'updatedAt'>>): Observable<IWifiMotion> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/wifiMotion`, data))
    );
  }

  homeSecurity$(): Observable<IHomeSecurity> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/homeSecurity`))
    );
  }

  homeSecurityPatch$(data: Partial<IHomeSecurity> & { source: string }): Observable<IHomeSecurity> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/homeSecurity`, data))
    );
  }

  homeSecuritySensitivity$(data: {
    petMode?: 'none' | 'over30';
    sensitivity?: 'high' | 'medium' | 'low';
    cooldown?: 60 | 120 | 180 | 300;
  }): Observable<IHomeSecurity> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/homeSecurity/sensitivity`, data))
    );
  }

  homeSecurityDevicesSounding$(): Observable<IHomeSecurityDevicesSounding> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/homeSecurity/devices/sounding`))
    );
  }

  homeSecurityDevicesSoundingPatch$(data: IHomeSecurityDevicesSounding): Observable<IHomeSecurityDevicesSounding> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/homeSecurity/devices/sounding`, data))
    );
  }

  wanStats$(period: number): Observable<IWanStatsMonthly | IWanStatsWeekly | IWanStatsDaily> {
    switch (period) {
      case 1:
        return this.wanStatsDaily$();
      case 7:
        return this.wanStatsWeekly$();
      case 30:
        return this.wanStatsMonthly$();
    }

    return null;
  }

  wanStatsMonthly$(): Observable<IWanStatsMonthly> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/wanStats?period=monthly`, 'reports'))
    );
  }

  wanStatsDaily$(): Observable<IWanStatsDaily> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/wanStats?period=daily`, 'reports'))
    );
  }

  wanStatsWeekly$(): Observable<IWanStatsWeekly> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/wanStats?period=weekly`, 'reports'))
    );
  }

  persons$(): Observable<IPerson[]> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/persons`))
    );
  }
}
