import { ActionReducer, MetaReducer } from '@ngrx/store';
import { ILocation } from '../lib/interfaces/interface';
import { MixpanelService } from '../lib/services/mixpanel.service';
import { customerChange, customerClose } from './customer/customer.actions';
import { initialState } from './customer/customer.reducer';
import { setInitialState } from './state-interfaces';

const clearer =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    if (action.type === customerChange.type || action.type === customerClose.type) {
      state = {
        customer: {
          ...initialState,
          locationList: setInitialState<ILocation[], string>(
            (action as any).customerid === state?.customer?.locationList?.data?.[0]?.customerId
              ? state.customer.locationList.data
              : null
          )
        }
      };
    }

    return reducer(state, action);
  };

export function storeMetaReducerLoggerFactory(mixpanel: MixpanelService): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    // mixpanel.storeEvent(`STORE_(${action.type})`); // TODO enable for mixpanel receive all store events
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearer];
