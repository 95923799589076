import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { DeepReadonly, ILocation } from 'src/app/lib/interfaces/interface';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';

@Component({
  selector: 'locationproducts',
  templateUrl: './locationproducts.component.html',
  styleUrls: ['./locationproducts.component.scss']
})
export class LocationProductsComponent {
  products$ = this.store.pipe(
    selectPipeLocationOnChange,
    map((data) => this.getProfileProducts(data))
  );

  constructor(public plume: PlumeService, private store: Store) {}

  getProfileProducts(location: DeepReadonly<ILocation>): any[] {
    const profiles = [];

    switch (location.profile) {
      case 'auto':
        profiles.push({ type: 'homepass', name: 'HOMEPASS' });
        break;
      case 'smallbusiness':
        profiles.push({ type: 'workpass', name: 'WORKPASS' });
        break;
      case 'property':
        profiles.push({ type: 'property', name: 'PROPERTY' });
    }

    if (location.flex) {
      profiles.push({ type: 'flex', name: '+ FLEX' });
    }

    if (location.uprise) {
      profiles.push({ type: 'uprise', name: '+ UPRISE' });
    }

    return profiles;
  }
}
