import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/lib/services/api.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import * as moment from 'moment/moment';

@Component({
  selector: 'firmwaretimeline',
  templateUrl: './firmwaretimeline.component.html',
  styleUrls: ['./firmwaretimeline.component.scss']
})
export class FirmwareTimelineComponent implements OnChanges {
  @Input()
  active: boolean;

  expanded: boolean = false;
  firmwareEvents: any[] = null;
  dayView: any = null;
  eventDetails: any = null;
  today = moment().endOf('day').utc().toISOString();
  endDay = this.today;
  maxEvents = 500;

  legend: any[] = [
    { text: 'timelines.firmware.legend.updated', color: 'green' },
    { text: 'timelines.firmware.legend.failed', color: 'red' },
    { text: 'timelines.firmware.legend.skipped', color: 'yellow' },
    { text: 'timelines.firmware.legend.others', color: 'blue' }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private plume: PlumeService,
    private mixpanel: MixpanelService
  ) {}


  ngOnChanges(changes: any): void {
    if (changes.active && changes.active.currentValue) {
      this.expanded = true;
      this.initData();
    } else {
      this.expanded = false;
      this.reset();
    }
  }

  initData(): void {
    this.reset();
    this.getFirmwareData();
  }

  reset(): void {
    this.firmwareEvents = null;
    this.dayView = null;
    this.eventDetails = null;
  }

  toggleExpand(): void {
    const enabledModes = this.route.snapshot.params?.mode?.length ? this.route.snapshot.params.mode.split(',') : [];

    if (this.expanded) {
      this.expanded = false;
      this.dayView = null;
      this.eventDetails = null;
      this.mixpanel.storeEvent('FIRMWARE_TIMELINE_MACHINE_EXPAND', { EXPANDED: false });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        enabledModes.filter((mode: string) => mode !== 'firmware').join(',')
      ]);
    } else {
      this.expanded = true;
      this.mixpanel.storeEvent('FIRMWARE_TIMELINE_MACHINE_EXPAND', { EXPANDED: true });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        [...enabledModes, 'firmware'].join(',')
      ]);
    }
  }

  getFirmwareData(): void {
    const startTime = moment(this.endDay).subtract(29, 'days').endOf('day').utc().toISOString();

    this.api
      .get(
        `/Customers/${this.plume.customerid}/locations/${this.plume.locationid}/firmwareUpgrades?startAt=${startTime}&endAt=${this.endDay}&limit=${this.maxEvents}`,
        'reports'
      )
      .subscribe((requests: any) => {
        this.firmwareEvents = requests
          .sort((a: any, b: any) => Number(new Date(a.createdAt)) - Number(new Date(b.createdAt)))
          .map((event: any) => {
            const timeOfDay = moment(event.createdAt).format('LT');
            const tooltipProperties = [
              { key: 'timelines.firmware.updated', value: timeOfDay },
              { key: 'timelines.firmware.versionMatrix', value: event.targetVersionMatrix },
              { key: 'timelines.firmware.status', value: event.status },
              { key: 'timelines.firmware.batchId', value: event.batchFirmwareUpgradeId }
            ];
            const nodes = JSON.parse(event.finalStatus).map((node: any) => {
              const init = JSON.parse(event.initStatus).find((n: any) => n.serialNumber === node.serialNumber);
              return {
                id: node.serialNumber,
                connectionState: node.connectionState,
                initFirmware: init.firmwareVersion,
                initState: init.state,
                finalFirmware: node.firmwareVersion,
                finalState: node.state,
                errorDescription: node.errorDescription
              };
            });

            return {
              createdAt: event.createdAt,
              eventData: {
                event,
                tooltipProperties,
                eventTime: timeOfDay,
                eventName: event.status,
                status: event.status,
                target: event.targetVersionMatrix,
                created: event.created,
                updated: event.updated,
                nodes
              },
              eventType: event.status
            };
          });
      });
  }

  previousDate(): void {
    this.endDay = moment(this.endDay).subtract(29, 'days').endOf('day').utc().toISOString();
    this.firmwareEvents = null;
    this.getFirmwareData();
  }

  nextDate(): void {
    this.endDay = moment(this.endDay).add(29, 'days').endOf('day').utc().toISOString();
    this.firmwareEvents = null;
    this.getFirmwareData();
  }

  changeView(event: any): void {
    this.dayView = event;
  }

  selectEvent(event: any): void {
    this.eventDetails = event.eventData;
  }
}
