import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropDownComponent implements OnInit, OnDestroy {
  @Input() closeOnClickValue = true;
  @Input() closeOnClickOutside = true;
  @Input() disabled = false;
  @Input() forceState: 'hover' | 'active' | '' = '';
  @Input() headerTemplate: TemplateRef<unknown>;
  @Input() listWidth: null | number | 'asHeader' = 'asHeader';
  @Input() headerClickToggleOpen = true;
  @Input() listMaxWidth: null | number;
  @Input() listMinWidth: null | number | 'asHeader' = null;
  @Output() opened = new EventEmitter<boolean>();

  @ViewChild('list', { read: ElementRef }) listElm: ElementRef<HTMLElement>;

  openedState = false;
  classes = '';
  scrollStrategy = this.sso.close();

  observer = new MutationObserver(() => {
    this.classes = this.elm.nativeElement.classList.toString();
  });

  constructor(private elm: ElementRef<HTMLElement>, private sso: ScrollStrategyOptions) {
    this.observer.observe(elm.nativeElement, {
      // detect class change
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false
    });
    this.classes = this.elm.nativeElement.classList.toString();
  }

  ngOnInit(): void {}

  toggleOpen(): void {
    this.setOpenState(!this.openedState);
  }

  listClicked(event: MouseEvent): void {
    let element = event.target as Element;

    if (!this.closeOnClickValue || window.getComputedStyle(element).cursor !== 'pointer') {
      // click place without pointer should not close
      return;
    }

    while (element) {
      // if some parent contains no-close class don't close
      if (element.classList.contains('no-close')) {
        return;
      }
      element = element.parentElement;
    }
    this.setOpenState(false);
  }

  @HostListener('window:click', ['$event.target'])
  onWindowClick(target: Element): void {
    if (
      this.closeOnClickOutside &&
      this.openedState &&
      !this.elm.nativeElement.contains(target) &&
      !this.listElm.nativeElement.contains(target)
    ) {
      this.setOpenState(false);
    }
  }

  setOpenState(state: boolean): void {
    this.openedState = state;
    this.opened.emit(this.openedState);
  }

  overlayDetached(): void {
    if (this.openedState) {
      this.setOpenState(false);
    }
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
