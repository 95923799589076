import { createSelector } from '@ngrx/store';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectCapabilities } from '../customer/capabilities.selector';

export const selectBandSteeringEditable = createSelector(
  selectCapabilities,
  (capabilities: ReturnType<typeof selectCapabilities>, props: { plume: PlumeService }) =>
    capabilities?.bandSteering?.capable &&
    (props.plume.isSomeAdminRole() ||
      (props.plume.cloudVersionAbove1_98() &&
        (props.plume.isStrictGroupSupportRole() || props.plume.isStrictSupportRole())))
);
