<div class="row">
  <div class="col-xs-12">
    <collapsiblebox [show]="device.expand" [collapsible]="true" (toggle)="toggleDevice()">
      <div header>
        <div class="column left">
          <div class="name">
            <div class="circle">
              <div class="icon-container">
                <icon [path]="iconURL" class="device-icon"></icon>
              </div>
              <icon
                name="icon-snowflake"
                class="size-0_8 appendix frozen"
                tooltip="{{ 'devices.device.frozen' | translate }}"
                *ngIf="device.freeze?.frozen"
              ></icon>

              <icon
                name="icon-eye"
                class="size-0_8 appendix eye"
                tooltip="{{ 'devices.device.focused' | translate }}"
                *ngIf="device.focused && !device.freeze?.frozen"
              ></icon>

              <icon
                name="icon-block-device"
                class="size-0_8 appendix blocked-device"
                tooltip="{{ 'devices.device.blocked' | translate }}"
                *ngIf="location?.profile === 'smallbusiness' && device.networkAccess?.mode === 'blocked'"
              ></icon>
              <icon
                name="icon-approve"
                class="size-0_8 appendix approve-device"
                tooltip="{{ 'devices.device.approved' | translate }}"
                *ngIf="location?.profile === 'smallbusiness' && device.networkAccess?.mode === 'approved'"
              ></icon>
              <icon
                name="icon-lock"
                class="size-0_8 appendix unauthorized-device"
                tooltip="{{ 'devices.device.notAuthorized' | translate }}"
                *ngIf="
                  location?.profile !== 'auto' && smallbusinessFilter === 'captivePortal' && device.authorized === false
                "
              ></icon>
              <icon
                name="icon-iot"
                class="size-0_8 appendix iot"
                tooltip="{{ 'devices.device.iot' | translate }}"
                *ngIf="device.networkId === 'iot'"
              ></icon>
            </div>
            <div class="data">
              <div class="nickname" [class.hide]="device.isRename">
                {{ device.nickname || device.name | macAddr: true }}
                <div class="status">
                  <div class="link" *ngIf="!hidePersonalDetails">
                    <icon
                      name="icon-copy-link"
                      tooltip="{{ 'devices.device.copyLink' | translate }}"
                      (click)="copyLink($event)"
                    ></icon>
                  </div>
                  <drop-down-icon
                    class="medium skip-toggle"
                    tooltip="{{ 'devices.device.options' | translate }}"
                    *ngIf="permissions?.uiFeatures.resetDevice || permissions?.uiFeatures.friendlyDeviceName"
                  >
                    <li
                      *ngIf="
                        device.connectionState == 'connected' &&
                        device.medium != 'ethernet' &&
                        permissions?.uiFeatures.resetDevice &&
                        ui != 'noc'
                      "
                      (click)="resetDevice()"
                    >
                      {{ 'devices.device.reset' | translate }}
                    </li>
                    <li
                      *ngIf="permissions?.uiFeatures.friendlyDeviceName && !isFlexRole"
                      (click)="
                        name.setValue(macAddrPipe.transform(device.nickname || device.name, true)); launchRename()
                      "
                    >
                      {{ 'devices.device.rename' | translate }}
                    </li>
                    <li
                      *ngIf="
                        permissions?.qoeAccess &&
                        (device.rawQoe | keyvalue)?.length > 0 &&
                        device.medium != 'ethernet' &&
                        device.connectionState == 'connected'
                      "
                      (click)="toggleQoeModal(true)"
                    >
                      {{ 'devices.device.qoe' | translate }}
                    </li>
                    <li
                      *ngIf="
                        ui == 'noc' &&
                        !isFlexRole &&
                        device.medium != 'ethernet' &&
                        device.connectionState == 'connected'
                      "
                      (click)="toggleSteerModal(true)"
                    >
                      {{ 'devices.device.steer.optionButton' | translate }}
                    </li>
                    <li
                      (click)="forceSteer()"
                      *ngIf="
                        forceSteerEnabled && !device.steering?.forced && device.medium !== 'ethernet' && !isFlexRole
                      "
                    >
                      {{ 'devices.device.steer.force' | translate }}
                    </li>
                    <li (click)="cancelForceSteer()" *ngIf="forceSteerEnabled && device.steering?.forced">
                      {{ 'devices.device.steer.cancelForce' | translate }}
                    </li>
                    <li
                      *ngIf="(capabilities$ | async)?.macPrioritization?.capable && !isFlexRole"
                      (click)="togglePriorityModal(true)"
                    >
                      {{ 'devices.device.prioritize.optionButton' | translate }}
                    </li>
                    <li
                      *ngIf="
                        device?.networkAccess?.mode === 'auto' &&
                        location?.profile === 'smallbusiness' &&
                        smallbusinessFilter !== 'captivePortal'
                      "
                      (click)="blockDevice(device.mac)"
                    >
                      {{ 'devices.device.block' | translate }}
                    </li>
                    <li
                      *ngIf="device?.networkAccess?.mode === 'blocked' && location?.profile === 'smallbusiness'"
                      (click)="unBlockDevice(device.mac)"
                    >
                      {{ 'devices.device.unblock' | translate }}
                    </li>
                    <li
                      *ngIf="
                        device?.networkAccess?.mode === 'auto' &&
                        location?.profile === 'smallbusiness' &&
                        smallbusinessFilter !== 'captivePortal'
                      "
                      (click)="approveDevice(device)"
                    >
                      {{ 'devices.device.approve' | translate }}
                    </li>
                    <li
                      *ngIf="device?.networkAccess?.mode === 'approved' && location?.profile === 'smallbusiness'"
                      (click)="unApproveDevice(device)"
                    >
                      {{ 'devices.device.unapprove' | translate }}
                    </li>
                    <li
                      *ngIf="ui == 'noc' && !isFlexRole && !location?.isUtilizingFocuses"
                      (click)="toggleFreezeModal(true)"
                    >
                      {{ 'devices.device.freeze' | translate }}
                    </li>
                    <li
                      *ngIf="
                        plume.isSomeAdminRole() &&
                        location?.profile !== 'auto' &&
                        smallbusinessFilter === 'captivePortal' &&
                        !device.authorized
                      "
                      (click)="authorizeGuestDevice(true)"
                    >
                      {{ 'devices.device.authorize' | translate }}
                    </li>
                    <li
                      *ngIf="
                        plume.isSomeAdminRole() &&
                        location?.profile !== 'auto' &&
                        smallbusinessFilter === 'captivePortal' &&
                        device.authorized
                      "
                      (click)="authorizeGuestDevice(false)"
                    >
                      {{ 'devices.device.unauthorize' | translate }}
                    </li>
                    <li
                      *ngIf="device?.connectionState === 'disconnected' && plume.isSomeAdminRole()"
                      (click)="deleteDevice(device)"
                    >
                      {{ 'devices.device.delete' | translate }}
                    </li>
                  </drop-down-icon>
                </div>
              </div>
              <div class="nickname skip-toggle" [class.hide]="!device.isRename">
                <div class="input">
                  <input
                    #input
                    class="name-input"
                    (keyup.enter)="name.value?.trim() && confirmRename()"
                    [formControl]="name"
                    type="text"
                    placeholder="{{ 'devices.device.newName' | translate }}"
                  />
                </div>
                <div class="data controls">
                  <icon
                    name="icon-check"
                    class="confirm"
                    tooltip="{{ 'techdash.nodeStrip.tooltip.save' | translate }}"
                    (click)="confirmRename()"
                    *ngIf="name.value?.trim()"
                  ></icon>
                  <icon
                    name="icon-close"
                    class="size-0_8 cancel"
                    tooltip="{{ 'techdash.nodeStrip.tooltip.cancel' | translate }}"
                    (click)="cancelRename()"
                  ></icon>
                </div>
              </div>
              <div class="name-input-underline" [class.show]="device.isRename"></div>
              <div class="sn">{{ device.mac | macAddr }}</div>
              <div class="details">
                <div>
                  <div class="alarms">
                    <span class="txt">{{ 'devices.device.alarms' | translate }}:</span>
                    <span class="value">
                      <span class="num" *ngIf="!qoeAlarm && !device.coverageAlarm">
                        {{ 'devices.device.alarmsNone' | translate }}
                      </span>
                      <span class="num" *ngIf="qoeAlarm">
                        {{ 'devices.device.alarmsHealthAlert' | translate }}
                        <span class="spacer" *ngIf="device.coverageAlarm">,</span>
                      </span>
                      <span class="num" *ngIf="device.coverageAlarm">
                        {{ 'devices.device.alarmsCoverage' | translate }}
                      </span>
                    </span>
                  </div>
                  <div class="health">
                    <span class="txt">{{ 'devices.device.health' | translate }}:</span>
                    <health-line [value]="device.health?.score" [status]="device.health?.status"></health-line>
                    <span class="description" [ngClass]="'t-' + device.health?.status" *ngIf="device.health?.status">
                      {{ device.health?.status | translate }}
                    </span>
                  </div>
                  <div class="quarantined" *ngIf="device.quarantine?.enable">
                    <span class="txt">{{ 'devices.device.quarantined' | translate }}</span>
                  </div>
                </div>
                <div
                  class="circle {{ device.connectionState }}"
                  tooltip="{{ device.connectionStateMsg | translate: { datetime: device?.connectionStateTime } }}"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="column right">
          <div class="info">
            <div class="details">
              <div class="alarms">
                <span class="txt">{{ 'devices.device.alarms' | translate }}:</span>
                <span class="value">
                  <span class="num" *ngIf="!qoeAlarm && !device.coverageAlarm">
                    {{ 'devices.device.alarmsNone' | translate }}
                  </span>
                  <span class="num" *ngIf="qoeAlarm">
                    {{ 'devices.device.alarmsHealthAlert' | translate }}
                    <span class="spacer" *ngIf="device.coverageAlarm">,</span>
                  </span>
                  <span class="num" *ngIf="device.coverageAlarm">
                    {{ 'devices.device.alarmsCoverage' | translate }}
                  </span>
                </span>
              </div>
              <div class="health" *ngIf="device.health?.status">
                <span class="txt">{{ 'devices.device.health' | translate }}:</span>
                <health-line [value]="device.health?.score" [status]="device.health?.status"></health-line>
                <span class="description" [ngClass]="'t-' + device.health?.status" *ngIf="device.health?.status">
                  {{ device.health?.status | translate }}
                </span>
              </div>

              <div class="quarantined" *ngIf="device.quarantine?.enable">
                <span class="txt">{{ 'devices.device.quarantined' | translate }}</span>
              </div>
            </div>
            <div class="status">
              <div class="link" *ngIf="!hidePersonalDetails">
                <icon
                  name="icon-copy-link"
                  tooltip="{{ 'devices.device.copyLink' | translate }}"
                  (click)="copyLink($event)"
                ></icon>
              </div>
              <drop-down-icon
                class="medium skip-toggle"
                tooltip="{{ 'devices.device.options' | translate }}"
                *ngIf="permissions?.uiFeatures.resetDevice || permissions?.uiFeatures.friendlyDeviceName"
              >
                <li
                  *ngIf="
                    device.connectionState == 'connected' &&
                    device.medium != 'ethernet' &&
                    permissions?.uiFeatures.resetDevice &&
                    ui != 'noc'
                  "
                  (click)="resetDevice()"
                >
                  {{ 'devices.device.reset' | translate }}
                </li>
                <li
                  *ngIf="permissions?.uiFeatures.friendlyDeviceName && !isFlexRole"
                  (click)="name.setValue(macAddrPipe.transform(device.nickname || device.name, true)); launchRename()"
                >
                  {{ 'devices.device.rename' | translate }}
                </li>
                <li
                  *ngIf="
                    permissions?.qoeAccess &&
                    (device.rawQoe | keyvalue)?.length > 0 &&
                    device.medium != 'ethernet' &&
                    device.connectionState == 'connected'
                  "
                  (click)="toggleQoeModal(true)"
                >
                  {{ 'devices.device.qoe' | translate }}
                </li>
                <li
                  *ngIf="
                    ui == 'noc' && !isFlexRole && device.medium != 'ethernet' && device.connectionState == 'connected'
                  "
                  (click)="toggleSteerModal(true)"
                >
                  {{ 'devices.device.steer.optionButton' | translate }}
                </li>
                <li
                  (click)="forceSteer()"
                  *ngIf="forceSteerEnabled && !device.steering?.forced && device.medium !== 'ethernet' && !isFlexRole"
                >
                  {{ 'devices.device.steer.force' | translate }}
                </li>
                <li (click)="cancelForceSteer()" *ngIf="forceSteerEnabled && device.steering?.forced">
                  {{ 'devices.device.steer.cancelForce' | translate }}
                </li>
                <li
                  *ngIf="(capabilities$ | async)?.macPrioritization?.capable && !isFlexRole"
                  (click)="togglePriorityModal(true)"
                >
                  {{ 'devices.device.prioritize.optionButton' | translate }}
                </li>
                <li
                  *ngIf="
                    device?.networkAccess?.mode === 'auto' &&
                    location?.profile === 'smallbusiness' &&
                    smallbusinessFilter !== 'captivePortal' &&
                    plume.isSomeAdminRole()
                  "
                  (click)="blockDevice(device.mac)"
                >
                  {{ 'devices.device.block' | translate }}
                </li>
                <li
                  *ngIf="
                    device?.networkAccess?.mode === 'blocked' &&
                    location?.profile === 'smallbusiness' &&
                    plume.isSomeAdminRole()
                  "
                  (click)="unBlockDevice(device.mac)"
                >
                  {{ 'devices.device.unblock' | translate }}
                </li>
                <li
                  *ngIf="
                    device?.networkAccess?.mode === 'auto' &&
                    location?.profile === 'smallbusiness' &&
                    smallbusinessFilter !== 'captivePortal' &&
                    plume.isSomeAdminRole()
                  "
                  (click)="approveDevice(device)"
                >
                  {{ 'devices.device.approve' | translate }}
                </li>
                <li
                  *ngIf="
                    device?.networkAccess?.mode === 'approved' &&
                    location?.profile === 'smallbusiness' &&
                    plume.isSomeAdminRole()
                  "
                  (click)="unApproveDevice(device)"
                >
                  {{ 'devices.device.unapprove' | translate }}
                </li>
                <li
                  *ngIf="ui == 'noc' && smallbusinessFilter !== 'captivePortal' && !isFlexRole && !isUtilizingFocus"
                  (click)="toggleFreezeModal(true)"
                >
                  {{ 'devices.device.freeze' | translate }}
                </li>
                <li *ngIf="ui == 'noc' && smallbusinessFilter !== 'captivePortal' && isUtilizingFocus" (click)="toggleFocusModal(true)">
                  {{ 'devices.device.focus' | translate }}
                </li>
                <li
                  *ngIf="
                    plume.isSomeAdminRole() &&
                    location?.profile !== 'auto' &&
                    smallbusinessFilter === 'captivePortal' &&
                    !device.authorized
                  "
                  (click)="authorizeGuestDevice(true)"
                >
                  {{ 'devices.device.authorize' | translate }}
                </li>
                <li
                  *ngIf="
                    plume.isSomeAdminRole() &&
                    location?.profile !== 'auto' &&
                    smallbusinessFilter === 'captivePortal' &&
                    device.authorized
                  "
                  (click)="authorizeGuestDevice(false)"
                >
                  {{ 'devices.device.unauthorize' | translate }}
                </li>
                <li
                  *ngIf="device?.connectionState === 'disconnected' && plume.isSomeAdminRole()"
                  (click)="deleteDevice(device)"
                >
                  {{ 'devices.device.delete' | translate }}
                </li>
              </drop-down-icon>
              <div
                class="circle {{ device.connectionState }}"
                tooltip="{{ device.connectionStateMsg | translate: { datetime: device?.connectionStateTime } }}"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div container *ngIf="device.expand">
        <div class="data">
          <div class="data-table column desktop">
            <div class="data-row info" *ngIf="device.kind?.category">
              <div class="data-cell key">{{ 'devices.device.category' | translate }}</div>
              <div class="data-cell value">{{ device.customType?.category || device.kind?.category }}</div>
            </div>
            <div class="data-row info" *ngIf="device.kind?.brand">
              <div class="data-cell key">{{ 'devices.device.brand' | translate }}</div>
              <div class="data-cell value">{{ device.customType?.brand || device.kind?.brand }}</div>
            </div>
            <div class="data-row info" *ngIf="device.model">
              <div class="data-cell key">{{ 'devices.device.model' | translate }}</div>
              <div class="data-cell value">
                <div class="group">
                  <span>{{ device.model }}</span>
                  <icon
                    *ngIf="device.customType"
                    name="icon-info"
                    class="info"
                    [tooltip]="'devices.device.customTypeSet' | translate"
                  ></icon>
                </div>
              </div>
            </div>
            <div class="data-row info" *ngIf="device.deviceType">
              <div class="data-cell key">{{ 'devices.device.deviceType' | translate }}</div>
              <div class="data-cell value">
                {{ device.deviceType }}
                <div
                  class="details-button"
                  *ngIf="device.deviceDetails && plume.isStrictAdminRole() && ui === 'noc' && !hidePersonalDetails"
                >
                  <button class="action small" (click)="toggleDeviceDetailsModal(true)">
                    {{ 'devices.device.detailsButton' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div class="data-row info" *ngIf="device.kind?.osName">
              <div class="data-cell key">{{ 'devices.device.osVersion' | translate }}</div>
              <div class="data-cell value">{{ device.kind?.osName }} {{ device.kind?.osVersion }}</div>
            </div>
            <div class="data-row info" *ngIf="device.bandwidth?.daily?.rank">
              <div class="data-cell key">{{ 'devices.device.bandwidthRank' | translate }}</div>
              <div class="data-cell value">{{ device.bandwidth.daily.rank }}</div>
            </div>
            <div class="data-row info list" *ngIf="device.capabilityString.length">
              <div class="data-cell key">{{ 'devices.device.capabilities' | translate }}</div>
              <div class="data-cell value">
                <div *ngFor="let capability of device.capabilityString">{{ capability }}</div>
              </div>
            </div>
            <div class="data-row info" *ngIf="device.connectionState == 'connected'">
              <div class="data-cell key">{{ 'devices.device.ip' | translate }}</div>
              <div class="data-cell value">
                <span
                  *ngIf="device.dhcpLease?.leaseTime && device.dhcpLease?.leaseTime >= 1"
                  class="tooltipHover"
                  tooltip="{{ 'devices.device.DHCPLease' | translate }} : {{
                    'devices.device.DHCPTime' | translate: getHMS(device.dhcpLease.leaseTime)
                  }}"
                >
                  {{ device?.ip }}
                </span>
                <span *ngIf="!device.dhcpLease?.leaseTime || device.dhcpLease?.leaseTime < 1">
                  {{ device?.ip }}
                </span>
              </div>
            </div>
            <div class="data-row info list" *ngIf="device?.ipv6?.length && device.connectionState == 'connected'">
              <div class="data-cell key">
                {{ 'devices.device.ipv6' | translate }}
                <icon class="icon-modal" name="icon-info" class="size-0_8" (click)="toggleIPV6Modal(true)"></icon>
              </div>
              <div class="data-cell value truncate" *ngIf="!device.dhcpV6Lease">
                <div class="data-trunc" *ngFor="let ip of device?.ipv6">
                  {{ ip | ipv6 }}
                </div>
              </div>
              <div
                class="data-cell value truncate tooltipHover"
                *ngIf="device.dhcpV6Lease && device.dhcpV6Lease?.leaseTime >= 1"
                tooltip="{{ 'devices.device.DHCPV6Lease' | translate }} :  {{
                  'devices.device.DHCPTime' | translate: getHMS(device.dhcpV6Lease.leaseTime)
                }}"
              >
                <div class="data-trunc" *ngFor="let ip of device?.ipv6">
                  {{ ip | ipv6 }}
                </div>
              </div>
            </div>
            <div class="data-row info">
              <div class="data-cell key">{{ 'devices.device.bandSteering' | translate }}</div>
              <div class="data-cell value">
                <div *ngIf="location?.bandSteering?.enable">
                  {{ device?.bandSteering?.enable ? ('enabled' | translate) : ('disabled' | translate) }}
                </div>
                <div class="additionalInfo" *ngIf="!location?.bandSteering?.enable">
                  {{ 'devices.device.disabledAtLocation' | translate }}
                </div>
              </div>
            </div>
            <div class="data-row info">
              <div class="data-cell key">{{ 'devices.device.clientSteering' | translate }}</div>
              <div class="data-cell value">
                <div #clientSteering class="clientSteering" *ngIf="location?.clientSteering?.enable">
                  <span>{{ device?.clientSteering?.enable ? ('enabled' | translate) : ('disabled' | translate) }}</span>
                  <slidetoggler
                    *ngIf="location?.clientSteering?.enable"
                    [options]="clientSteeringItems"
                    [editable]="selectBandSteeringEditable$ | async"
                    (toggle)="setClientSteering($event)"
                  >
                  </slidetoggler>
                  <div class="backoff" *ngIf="device?.clientSteering?.disableReasons">
                    {{ 'devices.device.backoff' | translate }}
                    <icon name="icon-info" tooltip="{{ device?.clientSteering?.disableReasons }}"></icon>
                  </div>
                  <button
                    *ngIf="plume.cloudVersionAbove1_105()"
                    class="action small tosButton"
                    (click)="showTosModal = true"
                  >
                    {{ 'devices.device.detailsButton' | translate }}
                  </button>
                </div>
                <div class="additionalInfo" *ngIf="!location?.clientSteering?.enable">
                  {{ 'devices.device.disabledAtLocation' | translate }}
                </div>
              </div>
            </div>
            <div class="data-row info" *ngIf="(capabilities$ | async)?.appTime?.capable && !isFlexRole">
              <div class="data-cell key">{{ 'devices.device.appTime' | translate }}</div>
              <div class="data-cell value">
                <slidetoggler
                  [options]="appTimeItems"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="setAppTime($event)"
                ></slidetoggler>
              </div>
            </div>
            <div class="data-row info">
              <div class="data-cell key">{{ 'devices.device.coverageAlarm' | translate }}</div>
              <div class="data-cell value">
                <ng-container *ngIf="coverageLoading">
                  <span>{{ 'devices.device.loading' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="!coverageLoading">
                  <span *ngIf="!device.coverageAlarm">{{ 'devices.device.alarmsNone' | translate }}</span>
                  <span *ngIf="device.coverageAlarm && !coverage15minAlarm">
                    {{ 'devices.device.alarms24hrs' | translate }}
                  </span>
                  <span *ngIf="device.coverageAlarm && coverage15minAlarm">
                    {{ 'devices.device.alarms15mins' | translate }}
                  </span>
                </ng-container>
              </div>
            </div>
            <div class="data-row info" *ngIf="(capabilities$ | async)?.mdns?.capable && !isFlexRole">
              <div class="data-cell key">{{ 'devices.device.outOfHomeProtection' | translate }}</div>
              <div class="data-cell value">
                <ng-container *ngIf="device?.ohp?.capable">
                  {{ 'devices.device.capable' | translate }}
                  <ng-container *ngIf="device?.ohp?.protectionState?.state || device?.ohp?.protectionState?.type">
                    /
                    {{
                      device.ohp.protectionState.type
                        ? device.ohp.protectionState.type
                        : device.ohp.protectionState.state
                    }}
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!device?.ohp?.capable">
                  {{ 'devices.device.notCapable' | translate }}
                </ng-container>
              </div>
            </div>
            <div
              class="data-row info"
              *ngIf="plume.cloudVersionAbove1_97() && !plume.isUpriseProperyManager() && !isFlexRole"
            >
              <div class="data-cell key">{{ 'devices.device.macStitching' | translate }}</div>
              <div class="data-cell value">
                <ng-container *ngIf="!macStitchingList.length">
                  <span>{{ 'devices.device.notInUse' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="macStitchingList.length">
                  <span>
                    {{ 'devices.device.inUse' | translate }}
                  </span>
                  <div class="details-button" *ngIf="plume.isStrictAdminRole()">
                    <button class="action small" (click)="showMacStitchingModal = true">
                      {{ 'devices.device.history' | translate }}
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="data-table column">
            <div class="data-row info mobile" *ngIf="device.kind?.category">
              <div class="data-cell key">{{ 'devices.device.category' | translate }}</div>
              <div class="data-cell value">{{ device.kind?.category }}</div>
            </div>
            <div class="data-row info mobile" *ngIf="device.kind?.brand">
              <div class="data-cell key">{{ 'devices.device.brand' | translate }}</div>
              <div class="data-cell value">{{ device.kind?.brand }}</div>
            </div>
            <div class="data-row info mobile" *ngIf="device.model">
              <div class="data-cell key">{{ 'devices.device.model' | translate }}</div>
              <div class="data-cell value">{{ device.model }}</div>
            </div>
            <div class="data-row info mobile" *ngIf="device.deviceType">
              <div class="data-cell key">{{ 'devices.device.deviceType' | translate }}</div>
              <div class="data-cell value">
                {{ device.deviceType }}
                <div class="details-button" *ngIf="device.deviceDetails && plume.isStrictAdminRole()">
                  <button class="action small" (click)="toggleDeviceDetailsModal(true)">
                    {{ 'devices.device.detailsButton' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div class="data-row info mobile" *ngIf="device.kind?.osName">
              <div class="data-cell key">{{ 'devices.device.osVersion' | translate }}</div>
              <div class="data-cell value">{{ device.kind?.osName }} {{ device.kind?.osVersion }}</div>
            </div>
            <div class="data-row info mobile" *ngIf="device.bandwidth?.daily?.rank">
              <div class="data-cell key">{{ 'devices.device.bandwidthRank' | translate }}</div>
              <div class="data-cell value">{{ device.bandwidth.daily.rank }}</div>
            </div>
            <div class="data-row info mobile" *ngIf="device.capabilities.length">
              <div class="data-cell key">{{ 'devices.device.capabilities' | translate }}</div>
              <div class="data-cell value">
                <div *ngFor="let capability of device.capabilities">{{ capability }}</div>
              </div>
            </div>
            <div class="data-row info" *ngIf="device.rssi || device.health?.details?.channelGain">
              <div class="data-cell key">{{ 'devices.device.rssi' | translate }}</div>
              <div class="data-cell value">
                <div class="group" *ngIf="device.rssi || device.health?.details?.channelGain">
                  <health-dots [rssiOrQoe]="device"></health-dots>
                  <div class="num">
                    {{ device.rssi || device.health?.details?.channelGain + 20 | number: '1.0-0' }} dBm
                  </div>
                </div>
              </div>
            </div>
            <div class="data-row info" *ngIf="device.health?.details?.interference">
              <div class="data-cell key">{{ 'devices.device.interface' | translate }}</div>
              <div class="data-cell value">
                <div class="group">
                  <health-ball [value]="device.health?.details?.interference"></health-ball>
                  <div class="num" *ngIf="device.health?.details?.interference">
                    {{ device.health?.details?.interference | number: '1.0-2' }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="data-row info" *ngIf="device.speed">
              <div class="data-cell key">{{ 'devices.device.predictedSpeed' | translate }}</div>
              <div class="data-cell value">{{ 'devices.device.noData' | translate }}</div>
            </div>
            <div class="data-row info" *ngIf="device.medium == 'ethernet'">
              <div class="data-cell key">
                {{
                  device.connectionState == 'connected'
                    ? ('devices.device.current' | translate)
                    : ('devices.device.last' | translate)
                }}
                {{ 'devices.device.connection' | translate }}
              </div>
              <div class="data-cell value">{{ 'devices.device.ethernet' | translate }}</div>
            </div>
            <div class="data-row info" *ngIf="device.medium == 'wifi' && device.freqBand && device.channel">
              <div class="data-cell key">
                {{
                  device.connectionState == 'connected'
                    ? ('devices.device.current' | translate)
                    : ('devices.device.last' | translate)
                }}
                {{ 'devices.device.connection' | translate }}
              </div>
              <div class="data-cell value wpaMode">
                <span>{{ device.freqBand }}, Ch {{ device.channel }}</span>
                <span *ngIf="device.wpaMode?.length && !isFlexRole">
                  , {{ 'health.networkInformation.wpaModes.' + device.wpaMode | translate }}
                </span>
                <icon
                  *ngIf="device.ssid && device.ssid.length && !isFlexRole"
                  name="icon-info"
                  tooltip="{{ 'devices.device.ssid' | translate }}: {{ device.ssid }}"
                ></icon>
              </div>
            </div>
            <div class="data-row info" *ngIf="device.connectedTo">
              <div class="data-cell key">{{ 'devices.device.connectedTo' | translate }}</div>
              <div class="data-cell value">
                <div class="connectedTo" (click)="gotoNode(device.connectedTo.id)">
                  {{ device.connectedTo.nickname | macAddr: true }}
                  <icon name="icon-external" class="size-0_8"></icon>
                </div>
              </div>
            </div>
            <div class="data-row info">
              <div class="data-cell key">{{ 'devices.device.connection' | translate }}</div>
              <div class="data-cell value no-connection" *ngIf="device.connectionState === 'disconnected'">
                {{ device.connectionStateMsg | translate: { datetime: device?.connectionStateTime } }} 
              </div>
              <div class="data-cell value"> 
                <div class="leaf-to-root" *ngIf="device.connectionState !== 'disconnected'">
                  <div class="root-name">
                    <div class="icon-container">
                      <icon [path]="iconURL" class="device-icon"></icon>
                    </div>
                    <span class="device-name">{{ device.nickname || device.name | macAddr: true }}</span>
                  </div>
                  <leaftoroot [device]="device" [nodes]="nodes" [alarm]=""></leaftoroot>
                </div>
              </div>
            </div>
            
            <div
              class="data-row info"
              *ngIf="device.accessZone?.type === 'internetAccessOnly' || device.accessZone?.type === 'guests'"
            >
              <div class="data-cell key">{{ 'devices.device.accessZoneId' | translate }}</div>
              <div class="data-cell value">
                {{ device.accessZone?.type | translate }}, {{ device.accessZone?.description }},
                {{ device?.keyId }}
              </div>
            </div>
          </div>
        </div>
        <collapsiblebox
          mode="bare"
          [showDivider]="false"
          [show]="device.deviceHistory"
          [collapsible]="true"
          (toggle)="toggleHistory()"
        >
          <ng-container header>
            <div class="title">{{ 'devices.device.viewHistory' | translate }}</div>
            <slidetoggler
              *ngIf="device.deviceHistory && ui === 'noc'"
              [options]="rssiModeItems"
              (toggle)="setRssiMode($event)"
            ></slidetoggler>
            <slidetoggler *ngIf="device.deviceHistory" [options]="chartModes" (toggle)="setMode($event)"></slidetoggler>
          </ng-container>
        </collapsiblebox>
        <ng-container *ngIf="device.deviceHistory">
          <div class="device-history">
            <div class="details" [class.show]="device.deviceHistory">
              <div class="graph rssi24" *ngIf="ui === 'central'">
                <div class="caption">{{ 'devices.device.historyRssi' | translate }}</div>
                <div class="history">
                  <ng-container
                    *ngIf="
                      this.device.connectionState !== 'connected' || this.device.medium !== 'ethernet';
                      else deviceConnToEth
                    "
                  >
                    <line-tick-chart
                      *ngIf="device.history"
                      [data]="{ ticks: device.history, axis: axis }"
                      [hasOfflines]="true"
                      translatePrefix="devices.device."
                    ></line-tick-chart>

                    <ng-container *ngIf="!device.history">{{ 'devices.device.loading' | translate }}</ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="graph busyness" *ngIf="ui === 'central'">
                <div class="caption">{{ 'devices.device.historyBusyness' | translate }}</div>
                <div class="history" *ngIf="device.busyness">
                  <line-tick-chart
                    [data]="{ ticks: device.busyness, axis: axis }"
                    [stateTemplate]="historyBusynessTemplate"
                  >
                    <ng-template #historyBusynessTemplate let-tick>
                      <div class="line-tick-chart-info">
                        {{ tick.bw }}
                      </div>
                    </ng-template>
                  </line-tick-chart>
                </div>
                <div class="history" *ngIf="!device.busyness">{{ 'devices.device.loading' | translate }}</div>
              </div>
              <div class="graph rssiGraph" *ngIf="ui === 'noc'">
                <div
                  class="caption"
                  [class.graphDisabled]="
                    this.device.connectionState === 'connected' && this.device.medium === 'ethernet'
                  "
                >
                  {{ 'devices.device.rssiHistory' | translate }}
                </div>
                <div class="history" *ngIf="device.deviceHistory">
                  <ng-container
                    *ngIf="
                      this.device.connectionState !== 'connected' || this.device.medium !== 'ethernet';
                      else deviceConnToEth
                    "
                  >
                    <div class="chart" *ngIf="rssiMode === 'dynamic'">
                      <nodechart
                        [series]="rssi.dynamic.series"
                        [data]="rssi.dynamic.graph"
                        [scale]="{ range: [-100, rssi.dynamic.maxValue], symbol: 'dBm' }"
                        [period]="chartMode === '24h' ? 1 : 7"
                        [isFilled]="false"
                      ></nodechart>
                    </div>
                    <div class="chart" *ngIf="rssiMode === 'static'">
                      <nodechart
                        [series]="rssi.static.series"
                        [data]="rssi.static.graph"
                        [scale]="{ range: [-100, rssi.static.maxValue], symbol: 'dBm' }"
                        [period]="chartMode === '24h' ? 1 : 7"
                        [isFilled]="false"
                      ></nodechart>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="graph dataConsumptionGraph" *ngIf="ui === 'noc'">
                <div class="caption">{{ 'devices.device.dataConsumption' | translate }}</div>
                <div class="history" *ngIf="device.deviceHistory">
                  <div class="stats">
                    <span class="total-stats" *ngIf="consumptionStats">
                      <span class="label upload">{{ 'devices.device.chartData.upload' | translate }}</span>
                      {{ consumptionStats.upload }} {{ consumptionStats.uploadUnit }}
                      <span class="label download">{{ 'devices.device.chartData.download' | translate }}</span>
                      {{ consumptionStats.download }} {{ consumptionStats.downloadUnit }}
                      <span class="label total"> {{ 'devices.device.chartData.total' | translate }}</span>
                      {{ consumptionStats.total }} {{ consumptionStats.totalUnit }}
                    </span>
                  </div>
                  <div class="chart">
                    <nodechart
                      [series]="dataConsumption.series"
                      [data]="dataConsumption.graph"
                      [scale]="{ range: [0, dataConsumption.maxValue], symbol: 'MB' }"
                      [period]="chartMode === '24h' ? 1 : 7"
                      [isFilled]="true"
                    ></nodechart>
                  </div>
                </div>
              </div>
              <div class="graph deviceOnline">
                <div class="caption">{{ 'devices.device.historyOnline' | translate }}</div>
                <div class="history">
                  <ng-container
                    *ngIf="
                      this.device.connectionState !== 'connected' || this.device.medium !== 'ethernet';
                      else deviceConnToEth
                    "
                  >
                    <line-tick-chart
                      *ngIf="device.nodeConnections"
                      [data]="{ ticks: device.nodeConnections, axis: axis }"
                      [hasOfflines]="true"
                    ></line-tick-chart>
                    <ng-container *ngIf="!device.nodeConnections">{{
                      'devices.device.loading' | translate
                    }}</ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="graph steering">
                <div class="caption">{{ 'devices.device.historyBandSteering' | translate }}</div>
                <div class="history">
                  <ng-container
                    *ngIf="
                      this.device.connectionState !== 'connected' || this.device.medium !== 'ethernet';
                      else deviceConnToEth
                    "
                  >
                    <line-tick-chart
                      *ngIf="device.bandsteering"
                      [data]="{ ticks: device.bandsteering, axis: axis }"
                      [stateTemplate]="bandSteeringTickTemplate"
                    >
                      <ng-template #bandSteeringTickTemplate let-tick>
                        <div class="line-tick-chart-info" *ngIf="tick.attempted > 0">
                          {{ 'devices.device.succeeded' | translate }}: {{ tick.succeeded }}
                          {{ 'devices.device.failed' | translate }}: {{ tick.failed }}
                          {{ 'devices.device.troubled' | translate }}: {{ tick.troubled }}
                        </div>
                      </ng-template>
                    </line-tick-chart>
                    <ng-container *ngIf="!device.bandsteering">{{ 'devices.device.loading' | translate }}</ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="graph steering">
                <div class="caption">{{ 'devices.device.historyClientSteering' | translate }}</div>
                <div class="history">
                  <ng-container
                    *ngIf="
                      this.device.connectionState !== 'connected' || this.device.medium !== 'ethernet';
                      else deviceConnToEth
                    "
                  >
                    <line-tick-chart
                      *ngIf="device.clientsteering"
                      [data]="{ ticks: device.clientsteering, axis: axis }"
                      [stateTemplate]="clientSteeringTickTemplate"
                    >
                      <ng-template #clientSteeringTickTemplate let-tick>
                        <div class="line-tick-chart-info" *ngIf="tick.attempted > 0">
                          {{ 'devices.device.succeeded' | translate }}: {{ tick.succeeded }}
                          {{ 'devices.device.failed' | translate }}: {{ tick.failed }}
                          {{ 'devices.device.troubled' | translate }}: {{ tick.troubled }}
                        </div>
                      </ng-template>
                    </line-tick-chart>
                    <ng-container *ngIf="!device.clientsteering">{{
                      'devices.device.loading' | translate
                    }}</ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="ui === 'noc'">
          <collapsiblebox
            mode="bare"
            [showDivider]="false"
            [show]="device.steeringTimeMachine"
            [collapsible]="true"
            (toggle)="toggleSteeringTimeMachine()"
          >
            <ng-container header>{{ 'devices.device.timeMachine' | translate }}</ng-container>
          </collapsiblebox>
          <ng-container *ngIf="device.steeringTimeMachine">
            <div class="steering-time-machine">
              <div class="details" [class.show]="device.steeringTimeMachine">
                <div class="time inputSection">
                  <div class="inputBox">
                    <div class="label">{{ 'devices.device.startingAt' | translate }}:</div>
                    <div class="input">
                      <calendar startDate="{{ steeringDefaultStartDate }}" (event)="steeringTMStartTime = $event">
                      </calendar>
                    </div>
                  </div>
                  <div class="inputBox">
                    <div class="label">{{ 'devices.device.endingAt' | translate }}:</div>
                    <div class="input">
                      <calendar (event)="steeringTMEndTime = $event"></calendar>
                    </div>
                  </div>
                  <div class="updateButton">
                    <button class="action medium" (click)="getSteeringTimeMachine()">
                      {{ 'devices.device.update' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row loading-indicator" *ngIf="steeringTimeMachineLoading">
                  <icon name="icon-spinner" class="spin"></icon>
                </div>
                <div class="row no-results" *ngIf="steerings && !steeringTimeMachineLoading && !steerings.length">
                  {{ (endAfterStart ? 'devices.device.endAfterStart' : 'devices.device.noResults') | translate }}
                </div>
                <div class="resultTable">
                  <div class="row time" *ngIf="steerings && !steeringTimeMachineLoading && steerings.length">
                    <div class="headers">
                      <div *ngFor="let steeringHeader of steeringHeaders" class="header-cell">
                        {{ 'devices.device.' + steeringHeader | translate }}
                      </div>
                    </div>
                    <div class="scroll-area">
                      <ng-container *ngFor="let steeringRow of steerings">
                        <div class="steering-row" [class.loading]="loadingSteerings" *ngIf="steerings">
                          <div>{{ steeringRow.timestamp | date: 'LLL' }}</div>
                          <div>{{ steeringRow.trigger.kickReason }}</div>
                          <div>{{ steeringRow.trigger.sourceNodeId }}</div>
                          <div>{{ steeringRow.trigger.sourceFreqBand }}</div>
                          <div>{{ steeringRow.trigger.currentSNR }}</div>
                          <div>{{ steeringRow.trigger.currentRate }}</div>
                          <div>{{ steeringRow.steerTo[0]?.nodeId }}</div>
                          <div>{{ (steeringRow.steerTo[0]?.freqBands)[0] }}</div>
                          <div>{{ steeringRow.trigger.expectedSNR }}</div>
                          <div>{{ steeringRow.trigger.expectedRate }}</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </collapsiblebox>
  </div>
</div>

<modal mode="compact" (overlayAction)="showQoeModal = $event" *ngIf="showQoeModal">
  <ng-container title>{{ 'qoe.charts.mostRecentMeasurements' | translate }}</ng-container>
  <ng-container content>
    <div class="qoeDetails">
      <div class="list" *ngFor="let qoe of device.rawQoe | keyvalue">
        <div class="key">{{ '' + qoe.key | translate }}</div>
        <div class="value">{{ qoe.key === 'mac' ? ('' + qoe.value | macAddr) : qoe.value }}</div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggleQoeModal(false)">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
    <button class="super-primary" (click)="gotoQoe()">
      {{ 'health.networkInformation.modal.qoeData' | translate }}
    </button>
  </ng-container>
</modal>

<modal class="editFocus" mode="compact" (overlayAction)="showFocusAddModal = $event" *ngIf="showFocusAddModal">
  <ng-container title>{{ 'devices.device.' + focusSchedule.mode + 'Title' | translate }}</ng-container>
  <ng-container content>
    <div class="wrapper">
      <div class="name">
        {{ 'devices.device.name' | translate }}: <input [formControl]="focusSchedule.name" type="text" />
      </div>
      <div class="schedules" *ngFor="let schedule of focusSchedule.schedules; let i = index">
        <div class="time">
          <div>
            {{ 'devices.device.from' | translate }}:
            <input [formControl]="schedule.startTime" type="text" placeholder="08:00" />
          </div>
          <div>
            {{ 'devices.device.to' | translate }}:
            <input [formControl]="schedule.endTime" type="text" placeholder="18:00" />
          </div>
          <icon name="icon-info" tooltip="{{ 'devices.device.timeFormatInfo' | translate }}"></icon>
          <icon
            name="icon-close"
            class="size-0_8"
            tooltip="{{ 'devices.device.remove' | translate }}"
            (click)="removeSchedule(i)"
            *ngIf="focusSchedule.schedules.length > 1"
          ></icon>
        </div>
        <div class="days-title">{{ 'devices.device.selectDays' | translate }}:</div>
        <div class="days">
          <div
            class="day"
            [class.selected]="schedule.selectedDays.indexOf(day) >= 0"
            (click)="toggleFocusedDays(day, i)"
            *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]"
          >
            {{ processDay(day) }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggleFocusAdd(false)">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>

    <button class="super-primary" (click)="addFocusSchedule()" *ngIf="focusSchedule.mode === 'add'" [disabled]="focusAddPending">
      {{ 'health.networkInformation.modal.add' | translate }}
    </button>
    <button class="super-primary" (click)="updateFocusSchedule()" *ngIf="focusSchedule.mode === 'update'">
      {{ 'health.networkInformation.modal.update' | translate }}
    </button>
  </ng-container>
</modal>

<modal class="editFreeze" mode="compact" (overlayAction)="showTemplateModal = $event" *ngIf="showTemplateModal">
  <ng-container title>{{ 'devices.device.' + freezeSchedule.mode + 'Title' | translate }}</ng-container>
  <ng-container content>
    <div class="wrapper">
      <div class="name">
        {{ 'devices.device.name' | translate }}: <input [formControl]="freezeSchedule.name" type="text" />
      </div>
      <div class="schedules" *ngFor="let schedule of freezeSchedule.schedules; let i = index">
        <div class="time">
          <div>
            {{ 'devices.device.from' | translate }}:
            <input [formControl]="schedule.startTime" type="text" placeholder="08:00" />
          </div>
          <div>
            {{ 'devices.device.to' | translate }}:
            <input [formControl]="schedule.endTime" type="text" placeholder="18:00" />
          </div>
          <icon name="icon-info" tooltip="{{ 'devices.device.timeFormatInfo' | translate }}"></icon>
          <icon
            name="icon-close"
            class="size-0_8"
            tooltip="{{ 'devices.device.remove' | translate }}"
            (click)="removeSchedule(i)"
            *ngIf="freezeSchedule.schedules.length > 1"
          ></icon>
        </div>
        <div class="days-title">{{ 'devices.device.selectDays' | translate }}:</div>
        <div class="days">
          <div
            class="day"
            [class.selected]="schedule.selectedDays.indexOf(day) >= 0"
            [class.disabled]="freezeSchedule.type === 'legacy'"
            (click)="toggleSelectedDays(day, i)"
            *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]"
          >
            {{ processDay(day) }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggleFreezeTemplate(false)">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
    <button class="action" (click)="addSchedule()" *ngIf="freezeSchedule.type !== 'legacy'">
      {{ 'health.networkInformation.modal.addSchedule' | translate }}
    </button>
    <button class="super-primary" (click)="addTimeTemplate()" *ngIf="freezeSchedule.mode === 'add'">
      {{ 'health.networkInformation.modal.add' | translate }}
    </button>
    <button class="super-primary" (click)="updateTimeTemplate()" *ngIf="freezeSchedule.mode === 'update'">
      {{ 'health.networkInformation.modal.update' | translate }}
    </button>
  </ng-container>
</modal>

<modal class="freeze" mode="compact" (overlayAction)="showFreezeModal = $event" *ngIf="showFreezeModal">
  <ng-container title>
    {{ device.nickname || device.name | macAddr: true }}
    {{ 'devices.device.freezeTitle' | translate }}
  </ng-container>
  <ng-container content>
    <div class="columns">
      <div class="column">
        <div class="row">
          <div class="key noShrink">{{ 'devices.device.frozen' | translate }}:</div>
          <div class="value noShrink" [ngClass]="device.freeze.frozen ? 't-good' : 't-bad'">
            {{ (device.freeze.frozen ? 'devices.device.yes' : 'devices.device.no') | translate }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="row">
          <div class="key noShrink">{{ 'devices.device.suspended' | translate }}:</div>
          <div class="value noShrink" [ngClass]="device.freeze.suspended.enable ? 't-good' : 't-bad'">
            {{ (device.freeze.suspended.enable ? 'devices.device.yes' : 'devices.device.no') | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngFor="let timetemplate of device.freeze.timeTemplates">
      <div class="key">
        <div class="toggler" (click)="toggleFreeze(timetemplate)">
          <div class="checkbox">
            <label
              for="toggleChannel"
              class="toggle"
              [class.checked]="timetemplate.enable"
              [class.disabled]="!plume.isSomeAdminRole()"
            ></label>
          </div>
        </div>
        <div class="time-wrapper">
          <div class="name">{{ timetemplate.name }}</div>
          <div class="schedules">
            <div class="schedule" *ngFor="let schedule of timetemplate.schedules; let i = index">
              <div class="time" *ngIf="location.isUtilizingSharedLocationFreezeSchedules">
                {{ schedule.startTime }} - {{ schedule.endTime || ('devices.device.endOfDay' | translate) }}
              </div>
              <div class="time" *ngIf="!location.isUtilizingSharedLocationFreezeSchedules">
                {{ schedule?.times[0]?.start }} - {{ schedule?.times[0]?.end }}
              </div>
              <div class="days">
                <div
                  class="day"
                  [class.selected]="schedule.daysOfWeek.indexOf(day) >= 0"
                  *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]"
                >
                  {{ processDay(day) }}
                </div>
              </div>
            </div>
          </div>
          <ng-container
            *ngIf="
              location.isUtilizingSharedLocationFreezeSchedules && timetemplate.schedules && plume.isSomeAdminRole()
            "
          >
            <icon
              name="icon-edit"
              tooltip="{{ 'devices.device.edit' | translate }}"
              (click)="editCustomTimeTemplate(timetemplate)"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_7"
              tooltip="{{ 'devices.device.remove' | translate }}"
              (click)="removeTimeTemplate(timetemplate)"
            ></icon>
          </ng-container>
          <ng-container *ngIf="!location.isUtilizingSharedLocationFreezeSchedules">
            <icon
              name="icon-edit"
              tooltip="{{ 'devices.device.edit' | translate }}"
              (click)="editTimeTemplate(timetemplate)"
              *ngIf="timetemplate.schedules && timetemplate.enable && plume.isSomeAdminRole()"
            ></icon>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row frozen-expire">
      {{ 'devices.device.timeOut' | translate }}
      <button
        class="action"
        [class.disabled]="!plume.isSomeAdminRole()"
        *ngIf="freezeAutoExpireDuration"
        (click)="updateAutoExpire()"
      >
        {{ 'devices.device.minutes' | translate: { duration: round(freezeAutoExpireDuration / 1000 / 60) } }}
      </button>
      <button
        class="action"
        [class.disabled]="!plume.isSomeAdminRole()"
        *ngIf="!freezeAutoExpireDuration"
        (click)="updateAutoExpire()"
      >
        <icon name="icon-feature-freeze" class="size-1_5"></icon>
        {{ 'devices.device.enable' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggleFreezeModal(false)">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
    <button
      class="action"
      (click)="toggleFreezeTemplate(true)"
      *ngIf="location.isUtilizingSharedLocationFreezeSchedules"
    >
      {{ 'devices.device.addTimeTemplate' | translate }}
    </button>
    <button class="super-primary" [class.disabled]="!plume.isSomeAdminRole()" (click)="toggleSuspend()">
      {{ (device.freeze.suspended.enable ? 'devices.device.unsuspend' : 'devices.device.suspend') | translate }}
    </button>
  </ng-container>
</modal>


<modal class="focus" mode="normal" (overlayAction)="showFocusModal = $event" *ngIf="showFocusModal">
  <ng-container title>
    {{ device.nickname || device.name | macAddr: true }}
    {{ 'devices.device.focusTitle' | translate }}
  </ng-container>

  <ng-container content>
    <icon name="icon-alert-warning" class="size-0_8 focusWarning" ></icon>
    {{ 'devices.device.changeFocusWarning' | translate }}
  </ng-container>

  <ng-container content>

    <div class="columns">
      <div class="column">
        <div class="row">
          <div class="key">{{ 'devices.device.focused' | translate }}:</div>
          <div class="value" [ngClass]="device.focused ? 't-good' : 't-bad'">
            {{ (device.focused ? 'devices.device.yes' : 'devices.device.no') | translate }}
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let focus of focuses; let focusId= index">
      <div class="columns">
        <div class="column">
          <div class="row">
              <div class="name">
                {{focus.name}}
                <icon name="icon-check" class="size-0_8 focusActive" tooltip="{{generateTooltip(focus) | translate}}" *ngIf="checkScheduleMac(focus)"></icon>
                <icon name="icon-info" class="size-0_8 focusInactive" tooltip="{{generateTooltip(focus) | translate}}" *ngIf="!checkScheduleMac(focus)"></icon>

              </div>
          </div>
          <div class="row">
            <button class="action small" (click)="toggleFocusEnable(false, focus)" *ngIf="focus.enabled">
              SUSPEND
            </button>
            <button class="action small" (click)="toggleFocusEnable(true, focus)" *ngIf="!focus.enabled">
              ENABLE
            </button>
            <icon
              name="icon-edit"
              class="size-1_2 icon"
              tooltip="{{ 'devices.device.edit' | translate }}"
              (click)="editFocusSchedule(focus)"
            ></icon>

              <icon
                name="icon-close"
                class="size-0_7 icon"
                tooltip="{{ 'devices.device.remove' | translate }}"
                (click)="deleteFocusSchedule(focus)"
              ></icon>


            </div>
          </div>
      </div>
      <div class="schedules" *ngIf="focus.schedule?.schedules.length>0">
        <div class="schedule" *ngFor="let schedule of focus.schedule.schedules; let id = index">
          <div  class="schedule-wrapper">
            <div class="schedule">
              <div class="time">
                {{ schedule.startTime }} - {{ schedule.endTime || ('devices.device.endOfDay' | translate) }}
              </div>
              <div class="days">
                <div
                  class="day"
                  [class.selected]="schedule.daysOfWeek.indexOf(day) >= 0"
                  *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]"
                >
                  {{ processDay(day) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <div class="control-container">
      <button class="tertiary light" (click)="toggleFocusModal(false)">
        {{ 'health.networkInformation.modal.close' | translate }}
      </button>
      <button
        class="action"
        (click)="toggleFocusAdd(true)"
      >
        {{ 'devices.device.addSchedule' | translate }}
      </button>
    </div>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showDeviceDetailsModal = $event" *ngIf="showDeviceDetailsModal">
  <ng-container title>{{ 'devices.device.deviceDetails' | translate }}</ng-container>
  <ng-container content>
    <div class="actions">
      <button class="action small" (click)="restartSniffing()">
        {{ 'devices.device.restartSniffing' | translate }}
      </button>
      <button class="action small" (click)="restartClassify()">
        {{ 'devices.device.restartClassify' | translate }}
      </button>
    </div>
    <div class="list" *ngFor="let deviceDetail of device.deviceDetails | keyvalue">
      <div class="key">{{ '' + deviceDetail.key | translate }}</div>
      <div class="value">{{ deviceDetail.value }}</div>
    </div>
    <div class="list deviceDetails">
      <div class="key">{{ 'devices.device.rawDeviceTyping' | translate }}</div>
      <div class="value">
        <div class="data-wrap">
          <div class="selector" [class.collapsed]="!deviceTypeDataExpand" (click)="toggleDataExpand()">
            {{ 'devices.device.object' | translate }}
            <icon name="icon-arrow-down" class="size-0_6" [class.rotate180]="deviceTypeDataExpand"></icon>
          </div>
          <div class="raw" *ngIf="deviceTypeDataExpand">
            <ngx-json-viewer [expanded]="false" [json]="deviceTypeData"></ngx-json-viewer>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggleDeviceDetailsModal(false)">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showPriorityModal = $event" *ngIf="showPriorityModal">
  <ng-container title>{{ 'devices.device.prioritize.header' | translate }}</ng-container>
  <ng-container content>
    <div class="list space prioritization">
      <div class="key">{{ 'devices.device.prioritize.trafficPriority' | translate }}</div>
      <div class="value">
        <drop-down class="selector medium" [disabled]="!canEditDeviceQos">
          {{
            'devices.device.prioritize.priorityTypes.' + (priority.current?.priorityType || priority.priorityTypes[0])
              | translate
          }}
          <ng-container dropdown>
            <li (click)="selectDevicePriority(priorityType)" *ngFor="let priorityType of priority.priorityTypes">
              {{ 'devices.device.prioritize.priorityTypes.' + priorityType | translate }}
            </li>
          </ng-container>
        </drop-down>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="togglePriorityModal(false)">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
    <button class="super-primary" (click)="setDevicePriority()" *ngIf="canEditDeviceQos">
      {{ 'devices.device.prioritize.setPriority' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showMacStitchingModal = $event" *ngIf="showMacStitchingModal">
  <ng-container title>{{ 'devices.device.stitching.header' | translate }}</ng-container>
  <ng-container content>
    <p>{{ 'devices.device.stitching.description' | translate }}</p>
    <div class="macStitching">
      <div>
        <div class="list space header">
          <div
            class="key"
            (click)="
              macStitchingSort === 'timestamp/desc'
                ? sortMacStitching('timestamp', 'asc')
                : sortMacStitching('timestamp', 'desc')
            "
          >
            {{ 'devices.device.stitching.date' | translate }}
            <icon name="icon-sort" *ngIf="macStitchingSort === 'timestamp/desc'"></icon>
            <icon name="icon-sort-up" *ngIf="macStitchingSort === 'timestamp/asc'"></icon>
          </div>
          <div
            class="value"
            (click)="macStitchingSort === 'mac/desc' ? sortMacStitching('mac', 'asc') : sortMacStitching('mac', 'desc')"
          >
            {{ 'devices.device.stitching.macAddress' | translate }}
            <icon name="icon-sort" *ngIf="macStitchingSort === 'mac/desc'"></icon>
            <icon name="icon-sort-up" *ngIf="macStitchingSort === 'mac/asc'"></icon>
          </div>
        </div>
        <div class="wrapper">
          <ng-container *ngFor="let stitch of macStitchingList">
            <div class="list space">
              <div class="key">{{ stitch.timestamp | date: 'L LT' }}</div>
              <div class="value">{{ stitch.mac }}</div>
              <icon
                name="icon-arrow-down"
                class="size-0_8"
                [class.rotate180]="stitch.details"
                (click)="getMacDeviceDetails(stitch)"
              ></icon>
            </div>
            <div class="details" *ngIf="stitch?.details">
              {{ 'devices.device.stitching.details' | translate }}: {{ stitch.details }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showMacStitchingModal = false">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showSteerModal = $event" *ngIf="showSteerModal">
  <ng-container title>{{ 'devices.device.steer.header' | translate }}</ng-container>
  <ng-container content>
    <div class="list steer">
      <div class="key">{{ 'devices.device.steer.deviceTypeLabel' | translate }}</div>
      <div class="value">
        <drop-down class="selector medium">
          {{ 'devices.device.steer.deviceTypes.' + steer.current?.deviceType | translate }}
          <ng-container dropdown>
            <li (click)="selectDeviceType(deviceType)" *ngFor="let deviceType of steer.deviceTypes">
              {{ 'devices.device.steer.deviceTypes.' + deviceType | translate }}
            </li>
          </ng-container>
        </drop-down>
      </div>
    </div>
    <div class="list steer">
      <div class="key">{{ 'devices.device.steer.targetLabel' | translate }}</div>
      <div class="value">
        <slidetoggler [options]="steer.targetOptions" [editable]="true" (toggle)="setSteerTarget($event)">
        </slidetoggler>
      </div>
    </div>
    <ng-container *ngIf="steer.current.target">
      <div class="list steer">
        <div class="key">{{ 'devices.device.steer.nodeLabel' | translate }}</div>
        <div class="value">
          <drop-down class="selector medium">
            {{
              (steer.current?.node.nickname || steer.current?.node.defaultName | macAddr: true) ||
                ('devices.device.steer.selectOne' | translate)
            }}
            <ng-container dropdown>
              <li (click)="selectSteerOption('node', node)" *ngFor="let node of this.steer.nodes$ | async">
                {{ node.nickname || node.defaultName | macAddr: true | translate }}
              </li>
            </ng-container>
          </drop-down>
        </div>
      </div>
      <div class="list steer">
        <div class="key">{{ 'devices.device.steer.bandLabel' | translate }}</div>
        <div class="value">
          <drop-down class="selector medium">
            {{ steer.current?.band || 'devices.device.steer.selectOne' | translate }}
            <ng-container dropdown>
              <li (click)="selectSteerOption('band', band)" *ngFor="let band of this.steer.bands">
                {{ band | translate }}
              </li>
            </ng-container>
          </drop-down>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggleSteerModal(false)">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
    <button class="super-primary" [class.disabled]="isSteerDisabled()" (click)="steerDevice()">
      {{ 'devices.device.steer.button' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showTosModal = $event" *ngIf="showTosModal">
  <ng-container title>
    {{ 'devices.device.steer.deviceTOSModalTitle' | translate }}
    <ng-container>
      <button class="action small resetTos" (click)="resetTos()" *ngIf="plume.isSomeAdminRole()">
        {{ 'health.networkInformation.modal.resetTOS' | translate }}
      </button>
    </ng-container>
  </ng-container>

  <ng-container content>
    <ngx-json-viewer [expanded]="true" [json]="deviceTOS"></ngx-json-viewer>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showTosModal = false">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="normal" (overlayAction)="showIPV6Modal = $event" *ngIf="showIPV6Modal">
  <ng-container title>{{ 'devices.device.ipv6' | translate }}</ng-container>
  <ng-container content>
    <div class="ipV6Details" *ngFor="let ip of device.ipv6">
      {{ ip | ipv6 }}
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showIPV6Modal = false">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>

<ng-template #deviceConnToEth>{{ 'devices.device.DeviceConnectedToEthernet' | translate }}</ng-template>
