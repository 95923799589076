import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ApiService } from 'src/app/lib/services/api.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { AuthService } from 'src/app/lib/services/auth.service';

@Component({
  templateUrl: './networkRouter.component.html',
  styleUrls: ['./networkRouter.component.scss']
})
export class NetworkRouterComponent implements OnInit {
  msg: string = 'networkRouter.searchingLocation';
  id: string = '';
  errormsg: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private logging: LoggingService,
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.location) {
        if (this.plume.isFlexRole()) {
          this.searchFlexLocation(params.location);
        } else {
          this.searchLocation(params.location);
        }
      }
    });
  }

  searchLocation(locationId: string): void {
    this.errormsg = false;
    this.id = '';
    this.msg = 'networkRouter.searchingLocation';

    let mode = 'GLOBAL';
    let searchEndpoint = '/Customers';

    if (this.plume.isGroupRole()) {
      mode = 'GROUP';
      searchEndpoint = '/Groups/customers';
    }

    this.api
      .get(searchEndpoint + '/search/' + locationId + '?field=locationId&exactMatch=false&startsWith=false')
      .subscribe(
        (response: any) => {
          if (response.length) {
            this.id = response[0].id;
            this.msg = mode === 'GLOBAL' ? 'networkRouter.foundCustomer' : 'networkRouter.foundGroupCustomer';
            this.logging.log(
              (mode === 'GLOBAL' ? '' : 'GROUP ') +
                'REROUTE url by locationId (' +
                locationId +
                ' to ' +
                response[0].id +
                ')'
            );
            this.mixpanel.storeEvent(mode === 'GLOBAL' ? 'REROUTE_BY_LOCATION' : 'GROUP_REROUTE_BY_LOCATION');
            setTimeout(() => this.router.navigate(['customer', response[0].id, 'location', locationId]), 500);
          } else {
            this.id = locationId;
            this.msg =
              mode === 'GLOBAL' ? 'networkRouter.errorFindingCustomer' : 'networkRouter.errorFindingGroupCustomer';
            this.errormsg = true;
            this.logging.error(
              'FAILED ' + (mode === 'GLOBAL' ? '' : 'group') + ' reroute url by locationId (' + locationId + ')'
            );
            this.mixpanel.storeEvent(
              mode === 'GLOBAL' ? 'ERROR_REROUTE_BY_LOCATION' : 'GROUP_ERROR_REROUTE_BY_LOCATION'
            );
          }
        },
        () => {
          this.id = locationId;
          this.msg =
            mode === 'GLOBAL' ? 'networkRouter.errorFindingCustomer' : 'networkRouter.errorFindingGroupCustomer';
          this.errormsg = true;
          this.logging.error(
            'FAILED ' + (mode === 'GLOBAL' ? '' : 'group') + ' reroute url by locationId (' + locationId + ')'
          );
          this.mixpanel.storeEvent(mode === 'GLOBAL' ? 'ERROR_REROUTE_BY_LOCATION' : 'GROUP_ERROR_REROUTE_BY_LOCATION');
        }
      );
  }

  searchFlexLocation(locationId: string): void {
    const env = this.plume.getEnv();
    this.api
      .raw(
        'POST',
        env.flexUrl + '/v1/locations/' + locationId + '/access-tokens',
        {},
        { headers: this.auth.getFrontlineHeader() }
      )
      .subscribe(
        (response: any) => {
          this.id = response.userId;
          this.msg = 'networkRouter.foundCustomer';
          this.auth.setFlexToken(response.id, false);
          this.mixpanel.storeEvent('REROUTE_BY_LOCATION_FLEX');
          setTimeout(() => this.router.navigate(['customer', response.userId, 'location', response.locationId]), 500);
        },
        (error: any) => {
          this.id = locationId;
          this.msg = 'networkRouter.errorFindingCustomer';
          this.errormsg = true;
          this.mixpanel.storeEvent('ERROR_REROUTE_BY_LOCATION_FLEX');
        }
      );
  }
}
