import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { iif, Observable, of } from 'rxjs';
import {
  lteConfigurationLoaded,
  lteConfigurationLoadedError,
  lteConfigurationOpened,
  lteCurrentStateLoaded,
  lteCurrentStateLoadedError,
  lteHWInfoLoaded,
  lteHWInfoLoadedError,
  lteNetworkConfigurationLoaded,
  lteNetworkConfigurationLoadedError,
  lteSignalStrengthLoaded,
  lteSignalStrengthLoadedError,
  lteSupportedLoaded,
  lteSupportedLoadedError
} from '../lte/lte.actions';
import { LteService } from 'src/app/lib/services/lte.service';
import { Store } from '@ngrx/store';
import { selectLocationConfigStateLoading } from '../customer/customer.selectors';
import { selectCapabilities } from '../customer/capabilities.selector';
import { customerLocationConfigAndStateLoaded } from '../customer/customer.actions';

@Injectable()
export class LteEffects {
  getSupportedObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lteConfigurationOpened),
      switchMap(() =>
        this.lteService.fullSupported$().pipe(
          map((supported) => lteSupportedLoaded({ supported })),
          catchError((error) => {
            return of(lteSupportedLoadedError({ error }));
          })
        )
      )
    )
  );

  getSignalStrength$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lteConfigurationOpened),
      switchMap(() =>
        this.store.select(selectLocationConfigStateLoading).pipe(
          filter((loading) => !loading),
          take(1)
        )
      ),
      switchMap(() => this.getSupported$().pipe(take(1))),
      switchMap((supported) =>
        iif(
          () => supported,
          this.lteService.signalStrength$().pipe(
            map((signalStrength) => lteSignalStrengthLoaded({ signalStrength })),
            catchError((error) => {
              return of(lteSignalStrengthLoadedError({ error }));
            })
          ),
          of(lteSignalStrengthLoadedError({ error: 'not supported' }))
        )
      )
    )
  );

  getHwInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lteConfigurationOpened),
      switchMap(() =>
        this.store.select(selectLocationConfigStateLoading).pipe(
          filter((loading) => !loading),
          take(1)
        )
      ),
      switchMap(() => this.getSupported$().pipe(take(1))),
      switchMap((supported) =>
        iif(
          () => supported,
          this.lteService.hwInfo$().pipe(
            map((hwInfo) => lteHWInfoLoaded({ hwInfo })),
            catchError((error) => {
              return of(lteHWInfoLoadedError({ error }));
            })
          ),
          of(lteHWInfoLoadedError({ error: 'not supported' }))
        )
      )
    )
  );

  getLteNetworkConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lteConfigurationOpened),
      switchMap(() =>
        this.store.select(selectLocationConfigStateLoading).pipe(
          filter((loading) => !loading),
          take(1)
        )
      ),
      switchMap(() => this.getSupported$().pipe(take(1))),
      switchMap((supported) =>
        iif(
          () => supported,
          this.lteService.networkConfiguration$().pipe(
            map((networkConfiguration) => lteNetworkConfigurationLoaded({ networkConfiguration })),
            catchError((error) => {
              return of(lteNetworkConfigurationLoadedError({ error }));
            })
          ),
          of(lteNetworkConfigurationLoadedError({ error: 'not supported' }))
        )
      )
    )
  );

  getLteConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lteConfigurationOpened),
      switchMap(() =>
        this.store.select(selectLocationConfigStateLoading).pipe(
          filter((loading) => !loading),
          take(1)
        )
      ),
      switchMap(() => this.getSupported$().pipe(take(1))),
      switchMap((supported) =>
        iif(
          () => supported,
          this.lteService.getConfiguration$().pipe(
            map((configuration) => lteConfigurationLoaded({ configuration })),
            catchError((error) => {
              return of(lteConfigurationLoadedError({ error }));
            })
          ),
          of(lteConfigurationLoadedError({ error: 'not supported' }))
        )
      )
    )
  );

  getCurrentState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerLocationConfigAndStateLoaded), // lteConfigurationOpened - not needed as it gets customerLocationConfig
      switchMap(() =>
        this.getSupported$().pipe(
          filter((supported) => supported),
          take(1)
        )
      ),
      switchMap(() =>
        this.lteService.currentState$().pipe(
          map((currentState) => lteCurrentStateLoaded({ currentState })),
          catchError((error) => {
            return of(lteCurrentStateLoadedError({ error }));
          })
        )
      )
    )
  );

  getSupported$(): Observable<boolean> {
    return this.store.select(selectCapabilities).pipe(
      filter((capabilities) => capabilities !== undefined),
      map((capabilities) => capabilities?.lte?.capable)
    );
  }

  constructor(private actions$: Actions, private store: Store, private lteService: LteService) {}
}
