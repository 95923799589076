import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { NetworkConfigurationService } from 'src/app/lib/services/network-configuration.service';
import { Store } from '@ngrx/store';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { selectDevices } from 'src/app/store/polling/polling.selector';
import { selectPipeLocationOnChangeOptimistic } from 'src/app/store/customer/customer.selectors';
import { IDhcp, IDHCPLeases } from 'src/app/lib/interfaces/interface';
import { selectConfigAndState } from 'src/app/store/customer/customer.selectors';

@Component({
  selector: 'dhcp',
  templateUrl: './dhcp.component.html',
  styleUrls: ['./dhcp.component.scss']
})
export class DhcpComponent implements OnInit, OnChanges, OnDestroy {
  capabilities$ = this.store.select(selectCapabilities);
  expand: boolean = false;
  location$ = this.store.pipe(selectPipeLocationOnChangeOptimistic);
  dhcp: IDhcp;
  dhcpReservation: any;
  devices: any[] = [];
  deviceSubscription: any;
  dataExpand: boolean = false;
  reservationDataExpand: boolean = false;
  editDhcp = false;
  dhcpSubnet: FormControl = new FormControl('');
  dhcpSubnetMask: FormControl = new FormControl('');
  dhcpStartIp: FormControl = new FormControl('');
  dhcpEndIp: FormControl = new FormControl('');
  subnetPrefix: any;
  currentDevice: any = {
    name: 'configurations.dhcp.dhcpReservation.selectDevice'
  };
  currentDeviceMac: FormControl = new FormControl('');
  currentDeviceIpSuffix: FormControl = new FormControl('');
  currentDeviceIp: string;
  showButton: boolean = false;
  editDhcpIp: boolean = false;
  formControls: FormControl[] = [];
  showPortForward: boolean = false;
  showPortForwardData: boolean = false;
  protocolValues = [
    {
      text: 'TCP',
      value: 'tcp'
    },
    {
      text: 'UDP',
      value: 'udp'
    },
    {
      text: 'TCP+UDP',
      value: 'tcp+udp'
    }
  ];
  natValues = [
    { text: 'AUTO', translation: 'auto', value: 'auto' },
    { text: 'ENABLE', translation: 'enable', value: 'enable' },
    {
      text: 'DISABLE',
      value: 'disable',
      translation: 'disable'
    }
  ];

  portForwardName: FormControl = new FormControl('');
  externalPort: FormControl = new FormControl('');
  internalPort: FormControl = new FormControl('');
  addProtocolValue = '';
  addNatValue = '';
  DHCPLeases: IDHCPLeases;
  leaseExpand: boolean = false;

  portForwardData: any;
  portForwards: any;
  editPortForwardName: boolean = false;
  editPortForwardinternalPort: boolean = false;
  editPortForwardProtocol: boolean = false;
  natLoopbackEnable: boolean = false;

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    private netConfigService: NetworkConfigurationService,
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private translate: TranslateService,
    private store: Store,
    private customer: CustomerService
  ) {}

  ngOnInit(): void {
    this.init();
    this.registerFilter();
    this.getDhcpReservation();
    this.checkDHCP();
    this.store.select(selectConfigAndState).subscribe((configAndState) => {
      this.natLoopbackEnable = configAndState.config.networkConfiguration?.natLoopback?.mode !== null;
    });
  }

  checkDHCP(): void {
    this.customer.getDHCPLeases$().subscribe((response: IDHCPLeases) => {
      if (response) {
        this.DHCPLeases = response;
      }
    });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  init(): void {
    this.netConfigService.dhcp$().subscribe((response) => {
      if (response) {
        this.dhcp = response;
        this.getSubnetPrefix();
        this.initItems();
      }
    });

    this.deviceSubscription = this.store.select(selectDevices).subscribe((devices) => {
      if (devices) {
        this.devices = devices;
      }
    });
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'dhcp' });

    this.translate
      .get('configurations.dhcp.subnet')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'dhcp', property: 'subnet', translation: translated })
      );

    this.translate
      .get('configurations.dhcp.netmask')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'dhcp', property: 'netmask', translation: translated })
      );

    this.translate
      .get('configurations.dhcp.startIp')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'dhcp', property: 'startIp', translation: translated })
      );

    this.translate
      .get('configurations.dhcp.endIp')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'dhcp', property: 'endIp', translation: translated })
      );

    this.translate
      .get('configurations.dhcp.dhcpData')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'dhcp', property: 'dhcpData', translation: translated })
      );

    this.translate
      .get('configurations.dhcp.createDhcpReservation')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'dhcp', property: 'dhcpReservation', translation: translated })
      );

    this.translate
      .get('configurations.dhcp.portForward.title')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'dhcp', property: 'portForward', translation: translated })
      );
  }

  getSubnetPrefix(): void {
    if (this.dhcp) {
      this.subnetPrefix = this.dhcp.subnet.split('.').slice(0, 3).join('.');
    }
  }

  initItems(): void {
    this.dhcpSubnet.setValue(this.dhcp?.subnet);
    this.dhcpSubnetMask.setValue(this.dhcp?.subnetMask);
    this.dhcpStartIp.setValue(this.dhcp?.startIp);
    this.dhcpEndIp.setValue(this.dhcp?.endIp);
  }

  saveDhcp(): void {
    this.netConfigService
      .setDhcp$({
        subnet: this.dhcpSubnet.value,
        subnetMask: this.dhcpSubnetMask.value,
        startIp: this.dhcpStartIp.value,
        endIp: this.dhcpEndIp.value
      })
      .subscribe(
        (response) => {
          this.dhcp = response;
          this.initItems();
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_SET');
          this.toast.success('configurations.dhcp.updatedSuccessfully', 'header.success');
        },
        (error: any) => {
          this.initItems();
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_SET_ERROR');
          this.toast.error(error?.error?.error?.message || 'configurations.dhcp.netmaskSaveFailed', 'header.failed');
        }
      );
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_DHCP_SCREEN');
      this.getSubnetPrefix();
      this.initItems();
    }
  }

  toggleLeaseExpand(): void {
    this.leaseExpand = !this.leaseExpand;
  }

  toggleDataExpand(): void {
    this.dataExpand = !this.dataExpand;
  }

  toggleReservationDataExpand(): void {
    this.reservationDataExpand = !this.reservationDataExpand;
  }

  dhcpEditDone(action: 'set' | 'close'): void {
    this.editDhcp = false;
    if (action === 'set') {
      this.saveDhcp();
    } else {
      this.initItems();
    }
  }

  enableDhcpIpEdit(reservation: any): void {
    reservation.editDhcpIp = true;
  }

  confirmDhcpIpEdit(reservation: any, index: number): void {
    reservation.editDhcpIp = false;

    this.netConfigService
      .addOrUpdateDhcpReservation$({ mac: reservation.mac, ip: this.formControls[index].value })
      .subscribe(
        () => {
          this.getDhcpReservation();
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_DELETE');
          this.toast.success('configurations.dhcp.updatedReservation', 'header.success');
        },
        (error: any) => {
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_DELETION_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
          this.formControls[index].setValue(reservation.ip);
        }
      );
  }

  cancelDhcpIpEdit(reservation: any, index: number): void {
    reservation.editDhcpIp = false;
    this.formControls[index].setValue(reservation.ip);
  }

  selectDevice(device: any): void {
    const selectedDevice = this.devices.find((d: any) => d.mac === device.mac);

    if (selectedDevice) {
      this.currentDevice.name = device.name;
      this.currentDeviceMac.setValue(selectedDevice.mac);
      this.currentDeviceIpSuffix.setValue(selectedDevice.ip?.split('.').pop() || '');
      this.currentDeviceIp = this.subnetPrefix + '.';
      this.showButton = true;
    }
  }

  getDhcpReservation(): void {
    this.netConfigService.dhcpReservations$().subscribe((response) => {
      if (response) {
        this.dhcpReservation = response.map((reservation) => ({
          ...reservation,
          portForwards: reservation.portForwards?.map((portDef) => ({
            ...this.dhcpReservation
              ?.find(
                (cachedReservation) =>
                  cachedReservation.ip === reservation.ip && cachedReservation.mac === reservation.mac
              )
              ?.portForwards?.find((cachedPortDef) => cachedPortDef.externalPort === portDef.externalPort),
            ...portDef
          }))
        }));
        this.createReservationFormArray();
      }
    });
  }

  setDhcpReservation(): void {
    this.currentDeviceIp = this.subnetPrefix + '.';
    this.currentDeviceIp = this.currentDeviceIp + this.currentDeviceIpSuffix.value;
    if (this.currentDeviceMac.value !== '' && this.currentDeviceIpSuffix.value !== '') {
      this.netConfigService
        .addOrUpdateDhcpReservation$({
          mac: this.currentDeviceMac.value,
          ip: this.currentDeviceIp
        })
        .subscribe(
          (response) => {
            this.getDhcpReservation();
            this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_SET');
            this.showButton = false;
          },
          (error: any) => {
            this.mixpanel.storeEvent('DHCP_RESERVATION_ERROR');
            this.toast.error(error.error.error.message, 'header.failed');
          }
        );
    } else {
      this.toast.error('configurations.dhcp.dhcpReservation.toast.errorMessage', 'header.failed');
    }
  }

  enableButton(): void {
    this.showButton = true;
  }

  createReservationFormArray(): void {
    this.formControls = [];
    if (this.dhcpReservation.length) {
      for (const reservation of this.dhcpReservation) {
        this.formControls.push(new FormControl(reservation.ip));
      }
    }
  }

  deleteDhcpReservation(index: number, mac: string): void {
    this.netConfigService.deleteDhcpReservation$(mac).subscribe(
      (response) => {
        this.getDhcpReservation();
        this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_DELETE');
        this.toast.success('configurations.dhcp.dhcpReservation.toast.portForwardDelete', 'header.success');
        this.enableButton();
      },
      (error: any) => {
        this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_DELETE_ERROR');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }

  openPortForward(reservation: any): void {
    reservation.showPortForward = true;
  }

  cancelPortForwarding(reservation: any): void {
    this.portForwardName.reset();
    this.externalPort.reset();
    this.internalPort.reset();
    this.addProtocolValue = '';
    this.addNatValue = '';
    reservation.showPortForward = false;
  }

  postPortForwarding(mac: string): void {
    if (this.addProtocolValue === '') {
      this.toast.error(
        'configurations.dhcp.dhcpReservation.toast.noProtocolMsg',
        'configurations.dhcp.dhcpReservation.toast.noProtocolTitle'
      );
      return;
    }

    if (this.addNatValue === '') {
      this.toast.error(
        'configurations.dhcp.dhcpReservation.toast.noNatMsg',
        'configurations.dhcp.dhcpReservation.toast.noNatTitle'
      );
      return;
    }

    this.netConfigService
      .addDhcpPortForwards$(mac, {
        name: this.portForwardName.value,
        internalPort: this.internalPort.value,
        externalPort: this.externalPort.value,
        protocol: this.addProtocolValue,
        natLoopback: this.addNatValue
      })
      .subscribe(
        () => {
          this.getDhcpReservation();
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_PORT_FORWARD_SET');
          this.toast.success('configurations.dhcp.dhcpReservation.toast.portForwardSaved', 'header.success');
        },
        (error: any) => {
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_PORT_FORWARD_SET_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
  }

  showPortForwardDelete(portForward: any): void {
    portForward.showDeleteModal = true;
  }

  closeDeleteModal(portForward: any): void {
    portForward.showDeleteModal = false;
  }

  deletePortForward(reservation: any, portforward: any): void {
    this.netConfigService.deleteDhcpPortForwards$(reservation.mac, portforward.externalPort).subscribe(
      (response) => {
        this.getDhcpReservation();
        this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_PORT_FORWARD_DELETE');
        this.toast.success('configurations.dhcp.dhcpReservation.toast.portForwardDelete', 'header.success');
      },
      (error: any) => {
        this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_DELETION_PORT_FORWARD_ERROR');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }

  enablePortForwardName(portForward: any): void {
    portForward.editPortForwardName = true;
    portForward.editName = portForward.name;
  }

  confirmNat(reservation: any, portForward: any): void {
    portForward.natLoopbackShow = false;
    this.netConfigService
      .updateDhcpPortForwards$(reservation.mac, {
        name: portForward.name,
        internalPort: portForward.internalPort,
        externalPort: portForward.externalPort,
        protocol: portForward.protocol,
        natLoopback: portForward.natLoopback
      })
      .subscribe(
        (response) => {
          this.getDhcpReservation();
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_NAT_LOOPBACK_UPDATE');
          this.toast.success('configurations.dhcp.dhcpReservation.toast.portForwardUpdate', 'header.success');
        },
        (error: any) => {
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_NAT_LOOPBACK_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
  }

  confirmPortForwardName(reservation: any, portForward: any): void {
    portForward.editPortForwardName = false;
    if (this.natLoopbackEnable) {
      this.portForwardData = {
        name: portForward.editName,
        internalPort: portForward.internalPort,
        externalPort: portForward.externalPort,
        protocol: portForward.protocol,
        natLoopback: portForward.natLoopback
      };
    } else {
      this.portForwardData = {
        name: portForward.editName,
        internalPort: portForward.internalPort,
        externalPort: portForward.externalPort,
        protocol: portForward.protocol
      };
    }
    this.netConfigService.updateDhcpPortForwards$(reservation.mac, this.portForwardData).subscribe(
      (response) => {
        this.getDhcpReservation();
        this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_PORT_FORWARD_NAME_UPDATE');
        this.toast.success('configurations.dhcp.dhcpReservation.toast.portForwardNameUpdate', 'header.success');
      },
      (error: any) => {
        this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_PORT_FORWARD_NAME_SET_ERROR');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }

  canclePortForwardName(portForward: any): void {
    portForward.editPortForwardName = false;
  }

  enablePortForwardInternalPort(portForward: any): void {
    portForward.editPortForwardinternalPort = true;
    portForward.editInternalPort = portForward.internalPort;
  }

  confirmPortForwardInternalPort(reservation: any, portForward: any): void {
    portForward.editPortForwardinternalPort = false;
    this.netConfigService
      .updateDhcpPortForwards$(reservation.mac, {
        name: portForward.name,
        internalPort: portForward.editInternalPort,
        externalPort: portForward.externalPort,
        protocol: portForward.protocol
      })
      .subscribe(
        (response) => {
          this.getDhcpReservation();
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_PORT_FORWARD_INTPORT_UPDATE');
          this.toast.success('configurations.dhcp.dhcpReservation.toast.portForwardInternalUpdate', 'header.success');
        },
        (error: any) => {
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_PORT_FORWARD_INTPORT_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
  }

  canclePortForwardInternalPort(portForward: any): void {
    portForward.editPortForwardinternalPort = false;
  }

  enablePortForwardProtocol(portForward: any): void {
    portForward.editPortForwardProtocol = true;
    portForward.editProtocol = portForward.protocol;
  }

  enablePortNat(portForward: any): void {
    portForward.natLoopbackShow = true;
    portForward.editNat = portForward.natLoopback;
  }

  cancelPortNat(portForward: any): void {
    portForward.natLoopbackShow = false;
  }

  confirmPortForwardProtocol(reservation: any, portForward: any): void {
    portForward.editPortForwardProtocol = false;
    this.netConfigService
      .updateDhcpPortForwards$(reservation.mac, {
        name: portForward.name,
        internalPort: portForward.internalPort,
        externalPort: portForward.externalPort,
        protocol: portForward.editProtocol
      })
      .subscribe(
        (response) => {
          this.getDhcpReservation();
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_PORT_FORWARD_PROTOCOL_UPDATE');
          this.toast.success('configurations.dhcp.dhcpReservation.toast.portForwardProtocolUpdate', 'header.success');
        },
        (error: any) => {
          this.mixpanel.storeEvent('CONFIGURATION_DHCP_RESERVATION_PORT_FORWARD_PROTOCOL_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
  }

  canclePortForwardProtocol(portForward: any): void {
    portForward.editPortForwardProtocol = false;
  }

  confirmDhcpSubnetEdit(): void {}
  cancelDhcpSubnetEdit(): void {}
  confirmDhcpSubnetMaskEdit(): void {}
  cancelDhcpSubnetmaskEdit(): void {}
  confirmDhcpStartIpEdit(): void {}
  cancelDhcpStartIpEdit(): void {}
  confirmDhcpEndIpEdit(): void {}
  cancelDhcpEndIpEdit(): void {}

  ngOnDestroy(): void {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
  }
}
