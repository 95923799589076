<spacer>{{ 'netops.firmwareUpgrade' | translate }}</spacer>
<div class="container-fluid">
  <div class="row info targetMethod">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-2 key">{{ 'netops.firmware.targetMethod' | translate }}:</div>
        <div class="col-xs-10">
          <slidetoggler [options]="targetMethodItems" (toggle)="targetMethod = $event"></slidetoggler>
        </div>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <ng-container *ngIf="targetMethod === 'location'">
    <div class="row info">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.targetMethodLocation' | translate }}:</div>
          <div class="col-xs-10">
            <div class="details no-margin">
              <div class="pill-hollow">
                <input
                  type="text"
                  [formControl]="specificLocationIds"
                  placeholder="{{ 'netops.firmware.addLocationIds' | translate }}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="targetMethod === 'criteria'">
    <div class="row info">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.maxRandomLocations' | translate }}:</div>
          <div class="col-xs-10">
            <div class="details no-margin">
              <div class="pill-hollow">
                <input
                  type="text"
                  [formControl]="randomLocationFormControl"
                  placeholder="{{ 'netops.firmware.max' | translate }}"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.partnerId' | translate }}:</div>
          <div class="col-xs-10">
            <div class="details no-margin">
              <input-tokenizer
                #partnerTokenizer
                [watchTokens]="partnerIDFormControl.value"
                (tokensDetected)="partnerIDFormControl.reset()"
                (tokensChange)="partnerIdSelected = $event"
              >
                <div class="selectors">
                  <div class="pill-hollow">
                    <input
                      type="text"
                      [formControl]="partnerIDFormControl"
                      placeholder="{{ 'netops.firmware.addPartnerId' | translate }}"
                    />
                  </div>
                  <icon
                    name="icon-copy"
                    [class.disabled]="!(partnerIdSelected?.length > 0)"
                    tooltip="{{ 'netops.firmware.copy' | translate }}"
                    (click)="copy(partnerIdSelected, 'partnerId')"
                  ></icon>
                  <icon
                    name="icon-close"
                    class="cancel"
                    [class.disabled]="!(partnerIdSelected?.length > 0)"
                    tooltip="{{ 'netops.firmware.clear' | translate }}"
                    (click)="partnerTokenizer.clearAll()"
                  ></icon>
                </div>
              </input-tokenizer>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.nodeModels' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <drop-down-select
                class="medium selector"
                [emptyText]="'netops.firmware.selectModels' | translate"
                [multiSelect]="true"
                [values]="modelsValues"
                [(ownModel)]="modelsSelected"
                [filterPlaceHolder]="'header.search' | translate"
                multiSelectSeparator=", "
              ></drop-down-select>
              <icon
                name="icon-copy"
                [class.disabled]="!(modelsSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.copy' | translate }}"
                (click)="copy(modelsSelected, 'nodeModels')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(modelsSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="modelsSelected = []"
              ></icon>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.countries' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <drop-down-select
                class="medium selector"
                [emptyText]="'netops.firmware.selectCountries' | translate"
                [multiSelect]="true"
                [values]="countriesValues"
                [(ownModel)]="countriesSelected"
                (opened)="countriesSelector($event)"
                [filterPlaceHolder]="'header.search' | translate"
                multiSelectSeparator=", "
              ></drop-down-select>
              <icon
                name="icon-copy"
                [class.disabled]="!(countriesSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.copy' | translate }}"
                (click)="copy(countriesSelected, 'countries')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(countriesSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="countriesSelected = []; citiesSelected = []"
              ></icon>
            </div>
          </div>
        </div>
        <div class="row" [class.disabled]="!countriesSelected.length">
          <div class="col-xs-2 key">{{ 'netops.firmware.cities' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <drop-down-select
                class="medium selector"
                [emptyText]="'netops.firmware.selectCities' | translate"
                [multiSelect]="true"
                [values]="citiesValues"
                [(ownModel)]="citiesSelected"
                [filterPlaceHolder]="'header.search' | translate"
                multiSelectSeparator=", "
              ></drop-down-select>
              <icon
                name="icon-copy"
                [class.disabled]="!(citiesSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.copy' | translate }}"
                (click)="copy(citiesSelected, 'cities')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(citiesSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="citiesSelected = []"
              ></icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <span class="title">{{ 'netops.firmware.includeFilters' | translate }}</span>
      </div>
    </div>
    <div class="row info">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.locationID' | translate }}:</div>
          <div class="col-xs-10">
            <div class="details no-margin">
              <input-tokenizer
                #includeLocationTokenizer
                [maxTokens]="10"
                [watchTokens]="includeLocationIDFormControl.value"
                (tokensDetected)="includeLocationIDFormControl.reset()"
                (tokensChange)="includeLocationIdSelected = $event"
              >
                <div class="selectors">
                  <div class="pill-hollow">
                    <input
                      type="text"
                      [formControl]="includeLocationIDFormControl"
                      placeholder="{{ 'netops.firmware.addLocationId' | translate }}"
                    />
                  </div>
                  <icon
                    name="icon-copy"
                    [class.disabled]="!(includeLocationIdSelected?.length > 0)"
                    tooltip="{{ 'netops.firmware.copy' | translate }}"
                    (click)="copy(includeLocationIdSelected, 'locationID')"
                  ></icon>
                  <icon
                    name="icon-close"
                    class="cancel"
                    [class.disabled]="!(includeLocationIdSelected?.length > 0)"
                    tooltip="{{ 'netops.firmware.clear' | translate }}"
                    (click)="includeLocationTokenizer.clearAll()"
                  ></icon>
                </div>
              </input-tokenizer>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.groupId' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <drop-down-select
                class="medium selector"
                [emptyText]="'netops.firmware.selectGroupId' | translate"
                [multiSelect]="true"
                [values]="includeGroupIdValues"
                [(ownModel)]="includeGroupIdSelected"
                [rowTemplate]="includeGroupIdTemplate"
                [filterPlaceHolder]="'header.search' | translate"
                [filterFunction]="filterGroupId"
                headerPrefix="{{ 'netops.firmware.selectGroupIdCount' | translate }}:"
              ></drop-down-select>
              <ng-template #includeGroupIdTemplate let-value>
                <div class="upgradeFirmware groupsSelectRow name">
                  {{ value.object.name }}
                </div>
                <div class="upgradeFirmware groupsSelectRow id">
                  {{ value.object.id }}
                </div>
              </ng-template>
              <icon
                name="icon-copy"
                [class.disabled]="!(includeGroupIdSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.copy' | translate }}"
                (click)="copy(includeGroupIdSelected, 'groupId')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(includeGroupIdSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="includeGroupIdSelected = []"
              ></icon>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.activeVersionMatrix' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <drop-down-select
                class="medium selector"
                [emptyText]="'netops.firmware.selectActiveMatrix' | translate"
                [multiSelect]="true"
                [values]="includeActiveMatrixValues"
                [(ownModel)]="includeActiveMatrixSelected"
                [rowTemplate]="includeActiveMatrixTemplate"
                [filterPlaceHolder]="'header.search' | translate"
                headerPrefix="{{ 'netops.firmware.selectActiveMatrixCount' | translate }}:"
              ></drop-down-select>
              <ng-template #includeActiveMatrixTemplate let-value>
                <div class="upgradeFirmware groupsSelectRow name">
                  {{ value.object.versionMatrix }}
                </div>
                <div class="upgradeFirmware groupsSelectRow id">
                  {{ 'netops.firmware.createdAt' | translate }}: {{ value.object.createdAt | date: 'LLL' }}
                </div>
              </ng-template>
              <icon
                name="icon-copy"
                [class.disabled]="!(includeActiveMatrixSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.copy' | translate }}"
                (click)="copy(includeActiveMatrixSelected, 'activeVersionMatrix')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(includeActiveMatrixSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="includeActiveMatrixSelected = []"
              ></icon>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.pendingVersionMatrix' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <drop-down-select
                class="medium selector"
                [emptyText]="'netops.firmware.selectPendingMatrix' | translate"
                [multiSelect]="true"
                [values]="includePendingMatrixValues"
                [(ownModel)]="includePendingMatrixSelected"
                [rowTemplate]="includePendingMatrixTemplate"
                [filterPlaceHolder]="'header.search' | translate"
                headerPrefix="{{ 'netops.firmware.selectPendingMatrixCount' | translate }}:"
              ></drop-down-select>
              <ng-template #includePendingMatrixTemplate let-value>
                <div class="upgradeFirmware groupsSelectRow name">
                  {{ value.object.versionMatrix }}
                </div>
                <div class="upgradeFirmware groupsSelectRow id">
                  {{ 'netops.firmware.createdAt' | translate }}: {{ value.object.createdAt | date: 'LLL' }}
                </div>
              </ng-template>
              <icon
                name="icon-copy"
                [class.disabled]="!(includePendingMatrixSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.copy' | translate }}"
                (click)="copy(includePendingMatrixSelected, 'pendingVersionMatrix')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(includePendingMatrixSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="includePendingMatrixSelected = []"
              ></icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <span class="title">{{ 'netops.firmware.excludeFilters' | translate }}</span>
      </div>
    </div>
    <div class="row info">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.locationID' | translate }}:</div>
          <div class="col-xs-10">
            <div class="details no-margin">
              <input-tokenizer
                #excludeLocationTokenizer
                [maxTokens]="10"
                [watchTokens]="excludeLocationIDFormControl.value"
                (tokensDetected)="excludeLocationIDFormControl.reset()"
                (tokensChange)="excludeLocationIdSelected = $event"
              >
                <div class="selectors">
                  <div class="pill-hollow">
                    <input
                      type="text"
                      [formControl]="excludeLocationIDFormControl"
                      placeholder="{{ 'netops.firmware.addLocationId' | translate }}"
                    />
                  </div>
                  <icon
                    name="icon-copy"
                    [class.disabled]="!(excludeLocationIdSelected?.length > 0)"
                    tooltip="{{ 'netops.firmware.copy' | translate }}"
                    (click)="copy(excludeLocationIdSelected, 'locationID')"
                  ></icon>
                  <icon
                    name="icon-close"
                    class="cancel"
                    [class.disabled]="!(excludeLocationIdSelected?.length > 0)"
                    tooltip="{{ 'netops.firmware.clear' | translate }}"
                    (click)="excludeLocationTokenizer.clearAll()"
                  ></icon>
                </div>
              </input-tokenizer>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.groupId' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <drop-down-select
                class="medium selector"
                [emptyText]="'netops.firmware.selectGroupId' | translate"
                [multiSelect]="true"
                [values]="excludeGroupIdValues"
                [(ownModel)]="excludeGroupIdSelected"
                [rowTemplate]="excludeGroupIdTemplate"
                [filterPlaceHolder]="'header.search' | translate"
                [filterFunction]="filterGroupId"
                headerPrefix="{{ 'netops.firmware.selectGroupIdCount' | translate }}:"
              ></drop-down-select>
              <ng-template #excludeGroupIdTemplate let-value>
                <div class="upgradeFirmware groupsSelectRow name">
                  {{ value.object.name }}
                </div>
                <div class="upgradeFirmware groupsSelectRow id">
                  {{ value.object.id }}
                </div>
              </ng-template>
              <icon
                name="icon-copy"
                [class.disabled]="!(excludeGroupIdSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.copy' | translate }}"
                (click)="copy(excludeGroupIdSelected, 'groupId')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(excludeGroupIdSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="excludeGroupIdSelected = []"
              ></icon>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.activeVersionMatrix' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <drop-down-select
                class="medium selector"
                [emptyText]="'netops.firmware.selectActiveMatrix' | translate"
                [multiSelect]="true"
                [values]="excludeActiveMatrixValues"
                [(ownModel)]="excludeActiveMatrixSelected"
                [rowTemplate]="excludeActiveMatrixTemplate"
                [filterPlaceHolder]="'header.search' | translate"
                headerPrefix="{{ 'netops.firmware.selectActiveMatrixCount' | translate }}:"
              ></drop-down-select>
              <ng-template #excludeActiveMatrixTemplate let-value>
                <div class="upgradeFirmware groupsSelectRow name">
                  {{ value.object.versionMatrix }}
                </div>
                <div class="upgradeFirmware groupsSelectRow id">
                  {{ 'netops.firmware.createdAt' | translate }}: {{ value.object.createdAt | date: 'LLL' }}
                </div>
              </ng-template>
              <icon
                name="icon-copy"
                [class.disabled]="!(excludeActiveMatrixSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.copy' | translate }}"
                (click)="copy(excludeActiveMatrixSelected, 'activeVersionMatrix')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(excludeActiveMatrixSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="excludeActiveMatrixSelected = []"
              ></icon>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.pendingVersionMatrix' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <drop-down-select
                class="medium selector"
                [emptyText]="'netops.firmware.selectPendingMatrix' | translate"
                [multiSelect]="true"
                [values]="excludePendingMatrixValues"
                [(ownModel)]="excludePendingMatrixSelected"
                [rowTemplate]="excludePendingMatrixTemplate"
                [filterPlaceHolder]="'header.search' | translate"
                headerPrefix="{{ 'netops.firmware.selectPendingMatrixCount' | translate }}:"
              ></drop-down-select>
              <ng-template #excludePendingMatrixTemplate let-value>
                <div class="upgradeFirmware groupsSelectRow name">
                  {{ value.object.versionMatrix }}
                </div>
                <div class="upgradeFirmware groupsSelectRow id">
                  {{ 'netops.firmware.createdAt' | translate }}: {{ value.object.createdAt | date: 'LLL' }}
                </div>
              </ng-template>
              <icon
                name="icon-copy"
                [class.disabled]="!(excludePendingMatrixSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.copy' | translate }}"
                (click)="copy(excludePendingMatrixSelected, 'pendingVersionMatrix')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(excludePendingMatrixSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="excludePendingMatrixSelected = []"
              ></icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="divider"></div>
  <div id="preview" class="row">
    <div class="col-xs-12">
      <span class="title">{{ 'netops.firmware.upgradeParameters' | translate }}</span>
    </div>
  </div>
  <div class="row info">
    <div class="col-xs-12 no-padding">
      <div class="row">
        <div class="col-xs-8">
          <div class="selectors">
            <div class="row time">
              <div class="col-xs-4">
                <button class="action medium" (click)="openMatrixSelect('targetVersion')">
                  {{ 'netops.firmware.selectVersionMatrix' | translate }}
                </button>
              </div>
              <div class="col-xs-2 label">{{ 'netops.firmware.startTime' | translate }}:</div>
              <div class="col-xs-2 details no-margin">
                <div class="pill-hollow">
                  <input [formControl]="startTimeFormControl" type="text" placeholder="00:00" />
                </div>
              </div>
              <div class="col-xs-2 label">{{ 'netops.firmware.endTime' | translate }}:</div>
              <div class="col-xs-2 details no-margin">
                <div class="pill-hollow">
                  <input [formControl]="endTimeFormControl" type="text" placeholder="23:59" />
                </div>
              </div>
            </div>
          </div>
          <div class="details">
            <div class="pill-hollow">
              <input
                #selectedTargetInput
                type="text"
                [formControl]="selectedTargetVersions"
                placeholder="{{ 'netops.firmware.targetVersion' | translate }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="row info">
    <div class="row col-xs-12 center">
      <div class="col-xs-12 no-padding">
        <div class="preview-buttons">
          <button class="action" [class.disabled]="loadingLocations" (click)="preview()">
            {{ 'netops.firmware.preview' | translate }}
          </button>
          <button
            class="action"
            (click)="requestUpgrade()"
            [class.disabled]="loadingRequestUpgrade || !selectedTargetVersions.value?.length"
            *ngIf="locations.length"
          >
            {{ 'netops.firmware.requestUpgrade' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="row col-xs-12 center">
      <button class="action" (click)="downloadCSV()" *ngIf="report">
        {{ 'netops.reports.downloadLocations' | translate }}
      </button>
      <div class="batch-details" *ngIf="batchFirmwareUpgrade">
        <div>
          {{ 'netops.firmware.batchID' | translate }}: <b>{{ batchFirmwareUpgrade.id }}</b>
        </div>
        <div>
          {{ 'netops.firmware.timestamp' | translate }}: <b>{{ batchFirmwareUpgrade.timestamp }}</b>
        </div>
        <div>
          {{ 'netops.firmware.batchSize' | translate }}: <b>{{ batchFirmwareUpgrade.batchSize }}</b>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="previewTriggered">
    <div class="col-xs-12">
      <div class="title row" *ngIf="!report && locations.length && !loadingLocations">
        <div class="col-xs-8 copy">
          {{ 'netops.firmware.locationsFound' | translate }}: {{ batchSize }}
          <icon
            name="icon-copy"
            [class.disabled]="!locations.length"
            tooltip="{{ 'netops.firmware.copy' | translate }}"
            (click)="copy(locations, 'locationsFound')"
          ></icon>
        </div>
      </div>
      <div class="title row" *ngIf="report && locations.length && !loadingLocations">
        <div class="col-xs-8 copy">
          {{ 'netops.firmware.locationsUpgradeRequested' | translate }}: {{ batchSize }}
          <icon
            name="icon-copy"
            [class.disabled]="!locations.length"
            tooltip="{{ 'netops.firmware.copy' | translate }}"
            (click)="copy(locations, 'locationsUpgradeRequested')"
          ></icon>
        </div>
      </div>
      <div class="locations">
        <ng-container *ngIf="loadingLocations">
          <div class="loading-text">
            {{ 'netops.firmware.loading' | translate }}
          </div>
        </ng-container>
        <ng-container *ngIf="!loadingLocations">
          <ng-container *ngIf="locations.length">
            <input-tokenizer
              [readOnly]="true"
              [watch]="false"
              [maxTokens]="10"
              [watchTokens]="locations.length === 1 ? locations[0] + ',' : locations.join(',')"
            ></input-tokenizer>
          </ng-container>
          <ng-container *ngIf="!locations.length">
            <div class="no-locations">
              {{ 'netops.firmware.noLocationsFound' | translate }}
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<modal mode="wide" class="version-select" (overlayAction)="showMatrixSelect = $event" *ngIf="showMatrixSelect">
  <ng-container title>{{ 'netops.firmware.lookupFirmware' | translate }}</ng-container>
  <ng-container content *ngIf="matrices.length === 0 && search.value?.length < 0">
    {{ 'configurations.firmware.noApplicableFirmware' | translate }}
  </ng-container>
  <ng-container content *ngIf="matrices.length > 0 || search.value?.length > 0">
    <div class="top">
      <div class="left">
        <slidetoggler [options]="downgradeItems" (toggle)="toggleDowngrade($event)"></slidetoggler>
      </div>
      <div class="right">
        <div class="actions">
          <button class="action medium" (click)="clear()">
            {{ 'configurations.firmware.clearTarget' | translate }}
          </button>
          <button class="action medium" (click)="disable()">
            {{ 'configurations.firmware.disableActiveMatrix' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="sections">
      <div class="section">
        <div class="search title">
          <span>
            {{ 'configurations.firmware.searchVersions' | translate }}
          </span>
          <span>
            {{ 'configurations.firmware.includeModelVersion' | translate }}
            <div class="toggler" (click)="filterIncludeModelsToggle()">
              <div class="checkbox">
                <label class="toggle" [class.checked]="filterIncludeModels"></label>
              </div>
            </div>
          </span>
        </div>
        <div class="search">
          <input
            type="text"
            placeholder="{{ 'netops.firmware.versionsPlaceholder' | translate }}"
            (input)="filterSearch()"
            [formControl]="search"
          />
        </div>
        <div class="list">
          <div
            class="version"
            [class.selected]="selectedVersion && version.versionMatrix === selectedVersion.versionMatrix"
            *ngFor="let version of matrices"
            (mouseenter)="versionDetails = version"
            (mouseleave)="versionDetails = selectedVersion ? selectedVersion : null"
            (click)="versionDetails = version; selectedVersion = version"
          >
            {{ version.versionMatrix }}
          </div>
        </div>
      </div>
      <div class="section">
        <div class="list" *ngIf="versionDetails">
          <div class="row date">
            <div class="key">{{ 'netops.firmware.createdAt' | translate }}</div>
            <div class="value">{{ versionDetails.createdAt | date: 'LLL' }}</div>
          </div>

          <div class="row date" *ngIf="versionDetails.versionMatrixDescription">
            <div class="key">{{ 'netops.firmware.description' | translate }}</div>
            <div class="value">{{ versionDetails.versionMatrixDescription }}</div>
          </div>

          <div class="model" *ngFor="let model of versionDetails.models">
            <div class="row">
              <div class="key">{{ 'netops.firmware.model' | translate }}</div>
              <div class="value">{{ model.model }}</div>
            </div>
            <div class="row">
              <div class="key">{{ 'netops.firmware.version' | translate }}</div>
              <div class="value">{{ model.firmwareVersion }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <div class="desktop">
      <button class="tertiary light" (click)="showMatrixSelect = false; selectedVersion = null">
        {{ 'netops.firmware.close' | translate }}
      </button>
      <div class="update" *ngIf="selectedVersion">
        <slidetoggler [options]="asapItems" (toggle)="toggleAsap($event)"></slidetoggler>
        <button class="super-primary" (click)="selectVersionMatix()">
          {{ 'netops.firmware.select' | translate }}
        </button>
      </div>
    </div>
    <button class="super-primary mobile" (click)="selectVersionMatix()" *ngIf="selectedVersion">
      {{ 'netops.firmware.select' | translate }}
    </button>
  </ng-container>
</modal>
