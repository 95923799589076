import { scaleLinear, scaleTime, max } from 'd3';
import { Line } from '../objects/line';
import { Tick } from '../objects/tick';
import { Point } from '../objects/point';
import { Dot } from '../objects/dot';
import * as moment from 'moment';

export class QoethroughputChart {
  private data: any[];
  private lines: Line[];
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private period: number;
  private margins: any = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepareScales(): void {
    this.xScale = scaleTime().range([0, this.calculateWidth()]);
    this.yScale = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareDomains(): void {
    const xdomains = [];
    const ydomains = [];

    this.data.forEach((line: any) => {
      xdomains.push(...line.data.map((d: any) => new Date(d.label)));
      ydomains.push(...line.data.map((d: any) => d.value));
    });

    const round = (subtract: number, moment: any) => {
      const roundedMinutes = Math.floor(moment.minute() / 15) * 15;
      return [
        moment.clone().minute(roundedMinutes).second(0).subtract(subtract),
        moment.clone().minute(roundedMinutes).second(0)
      ];
    };

    if (this.period === 1) {
      this.xScale.domain(round(24 * 60 * 60 * 1000 - 15 * 60 * 1000, moment()));
    } else {
      this.xScale.domain(round(7 * 24 * 60 * 60 * 1000, moment()));
    }

    const upper = max(ydomains);
    this.yScale.domain([0, upper + upper / 5]);
  }

  calculateLines(): void {
    let offset = [0];

    if (this.data.length === 2) {
      offset = [-1, 1];
    }
    if (this.data.length === 3) {
      offset = [-3, 0, 3];
    }

    this.lines = this.data.map((line: any, i: number) => {
      let buffer = '';

      line.dots = [];
      line.paths = [];
      line.fills = [];

      let firstX = 0;
      let lastX = 0;

      line.data.forEach((point: Point) => {
        if (point.value !== null) {
          const x = this.xScale(this.roundTime(new Date(point.label))) || 0;
          const y = this.yScale(point.value) || 0;

          line.dots.push(new Dot(line.series, 3, x, y + offset[i], false, point.label));

          if (buffer.length) {
            lastX = x;
            buffer += 'L' + x + ' ' + (y + offset[i] + ' ');
          } else {
            firstX = x;
            lastX = x;
            buffer = 'M' + x + ' ' + (y + offset[i] + ' ');
          }
        } else {
          if (buffer.length) {
            line.paths.push(buffer);
            line.fills.push(
              buffer + 'L' + lastX + ' ' + this.calculateHeight() + ' L' + firstX + ' ' + this.calculateHeight() + ' Z'
            );
          }
          buffer = '';
          firstX = 0;
          lastX = 0;
        }
      });

      if (buffer.length) {
        line.paths.push(buffer);
        line.fills.push(
          buffer + 'L' + lastX + ' ' + this.calculateHeight() + ' L' + firstX + ' ' + this.calculateHeight() + ' Z'
        );
      }

      return line;
    });
  }

  xAxis(): any[] {
    return this.xScale.ticks(7).map((tick: any) => {
      return new Tick(
        'xaxis',
        this.timeExtractor(tick),
        'translate(' + this.xScale(tick) + ', ' + (this.calculateHeight() + 20) + ')',
        { x: 0, dy: 0.32 },
        true,
        { y1: -20, y2: -this.calculateHeight() - 30 }
      );
    });
  }

  yAxis(): any[] {
    const converter = (value: number) => {
      if (value >= 1000) {
        return value / 1000 + ' Gbps';
      } else {
        return value + ' Mbps';
      }
    };

    return this.yScale.ticks(5).map((tick: any) => {
      return new Tick('yaxis', converter(tick), 'translate(0, ' + this.yScale(tick) + ')', { dx: -5, dy: 2 }, true, {
        x2: this.calculateWidth()
      });
    });
  }

  update(data: any[], width?: number, height?: number, margins?: any, period?: number): Line[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }
    if (period) {
      this.period = period;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateLines();

    return this.lines;
  }

  timeExtractor(time: any): string {
    if (this.period === 1) {
      return new Date(time).toLocaleTimeString(moment.locale(), {
        hour: '2-digit',
        minute: '2-digit'
      });
    }

    if (this.period === 7) {
      return new Date(time).toLocaleString(moment.locale(), {
        day: '2-digit',
        month: 'short'
      });
    }
  }

  roundTime(time: Date): Date {
    const ms = 60 * 1000;
    const round = Math.floor(time.getTime() / ms) * ms;

    return new Date(round);
  }

  scaleValue(axis: string, value: any): any {
    if (axis === 'x') {
      return this.xScale(value);
    } else {
      return this.yScale(value);
    }
  }
}
