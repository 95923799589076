import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import {
  IAppPrioritization,
  IAppPrioritizationMonitoring,
  IAppPrioritizationV2,
  IDevice,
  ITrafficClassStatsTimeBased,
  ITrafficClassStatsTotal,
  ITrafficConfiguration,
  ITrafficLegend,
  ITrafficMonitoring
} from '../interfaces/interface';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';

@Injectable({ providedIn: 'root' })
export class TrafficService {
  legend: ITrafficLegend[] = [
    { id: 'av_streaming', alternativeId: 'stream', order: 1, label: 'traffic.video', color: '#F78673' },
    { id: 'voice', order: 2, label: 'traffic.voice', color: '#656CFF' },
    { id: 'gaming', alternativeId: 'game', order: 3, label: 'traffic.gaming', color: '#FC5FA3' },
    { id: 'video_conferencing', alternativeId: 'video', order: 4, label: 'traffic.videoConference', color: '#00BEDB' },
    { id: 'other', order: 5, label: 'traffic.nonRealTime', color: '#FFE280' }
  ];

  avgLegend: ITrafficLegend[] = [
    { id: 'good', order: 1, label: 'traffic.good', color: '#1AE3AE' },
    { id: 'moderate', order: 2, label: 'traffic.moderate', color: '#FFC500' },
    { id: 'bad', order: 3, label: 'traffic.bad', color: '#FC5FA3' }
  ];

  constructor(private api: ApiService, private customer: CustomerService, private store: Store) {}

  get$(): Observable<IAppPrioritization> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/qos/appPrioritization`))
    );
  }

  set$(appPrioritization: IAppPrioritization): Observable<IAppPrioritization> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/qos/appPrioritization`, appPrioritization))
    );
  }

  delete$(): Observable<IAppPrioritization> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/qos/appPrioritization/customSetting`))
    );
  }

  getV2$(): Observable<IAppPrioritizationV2> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/qos/prioritization`))
    );
  }

  setV2$(appPrioritization: IAppPrioritizationV2): Observable<IAppPrioritizationV2> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/qos/prioritization`, appPrioritization))
    );
  }

  deleteV2$(): Observable<IAppPrioritizationV2> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.put(`${baseUrl}/qos/prioritization`, {
          category: null,
          personIds: [],
          deviceIds: []
        })
      )
    );
  }

  monitoring$(monitoring: IAppPrioritizationMonitoring): Observable<IAppPrioritizationMonitoring> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/qos/appPrioritization/monitoring`, monitoring))
    );
  }

  toggle$(enabled: boolean, mode: 'auto' | 'enable' | 'disable' = null): Observable<IAppPrioritization> {
    if (mode) {
      return this.customer
        .setAppTime$({ enable: true, appliesToAllDevices: true })
        .pipe(switchMap(() => this.set$({ mode })));
    } else {
      if (enabled) {
        return this.customer
          .setAppTime$({ enable: true, appliesToAllDevices: true })
          .pipe(switchMap(() => this.set$({ enabled })));
      } else {
        return this.set$({ enabled });
      }
    }
  }

  toggleV2$(mode: 'auto' | 'enable' | 'disable' = null): Observable<IAppPrioritizationV2> {
    return this.setV2$({ mode });
  }

  trafficClassStats$(
    granularity: 'total',
    startTime: string,
    endTime: string,
    macs?: string[],
    itemCount?: number
  ): Observable<ITrafficClassStatsTotal>;
  trafficClassStats$(
    granularity: '1 minute' | '15 minutes' | '1 hour' | '1 day',
    startTime: string,
    endTime: string,
    macs?: string[],
    itemCount?: number
  ): Observable<ITrafficClassStatsTimeBased>;
  trafficClassStats$(
    granularity: '1 minute' | '15 minutes' | '1 hour' | '1 day' | 'total',
    startTime: string,
    endTime: string,
    macs?: string[],
    itemCount?: number
  ): Observable<ITrafficClassStatsTotal | ITrafficClassStatsTimeBased> {
    const params = {
      granularity,
      startTime,
      endTime,
      macs
    };
    const queryParams = Object.entries(params)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/appqoe/traffic_class_stats?${queryParams}`)),
      map((data: ITrafficClassStatsTotal | ITrafficClassStatsTimeBased) =>
        this.prepareTrafficClasses(data as any, data.startTime, itemCount, granularity)
      )
    );
  }

  generateTrafficConfiguration(
    monitoring: IAppPrioritizationMonitoring,
    devices: IDevice[],
    mode: string
  ): ITrafficConfiguration {
    if (monitoring.devices) {
      const max = Math.max(...monitoring.devices.map((device) => device.total[mode][0]));
      const totalClasses = {};

      const top5devices = monitoring.devices
        .map((monitoringDevice) => {
          const name = devices.find((device) => device.mac === monitoringDevice.mac)?.name || monitoringDevice.mac;
          const total = monitoringDevice.total[mode][0];
          const classes = {};

          monitoringDevice.trafficClasses.forEach((trafficClass) => {
            const other = this.legend.find((legend) => legend.id === 'other');
            const legend =
              this.legend.find(
                (legend) =>
                  legend.id === trafficClass.name.trafficClass ||
                  legend.alternativeId === trafficClass.name.trafficClass
              ) || other;
            const bytes = trafficClass[mode][0];

            if (classes[legend.id]) {
              classes[legend.id].bytes += bytes;
            } else {
              classes[legend.id] = {
                ...legend,
                bytes
              };
            }

            if (totalClasses[legend.id]) {
              totalClasses[legend.id].bytes += bytes;
            } else {
              totalClasses[legend.id] = {
                ...legend,
                bytes
              };
            }
          });

          return {
            name,
            total,
            classes: Object.keys(classes)
              .map((key) => {
                return {
                  ...classes[key],
                  percent: (classes[key].bytes * 100) / max
                };
              })
              .sort((a, b) => (a.order < b.order ? 1 : -1))
          };
        })
        .sort((a, b) => (a.total < b.total ? 1 : -1))
        .slice(0, 5);

      const overview = {
        total: monitoring.devices
          .map((device) => device.total[mode][0])
          .reduce((partial, current) => partial + current, 0),
        classes: Object.keys(totalClasses)
          .map((key) => totalClasses[key])
          .sort((a, b) => (a.order > b.order ? 1 : -1))
      };

      return {
        top5devices,
        overview
      };
    } else {
      return null;
    }
  }

  generateTrafficMonitoring(
    monitoring: IAppPrioritizationMonitoring,
    devices: IDevice[],
    mode: string,
    interval: number
  ): ITrafficMonitoring[] {
    if (monitoring.devices && monitoring.startTime) {
      const allDevicesTotalBytes = monitoring.devices.reduce(
        (total, device) => total + device.total[mode].reduce((total, bytes) => total + bytes, 0),
        0
      );

      return devices.map((device) => {
        const monitoringDevice =
          monitoring.devices.find((monitoringDevice) => device.mac === monitoringDevice.mac) || null;
        const classes = {};
        const chart = {};

        if (monitoringDevice) {
          monitoringDevice.trafficClasses.forEach((trafficClass) => {
            const other = this.legend.find((legend) => legend.id === 'other');
            const legend =
              this.legend.find(
                (legend) =>
                  legend.id === trafficClass.name.trafficClass ||
                  legend.alternativeId === trafficClass.name.trafficClass
              ) || other;
            const bytes = trafficClass[mode].reduce((total, current) => total + current, 0);

            if (classes[legend.id]) {
              classes[legend.id].bytes += bytes;
            } else {
              classes[legend.id] = {
                ...legend,
                bytes
              };
            }

            if (chart[legend.id]) {
              chart[legend.id].bytes = chart[legend.id].bytes.map((bytes, i) => bytes + trafficClass[mode][i]);
            } else {
              let timestamps = [];

              if (monitoring.dateTimePeriods?.length) {
                timestamps = [...monitoring.dateTimePeriods];
              } else {
                timestamps = trafficClass[mode].map(
                  (bytes, index) => new Date(monitoring.startTime).getTime() + interval * index
                );
              }

              chart[legend.id] = {
                ...legend,
                timestamps,
                bytes: trafficClass[mode],
                total: monitoringDevice.total[mode]
              };
            }
          });
        }

        const totalUsage = monitoringDevice?.total[mode].reduce((total, bytes) => total + bytes, 0) || 0;

        return {
          mac: device.mac.toUpperCase(),
          name: device.name || device.mac.toUpperCase(),
          icon: device.iconV2 || 'unknown',
          iconV3: device.iconV3,
          connectionState: device.connectionState || 'disconnected',
          expanded: false,
          totalUsage: Math.round(((totalUsage * 100) / allDevicesTotalBytes + Number.EPSILON) * 100) / 100 || 0,
          details: classes,
          chartData: Object.keys(chart).map((key) => chart[key])
        };
      });
    } else {
      return null;
    }
  }

  private prepareTrafficClasses(
    data: ITrafficClassStatsTimeBased,
    startTime: string,
    itemCount: number,
    granularity: string
  ): ITrafficClassStatsTimeBased {
    const dateArray = [];
    const nullArray = [];

    const unit = itemCount === 30 || itemCount === 7 ? 'day' : itemCount === 24 ? 'hour' : 'minute';
    const interval =
      granularity === '1 day'
        ? 24 * 60 * 60 * 1000
        : granularity === '1 hour'
        ? 60 * 60 * 1000
        : granularity === '15 minutes'
        ? 15 * 60 * 1000
        : 60 * 1000;

    for (let i = 0; i < itemCount; i++) {
      const substract = granularity === '15 minutes' ? moment().minutes() % 15 : 0;

      const date = moment()
        .utc()
        .startOf(unit)
        .subtract(substract, 'minutes')
        .subtract(i * interval, 'milliseconds')
        .toISOString();

      dateArray.push(date);
      nullArray.push(null);
    }

    const sampleCount =
      data.devices.length && data.devices[0].trafficClasses.length
        ? data.devices[0].trafficClasses[0].stats.sampleCount.length
        : 0;
    const front =
      dateArray.sort().findIndex((date) => {
        return date.substring(0, 16) === startTime.substring(0, 16);
      }) || 0;

    const missingBefore = nullArray.slice(0, front);
    const missingAfter = nullArray.slice(front + sampleCount, itemCount);

    return {
      ...data,
      startTime: dateArray[0],
      devices: data.devices.map((device) => ({
        ...device,
        trafficClasses: this.collapseUnknownTrafficClasses(device.trafficClasses, missingBefore, missingAfter)
      }))
    };
  }

  collapseUnknownTrafficClasses(
    data: { name: string; apps: any[]; stats: { [key: string]: number[] } }[],
    missingBefore: null[],
    missingAfter: null[]
  ): {
    name: string;
    apps: any[];
    stats: ITrafficClassStatsTimeBased['devices'][0]['trafficClasses'][0]['stats'];
  }[] {
    const total = data.reduce((acc, item) => {
      const knownClass = !!this.legend.find((legend) => legend.id === item.name || legend.alternativeId === item.name);
      if (!knownClass) {
        return acc + (item.stats.sampleCount[0] - item.stats.unrecognized[0]);
      } else {
        return acc;
      }
    }, 0);

    const score = data.reduce((acc, item) => {
      const knownClass = !!this.legend.find((legend) => legend.id === item.name || legend.alternativeId === item.name);

      if (!knownClass) {
        return acc + item.stats.score[0] * ((item.stats.sampleCount[0] - item.stats.unrecognized[0]) / total || 0);
      } else {
        return acc;
      }
    }, 0);

    const collapsed = data.reduce(
      (acc, item) => {
        const knownClass = !!this.legend.find(
          (legend) => legend.id === item.name || legend.alternativeId === item.name
        );

        if (!knownClass) {
          Object.keys(item.stats).forEach((key) => {
            acc.other[key] = this.mixStatsArrays(acc.other[key] || [], item.stats[key], key, acc.unknownClassesCount);
          });
        }

        return {
          classes: knownClass ? [...acc.classes, item] : acc.classes,
          other: acc.other,
          unknownClassesCount: knownClass ? acc.unknownClassesCount : acc.unknownClassesCount + 1
        };
      },
      {
        classes: [],
        other: {},
        unknownClassesCount: 0
      }
    );

    if (collapsed.other['score'] && collapsed.other['score'].length === 1) {
      collapsed.other['score'][0] = score;
    }

    const classes = [...collapsed.classes, { name: 'other', apps: [], stats: collapsed.other }];

    classes.forEach((trClass) => {
      trClass.stats.sampleCount = this.removeStatsUnrecognized(trClass.stats.sampleCount, trClass.stats.unrecognized);
    });

    classes.forEach((c) => {
      Object.keys(c.stats).forEach((key) => {
        c.stats[key] = [...missingBefore, ...c.stats[key], ...missingAfter];
      });
    });

    return classes;
  }

  granularity(time: '15m' | '24h' | '7d' | '30d'): '1 minute' | '15 minutes' | '1 hour' | '1 day' {
    switch (time) {
      case '15m':
        return '1 minute';
      case '24h':
        return '1 hour';
      case '7d':
        return '1 day';
      case '30d':
        return '1 day';
    }
  }

  xAxisItemCount(time: '15m' | '24h' | '7d' | '30d'): number {
    switch (time) {
      case '15m':
        return 15;
      case '24h':
        return 24;
      case '7d':
        return 7;
      case '30d':
        return 30;
    }
  }

  private mixStatsArrays(a1: number[], a2: number[], property: string, previousItemsCount?: number): number[] {
    const result = [];
    for (let i = 0; i < Math.max(a1.length, a2.length); i++) {
      let val1 = a1[i] ?? 0;
      let val2 = a2[i] ?? 0;
      switch (property) {
        case 'bad':
        case 'good':
        case 'inNum':
        case 'outNum':
        case 'sampleCount':
        case 'unrecognized':
        case 'unstable':
          result[i] = val1 + val2;
          break;
        case 'outMin':
        case 'latencyMin':
        case 'inMin':
          val1 = a1[i] ?? Number.MAX_SAFE_INTEGER;
          val2 = a2[i] ?? Number.MAX_SAFE_INTEGER;
          result[i] = Math.min(val1, val2);
          break;
        case 'outMax':
        case 'latencyMax':
        case 'inMax':
          result[i] = Math.max(val1, val2);
          break;
        case 'score':
        case 'inAvg':
        case 'outAvg':
        case 'latencyAvg':
          result[i] = (val1 * (previousItemsCount + 1) + val2) / (previousItemsCount + 2);
          break;
      }
    }
    return result;
  }

  private removeStatsUnrecognized(sampleCount: number[], unrecognized: number[]): number[] {
    return sampleCount?.map((item, index) => item - (unrecognized[index] || 0)) ?? [0];
  }
}
