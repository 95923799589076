import { HierarchyNode, Link, TreeLayout, hierarchy, linkHorizontal, tree } from 'd3';

export interface TreeData {
  id?: string;
  name: string;
  icon: string;
  type: string;
  health?: string;
  usage?: string;
  traffic?: number;
  children?: TreeData[];
}

export interface HierarchyData {
  id?: string;
  children?: HierarchyData[];
  _children?: HierarchyData[];
  data?: TreeData;
  depth?: number;
  width?: number;
  height?: number;
  textWidth?: number;
  parent?: null | HierarchyData;
  traffic?: number;
  usage?: string;
  x?: number;
  x0?: number;
  y?: number;
  y0?: number;
}

export class TreeChart {
  nodeWidth: number = 150;
  dx: number = 110;
  dy: number = this.nodeWidth * 2;
  tree: TreeLayout<HierarchyData> = null;
  diagonal: Link<any, any, [number, number]> = null;
  hierarchy: HierarchyNode<HierarchyData> = null;
  maxTraffic: number = 0;

  margins: { top: number; left: number; right: number; bottom: number } = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  };

  data: TreeData;
  closed: string[] = [];

  constructor() {
    this.margins.top = 20 + this.dx / 2;
    this.margins.left = 20 + this.nodeWidth / 2;
    this.margins.right = 50 + this.nodeWidth / 2;
    this.margins.bottom = 20 + this.dx / 2;

    this.tree = tree<HierarchyData>().nodeSize([this.dx, this.dy]);
    this.diagonal = linkHorizontal()
      .x((d: any) => d.y)
      .y((d: any) => d.x);
  }

  init(data: TreeData): void {
    this.data = data;
  }

  update(): { width: number; height: number; viewbox: number[] } {
    this.hierarchy = hierarchy<TreeData>(this.sort(this.data));

    this.hierarchy.each((node: any) => {
      node.id = node.data.id;
      node._children = node.children ? node.children : null;
      node.expandable = node._children ? true : false;
      node.width = this.nodeWidth;
      node.textWidth = this.textSize(node.data.name, 12, 'Open Sans');

      if (this.closed.includes(node.data.id)) {
        node.children = null;
      }
    });

    this.tree(this.hierarchy);

    let top = 0;
    let left = 0;
    let right = 0;

    this.hierarchy.each((node: any) => {
      if (node.x < left) {
        left = node.x;
      }

      if (node.x > right) {
        right = node.x;
      }

      if (node.y > top) {
        top = node.y;
      }

      node.usage = node.data.usage;
      node.traffic = node.data.traffic;
      node.children = node.children ? node.children : null;

      if (node.data.traffic > this.maxTraffic) {
        this.maxTraffic = node.data.traffic;
      }
    });

    const width = top + this.margins.left + this.margins.right;
    const height = right - left + this.margins.top + this.margins.bottom;
    const viewbox = [-this.margins.left, left - this.margins.top, width, height];

    return { width, height, viewbox };
  }

  getNodes(): HierarchyData[] {
    return this.hierarchy.descendants().map((node: any) => {
      return { ...node };
    });
  }

  getLinks(): {
    id: string;
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    d: string;
    active: boolean;
    strokeWidth: number;
  }[] {
    return this.hierarchy
      .links()
      .map((link: { source: any; target: any }) => {
        const s = {
          x: link.source.x,
          y: link.source.y + this.nodeWidth / 2
        };

        const t = {
          x: link.target.x,
          y: link.target.y - this.nodeWidth / 2
        };

        const offset = link.target._children?.length ? this.nodeWidth : this.nodeWidth / 8;

        return {
          id: link.source.id + '-' + link.target.id,
          x1: link.source.y + this.nodeWidth / 2,
          x2: link.target.y - this.nodeWidth / 2,
          y1: link.source.x,
          y2: link.target.x,
          d: this.diagonal({ source: s, target: t }) + `L${t.y + offset},${t.x}`,
          active: link.target.traffic ? true : false,
          strokeWidth: (link.target.traffic * 20) / this.maxTraffic + 5
        };
      })
      .sort((a, b) => (b.active ? -1 : 1));
  }

  toggle(id: string): void {
    if (this.closed.includes(id)) {
      this.closed = this.closed.filter((i) => i !== id);
    } else {
      this.closed = [...this.closed, id];
    }
  }

  textSize(text: string, fontSize: number, fontFamily: string): number {
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.font = `400 ${fontSize}px "${fontFamily}"`;
    return ctx.measureText(text).width;
  }

  sort(data: TreeData): TreeData {
    if (data.children) {
      data.children.sort((a, b) => b.type.localeCompare(a.type) || a.name.localeCompare(b.name));

      data.children.forEach((node) => {
        this.sort(node);
      });
    }

    return data;
  }
}
