import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import { ILocationIpv6 } from '../interfaces/interface';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class IPv6Service {
  constructor(private api: ApiService, private store: Store) {}

  setMode$(mode: 'auto' | 'enable' | 'disable'): Observable<ILocationIpv6> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/ipv6`, { mode }))
    );
  }

  setAddressingConfig$(
    addressingConfig: 'auto' | 'slaac' | 'statelessDhcpv6' | 'statefulDhcpv6' | 'all'
  ): Observable<ILocationIpv6> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/ipv6`, { addressingConfig }))
    );
  }

  setPartialState$(state: Partial<ILocationIpv6>): Observable<ILocationIpv6> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/ipv6`, state))
    );
  }
}
