import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, interval, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { DeviceService } from 'src/app/lib/services/device.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { environment } from 'src/environments/environment';
import {
  customerChange,
  customerLocationConfigAndStateReload,
  updateDevicesList,
  updateNodesList
} from '../customer/customer.actions';
import { selectCustomerIdentification } from '../customer/customer.selectors';
import {
  alarmReportsLoaded,
  alarmReportsLoadedError,
  blankAction,
  broadbandEfficiencyAlertLoaded,
  broadbandEfficiencyAlertLoadedError,
  devicesLoaded,
  devicesLoadedError,
  internetLoaded,
  internetLoadedError,
  locationQoeLoaded,
  locationQoeLoadedError,
  nodesLoaded,
  nodesLoadedError,
  pollingIntervalPull,
  pollingPull,
  powerManagementChanged,
  powerManagementLoaded,
  stabilityLoaded,
  stabilityLoadedError,
  topologyChanged,
  topologyChangedError,
} from './polling.actions';
import { selectLocationTopology } from './polling.selector';

@Injectable()
export class PollingEffects {

  constructor(
    private actions$: Actions,
    private nodeService: NodeService,
    private deviceService: DeviceService,
    private qoeService: QoeService,
    private store: Store,
    private customer: CustomerService,
    private plume: PlumeService
  ) {}
  nodesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollingPull, pollingIntervalPull, customerChange, updateNodesList),
      switchMap((action) => {
        if (
          this.plume.pausedPolling ||
          (action.type === '[Polling] Polling interval pull' && this.plume.disableNodePolling)
        ) {
          return of(blankAction());
        } else {
          return this.nodeService.getAll$().pipe(
            map(({ nodes }) => {
              return nodesLoaded({ nodes });
            }),
            catchError((error) => {
              return of(nodesLoadedError({ error }));
            })
          );
        }
      })
    )
  );

  devicesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollingPull, pollingIntervalPull, customerChange, updateDevicesList),
      switchMap(() =>
        this.deviceService.getAll$().pipe(
          map((devices) => {
            return devicesLoaded({ devices });
          }),
          catchError((error) => {
            return of(devicesLoadedError({ error }));
          })
        )
      )
    )
  );

  qoeLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollingPull, pollingIntervalPull, customerChange),
      switchMap(() =>
        this.qoeService.locationQoe$().pipe(
          map((qoe) => {
            return locationQoeLoaded({ qoe });
          }),
          catchError((error) => {
            return of(locationQoeLoadedError({ error }));
          })
        )
      )
    )
  );

  bbeLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollingPull, pollingIntervalPull, customerChange),
      filter(() => !this.plume.isFlexRole()),
      switchMap(() =>
        this.customer.broadbandEfficiencyAlert$().pipe(
          map((bbe) => {
            return broadbandEfficiencyAlertLoaded({ bbe });
          }),
          catchError((error) => {
            return of(broadbandEfficiencyAlertLoadedError({ error }));
          })
        )
      )
    )
  );

  stabilityLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollingPull, pollingIntervalPull, customerChange),
      filter(() => !this.plume.isFlexRole()),
      switchMap(() =>
        this.customer.stability$().pipe(
          map((stability) => {
            return stabilityLoaded({ stability });
          }),
          catchError((error) => {
            return of(stabilityLoadedError({ error }));
          })
        )
      )
    )
  );

  alarmReportsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollingPull, pollingIntervalPull, customerChange),
      filter(() => !this.plume.isFlexRole()),
      switchMap(() =>
        this.customer.alarmReports$().pipe(
          map((alarmReports) => {
            return alarmReportsLoaded({ alarmReports });
          }),
          catchError((error) => {
            return of(alarmReportsLoadedError({ error }));
          })
        )
      )
    )
  );

  internetLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollingPull, pollingIntervalPull, customerChange),
      switchMap(() =>
        this.customer.internet$().pipe(
          map((internet) => {
            return internetLoaded({ internet });
          }),
          catchError((error) => {
            return of(internetLoadedError({ error }));
          })
        )
      )
    )
  );

  interval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollingPull, pollingIntervalPull, customerChange),
      map(() => {
        return (
          (environment.environments.find((env) => env.id === this.plume.getEnv().id && env.pollingInterval)
            ?.pollingInterval ?? 30) * 1000
        );
      }),
      switchMap((pollingInterval) => interval(pollingInterval).pipe(take(1))),
      concatLatestFrom(() => this.store.select(selectCustomerIdentification)),
      filter(([, customer]) => !!customer.customerid && !!customer.locationid),
      map(() => pollingIntervalPull())
    )
  );

  checkTopologyChangesOnNodeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nodesLoaded),
      switchMap((nodes) => combineLatest([of(nodes), this.store.select(selectLocationTopology).pipe(take(1))])),
      map(([{ nodes }, topology]) => {
        if (topology?.vertices.filter((v) => v.type === 'pod').length !== nodes.length) {
          return true;
        }

        return !nodes.every((newNode) =>
          topology?.vertices.find(
            (oldNode) => oldNode.id === newNode.id && oldNode.connectionState === newNode.connectionState
          )
        );
      }),
      filter((nodesChanged) => nodesChanged),
      switchMap(() =>
        this.customer.topology$().pipe(
          map((topology) => {
            return topologyChanged({ topology });
          }),
          catchError((error) => {
            return of(topologyChangedError({ error }));
          })
        )
      )
    )
  );

  powerManagementChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(powerManagementChanged),
      tap((state) => {
        if (state) {
          this.store.dispatch(
            customerLocationConfigAndStateReload({ actionType: '[Polling] Power management changed' })
          );
        }
      }),
      map((powerManagement) => powerManagementLoaded(powerManagement))
    )
  );

}
