<spacer>{{ 'netops.upgradeReports' | translate }}</spacer>
<div class="container-fluid">
  <div class="title">{{ 'netops.reports.title' | translate }}</div>

  <div class="info controls">
    <div class="row">
      <div class="col-xs-3 key">{{ 'netops.reports.excludeOnboarding' | translate }}:</div>
      <div class="col-xs-1 value">
        <div class="toggler" (click)="updateExcludeOnboarding()">
          <div class="checkbox"><label class="toggle" [class.checked]="excludeOnboarding"></label></div>
        </div>
      </div>

      <div class="col-xs-2 key">{{ 'netops.reports.batchJobs' | translate }}:</div>
      <div class="col-xs-1 value">
        <icon name="icon-swap" (click)="getReports()"></icon>
      </div>
    </div>
  </div>

  <div class="reports">
    <ng-container *ngIf="!reports.length">
      <div class="loading-text">
        {{ 'netops.reports.loading' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="reports.length">
      <div class="top-text">
        {{ 'netops.reports.records' | translate }} {{ reportsOffset }} - {{ reportsOffset + 500 }} of {{ reportsCount }}
      </div>
      <reportsBubble
        [loadingReports]="loadingReports"
        [reports]="reports"
        (selectBatchID)="selectBatchID($event)"
      ></reportsBubble>

      <div class="control-arrows">
        <button
          class="action medium"
          [class.disabled]="loadingReports"
          (click)="!loadingReports && getReports('down')"
          *ngIf="this.reportsOffset"
        >
          <icon name="icon-arrow-left"></icon>
        </button>
        <button class="action medium" [class.disabled]="loadingReports" (click)="!loadingReports && getReports('up')">
          <icon name="icon-arrow-left" class="rotate180"></icon>
        </button>
      </div>

      <div class="info search-panel">
        <span class="title no-margin">{{ 'netops.reports.search' | translate }}</span>
        <div class="row">
          <div class="col-xs-2 key top">{{ 'netops.reports.searchByID' | translate }}:</div>
          <div class="col-xs-3">
            <div class="pill-hollow">
              <input [formControl]="searchByIDFormControl" type="text" />
            </div>
          </div>
          <div class="col-xs-2">
            <button class="action medium" (click)="searchByID()">{{ 'netops.reports.search' | translate }}</button>
          </div>
        </div>

        <div class="col-xs-12" *ngIf="searchResults">
          <reportsBubble
            [reports]="[searchResults]"
            [noFilters]="true"
            (selectBatchID)="selectBatchID($event)"
          ></reportsBubble>
        </div>
      </div>

      <div class="info">
        <span class="title no-margin">{{ 'netops.reports.upgradeReport' | translate }}</span>

        <div class="row">
          <div class="col-xs-2 key top">{{ 'netops.reports.startDate' | translate }}:</div>
          <div class="col-xs-3">
            <calendar [startDate]="startTime" (event)="startTime = $event"></calendar>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-2 key top">{{ 'netops.reports.endDate' | translate }}:</div>
          <div class="col-xs-3">
            <calendar [startDate]="endTime" (event)="endTime = $event"></calendar>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-2 key top">{{ 'netops.reports.batchID' | translate }}:</div>
          <div class="col-xs-3">
            <div class="pill-hollow">
              <input [formControl]="editBatchIDsFormControl" type="text" />
            </div>
          </div>
        </div>

        <div class="row" *ngIf="selectedReport.batchSize">
          <div class="col-xs-2 key top">{{ 'netops.reports.rawJSON' | translate }}:</div>
          <div class="col-xs-2">
            <div class="data-wrap">
              <div class="selector" [class.collapsed]="!dataExpand" (click)="dataExpand = !dataExpand">
                {{ 'netops.reports.data' | translate }}
                <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="dataExpand"></icon>
              </div>
              <pre class="raw-json" *ngIf="dataExpand">
                <ngx-json-viewer [expanded]="false" [json]="selectedReport"></ngx-json-viewer>
              </pre>
            </div>
          </div>
        </div>

        <div class="row multi">
          <button
            class="action medium"
            (click)="requestReport()"
            [class.disabled]="!editBatchIDsFormControl.value || showLoading"
          >
            {{ 'netops.reports.requestReport' | translate }}
          </button>

          <button
            class="action medium"
            (click)="approveUpgrade()"
            *ngIf="selectedReport.id && !selectedReport.userIdOfApprover"
          >
            {{ 'netops.reports.approveUpgrade' | translate }}
          </button>

          <button
            class="action medium"
            (click)="requestClear()"
            *ngIf="selectedReport.id && selectedReport.userIdOfApprover"
          >
            {{ 'netops.reports.requestClear' | translate }}
          </button>

          <button class="action medium" (click)="downloadCSV()" *ngIf="selectedReport.id">
            {{ 'netops.reports.downloadLocations' | translate }}
          </button>
        </div>

        <div class="loading-text" *ngIf="showLoading">
          {{ 'netops.reports.loading' | translate }}
        </div>

        <div class="row upgrade-results" *ngIf="upgradeReportResults && !showLoading">
          <div class="col-xs-12">
            <span class="title">{{ 'netops.reports.upgradeResults' | translate }}</span>
          </div>

          <ng-container *ngIf="upgradeReportResults.scheduled && upgradeReportResults.scheduled.locationIds.length">
            <div class="col-xs-12">
              {{ 'netops.reports.scheduled' | translate }} ({{ upgradeReportResults.scheduled.count }})
            </div>
            <div class="col-xs-12">
              <p class="result-location">
                <span *ngFor="let location of upgradeReportResults.scheduled.locationIds; last as isLast">
                  {{ location }}{{ isLast ? '' : ',' }}
                </span>
              </p>
            </div>
          </ng-container>

          <ng-container *ngIf="upgradeReportResults.updated && upgradeReportResults.updated.locationIds.length">
            <div class="col-xs-12">
              {{ 'netops.reports.updated' | translate }} ({{ upgradeReportResults.updated.count }})
            </div>
            <div class="col-xs-12">
              <p class="result-location">
                <span *ngFor="let location of upgradeReportResults.updated.locationIds; last as isLast">
                  {{ location }}{{ isLast ? '' : ',' }}
                </span>
              </p>
            </div>
          </ng-container>

          <ng-container *ngIf="upgradeReportResults.cancelled && upgradeReportResults.cancelled.locationIds.length">
            <div class="col-xs-12">
              {{ 'netops.reports.cancelled' | translate }} ({{ upgradeReportResults.cancelled.count }})
            </div>
            <div class="col-xs-12">
              <p class="result-location">
                <span *ngFor="let location of upgradeReportResults.cancelled.locationIds; last as isLast">
                  {{ location }}{{ isLast ? '' : ',' }}
                </span>
              </p>
            </div>
          </ng-container>

          <ng-container *ngIf="upgradeReportResults.skipped && upgradeReportResults.skipped.locationIds.length">
            <div class="col-xs-12">
              {{ 'netops.reports.skipped' | translate }} ({{ upgradeReportResults.skipped.count }})
            </div>
            <div class="col-xs-12">
              <p class="result-location">
                <span *ngFor="let location of upgradeReportResults.skipped.locationIds; last as isLast">
                  {{ location }}{{ isLast ? '' : ',' }}
                </span>
              </p>
            </div>
          </ng-container>

          <ng-container *ngIf="upgradeReportResults.failed && upgradeReportResults.failed.locationIds.length">
            <div class="col-xs-12">
              {{ 'netops.reports.failed' | translate }} ({{ upgradeReportResults.failed.count }})
            </div>
            <div class="col-xs-12">
              <p class="result-location">
                <span *ngFor="let location of upgradeReportResults.failed.locationIds; last as isLast">
                  {{ location }}{{ isLast ? '' : ',' }}
                </span>
              </p>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !upgradeReportResults.scheduled?.locationIds?.length &&
              !upgradeReportResults.updated?.locationIds?.length &&
              !upgradeReportResults.cancelled?.locationIds?.length &&
              !upgradeReportResults.skipped?.locationIds?.length &&
              !upgradeReportResults.failed?.locationIds?.length
            "
          >
          <div class="col-xs-12">
            {{ 'netops.reports.noResultFound' | translate }}
          </div>
          </ng-container>
        </div>

        <div class="row" *ngIf="requestClearResults">
          <div class="col-xs-12">
            <span class="title">{{ 'netops.reports.success' | translate }}</span>
          </div>
          <div class="col-xs-12">
            <p>{{ 'netops.reports.clearSuccessMessage' | translate }} {{ selectedReport.id }}.</p>
          </div>

          <div class="col-xs-6">
            <div class="row">
              <div class="col-xs-6 key">{{ 'netops.reports.batchID' | translate }}:</div>
              <div class="col-xs-6">
                {{ requestClearResults.id }}
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6 key">{{ 'netops.reports.userIDOfRequestor' | translate }}:</div>
              <div class="col-xs-6">
                {{ requestClearResults.userIdOfRequestor }}
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6 key">{{ 'netops.reports.userIDOfApprover' | translate }}:</div>
              <div class="col-xs-6">
                {{ 'netops.reports.notYetApproved' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="approveReportResults">
          <div class="col-xs-12">
            <span class="title">{{ 'netops.reports.success' | translate }}</span>
          </div>
          <div class="col-xs-12">
            <p>{{ 'netops.reports.approveSuccessMessage' | translate }}</p>
          </div>

          <div class="col-xs-6">
            <div class="row">
              <div class="col-xs-6 key">{{ 'netops.reports.batchID' | translate }}:</div>
              <div class="col-xs-6">
                {{ approveReportResults.id }}
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6 key">{{ 'netops.reports.userIDOfRequestor' | translate }}:</div>
              <div class="col-xs-6">
                {{ approveReportResults.userIdOfRequestor }}
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6 key">{{ 'netops.reports.userIDOfApprover' | translate }}:</div>
              <div class="col-xs-6">
                {{ approveReportResults.userIdOfApprover }}
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6 key">{{ 'netops.reports.batchSize' | translate }}:</div>
              <div class="col-xs-6">
                {{ approveReportResults.batchSize }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
