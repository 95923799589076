import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { I5gQoeMetrics } from 'src/app/lib/interfaces/interface';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { load5gQoeMetrics } from 'src/app/store/qoeCellular/qoeCellular.actions';
import { selectQoe5gReports } from 'src/app/store/qoeCellular/qoeCellular.selector';
import { QoeComponent } from '../qoe.component';

@UntilDestroy()
@Component({
  selector: 'qoe5g',
  templateUrl: './qoe5g.component.html',
  styleUrls: ['./qoe5g.component.scss']
})
export class Qoe5gComponent implements OnInit, AfterViewInit {
  debouncerTimeout: any;
  historyDays = 7;
  searchValue = '';
  enableQoE5gReports: boolean = false;
  qoe5gReportsResponse: I5gQoeMetrics[];
  qoe5gReports: any = {
    data: [],
    loading: true
  };

  @Input()
  mode: any;

  constructor(
    private plume: PlumeService,
    private store: Store,
    private qoeComponent: QoeComponent,
    private modelRef: ModelRefService
  ) {}

  qoeChangeSort(collection: any, mode: string, column: string): void {
    if (this.qoeComponent) {
      this.qoeComponent.changeSort(collection, mode, column);
    }
  }

  ngOnInit(): void {
    const { start, end } = this.getStartEndTime('24h');
    this.store.dispatch(load5gQoeMetrics({ startTime: start, endTime: end }));
    this.enableQoE5gReports = this.plume.getPermissions().uiFeatures.qoe5gReports;
    if (this.enableQoE5gReports) {
      this.store
        .select(selectQoe5gReports)
        .pipe(untilDestroyed(this))
        .subscribe((response: any) => {
          if (response) {
            this.qoe5gReportsResponse = response;
            this.debounce(() => this.prepare5gQoeMetrics(), 500);
          }
        });
    }
  }

  ngAfterViewInit(): void {
    this.initQoe5gHeaders();
  }

  initQoe5gHeaders(): void {
    if (this.qoeComponent) {
      const live = this.qoeComponent.getLiveHeaders();
      const min15 = this.qoeComponent.getMin15Headers();

      if (live && min15) {
        this.qoe5gReports.live = JSON.parse(JSON.stringify(live));
        this.qoe5gReports.min15 = JSON.parse(JSON.stringify(min15));
        this.qoe5gReports.data = [];
        this.qoe5gReports.loading = true;
      }
    }
  }

  prepare5gQoeMetrics(): void {
    const reports = [];
    if (this.qoe5gReportsResponse) {
      const reportMap = new Map();
      // Group reports by pcid
      this.qoe5gReportsResponse.forEach((qoe5gReport) => {
        const old = this.qoe5gReports.data.find((finalQoe5gReport: any) => finalQoe5gReport.id === qoe5gReport.pcid);
        const pcid = qoe5gReport.pcid;
        const nodeId = qoe5gReport.node_id;
        if (!reportMap.has(pcid)) {
          reportMap.set(pcid, {
            loading: old ? old.loading : true,
            icon: this.modelRef.get('radiotower').icon,
            name: pcid,
            id: pcid,
            nodeId: qoe5gReport.node_id,
            connection: qoe5gReport.freq_band,
            channel: qoe5gReport.pcid,
            qoeScore: qoe5gReport.qoe_score,
            endts: qoe5gReport.end_ts,
            chartMode: old ? old.chartMode : '24h',
            charts: old ? old.charts : null,
            mode: this.mode,
            qoeMetrics: {
              weightedQoeScore: old ? old.qoeMetrics.weightedQoeScore : [],
              actualThroughput: old ? old.qoeMetrics.actualThroughput : [],
              estimatedThroughput: old ? old.qoeMetrics.estimatedThroughput : [],
              speedTestLatency: old ? old.qoeMetrics.speedTestLatency : [],
              signalBars: old ? old.qoeMetrics.signalBars : []
            },
            expand: false
          });
        }
        const reportEntry = reportMap.get(pcid);
        if (reportEntry) {
          const existingTimestamps = new Set(reportEntry.qoeMetrics.weightedQoeScore.map((item) => item.timestamp));
          const date = new Date(qoe5gReport.end_ts);
          const milliseconds = date.getTime();
          if (!existingTimestamps.has(qoe5gReport.end_ts)) {
            reportEntry.qoeMetrics.weightedQoeScore.push({
              timestamp: milliseconds,
              value: qoe5gReport.qoe_score
            });
            reportEntry.qoeMetrics.actualThroughput.push({
              timestamp: milliseconds,
              value: qoe5gReport.actual_throughput
            });
            reportEntry.qoeMetrics.estimatedThroughput.push({
              timestamp: milliseconds,
              value: qoe5gReport.estimated_throughput
            });
            reportEntry.qoeMetrics.speedTestLatency.push({
              timestamp: milliseconds,
              value: qoe5gReport.speedtest_latency
            });
            reportEntry.qoeMetrics.signalBars.push({
              timestamp: milliseconds,
              value: qoe5gReport.signal_bars
            });
          }
        }
      });

      reportMap.forEach((report) => {
        reports.push(report);
      });
      this.qoe5gReports.data = reports;
      this.qoe5gReports.loading = false;
      this.qoeComponent?.changeSort(this.qoe5gReports, 'min15');
    } else {
      if (this.qoe5gReports.loading) {
        setTimeout(() => (this.qoe5gReports.loading = false), 5000);
      }
    }
  }
  private getStartEndTime(value: '7d' | '24h'): { start: string; end: string } {
    return {
      start:
        value === '7d'
          ? moment().add(-this.historyDays, 'days').startOf('day').toISOString()
          : moment().add(-23, 'hours').startOf('hours').toISOString(),
      end: value === '7d' ? moment().add(-1, 'days').endOf('day').toISOString() : moment().toISOString()
    };
  }

  track(index: number, item: any): any {
    return item.id;
  }

  debounce(fn: any, delay: number): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, delay);
  }
}
