import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from 'src/app/lib/services/api.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@UntilDestroy()
@Component({
  selector: 'location-online-state',
  templateUrl: './location-online-state.component.html',
  styleUrls: ['./location-online-state.component.scss']
})
export class LocationOnlineStateComponent implements OnChanges {
  @Input() locationId: string;
  opened = true;
  axisType: '24hr' | '7d' | '30d' = '24hr';
  dataSet = [];
  chartModes = [];
  supported = this.plume.cloudVersionAbove1_89();
  outageEventsSupported = false;
  outageRecords = [];
  showMonthlyOutage: boolean = false;

  constructor(
    public plume: PlumeService,
    private api: ApiService,
    private mixpanel: MixpanelService,
    private toast: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const permissions = this.plume.getPermissions();
    this.outageEventsSupported = this.plume.cloudVersionAbove1_121() && permissions?.uiFeatures?.networkOutageEvents;
    this.initModeToggler();
    this.initChart();
  }

  toggleChartMode(event: '24hr' | '7d' | '30d'): void {
    this.axisType = event;
    this.initChart();
  }
  private initChart(): void {
    this.initModeToggler();
    this.dataSet = [];
    this.api
      .get(
        '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/onlineStats' +
          this.queryParams(),
        'reports'
      )
      .pipe(untilDestroyed(this))
      .subscribe((res: { locationState: { timestamp: string; value: 'partial' | 'online' | 'offline' | null }[] }) => {
        this.dataSet = res.locationState.map((item) => ({
          time: moment(item.timestamp).valueOf(),
          state: item.value
        }));
        this.prependEmpty();
      });
  }

  private initOutageEvents(): void {
    this.api
      .get(
        '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/networkoutages/stats' +
          this.queryEventParams('monthly'),
        'reports'
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: any) => {
          this.parseNetworkoutageMonthly(res);
          this.showMonthlyOutage = true;
          this.mixpanel.storeEvent('HEALTH_OUTAGE_EVENTS_TABLE');
        },
        (error: any) => {
          this.mixpanel.storeEvent('HEALTH_OUTAGE_EVENTS_TABLE_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
  }

  getMonthlyOutage(event: MouseEvent): void {
    event.stopPropagation();
    this.initOutageEvents();
  }

  private parseNetworkoutageMonthly(networkOutages: any): void {
    this.outageRecords = [];
    networkOutages.data.forEach((outageMonth: any) => {
      outageMonth.outagesPerMonth.forEach((outages: any) => {
        outages.forEach((outage: any) => {
          const startEpoch = moment(outage.startTime).format('L LT');
          const endEpoch = moment(outage.endTime).format('L LT');
          const diff = moment(outage.endTime).diff(moment(outage.startTime), 'minutes');
          this.outageRecords.push({ start: startEpoch, end: endEpoch, duration: diff });
        });
      });
    });

    this.outageRecords.sort((a, b) => (a.start > b.start ? -1 : 1));
  }

  private prependEmpty(): void {
    if (!this.dataSet?.length) {
      return;
    }
    const startTime =
      this.axisType === '30d'
        ? moment().subtract(30, 'days').valueOf()
        : this.axisType === '7d'
        ? moment().subtract(7, 'days').valueOf()
        : moment().subtract(24, 'hours').valueOf();

    if (this.dataSet[this.dataSet.length - 1].time > startTime) {
      this.dataSet.push({
        time: moment(this.dataSet[0].time).subtract(15, 'minutes').valueOf(),
        state: null
      });
      this.dataSet.push({
        time: startTime,
        state: null
      });
    }
  }

  private initModeToggler(): void {
    this.chartModes = [
      { value: '24hr', translation: '24h', selected: this.axisType === '24hr' ? true : false },
      { value: '7d', translation: '7d', selected: this.axisType === '7d' ? true : false }
    ];
  }

  private queryParams(): string {
    if (this.axisType === '24hr') {
      return `?limit=24&granularity=hours`;
    }
    if (this.axisType === '7d') {
      return `?limit=7&granularity=days`;
    }
    if (this.axisType === '30d') {
      return `?limit=30&granularity=days`;
    }
    return '';
  }

  private queryEventParams(timePeriod): string {
    const timeSpan = timePeriod === 'monthly' ? 'month' : 'year';
    const endStamp = new Date().valueOf();
    const startStamp = moment(new Date()).subtract(2, timeSpan).valueOf();
    const offsetMinutesFromUtc = moment().utcOffset();
    return `?startTimestamp=${startStamp}&endTimestamp=${endStamp}&offsetMinutesFromUTC=${offsetMinutesFromUtc}&type=${timePeriod}`;
  }
}
