import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Link } from 'src/app/lib/d3/models/objects/link';
import { Node } from 'src/app/lib/d3/models/objects/node';
import { ChannelHelper } from 'src/app/lib/helpers/channel.helper';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Component({
  selector: 'optimizerdetails',
  templateUrl: './optimizerdetails.component.html',
  styleUrls: ['./optimizerdetails.component.scss']
})
export class OptimizerDetailsComponent implements OnInit, OnChanges {
  @Input()
  event: any = null;

  @Input()
  nodes: any[] = [];

  showOpsLog: boolean = false;
  showTopology: boolean = false;
  topologyChanges: any = [];
  selector: any = {
    current: {},
    list: []
  };

  ui: string;
  table: any[] = [];
  enableTopology: boolean = false;
  checkTopology: number = 0;
  topologyNodes: Node[] = [];
  topologyLinks: Link[] = [];

  topologyModeItems: any[];
  channelHelper: ChannelHelper = new ChannelHelper();
  helper: GeneralHelper = new GeneralHelper();

  constructor(private plume: PlumeService, private mixpanel: MixpanelService, private translate: TranslateService) {}

  lpmStatus: any = { event: 'NPM', target: 'NPM' };

  ngOnInit(): void {
    this.ui = this.plume.getUI();
    this.initTopologyMode();
  }

  ngOnChanges(changes: any): void {
    if (!this.event || !this.event.eventData) {
      return;
    }

    if (changes.event) {
      if (this.event?.eventData?.response?.status) {
        this.event.eventData.response.status = this.event.eventData.response.status.split(',').join(', ');
      }
      this.checkLPM();
      this.initSelector();
      this.initTopology();
    }
  }

  checkLPM(): void {
    this.lpmStatus.event = this.event?.eventData?.response?.locationProfile?.features?.powerManagement?.mode
      ? this.event.eventData.response.locationProfile.features.powerManagement.mode
      : 'NPM';
    this.lpmStatus.target = this.event?.eventData?.response?.locationProfile?.features?.powerManagement?.targetMode
      ? this.event.eventData.response.locationProfile.features.powerManagement.targetMode
      : 'NPM';
  }

  initTopologyMode(): void {
    this.topologyModeItems = [
      { value: 'original', translation: 'timelines.optimizer.details.originalTopology', selected: false },
      { value: 'optimized', translation: 'timelines.optimizer.details.optimizedTopology', selected: true }
    ];
  }

  topologyModeChange(event: any): void {
    if (this.event && this.nodes) {
      const original = this.event.eventData.response.topology;
      const optimized = this.event.eventData.response.optimizedTopology;

      let topology: any = {};

      if (event === 'original') {
        topology = this.calculateTopology(original);
      }

      if (event === 'optimized') {
        topology = this.calculateTopology(optimized);
      }

      this.topologyNodes = topology.nodes;
      this.topologyLinks = topology.links;
      this.checkTopology = Date.now();
    }
  }

  initSelector(): void {
    const list = [];

    if (this.event && this.event.eventData.response.channelGainsOut) {
      if (this.event.eventData.response.channelGainsOut.radio24) {
        list.push({ id: 'radio24', name: 'timelines.optimizer.details.radio24' });
      }

      if (this.event.eventData.response.channelGainsOut.radio50) {
        list.push({ id: 'radio50', name: 'timelines.optimizer.details.radio50' });
      }

      if (this.event.eventData.response.channelGainsOut.radio50L) {
        list.push({ id: 'radio50L', name: 'timelines.optimizer.details.radio50L' });
      }

      if (this.event.eventData.response.channelGainsOut.radio50U) {
        list.push({ id: 'radio50U', name: 'timelines.optimizer.details.radio50U' });
      }

      if (this.event.eventData.response.channelGainsOut.radio60) {
        list.push({ id: 'radio60', name: 'timelines.optimizer.details.radio60' });
      }
    }

    if (this.event && this.event.eventData.response.chanUtilizations) {
      const bands = [];

      Object.keys(this.event.eventData.response.chanUtilizations).forEach((node: string) => {
        Object.keys(this.event.eventData.response.chanUtilizations[node]).forEach((band: string) => {
          band = band.substring(0, 7);

          if (bands.indexOf(band) === -1) {
            bands.push(band);
          }
        });
      });

      bands.sort((a: string, b: string) => a.localeCompare(b));

      bands.forEach((band: string) => {
        list.push({ id: 'interference-' + band, name: 'timelines.optimizer.details.interference' + band });
      });
    }

    list.push({ id: 'radar', name: 'timelines.optimizer.details.radar' });
    list.push({ id: 'perfPred', name: 'timelines.optimizer.details.perfPred' });

    if (
      this.event &&
      this.event.eventData &&
      this.event.eventData.response &&
      this.event.eventData.response.estimatedPhyRate &&
      Object.keys(this.event.eventData.response.estimatedPhyRate).length
    ) {
      list.push({ id: 'estPhy', name: 'timelines.optimizer.details.estPhy' });
    }

    if (
      this.event &&
      this.event.eventData &&
      this.event.eventData.response &&
      this.event.eventData.response.observedPhyRate &&
      Object.keys(this.event.eventData.response.observedPhyRate).length
    ) {
      list.push({ id: 'obsPhy', name: 'timelines.optimizer.details.obsPhy' });
    }

    if (
      this.event?.eventData?.response?.mduWifiConfig &&
      Object.keys(this.event.eventData.response.mduWifiConfig).length
    ) {
      list.push({ id: 'mduWifiConfig', name: 'timelines.optimizer.details.mduWifiConfig' });
    }
    list.push({ id: 'puncturelist', name: 'timelines.optimizer.details.punctChannel5g' });
    this.selector.list = list;
    this.selector.current = { id: null, name: 'timelines.optimizer.details.selectStats' };
  }

  initTopology(): void {
    const nodes = [];
    const nodesAtOptimization = this.event.eventData.response.nodes;

    if (this.event && this.nodes) {
      const nodesObjectsAtOptimization = Object.keys(nodesAtOptimization).map((nodeId: string) => {
        const node = this.nodes.find((node: any) => node.id === nodeId) || null;
        return node
          ? node
          : {
              id: nodeId,
              defaultName: this.translate.instant('health.networkInformation.topologyHistory.unclaimed')
            };
      });

      const original = this.event.eventData.response.topology;
      const optimized = this.event.eventData.response.optimizedTopology;
      nodesObjectsAtOptimization.forEach((node: any) => {
        const originalNode = original && original.find((item: any) => item.id === node.id);
        const optimizedNode = optimized && optimized.find((item: any) => item.id === node.id);
        const changes = [];

        if (originalNode && optimizedNode) {
          const originalBand24 = originalNode.wifiConfig.find((item: any) => item.freqBand === '2.4G');
          const optimizedBand24 = optimizedNode.wifiConfig.find((item: any) => item.freqBand === '2.4G');

          const originalBand50 = originalNode.wifiConfig.find((item: any) => item.freqBand === '5G');
          const optimizedBand50 = optimizedNode.wifiConfig.find((item: any) => item.freqBand === '5G');

          const originalBand50L = originalNode.wifiConfig.find((item: any) => item.freqBand === '5GL');
          const optimizedBand50L = optimizedNode.wifiConfig.find((item: any) => item.freqBand === '5GL');

          const originalBand50U = originalNode.wifiConfig.find((item: any) => item.freqBand === '5GU');
          const optimizedBand50U = optimizedNode.wifiConfig.find((item: any) => item.freqBand === '5GU');

          const originalBand60 = originalNode.wifiConfig.find((item: any) => item.freqBand === '6G');
          const optimizedBand60 = optimizedNode.wifiConfig.find((item: any) => item.freqBand === '6G');

          if (originalBand24 && optimizedBand24 && originalBand24.channel !== optimizedBand24.channel) {
            changes.push({ type: 'band', band: '2.4G', original: originalBand24, optimized: optimizedBand24 });
          }

          if (originalBand50 && optimizedBand50 && originalBand50.channel !== optimizedBand50.channel) {
            changes.push({ type: 'band', band: '5G', original: originalBand50, optimized: optimizedBand50 });
          }

          if (originalBand50L && optimizedBand50L && originalBand50L.channel !== optimizedBand50L.channel) {
            changes.push({ type: 'band', band: '5GL', original: originalBand50L, optimized: optimizedBand50L });
          }

          if (originalBand50U && optimizedBand50U && originalBand50U.channel !== optimizedBand50U.channel) {
            changes.push({ type: 'band', band: '5GU', original: originalBand50U, optimized: optimizedBand50U });
          }

          if (originalBand60 && optimizedBand60 && originalBand60.channel !== optimizedBand60.channel) {
            changes.push({ type: 'band', band: '6G', original: originalBand60, optimized: optimizedBand60 });
          }

          if (originalBand24 && optimizedBand24 && originalBand24.vaps.length !== optimizedBand24.vaps.length) {
            changes.push({ type: 'vaps', band: '2.4G', original: originalBand24, optimized: optimizedBand24 });
          }

          if (originalBand50 && optimizedBand50 && originalBand50.vaps.length !== optimizedBand50.vaps.length) {
            changes.push({ type: 'vaps', band: '5G', original: originalBand50, optimized: optimizedBand50 });
          }

          if (originalBand50L && optimizedBand50L && originalBand50L.vaps.length !== optimizedBand50L.vaps.length) {
            changes.push({ type: 'vaps', band: '5GL', original: originalBand50L, optimized: optimizedBand50L });
          }

          if (originalBand50U && optimizedBand50U && originalBand50U.vaps.length !== optimizedBand50U.vaps.length) {
            changes.push({ type: 'vaps', band: '5GU', original: originalBand50U, optimized: optimizedBand50U });
          }

          if (originalBand60 && optimizedBand60 && originalBand60.vaps.length !== optimizedBand60.vaps.length) {
            changes.push({ type: 'vaps', band: '6G', original: originalBand60, optimized: optimizedBand60 });
          }

          const originalParent = originalNode.wifiConfig.find((item: any) => (item.parentId.length > 0 ? true : false));
          const optimizedParent = optimizedNode.wifiConfig.find((item: any) =>
            item.parentId.length > 0 ? true : false
          );

          if (originalParent && optimizedParent && originalParent.channel !== optimizedParent.channel) {
            changes.push({
              type: 'parent',
              originalParent: this.nodes.find((item: any) => item.id === originalParent.parentId),
              optimizedParent: this.nodes.find((item: any) => item.id === optimizedParent.parentId),
              original: originalParent,
              optimized: optimizedParent
            });
          }
        }

        if (changes.length) {
          nodes.push({ node, changes });
        }
      });

      if (nodes.length) {
        this.enableTopology = true;
      } else {
        this.enableTopology = false;
      }

      this.topologyChanges = nodes;
      const topology = this.calculateTopology(optimized);

      this.topologyNodes = topology && topology.nodes;
      this.topologyLinks = topology && topology.links;
      this.checkTopology = Date.now();
    }
  }

  calculateTopology(topology: any): any {
    const nodes = [];
    const links = [];

    if (!topology) {
      return;
    }

    topology.forEach((node: any) => {
      const rawNode = this.nodes.find((n: any) => n.id === node.id);

      const radios = node.wifiConfig.map((config: any) => {
        if (config.parentId.length) {
          links.push(
            new Link(config.parentId, node.id, {
              direction: 'sourceToTarget',
              medium: 'wifi',
              metadata: {
                freqBand: config.freqBand,
                channel: config.channel
              },
              source: config.parentId,
              target: node.id
            })
          );
        }

        return {
          mode:
            config.freqBand === '2.4G'
              ? '2g'
              : config.freqBand === '5G'
              ? '5g'
              : config.freqBand === '5GL'
              ? '5gl'
              : config.freqBand === '5GU'
              ? '5gu'
              : config.freqBand === '6G'
              ? '6g'
              : '',
          channel: config.channel,
          hasClientNetwork: this.helper.hasClientNetwork(config.vaps)
        };
      });

      const options = {
        id: node.id,
        type: 'pod',
        label: rawNode ? rawNode.nickname || rawNode.defaultName : node.id,
        health: { status: 'excellent' },
        connectionState: 'connected',
        metadata: { model: node.model },
        radios: radios.sort((a: any, b: any) => (a.mode > b.mode ? 1 : -1))
      };

      nodes.push(new Node(node.id, options));
    });

    return {
      nodes,
      links
    };
  }

  prepareNode(node: any): any {
    node.type = 'pod';
    node.label = node.nickname || node.defaultName;
    node.health = { status: 'excellent' };
    node.connectionState = 'connected';
    node.metadata = { model: node.model };
    node.radios = [
      ...('2gChannel' in node ? [{ mode: '2g', channel: node['2gChannel'], hasClientNetwork: false }] : []),
      ...('5gChannel' in node ? [{ mode: '5g', channel: node['5gChannel'], hasClientNetwork: false }] : []),
      ...('5glChannel' in node ? [{ mode: '5gl', channel: node['5glChannel'], hasClientNetwork: false }] : []),
      ...('5guChannel' in node ? [{ mode: '5gu', channel: node['5guChannel'], hasClientNetwork: false }] : []),
      ...('6gChannel' in node ? [{ mode: '6g', channel: node['6gChannel'], hasClientNetwork: false }] : [])
    ];

    return node;
  }

  changeStats(stats: any): void {
    this.selector.current = stats;
    this.table = [];

    this.mixpanel.storeEvent('TIMELINES_OPTIMIZER_EVENT_STATS', { OPTIMIZER_STATS: stats.id });

    // radio24 rather than inferference-radio24
    if (stats.id.indexOf('radio') === 0) {
      this.calculateChannelGain(stats.id);
    }

    if (stats.id.indexOf('interference') > -1) {
      this.calculateInterference(stats.id);
    }

    if (stats.id.indexOf('puncturelist') > -1) {
      this.calculatePuncture();
    }

    if (stats.id === 'radar') {
      this.calculateRadar();
    }

    if (stats.id === 'perfPred') {
      this.calculatePerformancePredictions();
    }

    if (stats.id === 'mduWifiConfig') {
      this.calculateMduWifiConfig();
    }

    if (stats.id === 'estPhy') {
      this.calculatePhy('estimated');
    }

    if (stats.id === 'obsPhy') {
      this.calculatePhy('observed');
    }
  }

  calculatePuncture(): void {
    const event = this.event.eventData.response;
    const topology = event.optimizedTopology;
    const rows = [];
    topology.forEach((topo: any) => {
      const node = this.nodes.find((node: any) => node.id === topo.id) || null;
      const p5GObj = topo.wifiConfig.find((item: any) => item.freqBand === '5G');
      const p6GObj = topo.wifiConfig.find((item: any) => item.freqBand === '6G');

      if (p5GObj) {
        rows.push({
          type: 'data',
          id: topo.id,
          name: node ? node.nickname || node.defaultName : topo.id,
          channel: p5GObj.channel,
          channelColor: this.getChannelColor(parseInt(p5GObj.channel, 10)),
          punctured5G: p5GObj.puncturedChannels,
          punctured6G: p6GObj.puncturedChannels
        });
      }
    });
    this.table = rows;
  }

  calculateInterference(stat: string): void {
    const band = stat.substring(13);
    const chanUtilizations = this.event.eventData.response.chanUtilizations;
    let channels: any;
    let nodes = [];

    Object.keys(chanUtilizations).forEach((nodeId: string) => {
      const node = this.nodes.find((node: any) => node.id === nodeId) || null;

      let nodeChannels = {};

      if (chanUtilizations[nodeId] && chanUtilizations[nodeId][band] && (band === 'radio24' || band === 'radio60')) {
        nodeChannels = chanUtilizations[nodeId][band];
      }

      if (chanUtilizations[nodeId] && chanUtilizations[nodeId] && band === 'radio50') {
        nodeChannels = {
          ...chanUtilizations[nodeId].radio50,
          ...chanUtilizations[nodeId].radio50U,
          ...chanUtilizations[nodeId].radio50L
        };
      }

      channels = { ...channels, ...nodeChannels };

      nodes.push({
        id: node ? node.id : nodeId,
        name: node ? (node.nickname ? node.nickname : node.defaultName) : nodeId,
        channels: nodeChannels
      });
    });

    channels = Object.keys(channels);

    nodes = nodes.map((node: any) => {
      const values = [];

      channels.forEach((channel: string) => {
        if (Number(node.channels[channel]) >= 0) {
          values.push({
            type: 'value',
            value: node.channels[channel].toFixed(1),
            state: this.getInterferenceState(node.channels[channel].toFixed(1), channel)
          });
        } else {
          values.push({ type: 'empty' });
        }
      });

      node.type = 'data';
      node.channels = values;

      return node;
    });

    this.table = [
      {
        type: 'headers',
        channels: channels.map((channel: string) => {
          return {
            channelColor: this.getChannelColor(parseInt(channel, 10)),
            value: parseInt(channel, 10)
          };
        })
      },
      ...nodes
    ];
  }

  calculateRadar(): void {
    const radar = this.event.eventData.response?.nol || null;

    const channels = {};
    const nodes = {};

    if (radar.length) {
      radar.forEach((event: any) => {
        channels[event.channel] = true;
        nodes[event.nodeId] = true;
      });

      const table = [
        {
          type: 'headers',
          channels: Object.keys(channels).map((channel: string) => {
            return {
              channelColor: this.getChannelColor(parseInt(channel, 10)),
              value: parseInt(channel, 10)
            };
          })
        }
      ];

      Object.keys(nodes).forEach((nodeId: string) => {
        const node = this.nodes.find((node: any) => node.id === nodeId) || null;
        const row = {
          type: 'data',
          id: node ? node.id : nodeId,
          name: node ? (node.nickname ? node.nickname : node.defaultName) : nodeId,
          channels: []
        };

        Object.keys(channels).forEach((channel: string) => {
          const channelNode = radar.find(
            (event: any) => event.nodeId === nodeId && event.channel === parseInt(channel, 10)
          );

          if (channelNode) {
            if ('radarsPerHourDecayed' in channelNode) {
              row.channels.push({
                type: 'value',
                value: channelNode.radarsPerHourDecayed.toFixed(2),
                state: this.getRadarState(channelNode.radarBlackList)
              });
            } else if ('radarsPerHour' in channelNode) {
              row.channels.push({
                type: 'value',
                value: channelNode.radarsPerHour.toFixed(2),
                state: this.getRadarState(channelNode.radarBlackList)
              });
            } else {
              row.channels.push({ type: 'empty' });
            }
          } else {
            row.channels.push({ type: 'empty' });
          }
        });

        table.push(row);
      });

      this.table = table;
    } else {
      this.table = [];
    }
  }

  calculatePerformancePredictions(): void {
    const perfPred = this.event.eventData.response.predictions?.coverage || null;

    if (perfPred) {
      let has6g = false;
      let has5g = false;

      const table = Object.keys(perfPred).map((nodeId: string) => {
        const node = this.nodes.find((node: any) => node.id === nodeId) || null;
        const data = {
          id: node ? node.id : nodeId,
          name: node ? (node.nickname ? node.nickname : node.defaultName) : nodeId,
          radio24: perfPred[nodeId] && perfPred[nodeId].radio24 ? perfPred[nodeId].radio24.toFixed(2) : 0,
          type: 'data'
        };

        if (perfPred[nodeId] && perfPred[nodeId].radio50) {
          has5g = true;
          data['radio50'] = perfPred[nodeId].radio50.toFixed(2);
        }

        if (perfPred[nodeId] && perfPred[nodeId].radio60) {
          has6g = true;
          data['radio60'] = perfPred[nodeId].radio60.toFixed(2);
        }

        return data;
      });

      const header = {
        header: 'timelines.optimizer.details.nodes',
        type: 'header',
        radio24: '2.5 GHz'
      };

      if (has5g) {
        header['radio50'] = '5 GHz';
      }

      if (has6g) {
        header['radio60'] = '6 GHz';
      }

      this.table = [header, ...table];
    } else {
      this.table = [];
    }
  }

  calculateMduWifiConfig(): void {
    const mduWifiConfig = this.event.eventData.response.mduWifiConfig?.mduWifiConfig as
      | { nodeId: string; wifiConfig: { chWidth: number; channel: number; freqBand: string }[] }[]
      | null;

    if (mduWifiConfig) {
      const table = mduWifiConfig.map(({ nodeId, wifiConfig }) => {
        const node = this.nodes.find((node: any) => node.id === nodeId) || null;
        const data = {
          name: node ? (node.nickname ? node.nickname : node.defaultName) : nodeId,
          type: 'data',
          ...wifiConfig.reduce(
            (acc, item) => ({ ...acc, [item.freqBand]: `${item.channel}/${item.chWidth}` }),
            {} as { [key: string]: string }
          )
        };
        return data;
      });

      const header = {
        header: 'timelines.optimizer.details.nodes',
        type: 'header',
        ...table.reduce(
          (acc, item) => ({
            ...acc,
            ...Object.keys(item)
              .filter((key) => key !== 'name' && key !== 'type')
              .reduce((keys, key) => ({ ...keys, [key]: key }), {} as { [key: string]: string })
          }),
          {} as { [key: string]: string }
        )
      };

      this.table = [header, ...table];
    } else {
      this.table = [];
    }
  }

  calculatePhy(mode: string): void {
    const estPhy = this.event.eventData.response[mode + 'PhyRate'];
    const topology = this.event.eventData.response.optimizedTopology;
    const nodesAtOptimization = this.event.eventData.response.nodes;
    const table = [];

    const nodes = Object.keys(nodesAtOptimization).map((nodeId: string) => {
      const node = this.nodes.find((node: any) => node.id === nodeId) || null;
      return {
        type: 'header',
        id: node ? node.id : nodeId,
        name: node
          ? node.nickname
            ? node.nickname
            : node.defaultName
          : this.translate.instant('health.networkInformation.topologyHistory.unclaimed')
      };
    });

    const headers = [{ type: 'empty' }];

    nodes.forEach((node: any) => {
      headers.push(node);
    });

    table.push(headers);

    nodes.forEach((rowNode: any) => {
      const row = [];

      nodes.forEach((cellNode: any) => {
        if (!row.length) {
          row.push(rowNode);
        }

        if (rowNode.id === cellNode.id) {
          row.push({ type: 'empty' });
        } else {
          if (estPhy && estPhy[rowNode.id] && estPhy[rowNode.id][cellNode.id]) {
            const bands = Object.keys(estPhy[rowNode.id][cellNode.id]);

            const channels = [];

            bands.forEach((band: string) => {
              Object.keys(estPhy[rowNode.id][cellNode.id][band]).forEach((width: string) => {
                Object.keys(estPhy[rowNode.id][cellNode.id][band][width]).forEach((channel: string) => {
                  if (typeof estPhy[rowNode.id][cellNode.id][band][width][channel] === 'object') {
                    channels.push({
                      channel,
                      channelColor: this.getChannelColor(parseInt(channel, 10)),
                      value: null,
                      highlight: this.checkLegacyTopology(rowNode.id, cellNode.id, topology, parseInt(channel, 10)),
                      blacklisted: this.checkBlacklisted(rowNode, cellNode, parseInt(channel, 10))
                    });
                  } else {
                    const value = estPhy[rowNode.id][cellNode.id][band][width][channel].toFixed(2);

                    if (value > 0) {
                      const highlight = this.checkLegacyTopology(
                        rowNode.id,
                        cellNode.id,
                        topology,
                        parseInt(channel, 10)
                      );
                      const blacklisted = this.checkBlacklisted(rowNode, cellNode, parseInt(channel, 10));
                      let exist = false;

                      channels.forEach((c: any) => {
                        if (
                          c.channel === channel &&
                          c.value === value &&
                          c.highlight === highlight &&
                          c.blacklisted === blacklisted
                        ) {
                          exist = true;

                          const translatedBand = this.translate.instant('timelines.optimizer.details.band' + band);

                          if (c.band.indexOf(translatedBand) < 0) {
                            c.band.push(translatedBand);
                          }
                        }
                      });

                      if (!exist) {
                        channels.push({
                          channel,
                          channelColor: this.getChannelColor(parseInt(channel, 10)),
                          value,
                          highlight,
                          blacklisted,
                          band: [this.translate.instant('timelines.optimizer.details.band' + band)],
                          bandWidth: width
                        });
                      }
                    }
                  }
                });
              });
            });

            row.push({
              type: 'value',
              channels: channels.sort((a: any, b: any) => a.channel - b.channel)
            });
          } else {
            row.push({ type: 'empty' });
          }
        }
      });
      table.push(row);
    });

    this.table = table;
  }

  calculateChannelGain(stat: string): void {
    const chanGains = this.event.eventData.response.chanGains[stat];
    const channelGainsOut = this.event.eventData.response.channelGainsOut[stat];
    const topology = this.event.eventData.response.optimizedTopology;
    const table = [];

    const nodes = Object.keys(chanGains).map((nodeId: string) => {
      const node = this.nodes.find((node: any) => node.id === nodeId) || null;
      return {
        type: 'header',
        id: node ? node.id : nodeId,
        name: node
          ? node.nickname
            ? node.nickname
            : node.defaultName
          : this.translate.instant('health.networkInformation.topologyHistory.unclaimed')
      };
    });

    const headers = [{ type: 'empty' }];

    nodes.forEach((node: any) => {
      headers.push(node);
    });

    table.push(headers);

    nodes.forEach((rowNode: any) => {
      const row = [];
      nodes.forEach((cellNode: any) => {
        if (!row.length) {
          row.push(rowNode);
        }
        if (rowNode.id === cellNode.id) {
          row.push({ type: 'empty' });
        } else {
          if (channelGainsOut && channelGainsOut[rowNode.id] && channelGainsOut[rowNode.id][cellNode.id]) {
            row.push({
              type: 'value',
              value: channelGainsOut[rowNode.id][cellNode.id].toFixed(0),
              state: this.getGainState(channelGainsOut[rowNode.id][cellNode.id].toFixed(0)),
              highlight: this.checkLegacyTopology(rowNode.id, cellNode.id, topology)
            });
          } else if (chanGains && chanGains[rowNode.id] && chanGains[rowNode.id][cellNode.id]) {
            row.push({
              type: 'value',
              value: chanGains[rowNode.id][cellNode.id].toFixed(0),
              state: this.getGainState(chanGains[rowNode.id][cellNode.id].toFixed(0)),
              highlight: this.checkLegacyTopology(rowNode.id, cellNode.id, topology)
            });
          } else {
            row.push({ type: 'empty' });
          }
        }
      });

      table.push(row);
    });

    this.table = table;
  }

  getGainState(value: number): string {
    return value > -75 ? 'good' : value <= -75 && value > -85 ? 'warn' : value <= -85 ? 'bad' : '';
  }

  getRadarState(radarBlackList: string): string {
    if (radarBlackList) {
      return radarBlackList === 'yellow' ? 'warn' : radarBlackList === 'red' ? 'bad' : 'good';
    }
    return '';
  }

  getInterferenceState(value: number, channel: string): string {
    if (parseInt(channel, 10) < 14) {
      return value < 30 ? 'good' : value < 60 ? 'warn' : value < 100 ? 'bad' : '';
    }
    return value < 20 ? 'good' : value < 40 ? 'warn' : value < 100 ? 'bad' : ''; // 5G values
  }

  getChannelColor(channel: number): string {
    return this.channelHelper.getColor(channel);
  }

  checkLegacyTopology(sourceId: string, targetId: string, topology: any, channel: any = null): boolean {
    let linkFound = false;

    topology.forEach((node: any) => {
      if (node.id === sourceId || node.id === targetId) {
        node.wifiConfig.forEach((config: any) => {
          if (config.parentId === sourceId || config.parentId === targetId) {
            if (channel !== null) {
              if (config.channel === channel) {
                linkFound = true;
              }
            } else {
              linkFound = true;
            }
          }
        });
      }
    });

    return linkFound;
  }

  checkBlacklisted(rowNode: any, cellNode: any, channel: number): any {
    let failedFound = null;

    if (this.event.eventData.request.stats && this.event.eventData.request.stats.linkStates) {
      const blacklisted = this.event.eventData.request.stats.linkStates;

      blacklisted.forEach((failed: any) => {
        if (
          (failed.podId === rowNode.id && failed.peerId === cellNode.id && failed.channel === channel) ||
          (failed.podId === cellNode.id && failed.peerId === rowNode.id && failed.channel === channel)
        ) {
          failedFound = failed.failureCount;
        }
      });
    }

    return failedFound;
  }

  downloadResponse(): void {
    this.mixpanel.storeEvent('TIMELINES_OPTIMIZER_EVENT_DOWNLOAD_JSON');

    const json = JSON.stringify(this.event.eventData.response, null, 4);
    const blob = new Blob([json], { type: 'text/csv;charset=utf8;' });
    const filename = this.event.eventData.response.id + '.json';

    this.helper.download(blob, filename);
  }

  showTopologyModal(): void {
    this.mixpanel.storeEvent('TIMELINES_OPTIMIZER_EVENT_TOPOLOGY');
    this.initTopologyMode();
    this.showTopology = true;
  }

  showOpsLogModal(): void {
    this.mixpanel.storeEvent('TIMELINES_OPTIMIZER_EVENT_OPSLOG');
    this.showOpsLog = true;
  }

  closeEvent(): void {
    this.event = null;
  }
}
