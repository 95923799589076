import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BleComponent } from 'src/app/views/customer/configuration/ble/ble.component';
import { Store } from '@ngrx/store';
import {
  selectNetworkModeEditable,
  selectPipeLocationOnChange,
  selectWan
} from 'src/app/store/customer/customer.selectors';
import { DeepReadonly, ILocation, INetworkConfiguration } from 'src/app/lib/interfaces/interface';
import { networkModeSetWan } from 'src/app/store/customer-info/customer-info.actions';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { switchMap } from 'rxjs/operators';
import { pollingPull } from 'src/app/store/polling/polling.actions';
import { NetworkConfigurationService } from 'src/app/lib/services/network-configuration.service';

@Component({
  selector: 'wan',
  templateUrl: './wan.component.html',
  styleUrls: ['./wan.component.scss']
})
export class WanComponent implements OnInit, OnChanges, OnDestroy {
  locationSubscription: any;
  expand: boolean = false;
  location: DeepReadonly<ILocation> = {} as any;
  networkModeItems: any[] = [];
  ethernetLanItems: any[] = [];
  persistConfigurationItems: any[] = [];
  upnpItems: any[] = [];
  networkConfiguration: INetworkConfiguration = null;
  networkConfigurationUpnp: any = null;
  advancedOpen: boolean = false;

  dataExpand: boolean = false;
  bluetooth: boolean = false;
  showBLE: boolean = false;
  wanNodes$ = this.store.select(selectWan);
  capabilities$ = this.store.select(selectCapabilities);
  networkModeEditable$ = this.plume.permissions.pipe(
    switchMap((permissions) => this.store.select(selectNetworkModeEditable, { permissions }))
  );

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  @ViewChild('BLE')
  BLE: BleComponent;

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private translate: TranslateService,
    private store: Store,
    private networkConfigurationService: NetworkConfigurationService
  ) {}

  ngOnInit(): void {
    if (navigator.bluetooth) {
      navigator.bluetooth.getAvailability().then((isAvailable: boolean) => {
        this.bluetooth = isAvailable;
      });
    }

    this.init();
    this.registerFilter();

    this.locationSubscription = this.store.pipe(selectPipeLocationOnChange).subscribe((response: any) => {
      if (response) {
        this.location = response;
        this.initTogglers();
      }
    });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
    this.initTogglers();
  }

  init(): void {
    this.getNetworkConfiguration();
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'wan' });

    this.translate
      .get('configurations.wan.networkMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wan', property: 'networkMode', translation: translated })
      );

    this.translate
      .get('configurations.wan.ethernetLan')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wan', property: 'ethernetLan', translation: translated })
      );

    this.translate
      .get('configurations.wan.upnp')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wan', property: 'upnp', translation: translated })
      );

    this.translate
      .get('configurations.wan.persistConfigurationOnGateway')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'wan', property: 'persistConfigurationOnGateway', translation: translated })
      );
  }

  initTogglers(): void {
    if (!this.location) {
      return;
    }

    this.networkModeItems = [
      { value: 'auto', translation: 'auto', selected: this.location && this.location.networkMode === 'auto' },
      {
        value: 'bridge',
        translation: 'bridge',
        marked: this.location && this.location.networkModeRealized === 'bridge',
        selected: this.location && this.location.networkMode === 'bridge'
      },
      {
        value: 'router',
        translation: 'router',
        marked: this.location && this.location.networkModeRealized === 'router',
        selected: this.location && this.location.networkMode === 'router'
      }
    ];

    this.ethernetLanItems = [
      {
        value: 'auto',
        translation: 'auto',
        selected: this.networkConfiguration && this.networkConfiguration.ethernetLan.mode === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: this.location && this.location.ethernetLan && this.location.ethernetLan.enable,
        selected: this.networkConfiguration && this.networkConfiguration.ethernetLan.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: this.location && this.location.ethernetLan && !this.location.ethernetLan.enable,
        selected: this.networkConfiguration && this.networkConfiguration.ethernetLan.mode === 'disable'
      }
    ];
    this.upnpItems = [
      {
        value: 'auto',
        translation: 'auto',
        selected: this.networkConfigurationUpnp && this.networkConfigurationUpnp.mode === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: this.networkConfigurationUpnp && this.networkConfigurationUpnp.modeRealized,
        selected: this.networkConfigurationUpnp && this.networkConfigurationUpnp.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: this.networkConfigurationUpnp && !this.networkConfigurationUpnp.modeRealized,
        selected: this.networkConfigurationUpnp && this.networkConfigurationUpnp.mode === 'disable'
      }
    ];

    this.persistConfigurationItems = [
      {
        value: 'auto',
        translation: 'auto',
        selected:
          this.networkConfiguration &&
          this.networkConfiguration.persistConfigurationOnGateway &&
          this.networkConfiguration.persistConfigurationOnGateway.mode === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked:
          this.networkConfiguration &&
          this.networkConfiguration.persistConfigurationOnGateway &&
          this.networkConfiguration.persistConfigurationOnGateway.modeRealized === 'enable',
        selected:
          this.networkConfiguration &&
          this.networkConfiguration.persistConfigurationOnGateway &&
          this.networkConfiguration.persistConfigurationOnGateway.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked:
          this.networkConfiguration &&
          this.networkConfiguration.persistConfigurationOnGateway &&
          this.networkConfiguration.persistConfigurationOnGateway.modeRealized === 'disable',
        selected:
          this.networkConfiguration &&
          this.networkConfiguration.persistConfigurationOnGateway &&
          this.networkConfiguration.persistConfigurationOnGateway.mode === 'disable'
      }
    ];
  }

  action(command: string, action: string): void {
    switch (command) {
      case 'networkMode':
        this.store.dispatch(networkModeSetWan({ value: action as any }));
        break;
      case 'ethernetLan':
        this.networkConfigurationService.setEthernetLanMode$(action as any).subscribe(
          (response) => {
            this.getNetworkConfiguration();
            this.mixpanel.storeEvent('CONFIGURATION_ETHERNET_LAN_SET', { ETHERNET_LAN: action });
            const translatedResult = this.translate.instant(response.mode);
            this.toast.success('toast.customerinfo.ethernetLanMsg', 'toast.customerinfo.ethernetLanTitle', {
              params: { mode: translatedResult }
            });
            setTimeout(() => this.store.dispatch(pollingPull({ debugSource: 'wan ethernet lan' })), 1000); // Added delay as realised State it taking a while to update
          },
          (error: any) => {
            this.mixpanel.storeEvent('CONFIGURATION_ETHERNET_LAN_ERROR', {
              ETHERNET_LAN: action,
              ERROR: error.error.error.message
            });
            this.toast.error(error.error.error.message, 'configurations.wan.ethernetLan');
            this.getNetworkConfiguration();
          }
        );
        break;
      case 'upnp':
        this.networkConfigurationService.setUpnpMode$(action as any).subscribe(
          () => {
            this.getNetworkConfiguration();
            this.mixpanel.storeEvent('CONFIGURATION_UPNP_SET', { UPNP: action });
          },
          (error: any) => {
            this.mixpanel.storeEvent('CONFIGURATION_UPNP_ERROR', {
              UPNP: action,
              ERROR: error.error.error.message
            });
            this.toast.error(error.error.error.message, 'configurations.wan.upnp');
            this.getNetworkConfigurationUpnp();
          }
        );
        break;

      case 'persistConfiguration':
        this.networkConfigurationService.setPersistConfigurationOnGatewayMode$(action as any).subscribe(
          () => {
            this.getNetworkConfiguration();
            this.mixpanel.storeEvent('CONFIGURATION_PERSIST_CONFIGURATION_ON_GATEWAY', { CONFIGURATION: action });
          },
          (error: any) => {
            this.mixpanel.storeEvent('CONFIGURATION_PERSIST_CONFIGURATION_ON_GATEWAY_ERROR', {
              CONFIGURATION: action,
              ERROR: error.error.error.message
            });
            this.toast.error(error.error.error.message, 'PERSIST CONFIGURATION ON GATEWAY');
            this.getNetworkConfiguration();
          }
        );
        break;
    }
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);
    this.initTogglers();

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_WAN_SCREEN');
    }
  }

  getNetworkConfiguration(): void {
    this.networkConfigurationService.networkConfiguration$().subscribe((response) => {
      this.networkConfiguration = response;
      this.initTogglers();
    });

    this.getNetworkConfigurationUpnp();
  }

  getNetworkConfigurationUpnp(): void {
    this.networkConfigurationService.upnp$().subscribe((response) => {
      this.networkConfigurationUpnp = response;
      this.initTogglers();
    });
  }

  closeBLE(): void {
    this.BLE.disconnect();
    this.showBLE = false;
  }

  ngOnDestroy(): void {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }
}
