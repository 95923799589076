import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { GroupService } from 'src/app/lib/services/group.service';
import { IFilteredGroup } from 'src/app/lib/interfaces/groups.interface';
import {
  IFirmwareUpgradeCriteria,
  IFirmwareUpgradeReport,
  IFirmwareUpgradeReportsCriteria,
  IVersionMatrices,
  SlideTogglerItems
} from 'src/app/lib/interfaces/interface';

interface ModelObject {
  downloadUrl: string;
  encryptedDownloadUrl: string;
  firmwareVersion: string;
  model: string;
  residentialGateway: boolean;
}

@Component({
  templateUrl: './upgradefirmwarev2.component.html',
  styleUrls: ['./upgradefirmwarev2.component.scss']
})
export class UpgradeFirmwareV2Component implements OnInit {
  includeLocationIDFormControl: FormControl = new FormControl();
  excludeLocationIDFormControl: FormControl = new FormControl();
  partnerIDFormControl: FormControl = new FormControl();
  specificLocationIds: FormControl = new FormControl();
  randomLocationFormControl: FormControl = new FormControl();
  startTimeFormControl: FormControl = new FormControl();
  endTimeFormControl: FormControl = new FormControl();

  selectedVersionMatrixes: FormControl = new FormControl();
  selectedTargetVersions: FormControl = new FormControl();

  batchFirmwareUpgrade: any = null;
  report: IFirmwareUpgradeReport = null;
  helper: GeneralHelper = new GeneralHelper();

  editLocationID: boolean = false;
  editTimeWindowStart: boolean = false;
  editTimeWindowEnd: boolean = false;
  countriesValues: {
    text: string;
    value: string;
  }[] = [];
  countriesSelected: string[] = [];
  citiesValues: {
    text: string;
    value: string;
  }[] = [];
  citiesSelected: string[] = [];
  modelsValues: {
    text: string;
    value: string;
    object: ModelObject;
  }[] = [];
  modelsSelected: string[] = [];
  includeGroupIdValues: {
    text: string;
    value: string;
    object: IFilteredGroup;
  }[] = [];
  includeGroupIdSelected: string[] = [];
  excludeGroupIdValues: {
    text: string;
    value: string;
    object: IFilteredGroup;
  }[] = [];
  excludeGroupIdSelected: string[] = [];
  includeActiveMatrixValues: {
    text: string;
    value: string;
    object: IVersionMatrices;
  }[] = [];
  includeActiveMatrixSelected: string[] = [];
  excludeActiveMatrixValues: {
    text: string;
    value: string;
    object: IVersionMatrices;
  }[] = [];
  excludeActiveMatrixSelected: string[] = [];
  includePendingMatrixValues: {
    text: string;
    value: string;
    object: IVersionMatrices;
  }[] = [];
  includePendingMatrixSelected: string[] = [];
  excludePendingMatrixValues: {
    text: string;
    value: string;
    object: IVersionMatrices;
  }[] = [];
  excludePendingMatrixSelected: string[] = [];
  includeLocationIdSelected: string[] = [];
  excludeLocationIdSelected: string[] = [];
  partnerIdSelected: string[] = [];

  versionMatrix: any = {
    show: false,
    current: null
  };

  showMatrixSelect: boolean = false;

  allMatrices: IVersionMatrices[] = [];
  matrices: any[] = [];
  locationModels: string[] = [];
  search: FormControl = new FormControl();
  filterIncludeModels: boolean = false;

  downgradeItems: any[] = [];
  asapItems: any[] = [];

  asap: boolean = false;
  downgrade: boolean = false;

  selectedVersion: any = null;
  versionDetails: any = null;

  modalType: string = null;

  locationIDSelector: string = 'off';
  groupMembersSelector: string = 'off';
  versionMatrixSelector: string = 'off';
  groupMembersTypeSelector: string = 'name';
  modelSelector: string = 'off';

  loadingLocations: boolean = false;
  loadingRequestUpgrade: boolean = false;

  locations: string[] = [];
  batchSize: number = null;

  previewTriggered: boolean = false;

  targetMethodItems: SlideTogglerItems<'criteria' | 'location'> = [];
  targetMethod: string = 'criteria';

  @ViewChild('versionMatrixInput')
  versionMatrixInput: any;

  constructor(
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private pagescroll: PageScrollService,
    private troubleshootingService: TroubleshootingService,
    private groupService: GroupService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initTogglers();
    this.getGroups();
    this.getVersionMatrices();
    this.getCountries();
    this.startTimeFormControl.setValue('02:00');
    this.endTimeFormControl.setValue('05:00');
  }

  initTogglers(): void {
    this.targetMethodItems = [
      {
        value: 'criteria',
        translation: 'netops.firmware.targetMethodCriteria',
        selected: this.targetMethod === 'criteria'
      },
      {
        value: 'location',
        translation: 'netops.firmware.targetMethodLocation',
        selected: this.targetMethod === 'location'
      }
    ];

    this.downgradeItems = [
      { value: true, translation: 'configurations.firmware.preventDowngrades', selected: this.downgrade },
      { value: false, translation: 'configurations.firmware.enableDowngrades', selected: !this.downgrade }
    ];

    this.asapItems = [
      { value: true, translation: 'configurations.firmware.now', selected: this.asap },
      { value: false, translation: 'configurations.firmware.later', selected: !this.asap }
    ];
  }

  getCountries(): void {
    this.troubleshootingService.firmwareUpgradeReportsCountries$().subscribe((response: string[]) => {
      this.countriesValues = response
        .filter((country: string) => !!country)
        .map((country: string) => ({ text: country, value: country }));
    });
  }

  getCities(): void {
    if (this.countriesSelected.length) {
      this.troubleshootingService
        .firmwareUpgradeReportsCities$(this.countriesSelected)
        .subscribe((response: string[]) => {
          this.citiesValues = response
            .filter((city: string) => !!city)
            .map((city: string) => ({ text: city, value: city }));
        });
    } else {
      this.citiesValues = [];
      this.citiesSelected = [];
    }
  }

  countriesSelector(mode: boolean): void {
    if (!mode) {
      this.getCities();
    }
  }

  selectVersionMatix(): void {
    if (this.modalType === 'versionMatrix') {
      if (this.selectedVersionMatrixes.value?.split(', ').includes(this.selectedVersion.versionMatrix)) {
        this.toast.warning(
          'netops.firmware.errors.versionMatrixExistMessage',
          'netops.firmware.errors.versionMatrixExistHeader'
        );
        return;
      }

      const newValue = this.selectedVersionMatrixes.value
        ? `${this.selectedVersionMatrixes.value}, ${this.selectedVersion.versionMatrix}`
        : this.selectedVersion.versionMatrix;

      this.selectedVersionMatrixes.setValue(newValue);
      this.resize({ target: this.versionMatrixInput.nativeElement });
    } else {
      this.selectedTargetVersions.setValue(this.selectedVersion.versionMatrix);
    }

    this.setAsap();

    this.showMatrixSelect = false;
    this.selectedVersion = null;
    this.modalType = null;
  }

  setAsap(): void {
    if (this.asap) {
      this.startTimeFormControl.setValue('00:00');
      this.endTimeFormControl.setValue('23:59');
    } else {
      this.startTimeFormControl.setValue('02:00');
      this.endTimeFormControl.setValue('05:00');
    }
  }

  clear(): void {
    this.selectedTargetVersions.setValue('clear');

    this.setAsap();

    this.showMatrixSelect = false;
    this.selectedVersion = null;
    this.modalType = null;
  }

  disable(): void {
    this.selectedTargetVersions.setValue('disable');

    this.setAsap();

    this.showMatrixSelect = false;
    this.selectedVersion = null;
    this.modalType = null;
  }

  buildDataObject(mode: 'preview' | 'upgrade'): IFirmwareUpgradeReportsCriteria | IFirmwareUpgradeCriteria | null {
    const maxLocations = parseInt(this.randomLocationFormControl.value, 10) || 0;

    if (mode === 'preview') {
      return {
        locationCriteria: {
          ...(maxLocations > 0 ? { numberOfLocations: maxLocations } : {}),
          ...(this.countriesSelected.length ? { countries: this.countriesSelected } : {}),
          ...(this.citiesSelected.length ? { cities: this.citiesSelected } : {}),
          ...(this.includeGroupIdSelected.length ? { includeGroupIds: this.includeGroupIdSelected } : {}),
          ...(this.excludeGroupIdSelected.length ? { excludeGroupIds: this.excludeGroupIdSelected } : {}),
          ...(this.partnerIdSelected.length ? { partnerIds: this.partnerIdSelected } : {}),
          ...(this.modelsSelected.length ? { nodeModels: this.modelsSelected } : {}),
          ...(this.includeActiveMatrixSelected.length
            ? { includeActiveVersionMatrixIds: this.includeActiveMatrixSelected }
            : {}),
          ...(this.excludeActiveMatrixSelected.length
            ? { excludeActiveVersionMatrixIds: this.excludeActiveMatrixSelected }
            : {}),
          ...(this.includePendingMatrixSelected.length
            ? { includePendingVersionMatrixIds: this.includePendingMatrixSelected }
            : {}),
          ...(this.excludePendingMatrixSelected.length
            ? { excludePendingVersionMatrixIds: this.excludePendingMatrixSelected }
            : {}),
          ...(this.includeLocationIdSelected.length ? { includeLocationIds: this.includeLocationIdSelected } : {}),
          ...(this.excludeLocationIdSelected.length ? { excludeLocationIds: this.excludeLocationIdSelected } : {})
        }
      } as IFirmwareUpgradeReportsCriteria;
    }

    if (mode === 'upgrade') {
      return {
        locationCriteria: {
          locations: this.locations,
          locationsModifier: 'include-only'
        },
        upgradeParameters: {
          preventDowngrade: this.downgrade,
          schedule: {
            startTime: this.startTimeFormControl.value,
            endTime: this.endTimeFormControl.value
          },
          ...(this.selectedTargetVersions.value && { targetVersionMatrix: this.selectedTargetVersions.value })
        }
      } as IFirmwareUpgradeCriteria;
    }

    return null;
  }

  requestUpgrade(): void {
    this.previewTriggered = false;
    this.loadingLocations = true;
    this.loadingRequestUpgrade = true;

    const dataObject = this.buildDataObject('upgrade') as IFirmwareUpgradeCriteria;

    this.troubleshootingService.firmwareUpgrade$(dataObject).subscribe(
      (response) => {
        this.batchFirmwareUpgrade = response.batchFirmwareUpgrade;
        this.loadingRequestUpgrade = false;
        this.toast.warning('netops.firmware.upgradeMsg', 'netops.firmware.upgradeTitle');

        this.troubleshootingService.firmwareUpgradeReport$(this.batchFirmwareUpgrade.id).subscribe(
          (response) => {
            this.report = response.batchFirmwareUpgrade;
            this.locations = this.report.locationIds;
            this.previewTriggered = true;
            this.loadingLocations = false;
          },
          (error) => {
            this.loadingRequestUpgrade = false;
            this.loadingLocations = false;
            this.batchFirmwareUpgrade = null;
            this.toast.warning(error.error.error.message, error.error.error.name);
          }
        );
      },
      (error) => {
        this.loadingRequestUpgrade = false;
        this.loadingLocations = false;
        this.batchFirmwareUpgrade = null;
        this.toast.warning(error.error.error.message, error.error.error.name);
      }
    );
  }

  preview(): void {
    this.locations = [];
    this.batchSize = null;
    this.report = null;
    this.previewTriggered = true;
    this.loadingLocations = true;

    if (this.targetMethod === 'location') {
      if (this.specificLocationIds.value) {
        const locations = this.specificLocationIds.value.split(',').map((locationid: string) => locationid.trim());

        this.locations = locations;
        this.batchSize = locations.length;

        if (this.locations.length) {
          this.scrollTo('preview');
        }
      } else {
        this.locations = [];
        this.batchSize = null;
        this.toast.warning(
          'netops.firmware.errors.emptySpecificLocationMessage',
          'netops.firmware.errors.emptySpecificLocationTitle'
        );
      }

      this.loadingLocations = false;
    } else {
      const dataObject = this.buildDataObject('preview');

      this.troubleshootingService.firmwareUpgradeReportsPreview$(dataObject).subscribe(
        (response: any) => {
          const locations = response as string[];
          this.locations = locations;
          this.batchSize = locations.length;
          this.loadingLocations = false;

          if (this.locations.length) {
            this.scrollTo('preview');
          }
        },
        (error) => {
          this.loadingLocations = false;
          this.locations = [];
          this.batchSize = null;

          if (error.error.error) {
            this.toast.warning(error.error.error.message, error.error.error.name);
          } else {
            this.toast.warning(error.message, error.name);
          }
        }
      );
    }
  }

  getGroups(offset: number = 0, previousValue: IFilteredGroup[] = []): void {
    const limit = 500;

    this.groupService.getGroups$({ order: 'name ASC', limit, offset }).subscribe((response) => {
      const list = [...previousValue, ...response];

      if (response.length === limit) {
        this.getGroups(list.length, list);
      } else {
        this.includeGroupIdValues = list.map((obj) => ({
          text: obj.name,
          value: obj.id,
          object: obj
        }));

        this.excludeGroupIdValues = list.map((obj) => ({
          text: obj.name,
          value: obj.id,
          object: obj
        }));
      }
    });
  }

  getVersionMatrices(): void {
    this.troubleshootingService.getVersionMatrices$().subscribe((response) => {
      this.allMatrices = response;

      this.includeActiveMatrixValues = response.map((matrice) => {
        return {
          text: matrice.versionMatrix,
          value: matrice.versionMatrix,
          object: matrice
        };
      });

      this.excludeActiveMatrixValues = response.map((matrice) => {
        return {
          text: matrice.versionMatrix,
          value: matrice.versionMatrix,
          object: matrice
        };
      });

      this.includePendingMatrixValues = response.map((matrice) => {
        return {
          text: matrice.versionMatrix,
          value: matrice.versionMatrix,
          object: matrice
        };
      });

      this.excludePendingMatrixValues = response.map((matrice) => {
        return {
          text: matrice.versionMatrix,
          value: matrice.versionMatrix,
          object: matrice
        };
      });

      this.getModels(response);
      this.filterSearch();
    });
  }

  getModels(responses: IVersionMatrices[]): void {
    const models = {};

    responses?.map((response) => {
      response.models.map((model) => {
        const modelName = model.model;
        if (!models[modelName]) {
          models[modelName] = model;
        }
      });
    });

    const groupedModels: ModelObject[] = Object.keys(models).map((key) => models[key]);

    this.modelsValues = groupedModels.map((model) => ({
      text: model.model,
      value: model.model,
      object: model
    }));
  }

  openMatrixSelect(type: any): void {
    this.modalType = type;
    this.showMatrixSelect = true;
    this.mixpanel.storeEvent('LOCATION_FIRMWARE_MATRIX_SELECT_MODAL', { LOCATIONID: this.plume.locationid });
  }

  filterSearch(): void {
    let matrices = [];

    if (this.search.value && this.search.value.length) {
      matrices = this.allMatrices.filter((matrice) => {
        if (matrice.versionMatrix.toLowerCase().includes(this.search.value.toLowerCase())) {
          return true;
        }

        if (this.filterIncludeModels) {
          for (const model of matrice.models) {
            if (model.firmwareVersion.toLowerCase().includes(this.search.value.toLowerCase())) {
              return true;
            }
          }
        }

        return false;
      });
    } else {
      matrices = JSON.parse(JSON.stringify(this.allMatrices));
    }

    if (this.locationModels.length) {
      this.matrices = matrices.filter((matrice: any) => {
        let add = false;

        for (const models of matrice.models) {
          if (this.locationModels.indexOf(models.model) > -1) {
            add = true;
            break;
          }
        }

        return add;
      });
    } else {
      this.matrices = matrices;
    }
  }

  filterIncludeModelsToggle(): void {
    this.filterIncludeModels = !this.filterIncludeModels;
    this.filterSearch();
  }

  filterGroupId(value: { text: string; value: string; object?: IFilteredGroup }, filterText: string): boolean {
    return (
      value.object.name.toLowerCase().includes(filterText.toLowerCase()) ||
      value.object.id.toLowerCase().includes(filterText.toLowerCase())
    );
  }

  toggleDowngrade(value: boolean): void {
    this.downgrade = value;
  }

  toggleAsap(value: boolean): void {
    this.asap = value;
  }

  resize(e: any): void {
    setTimeout(() => {
      e.target.style.height = 'auto';
      e.target.style.height = e.target.scrollHeight + 'px';
    }, 0);
  }

  scrollTo(selector: string): void {
    setTimeout(() => {
      this.pagescroll.scroll({
        document: window.document,
        scrollTarget: '#' + selector,
        scrollViews: [document.getElementById('netops-content')]
      });
    }, 100);
  }

  getGroupNames(ids: string[], values: { text: string; value: string }[]): string[] {
    return ids?.map((id) => values?.find((val) => val.value === id)?.text ?? id) ?? [];
  }

  copy(data: any, section: string): void {
    navigator.clipboard.writeText(data.join('\n'));

    this.toast.success('netops.firmware.copyMsg', 'netops.firmware.copyTitle', {
      params: {
        section: this.translate.instant('netops.firmware.' + section)
      }
    });
  }

  downloadCSV(): void {
    this.mixpanel.storeEvent('FIRMWARE_UPGRADE_SAVE_CSV');

    const csv = 'LocationId\n' + this.report.locationIds.join('\n');
    const blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf8;' });
    const filename = this.report.id + '-locations.csv';

    this.helper.download(blob, filename);
  }
}
