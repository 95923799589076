import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { Store } from '@ngrx/store';
import { selectPipeLocationOnChangeOptimistic } from 'src/app/store/customer/customer.selectors';
import { steeringBandSteeringSet } from 'src/app/store/configview/config.actions';
import { map } from 'rxjs/operators';
import { selectBandSteeringEditable } from 'src/app/store/configview/config.selectors';

@Component({
  selector: 'steering',
  templateUrl: './steering.component.html',
  styleUrls: ['./steering.component.scss']
})
export class SteeringComponent implements OnInit, OnChanges {
  expand: boolean = false;
  location$ = this.store.pipe(selectPipeLocationOnChangeOptimistic);
  selectBandSteeringEditable$ = this.store.select(selectBandSteeringEditable, { plume: this.plume });
  bandSteeringItems$ = this.location$.pipe(
    map((location) => [
      { value: 'auto', translation: 'auto', selected: location.bandSteering.mode === 'auto' },
      {
        value: 'enable',
        translation: 'enabled',
        marked: location.bandSteering.enable,
        selected: location.bandSteering.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disabled',
        marked: !location.bandSteering.enable,
        selected: location.bandSteering.mode === 'disable'
      }
    ])
  );
  clientSteeringItems$ = this.location$.pipe(
    map((location) => [
      { value: 'auto', translation: 'auto', selected: location.clientSteering.mode === 'auto' },
      {
        value: 'enable',
        translation: 'enabled',
        marked: location.clientSteering.enable,
        selected: location.clientSteering.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disabled',
        marked: !location.clientSteering.enable,
        selected: location.clientSteering.mode === 'disable'
      }
    ])
  );

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.registerFilter();
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'steering' });

    this.translate
      .get('configurations.steering.bandSteering')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'steering', property: 'bandSteering', translation: translated })
      );

    this.translate
      .get('configurations.steering.clientSteering')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'steering', property: 'clientSteering', translation: translated })
      );
    this.translate
      .get('configurations.steering.opensyncSteering')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'steering', property: 'bandSteering', translation: translated })
      );

    this.translate
      .get('configurations.steering.cloudSteering')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'steering', property: 'clientSteering', translation: translated })
      );
  }

  action(command: string, action: string): void {
    switch (command) {
      case 'bandSteering':
        this.store.dispatch(steeringBandSteeringSet({ value: action as any }));
        break;
    }
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_BANDSTEERING_SCREEN');
    }
  }
}
