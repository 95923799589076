import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'reportsBubble',
  templateUrl: './reportsBubble.component.html',
  styleUrls: ['./reportsBubble.component.scss']
})
export class ReportsBubbleComponent implements OnInit, OnChanges {
  @Input()
  reports: any;

  @Input()
  noFilters: any;

  @Input()
  loadingReports: any;

  @Output()
  selectBatchID: any = new EventEmitter();

  formGroup: FormGroup;
  formBuilder = new FormBuilder();

  sort: string;
  headers: any;
  list: any[];

  batchSizeOverOne: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.initHeaders();

    const formControls = {};

    this.headers.forEach((e: any) => {
      formControls[e.key] = new FormControl();
    });

    this.formGroup = this.formBuilder.group(formControls);
  }

  ngOnChanges(changes: any): void {
    if (changes.reports) {
      const unfiltered = [...this.reports];
      let filtered = null;

      if (this.headers) {
        this.headers.map((item: any) => {
          const filterName = item.key;
          const filterValue = this.formGroup.controls[filterName].value;

          if (filterValue) {
            filtered = unfiltered.filter((report: any) =>
              report[filterName]
                ? filterName === 'upgradeParameters'
                  ? report[filterName].targetVersionMatrix.toLowerCase().includes(filterValue.toLowerCase())
                  : report[filterName].toLowerCase().includes(filterValue.toLowerCase())
                : null
            );
          }
        });
      }

      this.list = filtered ? filtered : unfiltered;
    }
  }

  selectID(report: any): void {
    this.selectBatchID.emit({ report });
  }

  initHeaders(): void {
    this.sort = 'timeStamp';
    this.headers = [
      {
        key: 'id',
        value: 'netops.reports.batchID',
        desc: false
      },
      {
        key: 'upgradeParameters',
        value: 'netops.reports.targetVersion',
        desc: false
      },
      {
        key: 'batchSize',
        value: 'netops.reports.batchSize',
        desc: false
      },
      {
        key: 'userIdOfRequestor',
        value: 'netops.reports.requestedBy',
        desc: false
      },
      {
        key: 'userIdOfApprover',
        value: 'netops.reports.approvedBy',
        desc: false
      },
      {
        key: 'timestamp',
        value: 'netops.reports.timeStamp',
        desc: false
      }
    ];
  }

  filterChange(event: any, key: string): void {
    const filterString = event.target.value.toLowerCase();

    this.headers.map((item: any) => {
      const itemName = item.key;
      if (itemName !== key) {
        this.formGroup.controls[itemName].setValue('');
      }
    });

    this.list = this.reports.filter((report: any) =>
      report[key]
        ? key === 'upgradeParameters'
          ? report[key].targetVersionMatrix.toLowerCase().includes(filterString)
          : report[key].toLowerCase().includes(filterString)
        : null
    );
  }

  toggleBatchSizeOverOne(): void {
    this.batchSizeOverOne = !this.batchSizeOverOne;
  }

  changeSort(key: string = null): void {
    if (key === 'upgradeParameters' || key === 'batchSize') {
      return;
    }

    if (key) {
      if (this.sort === key) {
        this.headers.forEach((header: any) => (header.key === key ? (header.desc = !header.desc) : null));
      }

      this.sort = key;
    }

    for (const header of this.headers) {
      if (header.key === this.sort) {
        if (header.desc) {
          this.list.sort((a: any, b: any) => {
            return a[header.key] === b[header.key] ? 0 : a[header.key] < b[header.key] ? -1 : 1;
          });
        } else {
          this.list.sort((a: any, b: any) => {
            return a[header.key] === b[header.key] ? 0 : a[header.key] > b[header.key] ? -1 : 1;
          });
        }
      }
    }
  }
}
