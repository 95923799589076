<div class="container-fluid">
  <div class="header" [class.authenticated]="authenticated" [class.mobile]="isMobile">
    <div class="logo">
      <icon [path]="logoURL" class="content" (click)="home()"></icon>
    </div>
    <div class="search" *ngIf="authenticated && !isMobile && permissions?.uiFeatures.search">
      <div class="content" (toggler)="toggleSearchResults($event)">
        <div class="search-container enable">
          <slidetoggler
            [options]="searchModeItems"
            (toggle)="changeMode($event)"
            *ngIf="!isFlexAdmin && !isUprisePropertyManager"
          ></slidetoggler>
          <input
            [formControl]="query"
            class="search-input"
            type="text"
            id="search-input"
            placeholder="{{ mode.placeholder | translate }}"
            autocomplete="none"
            autoreadonly
            (keyboard)="selectResult($event)"
            [size]="customers.length"
          />
          <icon name="icon-spinner" class="size-1_5" [class.spin]="loading"></icon>
        </div>
        <div class="results" [@toggle]="state" [@listStagger]="customers.length">
          <div
            #searchElements
            class="customer"
            *ngFor="let customer of customers"
            [class.selected]="customer.selected"
            (click)="selectCustomer(customer)"
          >
            <div *ngIf="mode.type === 'id' && mode.idSearchType === 'ACCOUNT_ID'">
              {{ customer.accountId }}
            </div>
            <div class="customer-name" *ngIf="mode.type === 'uprise' && mode.idSearchType === 'PROPERTY'">
              {{ customer.property }}
              <span>{{ 'header.network' | translate }}: {{ customer.network }}</span>
            </div>
            <div class="customer-name" *ngIf="mode.type === 'id' && mode.idSearchType === 'PARTNERS_LOCATION_ID'">
              {{ customer.customer.name | customerName }}
              <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.customer.id }}</span>
              <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.id }}</span>
            </div>
            <div class="customer-name" *ngIf="mode.type === 'id' && mode.idSearchType === 'GROUPS_LOCATION_ID'">
              {{ customer.customer.name | customerName }}
              <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.customer.id }}</span>
              <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.id }}</span>
            </div>
            <div class="customer-name" *ngIf="mode.type === 'id' && mode.idSearchType === 'GLOBAL_LOCATION_ID'">
              {{ customer.name | customerName }}
              <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.id }}</span>
              <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.locationId }}</span>
            </div>
            <div class="customer-name" *ngIf="mode.type === 'id' && mode.idSearchType === 'CUSTOMER_ID'">
              {{ customer.name | customerName }}
              <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.id }}</span>
            </div>
            <div class="customer-name" *ngIf="mode.type === 'id' && mode.idSearchType === 'SERVICE_ID'">
              {{ customer.name | customerName }}
              <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.id }}</span>
              <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.locationId }}</span>
            </div>
            <div class="customer-name" *ngIf="mode.type === 'user'">
              {{ customer.name | customerName }} <span>{{ customer.email | email }}</span>
            </div>
            <div *ngIf="mode.type === 'node'">
              {{ customer.serialNumber }} {{ customer.nickname ? '(' + customer.nickname + ')' : '' }}
              <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.customerId }}</span>
              <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.locationId }}</span>
            </div>
            <div *ngIf="mode.type === 'flex'">
              {{ customer.employeeId }}
              <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.locationId }}</span>
            </div>
          </div>
          <div class="more-results" *ngIf="moreResults">
            {{ 'header.thereAreMore' | translate: { count: moreResults } }}
          </div>
          <div class="more-results" *ngIf="maxGroupResultsReached">
            {{ 'header.maxResultsReached' | translate }}
          </div>
          <div class="more-results" *ngIf="searchResultsNone">
            {{ 'header.searchResultsNone' | translate }}
          </div>
          <div class="more-results" *ngIf="searchResultsIssue">
            {{ 'header.searchResultsIssue' | translate }}
          </div>
          <div class="more-results" *ngIf="searchResultsTimeout">
            {{ 'header.searchResultsTimeout' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="info" *ngIf="authenticated">
      <div class="content">
        <cloudstatus></cloudstatus>
        <div class="end-xs auth-user">
          <div class="user">
            <div class="name">{{ user.name }}</div>
            <div class="email">{{ user.email }}</div>
            <div class="role">{{ role | translate }}</div>
          </div>
          <div class="dropdown" toggler>
            <div class="circle">
              <icon name="icon-user" class="size-1_3"></icon>
            </div>
            <div class="dropdown-menu">
              <ul>
                <li (click)="home()">{{ 'header.home' | translate }}</li>
                <li
                  *ngIf="permissions?.uiFeatures.createCustomer && !isFlexAdmin"
                  (click)="showCreateCustomer = showCreateCustomer + 1"
                >
                  {{ 'header.createCustomer' | translate }}
                </li>
                <li
                  *ngIf="permissions?.uiFeatures.deleteCustomer && enableDeleteCustomer && !isFlexAdmin"
                  [class.disable]="isUprise$ | async"
                  (click)="deleteCustomer()"
                >
                  {{ 'header.deleteCustomer' | translate }}
                </li>
                <li *ngIf="permissions?.uiFeatures.globalInventory && !isFlexAdmin" (click)="globalInventory()">
                  {{ 'header.globalInventory' | translate }}
                </li>
                <li *ngIf="ui === 'noc' && !isFlexAdmin" (click)="goto('groups')">
                  {{ 'header.groups' | translate }}
                </li>
                <li *ngIf="ui === 'noc' && !isFlexAdmin" (click)="goto('explorer')">
                  {{ 'header.apiexplorer.title' | translate }}
                </li>
                <li *ngIf="ui === 'noc' && plume.isStrictAdminRole() && !isFlexAdmin" (click)="goto('netops/firmware')">
                  {{ 'header.netops' | translate }}
                </li>
                <li (click)="showUserSettings = true">
                  {{ 'header.userSettings.menu' | translate }}
                </li>
                <li (click)="showVersion = true">
                  {{ 'header.version' | translate }}
                </li>
                <li (click)="openFeedback()">
                  {{ 'header.feedback.menu' | translate }}
                </li>
                <li (click)="logout()">{{ 'header.logout' | translate }}</li>
              </ul>
              <div class="theme">
                <slidetoggler [options]="themeItems" (toggle)="changeTheme($event)"></slidetoggler>
              </div>
              <div class="version">{{ ui === 'noc' ? 'Frontline Tier 2 & 3' : 'Frontline Tier 1' }} v{{ version }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="menu" *ngIf="authenticated">
      <icon name="icon-menu" class="size-1_5" (click)="menuToggle('open')"></icon>
      <div class="content" [class.show]="menu" *ngIf="menu">
        <div class="auth-user">
          <div class="user">
            <div class="name">{{ user.name }}</div>
            <div class="email">{{ user.email }}</div>
            <div class="role">{{ role | translate }}</div>
          </div>
          <icon name="icon-close" class="size-1_2" (click)="menuToggle('close')"></icon>
        </div>
        <ng-container *ngrxLet="locationList$ as locationList">
          <div class="locations mobile" *ngIf="locationList?.length > 1">
            <drop-down class="selector medium" *ngIf="location$ | async as location">
              {{ location?.name }}
              <ng-container dropdown>
                <ng-container *ngFor="let dropDownLocation of locationList">
                  <li (click)="changeLocation(dropDownLocation)" *ngIf="dropDownLocation.id !== location.id">
                    {{ dropDownLocation.name }}
                  </li>
                </ng-container>
              </ng-container>
            </drop-down>
          </div>
        </ng-container>
        <div class="search mobile">
          <div class="search-container" *ngIf="permissions?.uiFeatures.search && isFlexAdmin">
            <input
              [formControl]="query"
              (focus)="toggleSearchResults(true)"
              (focusout)="toggleSearchResults(false)"
              class="search-input"
              type="text"
              id="search-input-mobile"
              placeholder="{{ 'header.flexSearch' | translate }}"
              autocomplete="none"
              autoreadonly
            />
            <icon name="icon-spinner" class="size-1_5" [class.spin]="loading"></icon>
          </div>
          <div class="search-selector" *ngIf="permissions?.uiFeatures.search && !isFlexAdmin">
            <slidetoggler [options]="searchModeItems" (toggle)="changeMode($event)"></slidetoggler>
          </div>
          <div class="search-container" *ngIf="permissions?.uiFeatures.search && !isFlexAdmin">
            <input
              [formControl]="query"
              (focus)="toggleSearchResults(true)"
              (focusout)="toggleSearchResults(false)"
              class="search-input"
              type="text"
              id="search-input-mobile"
              placeholder="{{ mode.placeholder | translate }}"
              autocomplete="none"
              autoreadonly
            />
            <icon name="icon-spinner" class="size-1_5" [class.spin]="loading"></icon>
          </div>
          <div class="results" [@listStagger]="customers.length">
            <div class="customer" *ngFor="let customer of customers" (click)="selectCustomer(customer)">
              <div *ngIf="mode.type === 'id' && mode.idSearchType === 'ACCOUNT_ID'">{{ customer.accountId }}</div>
              <div class="customer-name" *ngIf="mode.type === 'uprise' && mode.idSearchType === 'PROPERTY'">
                {{ customer.property }}
                <span>{{ 'header.network' | translate }}: {{ customer.network }}</span>
              </div>
              <div class="customer-name" *ngIf="mode.type === 'id' && mode.idSearchType === 'PARTNERS_LOCATION_ID'">
                {{ customer.customer.name | customerName }}
                <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.customer.id }}</span>
                <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.id }}</span>
              </div>
              <div class="customer-name" *ngIf="mode.type === 'id' && mode.idSearchType === 'GROUPS_LOCATION_ID'">
                {{ customer.customer.name | customerName }}
                <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.customer.id }}</span>
                <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.id }}</span>
              </div>
              <div class="customer-name" *ngIf="mode.type === 'id' && mode.idSearchType === 'GLOBAL_LOCATION_ID'">
                {{ customer.name | customerName }}
                <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.id }}</span>
                <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.locationId }}</span>
              </div>
              <div class="customer-name" *ngIf="mode.type === 'id' && mode.idSearchType === 'CUSTOMER_ID'">
                {{ customer.name | customerName }}
                <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.id }}</span>
              </div>
              <div class="customer-name" *ngIf="mode.type === 'user'">
                {{ customer.name | customerName }} <span>{{ customer.email | email }}</span>
              </div>
              <div *ngIf="mode.type === 'node'">
                {{ customer.serialNumber }} {{ customer.nickname ? '(' + customer.nickname + ')' : '' }}
                <span>{{ 'header.nodeCustomerId' | translate }}: {{ customer.customerId }}</span>
                <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.locationId }}</span>
              </div>
              <div *ngIf="mode.type === 'flex'">
                {{ customer.employeeId }}
                <span>{{ 'header.nodeLocationId' | translate }}: {{ customer.locationId }}</span>
              </div>
            </div>
            <div class="more-results" *ngIf="moreResults">
              {{ 'header.thereAreMore' | translate: { count: moreResults } }}
            </div>
            <div class="more-results" *ngIf="maxGroupResultsReached">
              {{ 'header.maxResultsReached' | translate }}
            </div>
          </div>
        </div>
        <div class="logout" *ngIf="permissions?.uiFeatures.createCustomer && !isFlexAdmin">
          <button class="action" (click)="menuToggle('close'); showCreateCustomer = showCreateCustomer + 1">
            {{ 'header.createCustomer' | translate }}
          </button>
        </div>
        <div class="logout">
          <button class="action" (click)="logout()">{{ 'header.logout' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<modal class="versionModal" (overlayAction)="showVersion = $event" *ngIf="showVersion">
  <ng-container title>
    {{ 'header.versionModal.modalTitle' | translate }}
    <icon
      name="icon-copy"
      tooltip="{{ 'header.versionModal.copyToClipboard' | translate }}"
      (click)="copyMatrix()"
    ></icon>
  </ng-container>
  <ng-container content>
    <div class="table" #versionContent>
      <div class="grid" *ngFor="let endpoint of cloudMatrix">
        <div class="label">
          {{ endpoint.api }}
        </div>
        <div class="value">
          {{ endpoint.version }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showVersion = false">
      {{ 'header.versionModal.close' | translate }}
    </button>
    <button class="action" (click)="gotoReleaseNotespage()">
      {{ 'header.versionModal.fullReleaseNotes' | translate }}
    </button>
    <button class="super-primary" (click)="showReleaseNotes = true; showVersion = false">
      {{ 'header.versionModal.frontlineSummary' | translate }}
    </button>
  </ng-container>
</modal>

<createcustomer
  [open]="showCreateCustomer"
  (newCustomer)="setCustomer($event.customer, $event.location)"
></createcustomer>

<modal class="globalInventory" (overlayAction)="showGlobalInventory = $event" *ngIf="showGlobalInventory">
  <ng-container content>
    <tabs name="globalInventory" (tabChange)="globalInventoryActive = $event">
      <tab id="globalInventory" label="header.globalInventoryDialog.title">
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.id' | translate }}:</div>
          <input
            [formControl]="id"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.id' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.model' | translate }}:</div>
          <input
            [formControl]="model"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.model' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.packId' | translate }}:</div>
          <input
            [formControl]="packId"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.packId' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.partnerId' | translate }}:</div>
          <input
            [formControl]="partnerId"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.partnerId' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid" *ngIf="radio6G">
          <div class="label">{{ 'header.globalInventoryDialog.radio6G' | translate }}:</div>
          <input
            [formControl]="radio6G"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.radio6G' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.radio5G' | translate }}:</div>
          <input
            [formControl]="radio5G"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.radio5G' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.radio24G' | translate }}:</div>
          <input
            [formControl]="radio24G"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.radio24G' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.radio5GU' | translate }}:</div>
          <input
            [formControl]="radio5GU"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.radio5GU' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.radio5GL' | translate }}:</div>
          <input
            [formControl]="radio5GL"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.radio5GL' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.eth0' | translate }}:</div>
          <input
            [formControl]="eth0"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.eth0' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.eth1' | translate }}:</div>
          <input
            [formControl]="eth1"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.eth1' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.residentialGateway' | translate }}:</div>
          <div class="toggler inline">
            <div class="checkbox" (click)="residentialGateway = !residentialGateway">
              <label class="toggle" [class.checked]="residentialGateway"></label>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.claimKeyRequired' | translate }}:</div>
          <div class="toggler inline">
            <div class="checkbox" (click)="claimKeyRequired = !claimKeyRequired">
              <label class="toggle" [class.checked]="claimKeyRequired"></label>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.subscriptionRequired' | translate }}:</div>
          <div class="toggler inline">
            <div class="checkbox" (click)="subscriptionRequired = !subscriptionRequired">
              <label class="toggle" [class.checked]="subscriptionRequired"></label>
            </div>
          </div>
        </div>
      </tab>
      <tab
        id="renamePackId"
        label="header.globalInventoryDialog.renamePackID"
        *ngIf="ui === 'noc' && plume.isStrictAdminRole()"
      >
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.podID' | translate }}:</div>
          <input
            [formControl]="podID"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.podID' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
        <div class="grid">
          <div class="label">{{ 'header.globalInventoryDialog.packName' | translate }}:</div>
          <input
            [formControl]="packName"
            type="text"
            placeholder="{{ 'header.globalInventoryDialog.placeholders.packName' | translate }}"
            autocomplete="none"
            autoreadonly
          />
        </div>
      </tab>
    </tabs>
  </ng-container>
  <ng-container controls>
    <ng-container *ngIf="globalInventoryActive === 'globalInventory'">
      <button class="tertiary light" (click)="clearDownCreatingNodeForm()">
        {{ 'header.globalInventoryDialog.cancel' | translate }}
      </button>
      <button class="super-primary" (click)="confirmNewNode()">
        {{ 'header.globalInventoryDialog.submit' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="globalInventoryActive === 'renamePackId'">
      <button class="tertiary light" (click)="clearDownCreatingNodeForm()">
        {{ 'header.globalInventoryDialog.cancel' | translate }}
      </button>
      <button class="super-primary" (click)="renamePackID()">
        {{ 'header.globalInventoryDialog.submit' | translate }}
      </button>
    </ng-container>
  </ng-container>
</modal>

<modal class="releaseNotes" (overlayAction)="closeReleaseNotes()" *ngIf="showReleaseNotes">
  <ng-container title>{{ 'header.releaseNotes.title' | translate }} v{{ version }}</ng-container>
  <ng-container content>
    <span>{{ 'header.releaseNotes.' + ui + '.description' | translate }}</span>
    <ul>
      <li class="note" *ngFor="let note of releaseNotes; let i = index">
        {{ 'header.releaseNotes.' + ui + '.notes.' + i | translate }}
      </li>
    </ul>
    <div class="banner" *ngIf="checkPlumeUser()">
      <p class="title">{{ 'header.releaseNotes.plumeUserTitle' | translate }}</p>
      <p>
        {{ 'header.releaseNotes.plumeUserContent1' | translate }}
        <a href="{{ 'header.releaseNotes.plumeUserLink' | translate }}" target="_blank">
          {{ 'header.releaseNotes.plumeUserLinkContent' | translate }}
        </a>
        {{ 'header.releaseNotes.plumeUserContent2' | translate }}
        <a href="mailto:{{ 'header.releaseNotes.plumeUserEmail' | translate }}">
          {{ 'header.releaseNotes.plumeUserEmail' | translate }}
        </a>
        {{ 'header.releaseNotes.plumeUserContent3' | translate }}
      </p>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="super-primary" (click)="closeReleaseNotes()">
      {{ 'header.releaseNotes.gotIt' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" class="userSettings" (overlayAction)="showUserSettings = $event" *ngIf="showUserSettings">
  <ng-container title>{{ 'header.userSettings.title' | translate }}</ng-container>
  <ng-container content>
    <ng-container *ngIf="ui === 'noc'">
      <div class="grid">
        <div class="label">{{ 'header.userSettings.inactivityTimer' | translate }}</div>
        <div class="toggler">
          <slidetoggler [options]="inactivityTimerItems" (toggle)="changeInactivityTimer($event)"></slidetoggler>
        </div>
      </div>
    </ng-container>
    <div class="grid">
      <div class="label">{{ 'header.userSettings.language' | translate }}</div>
      <drop-down class="selector medium">
        {{ 'header.userSettings.languages.' + langSelector.current.translation | translate }}
        <ng-container dropdown>
          <li (click)="changeLang(lang)" *ngFor="let lang of langSelector.list">
            {{ 'header.userSettings.languages.' + lang.translation | translate }}
          </li>
        </ng-container>
      </drop-down>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showUserSettings = false">
      {{ 'header.userSettings.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" class="userModal" (overlayAction)="userModal.show = $event" *ngIf="userModal.show">
  <ng-container title>{{ 'header.userModal.title' | translate }}</ng-container>
  <ng-container content>
    <p>{{ 'header.userModal.description' | translate }}</p>
    <div class="grid">
      <div class="label">{{ 'header.userModal.name' | translate }}</div>
      <div class="value">
        <div class="wrapper" *ngIf="!userModal.data.name.edit">
          {{ userModal.user.name }}
        </div>
        <labeled-input *ngIf="userModal.data.name.edit">
          <input [formControl]="userModal.data.name" />
          <div info error *ngIf="userModal.data.name.errors">
            {{ 'header.userModal.validation.name' | translate }}
          </div>
        </labeled-input>
        <input-buttons
          [disabled]="!permissions?.uiFeatures.editNameEmailLocation"
          [disableConfirm]="userModal.data.name.errors"
          (editStateChange)="updateUserValue('name', 'edit', $event)"
          (action)="updateUserValue('name', $event, userModal.data.name.value)"
        ></input-buttons>
      </div>
    </div>
    <div class="grid">
      <div class="label">{{ 'header.userModal.email' | translate }}</div>
      <div class="value">
        <div class="wrapper" *ngIf="!userModal.data.email.edit">
          {{ userModal.user.email }}
        </div>
        <labeled-input *ngIf="userModal.data.email.edit">
          <input [formControl]="userModal.data.email" />
          <div info error *ngIf="userModal.data.email.errors">
            {{ 'header.userModal.validation.email' | translate }}
          </div>
        </labeled-input>
        <input-buttons
          [disabled]="!permissions?.uiFeatures.editNameEmailLocation"
          [disableConfirm]="userModal.data.email.errors"
          (editStateChange)="updateUserValue('email', 'edit', $event)"
          (action)="updateUserValue('email', $event, userModal.data.email.value)"
        ></input-buttons>
      </div>
    </div>
    <div class="grid">
      <div class="label">{{ 'header.userModal.accountId' | translate }}</div>
      <div class="value">
        <div class="wrapper" *ngIf="!userModal.data.accountId.edit">
          {{ userModal.user.accountId }}
        </div>
        <labeled-input *ngIf="userModal.data.accountId.edit">
          <input [formControl]="userModal.data.accountId" />
          <div info error *ngIf="userModal.data.accountId.errors">
            {{ 'header.userModal.validation.accountId' | translate }}
          </div>
        </labeled-input>
        <input-buttons
          [disabled]="!permissions?.uiFeatures.editAccountId"
          [disableConfirm]="userModal.data.accountId.errors"
          (editStateChange)="updateUserValue('accountId', 'edit', $event)"
          (action)="updateUserValue('accountId', $event, userModal.data.accountId.value)"
        ></input-buttons>
      </div>
    </div>
    <div class="grid">
      <div class="label">{{ 'header.userModal.locations' | translate }}</div>
      <div class="list">
        <div class="location" *ngFor="let location of userModal.locations">
          <div class="data">
            <div class="id">{{ location.location.id }}</div>
            <div class="name">{{ location.name }}</div>
            <div class="email">{{ location.email }}</div>
          </div>
          <button class="action medium" (click)="navigateUserLocation(location.ownerId, location.location.id)">
            {{ 'header.userModal.openLocation' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="label center">{{ 'header.userModal.emailVerified' | translate }}</div>
      <div class="value">
        <slidetoggler
          [editable]="permissions?.uiFeatures.editEmailVerified"
          [options]="userModal.data.emailVerified"
          (toggle)="updateUserValue('emailVerified', 'set', $event)"
        ></slidetoggler>
      </div>
    </div>
    <div class="grid">
      <div class="label center">{{ 'header.userModal.delete' | translate }}</div>
      <div class="value">
        <button
          class="action medium critical"
          [class.disabled]="!permissions?.uiFeatures.deleteCustomer"
          (click)="confirmDeleteUser()"
        >
          {{ 'header.userModal.deleteUser' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggleUserModal()">
      {{ 'header.userModal.close' | translate }}
    </button>
  </ng-container>
</modal>

<div class="feedback-overlay" (click)="closeFeedback()" *ngIf="feedback.show"></div>
<div class="feedback" *ngIf="feedback.show">
  <icon name="icon-close" class="size-0_6" (click)="closeFeedback()"></icon>
  <ng-container *ngIf="feedback.step === 'performance'">
    <div class="title">{{ 'header.feedback.performance' | translate }}</div>
    <div class="options">
      <icon class="size-2" name="icon-thumbsup" (click)="feedbackAction('yes')"></icon>
      <icon class="size-2 rotate180" name="icon-thumbsup" (click)="feedbackAction('no')"></icon>
    </div>
  </ng-container>
  <ng-container *ngIf="feedback.step === 'improve'">
    <div class="title">{{ 'header.feedback.improve' | translate }}</div>
    <textarea [formControl]="feedback.improve" rows="3"></textarea>
    <div class="buttons">
      <button class="super-primary" (click)="feedbackAction('submit')">
        {{ 'header.feedback.submit' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="feedback.step === 'thankYou'">
    <div class="title">{{ 'header.feedback.thankYou' | translate }}</div>
  </ng-container>
</div>
