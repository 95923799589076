import { Component, ComponentFactoryResolver, Directive, ElementRef, OnInit, ViewContainerRef } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Directive({
  selector: '[breadcrumbItem]'
})
export class BreadcrumbItemDirective implements OnInit {
  constructor(
    private element: ElementRef<HTMLDivElement>,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    if (!this.element.nativeElement.nextElementSibling) {
      return; // no icon for last item
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(IconComponent);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.name = 'right';
    componentRef.instance.ngOnChanges();
    const host = this.element.nativeElement;
    host.style.display = 'flex';
    (componentRef.location.nativeElement as HTMLDivElement).style.marginLeft = '0.5rem';
    (componentRef.location.nativeElement as HTMLDivElement).style.marginRight = '0.5rem';
    host.insertBefore(componentRef.location.nativeElement, null);
  }
}

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {}
