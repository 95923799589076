export class GeneralHelper {
  getRegexValidation(type: string): string {
    switch (type) {
      case 'ipv4':
        return '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$';
      case 'ipv6':
        return '^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$';
      case 'domain':
        return '^(?:(?!-)[A-Za-z0-9-]{1,63}\\.)+[A-Za-z]{2,63}$';
      default:
        return '';
    }
  }

  generateRandomString(length: number): string {
    const randomCharset = 'abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let random = '';
    for (let c = 0, cl = randomCharset.length; c < length; ++c) {
      random += randomCharset[Math.floor(Math.random() * cl)];
    }
    return random;
  }

  formatBytes(value: number, unit: string, decimals: number = 2, k: number = 1024): { value: number; unit: string } {
    if (unit.length === 2) {
      unit = unit.toUpperCase();
    }

    if (!value || value === 0) {
      return { value: 0, unit };
    }

    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const bytes = value * Math.pow(k, sizes.indexOf(unit));

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return {
      value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
      unit: sizes[i]
    };
  }

  isGateway(nodeId: string, nodes: any[]): boolean {
    let onlineNodes = 0;
    let bridgeNodes = 0;
    let foundNode = null;

    for (const node of nodes) {
      if (node.connectionState === 'connected') {
        onlineNodes++;

        if (node.networkMode === 'bridge') {
          bridgeNodes++;
        }
      }

      if (node.id === nodeId) {
        if (node.networkMode === 'router') {
          return true;
        } else {
          foundNode = { ...node };
        }
      }
    }

    if (bridgeNodes === onlineNodes && foundNode) {
      return (
        foundNode.isGateway ??
        (!(foundNode.leafToRoot && foundNode.leafToRoot.length > 0) && foundNode.backhaulType === 'ethernet')
      );
    } else {
      return false;
    }
  }

  hasClientNetwork(vaps: string[]): boolean {
    return vaps.indexOf('home') > -1 || vaps.indexOf('fronthaul') > -1 || vaps.indexOf('captivePortal') > -1;
  }

  isPasswordValid(password: string): { valid: boolean; code: number; error: string } {
    // tslint:disable:quotemark
    const isSpc = new RegExp("^.*[#?!@$%^&(){}_|'*-]+").test(password);
    const isab = new RegExp('^.*[a-z]+').test(password);
    const isAB = new RegExp('^.*[A-Z]+').test(password);
    const isNum = new RegExp('^.*[0-9]+').test(password);
    const isLen = password?.length > 7;
    let cnt = 0;

    if (isab) {
      cnt++;
    }

    if (isAB) {
      cnt++;
    }

    if (isNum) {
      cnt++;
    }

    if (isSpc) {
      cnt++;
    }

    if (cnt < 2) {
      return { valid: false, code: -2, error: 'Failed character check' };
    }

    if (!isLen) {
      return { valid: false, code: -1, error: 'Too Short' };
    }

    return { valid: true, code: 0, error: '' };
  }

  truncateString(value: string, maxLength: number): string {
    if (value.length <= maxLength) {
      return value;
    }

    return value.slice(0, maxLength) + '...';
  }

  download(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();
  }

  wpaMode(mode: string): string {
    return 'health.networkInformation.wpaModes.' + mode;
  }
}
