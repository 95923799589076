import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { IconService } from 'src/app/lib/services/icon.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { MacAddrPipe } from 'src/app/lib/pipes/mac-addr.pipe';
import { selectNodeIcon } from 'src/app/store/lte/lte.selectors';
import { selectIsUpriseLocation, selectPartnerId } from 'src/app/store/customer/customer.selectors';
import { pollingPull } from 'src/app/store/polling/polling.actions';
import * as moment from 'moment';

@Component({
  selector: 'node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss']
})
export class NodeComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  node: any = {};

  @Output()
  delete = new EventEmitter<string>();

  @ViewChild('input')
  input: ElementRef;

  ui: string = '';
  permissions: any;
  subscription: any;
  name: FormControl = new FormControl();
  loading: boolean = false;
  ledMode: string = '';
  show5GModal: boolean = false;
  showQoeModal: boolean = false;
  showHardwareInfoModal: boolean = false;
  showIPV6Modal: boolean = false;
  showPublicIPModal: boolean = false;
  showLedModeOption: boolean = true;
  showNodeRenameOption: boolean = true;
  showNodeRebootOption: boolean = true;
  showDeleteOption: boolean = true;
  showPuncturingModal: boolean = false;

  congestionFrequencyItems: any[] = [];
  congestionPeriodItems: any[] = [];
  congestionFrequency: string = '2.4G';
  congestionPeriod: number = 1;
  congestionStats: any = null;

  hardwareInfo: any = {};

  bandwidthFrequencyItems: any[] = [];
  bandwidthPeriodItems: any[] = [];
  bandwidthFrequency: string = '2.4G';
  bandwidthPeriod: number = 1;
  bandwidthStats: any = null;
  moment = moment;
  gateWayIcon$ = this.store.pipe(selectNodeIcon(''));
  isDemo$ = this.store.select(selectPartnerId).pipe(map((partnerId) => partnerId === 'Lte-Plume-Demo'));
  isUprise$ = this.store.select(selectIsUpriseLocation);

  puncInfo: any = [];
  puncChan: any = [];


  demo5gWAN = {
    'Frequency Band': 71,
    'Connection Type': '5G NR',
    Bandwidth: '40MHz',
    'Current RSSI': -73.42,
    RSRP: -100.65,
    RSRQ: -9.44,
    SINR: 12.79,
    Carrier: 'T-Mobile',
    MMC: 310,
    MNC: 260,
    CellID: 16501311,
    TAC: 31891
  };

  constructor(
    private toast: ToastService,
    private router: Router,
    private plume: PlumeService,
    private troubleshoot: TroubleshootingService,
    private modal: ModalService,
    private mixpanel: MixpanelService,
    private modelRef: ModelRefService,
    private icons: IconService,
    private nodeService: NodeService,
    private store: Store,
    private macAddr: MacAddrPipe
  ) {}

  ngOnInit(): void {
    this.ui = this.plume.getUI();
    this.gateWayIcon$ = this.store.pipe(selectNodeIcon(this.node.id));

    this.subscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });

    this.ledMode = 'locate';
    this.showLedModeOption = this.plume.isStrictSupportRole() || this.plume.isFlexRole() ? false : true;
    this.showNodeRenameOption = this.plume.isStrictSupportRole() || this.plume.isFlexRole() ? false : true;
    this.showDeleteOption = this.plume.isFlexRole() ? false : true;
    this.showNodeRebootOption =
      (!this.plume.cloudVersionAbove1_119() && this.plume.isStrictSupportRole()) || this.plume.isFlexRole()
        ? false
        : true;
    this.initiateSliders();
    this.prepareHardwareInfo();
    this.preparePunctureInfo();
  }

  getHMS(secs: number): { h: string; m: string; s: string } {
    const time = moment.utc(secs * 1000);

    return {
      h: `${time.format('HH')}`,
      m: `${time.format('mm')}`,
      s: `${time.format('ss')}`
    };
  }

  getIcon(model: string): string {
    return this.modelRef.get(model).icon;
  }

  getIconPath(device: any): string {
    if (device?.iconV3) {
        return this.icons.getV3Path(device.iconV3, 'small');
    }
    return this.icons.getPath(device.kind?.type?.iconV2 || device.iconV2);
  }

  ngOnChanges(changes: any): void {
    if (changes.node.currentValue && changes.node.currentValue.connectionState === 'disconnected') {
      this.loading = false;
    }
  }

  initiateSliders(): void {
    this.congestionPeriodItems = [
      { value: 1, translation: 'nodes.node.slider.24h', selected: true },
      { value: 7, translation: 'nodes.node.slider.7days', selected: false }
    ];

    if ('2gChannel' in this.node || 'radioMac24' in this.node) {
      this.congestionFrequencyItems.push({
        value: '2.4G',
        translation: 'nodes.node.slider.24ghz',
        selected: true
      });
    }

    if ('5glChannel' in this.node || 'radioMac50L' in this.node) {
      this.congestionFrequencyItems.push({ value: '5GL', translation: 'nodes.node.slider.5GL', selected: false });
    }

    if ('5guChannel' in this.node || 'radioMac50U' in this.node) {
      this.congestionFrequencyItems.push({ value: '5GU', translation: 'nodes.node.slider.5GU', selected: false });
    }

    if ('5gChannel' in this.node || 'radioMac50' in this.node) {
      this.congestionFrequencyItems.push({ value: '5G', translation: 'nodes.node.slider.5G', selected: false });
    }

    if ('6gChannel' in this.node || 'radioMac60' in this.node) {
      this.congestionFrequencyItems.push({ value: '6G', translation: 'nodes.node.slider.6G', selected: false });
    }

    this.bandwidthPeriodItems = [
      { value: 1, translation: 'nodes.node.slider.24h', selected: true },
      { value: 7, translation: 'nodes.node.slider.7days', selected: false }
    ];

    this.bandwidthFrequencyItems = [
      ...(this.node.radioMac24 || '2gChannel' in this.node
        ? [{ value: '2.4G', translation: 'nodes.node.slider.24ghz', selected: true }]
        : []),
      ...(this.node.radioMac50 || '5gChannel' in this.node
        ? [{ value: '5G', translation: 'nodes.node.slider.5G', selected: false }]
        : []),
      ...(this.node.radioMac50L || '5glChannel' in this.node
        ? [{ value: '5GL', translation: 'nodes.node.slider.5GL', selected: false }]
        : []),
      ...(this.node.radioMac50U || '5guChannel' in this.node
        ? [{ value: '5GU', translation: 'nodes.node.slider.5GU', selected: false }]
        : []),
      ...(this.node.radioMac60 || '6gChannel' in this.node
        ? [{ value: '6G', translation: 'nodes.node.slider.6G', selected: false }]
        : [])
    ];

    this.bandwidthFrequency = this.bandwidthFrequencyItems[0]?.value;
  }

  copyLink(event: any): void {
    event.stopPropagation();
    const deviceURL =
      location.host +
      '/customer/' +
      this.plume.customerid +
      '/location/' +
      this.plume.locationid +
      '/nodes/' +
      this.node.id;

    const copyElement = document.createElement('textarea');
    copyElement.style.position = 'fixed';
    copyElement.style.opacity = '0';
    copyElement.textContent = deviceURL;
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(copyElement);
    copyElement.select();
    document.execCommand('copy');
    body.removeChild(copyElement);

    this.toast.success('nodes.node.linkCopied', 'nodes.node.success', {
      params: {
        name: this.node.nickname
      }
    });
  }

  toggle5GModal(state: boolean): void {
    this.show5GModal = state;
  }

  toggleQoeModal(state: boolean): void {
    this.showQoeModal = state;
  }

  toggleIPV6Modal(state: boolean): void {
    this.showIPV6Modal = state;
  }

  togglePublicIPModal(state: boolean): void {
    this.showPublicIPModal = state;
  }

  preparePunctureInfo(): void {
    if (this.node?.radioStats && this.node?.radioStats.length) {
      this.puncInfo = [];
      this.puncInfo['2.4G'] = this.node.radioStats.find((ch) => ch.freqBand === '2.4G')?.channelWidth;
      this.puncInfo['5G'] = this.node.radioStats.find((ch) => ch.freqBand === '5G')?.channelWidth;
      this.puncInfo['5GL'] = this.node.radioStats.find((ch) => ch.freqBand === '5GL')?.channelWidth;
      this.puncInfo['5GU'] = this.node.radioStats.find((ch) => ch.freqBand === '5GU')?.channelWidth;
      this.puncInfo['6G'] = this.node.radioStats.find((ch) => ch.freqBand === '6G')?.channelWidth;
      this.puncChan = [];
      this.puncChan.push ( { key: 'nodes.node.wifi2g', radio: '2.4G', puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '2.4G')?.puncturedChannels  } );
      this.puncChan.push ( { key: 'nodes.node.wifi5g', radio: '5G', puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '5G')?.puncturedChannels  } );
      this.puncChan.push ( { key: 'nodes.node.wifi5g1', radio: '5GL', puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '5GL')?.puncturedChannels  } );
      this.puncChan.push ( { key: 'nodes.node.wifi5g2', radio: '5GU', puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '5GU')?.puncturedChannels  } );
      this.puncChan.push ( { key: 'nodes.node.wifi6g', radio: '6G', puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '6G')?.puncturedChannels  } );
    }
  }

  prepareHardwareInfo(): void {
    this.hardwareInfo = [
      { key: 'nodes.node.hardwareInfoDialog.id', value: this.node.id },
      { key: 'nodes.node.hardwareInfoDialog.model', value: this.node.model },
      { key: 'nodes.node.hardwareInfoDialog.ethernet0Mac', value: this.macAddr.transform(this.node.mac) },
      { key: 'nodes.node.hardwareInfoDialog.ethernet1Mac', value: this.macAddr.transform(this.node.ethernet1Mac) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac24', value: this.macAddr.transform(this.node.radioMac24) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac50', value: this.macAddr.transform(this.node.radioMac50) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac50L', value: this.macAddr.transform(this.node.radioMac50L) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac50U', value: this.macAddr.transform(this.node.radioMac50U) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac60', value: this.macAddr.transform(this.node.radioMac60) },
      { key: 'nodes.node.hardwareInfoDialog.bluetoothMac', value: this.macAddr.transform(this.node.bluetoothMac) },
    ];
    if (this.node.openSyncVersion) {
      this.hardwareInfo.push({
        key: 'nodes.node.hardwareInfoDialog.openSyncVersion',
        value: this.node.openSyncVersion
      });
    }

    if (this.node.vendorName) {
      this.hardwareInfo.push({ key: 'nodes.node.hardwareInfoDialog.vendorName', value: this.node.vendorName });
    }


  }

  track(index: number, device: any): string {
    return device.mac;
  }

  redirectToDevice(device: any): void {
    if (!this.plume.hidePersonalDetails()) {
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'devices',
        device.mac
      ]);
    }
  }

  gotoQoe(): void {
    this.router.navigate([
      'customer',
      this.plume.customerid,
      'location',
      this.plume.locationid,
      'qoe',
      'nodes',
      this.node.id
    ]);
  }

  toggleDeviceList(): void {
    this.node.deviceListOpen = !this.node.deviceListOpen;
    this.mixpanel.storeEvent('NODES_EXPAND_DEVICES', { NODE_ID: this.node.id, EXPANDED: this.node.deviceListOpen });
  }

  action(command: string, action: any): void {
    switch (command) {
      case 'congestionFrequency':
        this.congestionFrequency = action;
        break;
      case 'congestionPeriod':
        this.congestionPeriod = action;
        break;
      case 'bandwidthFrequency':
        this.bandwidthFrequency = action;
        break;
      case 'bandwidthPeriod':
        this.bandwidthPeriod = action;
        break;
    }

    if (command.indexOf('congestion') >= 0) {
      this.getCongestionGraphData();
    }

    if (command.indexOf('bandwidth') >= 0) {
      this.getBandwidthUsageData();
    }
  }

  toggleChannelCongestionGraph(): void {
    this.node.channelCongestionGraphOpen = !this.node.channelCongestionGraphOpen;
    this.mixpanel.storeEvent('NODES_EXPAND_CONGESTION_GRAPH', {
      NODE_ID: this.node.id,
      EXPANDED: this.node.channelCongestionGraphOpen
    });
    this.getCongestionGraphData();
  }

  getCongestionGraphData(): void {
    this.congestionStats = null;
    this.node.congestionChart = {
      interference: {
        series: {
          color: 'var(--chart-yellow)',
          text: 'congestionChartInterference',
          translation: 'nodes.node.channelCongestionGraph.congestionChartInterference'
        },
        axis: 'left',
        data: []
      },
      total: {
        series: {
          color: 'var(--chart-green)',
          text: 'congestionChartTotal',
          translation: 'nodes.node.channelCongestionGraph.congestionChartTotal'
        },
        axis: 'left',
        data: []
      }
    };

    this.nodeService
      .channelUtilization$(
        this.node.id,
        this.congestionFrequency,
        this.congestionPeriod === 1 ? 'hours' : 'days',
        this.congestionPeriod === 1 ? 24 : 7
      )
      .subscribe((response: any) => {
        const interference = response?.interference?.length > 0 ? this.fillEmpty(response.interference) : [];
        const total = response?.total?.length > 0 ? this.fillEmpty(response.total) : [];

        this.node.congestionChart.interference = new Line(
          new Series(
            'var(--chart-yellow)',
            'congestionChartInterference',
            'nodes.node.channelCongestionGraph.congestionChartInterference'
          ),
          'left',
          interference.map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
        );

        this.node.congestionChart.total = new Line(
          new Series(
            'var(--chart-green)',
            'congestionChartTotal',
            'nodes.node.channelCongestionGraph.congestionChartTotal'
          ),
          'left',
          total.map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
        );

        this.congestionStats =
          response.interference.length || response.total.length
            ? {
                interference: Math.round(this.statsCalc('mean', response.interference)) + '%',
                total: Math.round(this.statsCalc('mean', response.total)) + '%'
              }
            : null;
      });
  }

  fillEmpty(data: any[]): any[] {
    const current = this.round(
      this.congestionPeriod === 1 ? 'minute' : 'hours',
      this.congestionPeriod === 1 ? 15 : 3,
      moment()
    );
    const ticks = [];

    for (let i = 0; i < (this.congestionPeriod === 1 ? 97 : 57); i++) {
      const timestamp = current.utc().format('YYYY-MM-DD[T]HH:mm:ss.[000Z]');
      const value = data.find((tick: any) => tick.timestamp === timestamp)?.value || null;
      ticks.push({ timestamp, value });
      current.subtract(this.congestionPeriod === 1 ? 15 : 180, 'minutes');
    }

    return ticks;
  }

  round(mode: any, interval: number, moment: any): any {
    if (mode === 'minute') {
      return moment
        .utc()
        .clone()
        .minute(Math.floor(moment.minute() / interval) * interval)
        .second(0);
    } else {
      return moment
        .utc()
        .clone()
        .hours(Math.round(moment.hours() / interval) * interval)
        .minute(0)
        .second(0);
    }
  }

  toggleBandwidthUsageGraph(): void {
    this.node.bandwidthUsageGraphOpen = !this.node.bandwidthUsageGraphOpen;
    this.mixpanel.storeEvent('NODES_EXPAND_BANDWIDTH_GRAPH', {
      NODE_ID: this.node.id,
      EXPANDED: this.node.bandwidthUsageGraphOpen
    });
    this.getBandwidthUsageData();
  }

  getBandwidthUsageData(): void {
    this.bandwidthStats = null;
    this.node.bandwidthChart = {
      received: {
        series: {
          color: 'var(--chart-yellow)',
          text: 'bandwidthChartReceived',
          translation: 'nodes.node.bandwidthUsageGraph.bandwidthChartReceived'
        },
        axis: 'left',
        data: []
      },
      transmitted: {
        series: {
          color: 'var(--chart-green)',
          text: 'bandwidthChartTransmitted',
          translation: 'nodes.node.bandwidthUsageGraph.bandwidthChartTransmitted'
        },
        axis: 'left',
        data: []
      }
    };

    this.nodeService
      .bandwidth$(
        this.node.id,
        this.bandwidthFrequency,
        this.bandwidthPeriod === 1 ? 'hours' : 'days',
        this.bandwidthPeriod === 1 ? 24 : 7
      )
      .subscribe((response: any) => {
        this.node.bandwidthChart.received = new Line(
          new Series(
            'var(--chart-yellow)',
            'bandwidthChartReceived',
            'nodes.node.bandwidthUsageGraph.bandwidthChartReceived'
          ),
          'left',
          response.received.map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
        );

        this.node.bandwidthChart.transmitted = new Line(
          new Series(
            'var(--chart-green)',
            'bandwidthChartTransmitted',
            'nodes.node.bandwidthUsageGraph.bandwidthChartTransmitted'
          ),
          'left',
          response.transmitted.map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
        );

        this.bandwidthStats =
          response.received.length || response.transmitted.length
            ? {
                received: Math.round(this.statsCalc('sum', response.received)) + ' MB',
                transmitted: Math.round(this.statsCalc('sum', response.transmitted)) + ' MB'
              }
            : null;
      });
  }

  statsCalc(mode: string, data: any[]): number {
    let sum = 0;
    data.forEach((item: any) => (sum += item.value));

    if (mode === 'mean') {
      return sum / data.length;
    }

    if (mode === 'sum') {
      return sum;
    }

    return 0;
  }

  launchRename(): void {
    if (!this.node.isRename) {
      this.nodeService.setLedMode$(this.node.id, 'locate').subscribe((response: any) => {
        this.mixpanel.storeEvent('NODES_RENAME_ENABLE', { NODE_ID: this.node.id });
        this.node.isRename = true;

        setTimeout(() => {
          this.input.nativeElement.focus();
        }, 100);
      });
    } else {
      this.node.isRename = false;
    }
  }

  confirmRename(): void {
    this.nodeService.setLedMode$(this.node.id, 'normal').subscribe((response: any) => {
      const currentName = this.node.nickname || this.node.name;

      if (currentName !== this.name.value) {
        this.nodeService.rename$(this.node.id, this.name.value).subscribe(
          (response: any) => {
            this.mixpanel.storeEvent('NODES_RENAME_SUCCESS', {
              NODE_ID: this.node.id,
              OLD_NAME: currentName,
              NEW_NAME: this.name.value
            });

            this.node.nickname = this.name.value;
            this.node.isRename = false;
            this.name.reset();

            this.store.dispatch(pollingPull({ debugSource: 'node rename' }));

            this.toast.success('toast.nodeStrip.successNameMessage', 'toast.nodeStrip.successNameTitle');
          },
          (error: any) => {
            this.mixpanel.storeEvent('NODES_RENAME_ERROR', {
              NODE_ID: this.node.id,
              OLD_NAME: currentName,
              NEW_NAME: this.name.value,
              ERROR: error.error.error.message
            });

            this.node.isRename = false;
            this.name.reset();

            this.toast.error('toast.nodeStrip.errorNameMessage', 'toast.nodeStrip.errorNameTitle', {
              params: {
                error: error.error.error.message
              }
            });
          }
        );
      } else {
        this.node.isRename = false;
        this.name.reset();
      }
    });
  }

  cancelRename(): void {
    this.nodeService.setLedMode$(this.node.id, 'normal').subscribe((response: any) => {
      this.mixpanel.storeEvent('NODES_RENAME_DISABLE', { NODE_ID: this.node.id });
      this.node.isRename = false;
      this.name.reset();
    });
  }

  reboot(): void {
    this.mixpanel.storeEvent('NODES_REBOOT_DIALOG', { NODE_ID: this.node.id });
    this.modal
      .showDialog('nodes.node.modal.messageReboot', 'nodes.node.modal.title', {
        buttons: [
          { style: 'tertiary light', value: 'nodes.node.modal.cancel' },
          { style: 'super-primary', value: 'nodes.node.modal.reboot' }
        ]
      })
      .subscribe((response: any) => {
        if (response.item?.value === 'nodes.node.modal.reboot') {
          this.loading = true;
          this.mixpanel.storeEvent('NODES_REBOOT_CONFIRM', { NODE_ID: this.node.id });
          this.troubleshoot.resetNode(this.node.id).subscribe();
        }
      });
  }

  setLedMode(mode: 'locate' | 'normal'): void {
    this.nodeService.setLedMode$(this.node.id, mode).subscribe((response: any) => {
      this.mixpanel.storeEvent('NODES_LOCATE', { NODE_ID: this.node.id });
    });

    if (this.ledMode === 'normal') {
      this.ledMode = 'locate';
    } else {
      this.ledMode = 'normal';
    }
  }

  deleteNode(id: string): void {
    this.isUprise$
      .pipe(
        take(1),
        filter((isUprise) => !isUprise),
        switchMap(() => this.nodeService.deleteNode$(id, false, true))
      )
      .subscribe(
        (response: any) => {
          this.delete.emit(id);
          this.mixpanel.storeEvent('NODES_DELETE_NODE_SUCCESS', { NODE_ID: id });
          this.toast.success('toast.node.successNodeDeletedMessage', 'toast.node.successNodeDeletedTitle', {
            params: {
              id
            }
          });
        },
        (error: any) => {
          this.mixpanel.storeEvent('NODES_DELETE_NODE_ERROR', { NODE_ID: id, ERROR: error.error.error.message });
          this.toast.error(error.error.error.message, 'toast.node.errorFindNodeTitle');
        }
      );
  }

  launchDeleteNode(): void {
    this.isUprise$
      .pipe(
        take(1),
        filter((isUprise) => !isUprise),
        tap(() => this.mixpanel.storeEvent('NODES_DELETE_NODE_DIALOG', { NODE_ID: this.node.id })),
        switchMap(() =>
          this.modal.showDialog('nodes.node.modal.message', 'nodes.node.modal.title', {
            params: { name: this.node.nickname || this.node.defaultName },
            buttons: [
              { style: 'tertiary light', value: 'nodes.node.modal.cancel' },
              { style: 'super-primary', value: 'nodes.node.modal.confirm' }
            ]
          })
        )
      )
      .subscribe((response) => {
        if (response.item?.value === 'nodes.node.modal.confirm') {
          this.deleteNode(this.node.id);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
