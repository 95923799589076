import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {
  show: boolean = false;

  constructor() {}

  ngOnInit(): void {
    const cookie = localStorage.getItem('cookie');

    if (cookie !== 'true') {
      this.show = true;
    }
  }

  accept(): void {
    localStorage.setItem('cookie', 'true');
    this.show = false;
  }
}
