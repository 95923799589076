import { IParsedHostName } from 'src/app/lib/interfaces/interface';

const clientIds = {
  beta: {
    prod: '0oavliiygEcuep6ZE356',
    stg: '0oavlh2si0KVXE6gr356',
    dev: '0oavli5og6AlOyGcl356',
    noc_prod: '0oa169rzos6ojMkSk357',
    noc_stg: '0oa169rzos6ojMkSk357',
    noc_dev: '0oa169rzos6ojMkSk357'
  },
  gamma: {
    prod: '0oavlcw1ujirVSm5K356',
    stg: '0oavlei45YqxY5ayO356',
    dev: '0oavlefutcoMf2poa356',
    noc_prod: '0oa16a2cw1IIfsm7N357',
    noc_stg: '0oa16a2cw1IIfsm7N357',
    noc_dev: '0oa16a2cw1IIfsm7N357'
  },
  kappa: {
    prod: '0oaok2ovllN6YTDDa356',
    stg: '0oaok3brpdcfLtKXi356',
    dev: '0oaojt7wxd0UqoPIE356',
    noc_prod: '0oa16a23wujewFtSQ357',
    noc_stg: '0oa16a23wujewFtSQ357',
    noc_dev: '0oa16a23wujewFtSQ357'
  },
  iota: {
    prod: '0oa7xgnbx5tvV0i3L357',
    stg: '0oa7xgnbx5tvV0i3L357',
    dev: '0oa7xgnbx5tvV0i3L357',
    noc_prod: '0oa7xgko96Y6ZyX34357',
    noc_stg: '0oa7xgko96Y6ZyX34357',
    noc_dev: '0oa7xgko96Y6ZyX34357'
  },
  phi: {
    prod: '0oa137qxqosavarP1358',
    stg: '0oa137qxqosavarP1358',
    dev: '0oa137qxqosavarP1358',
    noc_prod: '0oa137r6c8rJI6hUj358',
    noc_stg: '0oa137r6c8rJI6hUj358',
    noc_dev: '0oa137r6c8rJI6hUj358'
  },
  pml: {
    prod: '0oavlhd3hyyZn1rZA356',
    stg: '0oavlk1nwbVprn2zg356',
    dev: '0oavli3rlJOGoFXyI356',
    vodafone: {
      prod: '0oavlhd3hyyZn1rZA356',
      stg: '0oavlk1nwbVprn2zg356',
      dev: '0oavli3rlJOGoFXyI356'
    }
  },
  sigma: {
    prod: '0oavljs8z0StNdniJ356',
    stg: '0oavljhv6jtlpo781356',
    dev: '0oavlk3i7yDnVL23t356',
    vodafone: {
      prod: '0oavljs8z0StNdniJ356',
      stg: '0oavljhv6jtlpo781356',
      dev: '0oavlk3i7yDnVL23t356'
    },
    noc_prod: '0oa16a9zk4fAhaHMR357',
    noc_stg: '0oa16a9zk4fAhaHMR357',
    noc_dev: '0oa16a9zk4fAhaHMR357'
  },
  theta: {
    prod: '0oavlkm8sUvnMUOqt356',
    stg: '0oavlpjy9y00fAnlP356',
    dev: '0oavllntoLgCqsTsc356',
    noc_prod: '0oa16ab4lpvTteTLP357',
    noc_stg: '0oa16ab4lpvTteTLP357',
    noc_dev: '0oa16ab4lpvTteTLP357'
  },
  dogfood: {
    prod: '0oavlr5jgo4343Eym356',
    stg: '0oavlrp2h6ZYBKNSe356',
    dev: '0oavls5k7cEbKdIk8356',
    noc_prod: '0oacjghkdGWW0iZMs356',
    noc_stg: '0oacjghkdGWW0iZMs356',
    noc_dev: '0oacjghkdGWW0iZMs356'
  },
  etna: {
    prod: '0oavlvkn1FNEZhF3g356',
    stg: '0oavlvo6z3ih5wNMU356',
    dev: '0oavluix4qQRDIsBf356',
    noc_prod: '0oa1d204zmgIMGTgh357',
    noc_stg: '0oa1d204zmgIMGTgh357',
    noc_dev: '0oa1d204zmgIMGTgh357'
  },
  etna2: {
    prod: '0oa17g5q88y55owF1357',
    stg: '0oa17g5q88y55owF1357',
    dev: '0oa17g5q88y55owF1357',
    noc_prod: '0oa17g5q88y55owF1357',
    noc_stg: '0oa17g5q88y55owF1357',
    noc_dev: '0oa17g5q88y55owF1357'
  },
  func4: {
    prod: '0oa13azppxNN3mBYB357',
    stg: '0oa13azppxNN3mBYB357',
    dev: '0oa13azppxNN3mBYB357',
    noc_prod: '0oa13azppxNN3mBYB357',
    noc_stg: '0oa13azppxNN3mBYB357',
    noc_dev: '0oa13azppxNN3mBYB357'
  },
  'aio-dev01': {
    prod: '0oa1h5jx0g0tfMxlo357',
    stg: '0oa1h5jx0g0tfMxlo357',
    dev: '0oa1h5jx0g0tfMxlo357',
    noc_prod: '0oa1h5jx0g0tfMxlo357',
    noc_stg: '0oa1h5jx0g0tfMxlo357',
    noc_dev: '0oa1h5jx0g0tfMxlo357'
  },
  'aio-qa03': {
    prod: '0oavlhd3hyyZn1rZA356',
    stg: '0oavlk1nwbVprn2zg356',
    dev: '0oavli3rlJOGoFXyI356',
    noc_prod: '0oa19vl43ozvlA97i357',
    noc_stg: '0oa19vl43ozvlA97i357',
    noc_dev: '0oa19vl43ozvlA97i357'
  },
  'aio-qa04': {
    prod: '0oa17g5q88y55owF1357',
    stg: '0oa17g5q88y55owF1357',
    dev: '0oa17g5q88y55owF1357',
    noc_prod: '0oa17g5q88y55owF1357',
    noc_stg: '0oa17g5q88y55owF1357',
    noc_dev: '0oa17g5q88y55owF1357'
  },
  padev2: {
    prod: '0oa40cx68p9Z8OGiY357',
    stg: '0oa40cx68p9Z8OGiY357',
    dev: '0oa40cx68p9Z8OGiY357',
    noc_prod: '0oa40cx68p9Z8OGiY357',
    noc_stg: '0oa40cx68p9Z8OGiY357',
    noc_dev: '0oa40cx68p9Z8OGiY357'
  },
  thetadev: {
    prod: '0oavsak8ajSCKvQNE356',
    stg: '0oavsgbh4IEeqKciW356',
    dev: '0oavsg2qqMmP29X7y356',
    noc_prod: '0oa19vn8zuKIIuex4357',
    noc_stg: '0oa19vn8zuKIIuex4357',
    noc_dev: '0oa19vn8zuKIIuex4357'
  },
  'theta-stage': {
    prod: '0oaqvs5xjwfqAZR5H357',
    stg: '0oaqvs5xjwfqAZR5H357',
    dev: '0oaqvs5xjwfqAZR5H357',
    noc_prod: '0oaqvslnlfeUpJgVC357',
    noc_stg: '0oaqvslnlfeUpJgVC357',
    noc_dev: '0oaqvslnlfeUpJgVC357'
  },
  chi: {
    prod: '0oavrqpyy6QPV44ZV356',
    stg: '0oavrqlcnVWyFDDqb356',
    dev: '0oavrq5x8IWqbDVHE356'
  },
  delta: {
    prod: '0oavs03nslUUtriPR356',
    stg: '0oavs0suhtjmKynWp356',
    dev: '0oavs0kri8Rt2Z2mY356',
    noc_prod: '0oa1iv8lq9DsgSFez357',
    noc_stg: '0oa1iv8lq9DsgSFez357',
    noc_dev: '0oa1iv8lq9DsgSFez357'
  },
  opensyncacademy: {
    prod: '0oavs38e3AATk4zPp356',
    stg: '0oavs372e9pj63tPb356',
    dev: '0oavs4200dZjnPhxi356',
    noc_prod: '0oa1c05vrfekpRWN5357',
    noc_stg: '0oa1c05vrfekpRWN5357',
    noc_dev: '0oa1c05vrfekpRWN5357'
  },
  opensync: {
    prod: '0oavs57h7H6o8rLIs356',
    stg: '0oavsdlenRRVwiTV1356',
    dev: '0oavselj6xRwsH4gI356',
    noc_prod: '0oa1b6l3tkwVQ1fye357',
    noc_stg: '0oa1b6l3tkwVQ1fye357',
    noc_dev: '0oa1b6l3tkwVQ1fye357'
  },
  osync: {
    prod: '0oavs5nnvTd6afdsD356',
    stg: '0oavsc46iZOF8Egxu356',
    dev: '0oavscz2wy1KPVTCp356',
    noc_prod: '0oa1c07kumEP40DQ9357',
    noc_stg: '0oa1c07kumEP40DQ9357',
    noc_dev: '0oa1c07kumEP40DQ9357'
  },
  ci: {
    prod: '0oaja8eoq2vFt5nA10h7',
    stg: '0oaja8eoq2vFt5nA10h7',
    dev: '0oaja8eoq2vFt5nA10h7',
    noc_prod: '0oajlnu7zq0fjP0i00h7',
    noc_stg: '0oajlnu7zq0fjP0i00h7',
    noc_dev: '0oajlnu7zq0fjP0i00h7'
  },
  'rho-dev': {
    prod: '0oarkusthbJaFsr3U357',
    stg: '0oarkusthbJaFsr3U357',
    dev: '0oarkusthbJaFsr3U357',
    noc_prod: '0oarkunkhdfCqdzAh357',
    noc_stg: '0oarkunkhdfCqdzAh357',
    noc_dev: '0oarkunkhdfCqdzAh357'
  },
  rho: {
    prod: '0oatet3415CjBsv7G357',
    stg: '0oatet3415CjBsv7G357',
    dev: '0oatet3415CjBsv7G357',
    noc_prod: '0oatet01avnMTLG2z357',
    noc_stg: '0oatet01avnMTLG2z357',
    noc_dev: '0oatet01avnMTLG2z357'
  },
  'tau-dev3': {
    prod: '0oa1tsl0szbtfH9hw357',
    stg: '0oa1tsl0szbtfH9hw357',
    dev: '0oa1tsl0szbtfH9hw357',
    noc_prod: '0oa1tsl0szbtfH9hw357',
    noc_stg: '0oa1tsl0szbtfH9hw357',
    noc_dev: '0oa1tsl0szbtfH9hw357'
  },
  'tau-int': {
    prod: '0oa3gefkyfFnoViwP357',
    stg: '0oa3gefkyfFnoViwP357',
    dev: '0oa3gefkyfFnoViwP357',
    noc_prod: '0oa3gefkyfFnoViwP357',
    noc_stg: '0oa3gefkyfFnoViwP357',
    noc_dev: '0oa3gefkyfFnoViwP357'
  },
  'tau-perf': {
    prod: '0oa3gekkn0BTGrabc357',
    stg: '0oa3gekkn0BTGrabc357',
    dev: '0oa3gekkn0BTGrabc357',
    noc_prod: '0oa3gekkn0BTGrabc357',
    noc_stg: '0oa3gekkn0BTGrabc357',
    noc_dev: '0oa3gekkn0BTGrabc357'
  },
  'tau-stage': {
    prod: '0oa7qkviq3SgxA5Ai357',
    stg: '0oa7qkviq3SgxA5Ai357',
    dev: '0oa7qkviq3SgxA5Ai357',
    noc_prod: '0oa7qkviq3SgxA5Ai357',
    noc_stg: '0oa7qkviq3SgxA5Ai357',
    noc_dev: '0oa7qkviq3SgxA5Ai357'
  },
  'tau-prod': {
    prod: '0oa991vlj9OEZhZiV357',
    stg: '0oa991vlj9OEZhZiV357',
    dev: '0oa991vlj9OEZhZiV357',
    noc_prod: '0oa991vlj9OEZhZiV357',
    noc_stg: '0oa991vlj9OEZhZiV357',
    noc_dev: '0oa991vlj9OEZhZiV357'
  }
};

export function hostNameWithoutDomain(): string {
  return window.location.hostname
    .replace(/\.plume\.com$/, '')
    .replace(/\.[^\.]+\.comcast\.net$/, '')
    .replace(/\.jio-hs\.net$/, '');
}

export function hostNameParse(): IParsedHostName {
  const hostnameBits = hostNameWithoutDomain().split('.').reverse();

  return {
    deployment: hostnameBits[0] as any,
    cloud: hostnameBits[1],
    partner: hostnameBits[2],
    group: hostnameBits[3],
    lang: hostnameBits[4]
  };
}

export function generateOktaConfig(env: string = null): any {
  const config = {
    domain: 'https://dev-741674.oktapreview.com',
    vanity: 'https://external-dev.sso.plume.com',
    clientId: '0oaja8eoq2vFt5nA10h7',
    scope: 'openid profile email plume central'
  };

  const { cloud, deployment, lang, partner } = hostNameParse();

  if (cloud || (deployment && env)) {
    if (!env) {
      env = cloud;
    }

    if (env in clientIds) {
      if (env !== 'ci') {
        config.domain = 'https://plume.okta.com';
        config.vanity = 'https://external.sso.plume.com';
      }

      let client = clientIds[env];

      if (partner in clientIds[env]) {
        client = clientIds[env][partner];

        if (lang in clientIds[env][partner]) {
          client = clientIds[env][partner][lang];
        }
      }

      if (deployment === 'central') {
        config.clientId = client.prod;
      }

      if (deployment === 'central-stg') {
        config.clientId = client.stg;
      }

      if (deployment === 'central-dev') {
        config.clientId = client.dev;
      }

      if (deployment === 'noc') {
        config.clientId = client.noc_prod;
      }

      if (deployment === 'noc-stg') {
        config.clientId = client.noc_stg;
      }

      if (deployment === 'noc-dev') {
        config.clientId = client.noc_dev;
      }
    }
  }

  return config;
}

export const okta = generateOktaConfig();
