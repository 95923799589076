import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { selectDevices } from 'src/app/store/polling/polling.selector';
import { map } from 'rxjs/operators';
import { selectReducedMode } from 'src/app/store/customer/customer.selectors';
import * as moment from 'moment';

@Component({
  selector: 'homepass',
  templateUrl: './homepass.component.html',
  styleUrls: ['./homepass.component.scss']
})
export class HomepassComponent implements OnInit, OnDestroy {
  @Input()
  network: any = {};

  @Output()
  networks: any = new EventEmitter<any>();

  permissionsSubscription: any;
  permissions: any;
  reducedMode$ = this.store.select(selectReducedMode);
  selectedDevices: any[] = [];

  homepassDescriptionFormControl: FormControl = new FormControl({ value: '', disabled: true });
  homepassKeyFormControl: FormControl = new FormControl();
  homepass = {
    add: {
      description: 'home',
      type: {
        show: false,
        current: 'home',
        list: ['internetAccessOnly', 'guests']
      },
      expiresAt: '',
      devices: {
        selected: [],
        list$: this.store
          .select(selectDevices)
          .pipe(
            map((devices) =>
              this.prepareDevices(devices?.filter((device: any) => device.accessZoneType === 'home') ?? [])
            )
          )
      },
      enabled: true,
      errorDesc: false,
      errorPsk: false
    }
  };

  advancedDialog = {
    show: false,
    showExpiresAt: false,
    expiresAt: '',
    showDevices: false,
    readOnly: false,
    showExpiredAtItems: [
      { value: true, translation: 'health.networkInformation.modal.yes', selected: false },
      { value: false, translation: 'health.networkInformation.modal.no', selected: true }
    ]
  };

  disabledItems: any[] = [
    { value: false, translation: 'health.networkInformation.modal.yes', selected: false },
    { value: true, translation: 'health.networkInformation.modal.no', selected: true }
  ];

  constructor(
    private plume: PlumeService,
    private customerService: CustomerService,
    private toast: ToastService,
    private modal: ModalService,
    private mixpanel: MixpanelService,
    private store: Store,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.permissionsSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });
  }

  prepareDevices(devices: any): any {
    return devices.map((device: any) => {
      return {
        text: device.name,
        value: device.mac,
        object: device
      };
    });
  }

  getNetwork(): void {
    this.networks.emit();
  }

  trackHomepass(index: number, key: any): void {
    return key.id;
  }

  addHomepassKey(): void {
    let description = true;
    let password = true;

    this.homepass.add.errorDesc = false;
    this.homepass.add.errorPsk = false;

    if (this.homepass.add.type.current === 'guests' && !this.homepassDescriptionFormControl.value) {
      description = false;
      this.homepass.add.errorDesc = true;
      this.toast.warning(
        'health.networkInformation.toast.descriptionMsg',
        'health.networkInformation.toast.descriptionTitle'
      );
    }

    if (!this.homepassKeyFormControl.value || this.homepassKeyFormControl.value.length < 8) {
      password = false;
      this.homepass.add.errorPsk = true;
      this.toast.warning(
        'health.networkInformation.toast.passwordMsg',
        'health.networkInformation.toast.passwordTitle'
      );
    }

    if (description && password) {
      if (this.homepass.add.type.current === 'guests') {
        const zone = {
          description: this.homepassDescriptionFormControl.value,
          type: 'guests' as const,
          accessibleDevices: this.homepass.add.devices.selected
        };

        this.customerService.addAccessZone$(zone).subscribe(
          (response: any) => {
            const params: { encryptionKey: string; enable: boolean; format: 'encryptionKey'; expiresAt?: string } = {
              encryptionKey: this.homepassKeyFormControl.value,
              enable: this.homepass.add.enabled,
              format: 'encryptionKey'
            };
            if (this.homepass.add.expiresAt) {
              params.expiresAt = this.homepass.add.expiresAt;
            }

            this.customerService.addAccessZoneKey$(response.id, params).subscribe(
              () => {
                this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_ADD_KEY_SUCCESS', {
                  HOMEPASS_KEY_TYPE: this.homepass.add.type.current
                });
                this.getNetwork();
                this.resetHomepass();
              },
              (error: any) => {
                this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_ADD_KEY_FAILURE', {
                  HOMEPASS_KEY_TYPE: this.homepass.add.type.current,
                  ERROR: 'password exists'
                });
                this.customerService.deleteAccessZone$(response.id).subscribe();

                this.homepass.add.errorPsk = true;
                this.toast.warning(error.error.error.message, 'health.networkInformation.toast.passwordExistTitle');
              }
            );
          },
          (error: any) => {
            this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_ADD_KEY_FAILURE', {
              HOMEPASS_KEY_TYPE: this.homepass.add.type.current,
              ERROR: 'description exists'
            });
            this.homepass.add.errorDesc = true;
            this.toast.warning(error.error.error.message, 'health.networkInformation.toast.keyErrorTitle');
          }
        );
      } else {
        const params: { encryptionKey: string; enable: boolean; format: 'encryptionKey'; expiresAt?: string } = {
          encryptionKey: this.homepassKeyFormControl.value,
          enable: this.homepass.add.enabled,
          format: 'encryptionKey'
        };
        if (this.homepass.add.expiresAt) {
          params.expiresAt = this.homepass.add.expiresAt;
        }

        this.customerService.addAccessZoneKey$(this.homepass.add.type.current, params).subscribe(
          () => {
            this.getNetwork();
            this.resetHomepass();
            this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_ADD_KEY_SUCCESS', {
              HOMEPASS_KEY_TYPE: this.homepass.add.type.current
            });
          },
          (error: any) => {
            this.homepass.add.errorPsk = true;
            this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_ADD_KEY_FAILURE', {
              HOMEPASS_KEY_TYPE: this.homepass.add.type.current
            });
            this.toast.warning(error.error.error.message, 'health.networkInformation.toast.passwordExistTitle');
          }
        );
      }
    }
  }

  confirmDeleteHomepass(key: any): void {
    this.modal
      .showDialog(
        'health.networkInformation.popup.wantDeleteHomepassMsg',
        'health.networkInformation.popup.wantDeleteTitle',
        {
          buttons: [
            { style: 'tertiary light', value: 'health.networkInformation.popup.cancel' },
            { style: 'super-primary', value: 'health.networkInformation.popup.deleteHomepass' }
          ]
        }
      )
      .subscribe((response: any) => {
        if (response.item?.value === 'health.networkInformation.popup.deleteHomepass') {
          this.deleteHomepass(key.accessZone, key.accessZoneId, key.id);
        }
      });
  }

  deleteHomepass(accessZone: string, zoneId: number, id: number): void {
    if (accessZone === 'guests') {
      this.customerService.deleteAccessZone$(zoneId).subscribe(() => {
        this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_DELETE_ZONE_SUCCESS', {
          HOMEPASS_ZONE_ID: zoneId,
          HOMEPASS_KEY_TYPE: accessZone
        });
        this.getNetwork();
      });
    } else {
      this.customerService.deleteAccessZoneKey$(zoneId, id).subscribe(
        () => {
          this.getNetwork();
          this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_DELETE_KEY_SUCCESS', { HOMEPASS_KEY_ID: id });
        },
        () => {
          this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_DELETE_KEY_FAILURE', { HOMEPASS_KEY_ID: id });
          this.toast.warning(
            'health.networkInformation.toast.cannotDeleteMsg',
            'health.networkInformation.toast.cannotDeleteTitle'
          );
        }
      );
    }
  }

  editHomepass(key: any): void {
    const input: any = document.querySelector('#homepass-' + key.id);

    key.error = false;

    if (input.value.length > 7) {
      const params = {
        encryptionKey: input.value,
        enable: key.enable,
        format: 'encryptionKey' as const
      };

      this.customerService.editAccessZoneKey$(key.accessZoneId, key.id, params).subscribe(
        () => {
          this.getNetwork();
          key.editing = false;
          this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_EDIT_KEY_SUCCESS', { HOMEPASS_KEY_ID: key.id });
        },
        (error: any) => {
          key.error = true;
          this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_EDIT_KEY_FAILURE', { HOMEPASS_KEY_ID: key.id });
          this.toast.warning(error.error.error.message, 'health.networkInformation.toast.passwordExistTitle');
        }
      );
    } else {
      key.error = true;
      this.mixpanel.storeEvent('HEALTH_EDIT_HOMEPASS_EDIT_KEY_FAILURE', { HOMEPASS_KEY_ID: key.id, ERROR: 'PASSWORD' });
      this.toast.warning(
        'health.networkInformation.toast.passwordMsg',
        'health.networkInformation.toast.passwordTitle'
      );
    }
  }

  resetHomepass(): void {
    this.homepassDescriptionFormControl.reset();
    this.homepassDescriptionFormControl.disable();
    this.homepassKeyFormControl.reset();
    this.selectedDevices = [];
    this.homepass = {
      ...this.homepass,
      ...{
        add: {
          description: 'home',
          type: {
            show: false,
            current: 'home',
            list: ['internetAccessOnly', 'guests']
          },
          expiresAt: '',
          devices: {
            selected: [],
            list$: this.homepass.add.devices.list$
          },
          enabled: true,
          errorDesc: false,
          errorPsk: false
        }
      }
    };

    this.disabledItems = [
      { value: false, translation: 'health.networkInformation.modal.yes', selected: false },
      { value: true, translation: 'health.networkInformation.modal.no', selected: true }
    ];
  }

  selectType(type: string): void {
    this.homepass.add.type.current = type;
    this.homepass.add.type.list = ['home', 'internetAccessOnly', 'guests'].filter((t: string) => t !== type);
    this.homepass.add.type.show = false;
    this.homepass.add.expiresAt = '';

    if (type === 'guests') {
      this.homepass.add.description = 'description';
      this.homepassDescriptionFormControl.enable();
    } else {
      this.selectedDevices = [];
      this.homepass.add.description = type;
      this.homepass.add.devices.selected = [];
      this.homepassDescriptionFormControl.reset();
      this.homepassDescriptionFormControl.disable();
    }
  }

  selectDevice(selected: any[]): void {
    this.selectedDevices = selected;
  }

  openAdvanced(devices: string[], expiresAt: string, showDevices: boolean, readOnly: boolean): void {
    this.selectedDevices = devices ? [...devices] : [];

    this.advancedDialog.show = true;
    this.advancedDialog.expiresAt = expiresAt;
    this.advancedDialog.showExpiresAt = !!this.advancedDialog.expiresAt;
    this.advancedDialog.showDevices = showDevices;
    this.advancedDialog.readOnly = readOnly;
    this.advancedDialog.showExpiredAtItems = [
      { value: true, translation: 'health.networkInformation.modal.yes', selected: this.advancedDialog.showExpiresAt },
      { value: false, translation: 'health.networkInformation.modal.no', selected: !this.advancedDialog.showExpiresAt }
    ];
  }

  saveAdvanced(): void {
    this.advancedDialog.show = false;
    this.homepass.add.devices.selected = this.selectedDevices;
    this.homepass.add.expiresAt = this.advancedDialog.showExpiresAt
      ? moment(this.advancedDialog.expiresAt).toISOString()
      : '';
  }

  cancelAdvanced(): void {
    this.advancedDialog.show = false;
  }

  setAdvancedDialogExpirationDate(date: string): void {
    this.advancedDialog.expiresAt = date;
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
  }
}
