import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IQoeLiveChartScale } from 'src/app/lib/d3/models/charts/qoelive.chart';
import { IconService } from 'src/app/lib/services/icon.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { selectPowerManagementState } from 'src/app/store/customer/customer.selectors';

@UntilDestroy()
@Component({
  selector: 'qoestriplive',
  templateUrl: './qoestriplive.component.html',
  styleUrls: ['./qoestriplive.component.scss']
})
export class QoestripliveComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: any = {};

  @Input() type: 'node' | 'device' = 'node';

  loopSubscription: any;
  showLiveCharts: boolean = false;
  showCurrentQoe: boolean = false;
  expanded: boolean = false;
  iconURL: string = '';
  rssiModeItems: any[] = [];
  rssiMode: string = '';
  rssiRange: {
    static: IQoeLiveChartScale['range'];
    dynamic: IQoeLiveChartScale['range'];
  } = { static: [-95, -40], dynamic: [-95, -40] };
  hideStrips: string[] = [];
  powerCapable: boolean = false;

  constructor(
    private qoe: QoeService,
    private troubleshooting: TroubleshootingService,
    private mixpanel: MixpanelService,
    private icons: IconService,
    private modal: ModalService,
    private store: Store,
    private toast: ToastService,
    private plume: PlumeService
  ) {}

  ngOnInit(): void {
    this.setRssiMode('dynamic');

    if (this.data.expand) {
      this.toggleExpand();
    }

    this.store
      .select(selectPowerManagementState)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.powerCapable = data.capable;
      });
  }

  ngOnChanges(): void {
    this.getIconPath();

    if (this.data.liveData) {
      this.data.liveCharts = this.qoe.lineCharts(
        JSON.parse(JSON.stringify(this.data.liveData)),
        this.data.demo ? this.data.demo.current.values : {}
      );

      this.calculateRssiRange();
    }
  }

  getIconPath(): void {
    if (this.data.type === 'pod') {
      this.iconURL = this.data.icon;
    } else {
      this.iconURL = this.data?.iconV3
        ? this.icons.getV3Path(this.data.iconV3, 'small')
        : this.icons.getPath(this.data?.iconV2);
    }
  }

  isShowChart(id: string): boolean {
    return !this.hideStrips.includes(id);
  }

  hideGraph(id: string): void {
    if (this.hideStrips.includes(id)) {
      this.hideStrips = this.hideStrips.filter((sid) => sid !== id);
    } else {
      this.hideStrips.push(id);
    }
  }

  toggleExpand(): void {
    this.mixpanel.storeEvent('QOE_CHANGE_EXPAND', {
      EXPANDED: this.expanded,
      QOE_TYPE: this.data.type === 'pod' ? 'node' : 'device',
      LIVE_MODE: this.data.liveMode,
      ID: this.data.id
    });

    if (!this.expanded) {
      this.expanded = true;
      this.getLiveData();
    } else {
      this.expanded = false;
      this.showLiveCharts = false;
      this.data.liveData = null;
      this.data.liveCharts = null;
      this.calculateRssiRange();

      if (this.loopSubscription) {
        clearTimeout(this.loopSubscription);
      }
    }
  }

  getLiveData(): void {
    (this.data.type === 'pod'
      ? this.qoe.nodeLiveModeStream$(this.data.id)
      : this.qoe.deviceLiveModeStream$(this.data.id)
    )
      .pipe(catchError((error: any) => of(error)))
      .subscribe((response: any) => {
        if (response?.snr) {
          response.dynamicRssi = response.snr.map((data: any) => {
            return {
              timestamp: data.timestamp,
              value: data.value
                ? this.data.rawQoe.noiseFloor
                  ? data.value + parseFloat(this.data.rawQoe.noiseFloor)
                  : data.value - 95
                : null
            };
          });
        }

        if (response?.isLpm) {
          response.isLpm = response.isLpm.map((lpm: any) => {
            return {
              timestamp: lpm.timestamp,
              value: lpm.value ? 1 : null
            };
          });
        }

        this.data.liveData = JSON.parse(JSON.stringify(response));
        this.data.liveCharts = this.qoe.lineCharts(response, this.data.demo ? this.data.demo.current.values : {});
        this.calculateRssiRange();
        this.showLiveCharts = true;

        this.loopSubscription = setTimeout(() => this.getLiveData(), 60 * 1000);
      });
  }

  calculateRssiRange(): void {
    this.rssiRange = { static: [-95, -40], dynamic: [-95, -40] };

    if (this.data?.liveCharts?.dynamicRssi.allData.find((data) => data.value > -40)) {
      this.rssiRange.dynamic[1] = 0;
    }

    if (this.data.liveCharts?.snr.allData.find((data) => data.value > -40)) {
      this.rssiRange.static[1] = 0;
    }
  }

  resolveAction(action: string): void {
    switch (action) {
      case 'qoe.recommendations.actions.rebootNode':
        if (this.data.type === 'pod') {
          if ((this.plume.cloudVersionAbove1_119() || !this.plume.isStrictSupportRole()) && !this.plume.isFlexRole()) {
            this.troubleshooting.resetNode(this.data.id).subscribe();
            this.mixpanel.storeEvent('QOE_RECOMMENDATION_REBOOT_NODE', { NODE_ID: this.data.id });
          } else {
            this.toast.error('qoe.recommendations.roleMsg', 'qoe.recommendations.roleTitle');
          }
        }
        break;
      case 'qoe.recommendations.actions.resetDevice':
        if (this.data.type === 'device') {
          this.troubleshooting.resetDevice(this.data.id, this.data.parentNodeId, this.data.freqBand).subscribe();
          this.mixpanel.storeEvent('QOE_RECOMMENDATION_RESET_DEVICE', {
            DEVICE_MAC: this.data.id,
            PARENT_ID: this.data.parentNodeId,
            FREQ_BAND: this.data.freqBand
          });
        }
        break;
    }
  }

  setRssiMode(mode: any): void {
    if (mode) {
      this.rssiMode = mode;
    }

    this.rssiModeItems = [
      {
        value: 'static',
        translation: 'qoe.charts.staticNoiseFloor',
        selected: this.rssiMode === 'static' ? true : false
      },
      {
        value: 'dynamic',
        translation: 'qoe.charts.dynamicNoiseFloor',
        selected: this.rssiMode === 'dynamic' ? true : false
      }
    ];
  }

  showModal(): void {
    this.modal.showDialog('qoe.rssiModalMessage', 'qoe.rssiModalTitle', {
      buttons: [{ style: 'tertiary light', value: 'qoe.close' }]
    });
  }

  ngOnDestroy(): void {
    if (this.loopSubscription) {
      clearTimeout(this.loopSubscription);
    }
  }
}
