import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import {
  IManager,
  IManagerAdded,
  INetworkAccessDeviceGroup,
  INetworkAccessNetworks,
  IPerson
} from '../interfaces/interface';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class NetworkAccessService {
  constructor(private api: ApiService, private store: Store) {}

  networks$(): Observable<INetworkAccessNetworks[]> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/networkAccess/networks`))
    );
  }

  patchNetwork$(networkId: string, data: Partial<INetworkAccessNetworks>): Observable<{ purgatory: boolean }> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/networkAccess/networks/${networkId}`, data))
    );
  }

  block$(mac: string): Observable<undefined> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/networkAccess/blocked`, [mac]))
    );
  }

  unblock$(mac: string): Observable<undefined> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/networkAccess/blocked/${mac}`))
    );
  }

  approve$(mac: string, networkId: string): Observable<undefined> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/networkAccess/networks/${networkId}/approved`, [mac]))
    );
  }

  unApprove$(mac: string, networkId: string): Observable<undefined> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/networkAccess/networks/${networkId}/approved/${mac}`))
    );
  }

  deviceGroups$(networkId: string): Observable<INetworkAccessDeviceGroup[]> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/networkAccess/networks/${networkId}/deviceGroups`))
    );
  }

  addHomeGroup$(
    networkId: string,
    name: string,
    devices: string[]
  ): Observable<{ devices: string[]; groupId: string; name: string; networkId: string }> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.post(`${baseUrl}/networkAccess/networks/${networkId}/deviceGroups`, {
          name,
          devices
        })
      )
    );
  }

  addFronthaulGroup$(name: string, devices: string[]): Observable<IPerson> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.post(`${baseUrl}/persons`, {
          nickname: name,
          imageId: 'PROFILE_MAN',
          assignedDevices: devices,
          profile: { type: 'employee' }
        })
      )
    );
  }

  editGroups$(
    mode: 'home' | 'fronthaul',
    networkId: string,
    groupId: string,
    name: string,
    devices: string[],
    groups: string[]
  ): Observable<unknown> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) =>
        (mode === 'home'
          ? this.api.patch(`${baseUrl}/networkAccess/networks/${networkId}/deviceGroups/${groupId}`, {
              name,
              devices
            })
          : this.api.patch(`${baseUrl}/persons/${groupId}`, {
              nickname: name,
              assignedDevices: devices
            })
        ).pipe(
          switchMap(() =>
            this.api.put(`${baseUrl}/networkAccess/networks/${networkId}/deviceGroups/${groupId}/groupShares`, {
              groups,
              devices: []
            })
          )
        )
      )
    );
  }

  deleteHomeGroup$(networkId: string, groupId: string): Observable<unknown> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/networkAccess/networks/${networkId}/deviceGroups/${groupId}`))
    );
  }

  deleteFronthaulGroup$(groupId: string): Observable<unknown> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/persons/${groupId}`))
    );
  }

  managers$(): Observable<IManager[]> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/managers`))
    );
  }

  addManager$(name: string, email: string): Observable<IManagerAdded> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.post(`${baseUrl}/managers`, {
          name,
          email,
          accessType: 'admin'
        })
      )
    );
  }

  deleteManager$(id: string): Observable<undefined> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/managers/${id}`))
    );
  }

  managerResendInvite$(managerId: string): Observable<undefined> {
    return this.store.select(selectBaseUrl).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/managers/${managerId}/resendInvite`, {}))
    );
  }
}
