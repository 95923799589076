import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from 'src/app/lib/services/toast.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import * as moment from 'moment';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Component({
  selector: 'internetconnectionalarm',
  templateUrl: './internetconnectionalarm.component.html',
  styleUrls: ['./internetconnectionalarm.component.scss']
})
export class InternetconnectionalarmComponent implements OnInit, OnDestroy {
  @Input()
  alarm: any = {};
  themeSubscription: any;
  expand: boolean = false;
  offlineTime: any;
  enableGuidedTroubleshootAccess: boolean = false;
  @Input() isGuidedTroubleshootPubnubSubscribed: boolean = false;

  @Output() openTroubleShoot: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private toast: ToastService, private modelRef: ModelRefService, private theme: ThemeService, private plume: PlumeService, ) {}

  ngOnInit(): void {
    this.offlineTime = moment.utc(this.alarm.connectionStateChangeAt).local().format('L LT');
    this.enableGuidedTroubleshootAccess = this.plume.getPermissions().guidedTroubleshootingAccess;
    this.themeSubscription = this.theme.listener.subscribe((theme: any) => {
      this.prepare();
    });
  }

  prepare(): void {
    if (this.alarm) {
      const model = this.modelRef.get(this.alarm.model);

      if (model) {
        if (this.alarm.kind) {
          this.alarm.kind.icon = model.icon;
        } else {
          this.alarm.kind = { icon: model.icon };
        }
      }
    }
  }

  openGuidedTroubleshoot(): void {
    this.openTroubleShoot.emit(true);
  }
  preventExpand(event: Event): void {
    event.stopPropagation();
  }
  ngOnDestroy(): void {
    if (this.alarm.type === 'extender') {
      this.toast.success('toast.internetConnectionAlarm.successMessage', 'toast.internetConnectionAlarm.successTitle', {
        params: {
          name: this.alarm.kind.name
        }
      });
    }

    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
}
