import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import produce from 'immer';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/lib/services/auth.service';
import { CaptiveService } from 'src/app/lib/services/captive.service';
import {  publishClosed } from 'src/app/store/captive-portal/captive-portal.actions';
import {
  selectCaptivePortalConfig,
  selectCaptivePortalLoginOptions,
  selectCaptivePortalPreviewErrorMsg,
  selectCaptivePortalPreviewMode,
  selectCaptivePortalPreviewUrl,
  selectCaptivePortalPublishMode
} from 'src/app/store/captive-portal/captive-portal.selectors';

@Component({
  selector: 'captive-preview',
  templateUrl: './captive-preview.component.html',
  styleUrls: ['./captive-preview.component.scss']
})
export class CaptivePreviewComponent {
  loadingPreview: boolean = false;
  previewMode$ = this.store.select(selectCaptivePortalPreviewMode).pipe(tap(() => (this.loadingPreview = true)));
  previewUrl$ = this.store.select(selectCaptivePortalPreviewUrl);
  previewErrorMsg$ = this.store.select(selectCaptivePortalPreviewErrorMsg);
  publishMode$ = this.store.select(selectCaptivePortalPublishMode);

  loginOptions$ = combineLatest([
    this.store.select(selectCaptivePortalConfig),
    this.store.select(selectCaptivePortalLoginOptions)
  ]).pipe(
    map(([config, loginOption]) =>
      loginOption
        .filter((loginOpt) => config?.loginOptions?.options.find((configOpt) => configOpt.name === loginOpt.data.name))
        .sort((a, b) => (a.data.name > b.data.name ? 1 : -1))
    )
  );

  portal$ = this.store.select(selectCaptivePortalConfig).pipe(
    map((config) => {
      const updatedConfig = produce(config, (draftConfig) => {
        if (typeof draftConfig.branding.background === 'string' && draftConfig.branding.background.startsWith('url(')) {
          const relativeUrl = draftConfig.branding.background.match(/url\(['"]?(.*?)['"]?\)/)?.[1];
          if (relativeUrl && !relativeUrl.startsWith('https://')) {
            const formattedUrl = `${this.auth.getEnvFromUrl().mywifi}${relativeUrl}`;
            draftConfig.branding.background = formattedUrl;
          }
        }
      });
      return {
        multilingual: config.advancedOptions.language.showMultilingual,
        language: this.captive.getLanguage(config.advancedOptions.language.defaultLanguage.lang_code),
        name: config.branding.titleText,
        logo: config.branding.logo,
        footer: this.valueTokenHandler(config.branding.campaignFooter.support),
        color: config.branding.textColor,
        background: updatedConfig.branding.background,
        backgroundRepeatAlignment: config.branding.backgroundRepeatAlignment?.value,
        logoSize: config.branding.logoImageSize,
        layer: config.branding.layer,
        backgroundFilter: this.getBackgroundFilter(config.branding.layer)
      };
    })
  );

  getBackgroundFilter(layer: { enabled: boolean; layerColor: string }): string {
    if (!layer.enabled) {
      return 'none';
    }

    if (layer.layerColor === '#FFFFFF80') {
      return 'brightness(1.2)';
    }

    if (layer.layerColor === '#00000080') {
      return 'brightness(0.7)';
    }

    return 'none';
  }
  constructor(private store: Store, private captive: CaptiveService, private auth: AuthService) {}

  doneLoading(): void {
    setTimeout(() => {
      this.loadingPreview = false;
    }, 10);
  }

  valueTokenHandler(input: string): string {
    if (!input) {
      return null;
    }
    if (input.includes('{%YEAR%}')) {
      return input.replace(/{%YEAR%}/g, new Date().getFullYear().toString());
    }

    return input;
  }

  closePublish(): void {
    this.store.dispatch(publishClosed());
  }
}
