import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { selectCustomerIdentification } from 'src/app/store/customer/customer.selectors';
import { take, tap } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'wanstats',
  templateUrl: './wanstats.component.html',
  styleUrls: ['./wanstats.component.scss']
})
export class WanStatsComponent implements OnInit, OnChanges {
  @Input()
  reset: any;

  @Input()
  wanStatsMonthly: any;

  @Input()
  wanStatsWeekly: any;

  @Input()
  wanStatsDaily: any;

  wanStats: any = {};
  loading: boolean = true;
  helper: GeneralHelper = new GeneralHelper();
  url = '';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectCustomerIdentification)
      .pipe(
        take(1),
        tap((data) => {
          this.url = '/customer/' + data.customerid + '/location/' + data.locationid;
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: any): void {
    if (changes.reset) {
      this.wanStats = {};
      this.loading = true;
    }

    if (changes.wanStatsWeekly || changes.wanStatsDaily || changes.wanStatsMonthly) {
      this.prepare();
    }
  }

  prepare(): void {
    const response24h = this.wanStatsDaily?.fifteenMins || null;
    const response7d = this.wanStatsWeekly?.hours || null;
    const response30d = this.wanStatsMonthly?.days || null;
    const data = {
      download24h: 0,
      download7d: 0,
      download30d: 0,
      hero24h: { value: 0, unit: '' },
      hero7d: { value: 0, unit: '' },
      hero30d: { value: 0, unit: '' },
      consumption24h: '',
      consumption7d: '',
      consumption30d: '',
      chart24h: [],
      chart7d: [],
      nullValues24h: 0,
      nullValues7d: 0,
      nullValues30d: 0,
      noData24h: false,
      noData7d: false,
      noData30d: false
    };

    if (response30d) {
      response30d.forEach((day: any, index: number) => {
        if (day.rxMbytes !== null) {
          data.download30d += day.rxMbytes;
        } else {
          data.nullValues30d++;
        }

        if (index < 7) {
          if (day.rxMbytes !== null) {
            data.download7d += day.rxMbytes;
          } else {
            data.nullValues7d++;
          }

          const timestamp = moment(day.timestamp).local().startOf('day').valueOf();

          data.chart7d.push({
            timestamp,
            value: day.rxMbytes ? day.rxMbytes : 0,
            date: moment(timestamp).format('l ddd'),
            datetime: moment(timestamp).format('L LT')
          });
        }
      });

      data.chart7d.forEach((datapoint) => {
        datapoint.total = data.download7d;
        datapoint.percent = datapoint.total === 0 ? -1 : (datapoint.value * 100) / datapoint.total;
        datapoint.amount = this.helper.formatBytes(datapoint.value, 'MB');
        datapoint.consumption = this.getPercentColor(datapoint.percent);
      });

      data.chart7d = data.chart7d.reverse();

      if (data.nullValues30d !== response30d.length) {
        data.hero30d = this.helper.formatBytes(data.download30d, 'MB');

        if (data.download30d <= 300000) {
          data.consumption30d = 'low';
        }

        if (data.download30d > 300000 && data.download30d <= 990000) {
          data.consumption30d = 'medium';
        }

        if (data.download30d > 990000) {
          data.consumption30d = 'high';
        }
      } else {
        data.noData30d = true;
        data.consumption30d = 'none';
        data.hero30d = this.helper.formatBytes(0, 'MB');
      }

      if (data.nullValues7d !== 7) {
        data.hero7d = this.helper.formatBytes(data.download7d, 'MB');

        if (data.download7d <= 70000) {
          data.consumption7d = 'low';
        }

        if (data.download7d > 70000 && data.download7d <= 231000) {
          data.consumption7d = 'medium';
        }

        if (data.download7d > 231000) {
          data.consumption7d = 'high';
        }
      } else {
        data.noData7d = true;
        data.consumption7d = 'none';
        data.hero7d = this.helper.formatBytes(0, 'MB');
      }
    }

    if (response7d) {
      response7d.forEach((hours: any, index: number) => {
        if (index < 24) {
          if (hours.rxMbytes !== null) {
            data.download24h += hours.rxMbytes;
          } else {
            data.nullValues24h++;
          }

          const timestamp = moment(hours.timestamp).local().startOf('hour').valueOf();

          if (index % 4 === 0) {
            data.chart24h.push({
              timestamp,
              value: hours.rxMbytes ? hours.rxMbytes : 0,
              date: moment(timestamp).format('LT'),
              datetime: moment(timestamp).format('L LT')
            });
          } else {
            data.chart24h[data.chart24h.length - 1].value += hours.rxMbytes ? hours.rxMbytes : 0;
          }
        }
      });

      data.chart24h.forEach((datapoint: any) => {
        datapoint.total = data.download24h;
        datapoint.percent = datapoint.total === 0 ? -1 : (datapoint.value * 100) / datapoint.total;
        datapoint.amount = this.helper.formatBytes(datapoint.value, 'MB');
        datapoint.consumption = this.getPercentColor(datapoint.percent);
      });

      data.chart24h = data.chart24h.reverse();

      if (data.nullValues24h !== 24) {
        data.hero24h = this.helper.formatBytes(data.download24h, 'MB');

        if (data.download24h <= 10000) {
          data.consumption24h = 'low';
        }

        if (data.download24h > 10000 && data.download24h <= 33000) {
          data.consumption24h = 'medium';
        }

        if (data.download24h > 33000) {
          data.consumption24h = 'high';
        }
      } else {
        data.noData24h = true;
        data.consumption24h = 'none';
        data.hero24h = this.helper.formatBytes(0, 'MB');
      }
    }

    this.wanStats = data;
    this.loading = false;
  }

  getPercentColor(percent: number): string {
    if (percent >= 40) {
      return 'percent100';
    }

    if (percent >= 30) {
      return 'percent80';
    }

    if (percent >= 20) {
      return 'percent60';
    }

    if (percent >= 10) {
      return 'percent40';
    }

    return 'percent20';
  }
}
