import { scaleLinear, scaleTime, extent, ScaleTime, ScaleLinear } from 'd3';
import { MultiLine } from '../objects/line';
import { Dot } from '../objects/dot';
import { Tick } from '../objects/tick';

export interface IQoeLiveChartScale {
  symbol: string;
  timeSpan?: number;
  range: [number, number];
  thresholdValue?: number;
  above?: boolean;
}

export class QoeliveChart {
  private data: MultiLine[];
  private lines: MultiLine[];
  private width: number;
  private height: number;
  private xScale: ScaleTime<number, number>;
  private yScale: ScaleLinear<number, number>;
  private scale: IQoeLiveChartScale = null;
  private margins = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepareScales(): void {
    this.xScale = scaleTime().range([0, this.calculateWidth()]);
    this.yScale = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareDomains(): void {
    const xdomains = [];
    const ydomains = [];

    this.data.forEach((line) => {
      if (!line) {
        return;
      }
      xdomains.push(...line.data.map((d) => new Date(d.label)));
      ydomains.push(...line.data.map((d) => d.value));
    });

    if (this.scale.timeSpan) {
      this.xScale.domain([new Date(Date.now() - this.scale.timeSpan), new Date()]);
    } else {
      if (xdomains.length) {
        this.xScale.domain([new Date(xdomains[0].getTime() - 10 * 60 * 1000), xdomains[0]]);
      } else {
        this.xScale.domain([new Date(Date.now() - 10 * 60 * 1000), new Date()]);
      }
    }

    if (this.scale && this.scale.range) {
      this.yScale.domain(this.scale.range);
    } else {
      const max = extent([0, ...ydomains]);
      this.yScale.domain(max[1] > 0 ? max : [0, 1]);
    }
  }

  calculateLines(): void {
    let offset = [0];

    if (this.data.length === 2) {
      offset = [-1, 1];
    }
    if (this.data.length === 3) {
      offset = [-3, 0, 3];
    }

    this.lines = this.data.map((line, i) => {
      if (!line) {
        return;
      }

      let buffer = '';

      line.alarms = [];
      line.dots = [];
      line.paths = [];
      line.fills = [];

      let firstX = 0;
      let lastX = 0;

      line.data.forEach((point) => {
        if (point.value !== null) {
          const x = this.xScale(this.roundTime(new Date(point.label))) || 0;
          const y = this.yScale(point.value) || 0;

          if (x > 0 && x < this.width) {
            if ('thresholdValue' in this.scale && 'above' in this.scale) {
              const isInAlarm = this.scale.above
                ? point.value > this.scale.thresholdValue
                : point.value < this.scale.thresholdValue;

              if (isInAlarm) {
                line.alarms.push(new Dot(line.series, 3, x, y + offset[i], true, point.label));
              }
            }

            line.dots.push(new Dot(line.series, 3, x, y + offset[i], true, point.label));
          }

          if (buffer.length) {
            lastX = x;
            buffer += 'L' + x + ' ' + (y + offset[i] + ' ');
          } else {
            firstX = x;
            lastX = x;
            buffer = 'M' + x + ' ' + (y + offset[i] + ' ');
          }
        } else {
          if (buffer.length) {
            line.paths.push(buffer);
            line.fills.push(
              buffer + 'L' + lastX + ' ' + this.calculateHeight() + ' L' + firstX + ' ' + this.calculateHeight() + ' Z'
            );
          }
          buffer = '';
          firstX = 0;
          lastX = 0;
        }
      });

      if (buffer.length) {
        line.paths.push(buffer);
        line.fills.push(
          buffer + 'L' + lastX + ' ' + this.calculateHeight() + ' L' + firstX + ' ' + this.calculateHeight() + ' Z'
        );
      }

      return line;
    });
  }

  getXbyValue(input: Date): number {
    return this.xScale(input) || 0;
  }

  getYbyValue(input: Date): number {
    return this.yScale(input) || 0;
  }

  xAxis(): Tick[] {
    return this.xScale.ticks(10).map((tick) => {
      return new Tick(
        'xaxis',
        this.timeExtractor(tick),
        'translate(' + this.xScale(tick) + ', ' + (this.calculateHeight() + 20) + ')',
        { x: 0, dy: 0.32 },
        true,
        { y1: -20, y2: -this.calculateHeight() - 30 }
      );
    });
  }

  xAxisNarrow(): Tick[] {
    return this.xScale.ticks(5).map((tick) => {
      return new Tick(
        'xaxis',
        this.timeExtractor(tick),
        'translate(' + this.xScale(tick) + ', ' + (this.calculateHeight() + 20) + ')',
        { x: 0, dy: 0.32 },
        true,
        { y1: -20, y2: -this.calculateHeight() - 30 }
      );
    });
  }

  yAxis(): Tick[] {
    let symbol = 'Mbps';

    if (this.scale) {
      symbol = this.scale.symbol;
    }

    return this.yScale.ticks(5).map((tick) => {
      return new Tick('yaxis', tick + ' ' + symbol, 'translate(0, ' + this.yScale(tick) + ')', { dx: -5 }, true, {
        x2: this.calculateWidth()
      });
    });
  }

  update(
    data: MultiLine[],
    width?: number,
    height?: number,
    margins?: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    },
    scale?: { symbol: string; timeSpan: number; range: [number, number] }
  ): MultiLine[] {
    this.data = data.filter((n) => (n === null ? false : true));

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }
    if (scale) {
      this.scale = scale;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateLines();

    return this.lines;
  }

  timeExtractor(time: Date): string {
    return new Date(time).toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  roundTime(time: Date): Date {
    const ms = 1000;
    const round = Math.floor(time.getTime() / ms) * ms;

    return new Date(round);
  }

  scaleValue(axis: string, value: Date): number {
    if (axis === 'x') {
      return this.xScale(value);
    } else {
      return this.yScale(value);
    }
  }
}
